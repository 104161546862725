import {TableFilter} from "@/enum/table_filter";

const carrierTypeItems = [
    'CPOST',
    'DPD',
    'DPD_HU',
    'DPD_SK',
    'GLS',
    'WEDO',
    'ZASILKOVNA',
    'PERSONAL_COLLECTION',
    'BOSCH'
].map(type => ({text: type, value: type}));

function CarrierForm() {
    return {
        name: '',
        type: '',
        active: true
    };
}

function CarrierFormRender() {
    return {
        name: {
            icon: '$carrierName',
            max: 50,
            required: true
        },
        type: {
            icon: '$carrierType',
            required: true,
            select: carrierTypeItems
        },
        active: {
            icon: '$carrierActive',
            checkbox: true
        }
    };
}

const carrierTableBase = {
    tableImage: {
        sortable: false
    },
    name: {
        filterType: TableFilter.TEXT
    },
    tableFoilImage: {
        sortable: false
    },
    type: {},
    services: {
        label: 'services.title'
    }
};

const carrierTable = {
    ...carrierTableBase,
    active: {
        filterType: TableFilter.BOOLEAN,
        label: 'active'
    }
};

const carrierTableInSubstock = {
    ...carrierTableBase,
    contracted: {
        filterType: TableFilter.BOOLEAN,
        label: 'contracted.label'
    }
};



export {CarrierForm, CarrierFormRender, carrierTable, carrierTableInSubstock};
