import {TaskDeliveryAcceptAPI} from "@/api/TaskDeliveryAcceptAPI";
import {TaskStockLoadingAPI} from "@/api/TaskStockLoadingAPI";
import {TaskStockTakingAPI} from "@/api/TaskStockTakingAPI";
import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
import {TaskStockPickingSetAPI} from "@/api/TaskStockPickingSetAPI";
import {TaskMoveProductsAPI} from "@/api/TaskMoveProductsAPI";
import {TaskSubstockTransferAPI} from "@/api/TaskSubstockTransferAPI";
import {TaskPreparePackageAPI} from "@/api/TaskPreparePackageAPI";
import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
import {locationLabel} from "@/utils/string";
import {TaskLocationTransferAPI} from "@/api/TaskLocationTransferAPI";

const taskTypes = {
    DELIVERY_ACCEPT: 'DELIVERY_ACCEPT',
    STOCK_LOADING: 'STOCK_LOADING',
    STOCK_TAKING: 'STOCK_TAKING',
    STOCK_PICKING: 'STOCK_PICKING',
    STOCK_PICKING_SET: 'STOCK_PICKING_SET',
    MOVE_PRODUCTS: 'MOVE_PRODUCTS',
    LOCATION_TRANSFER: 'LOCATION_TRANSFER',
    SUBSTOCK_TRANSFER: 'SUBSTOCK_TRANSFER',
    PREPARE_PACKAGE: 'PREPARE_PACKAGE',
    EXTERNAL_ORDER: 'EXTERNAL_ORDER'
};

const taskLangPaths = {
    DELIVERY_ACCEPT: 'tasks.deliveryAccept.',
    STOCK_LOADING: 'tasks.stockLoading.',
    STOCK_TAKING: 'tasks.stockTaking.',
    STOCK_PICKING: 'tasks.stockPicking.',
    STOCK_PICKING_SET: 'tasks.stockPickingSet.',
    MOVE_PRODUCTS: 'tasks.moveProducts.',
    LOCATION_TRANSFER: 'tasks.locationTransfer.',
    SUBSTOCK_TRANSFER: 'tasks.substockTransfer.',
    PREPARE_PACKAGE: 'tasks.preparePackage.',
    EXTERNAL_ORDER: 'tasks.externalOrder.'
};

const taskNames = {
    DELIVERY_ACCEPT: taskLangPaths.DELIVERY_ACCEPT + 'name',
    STOCK_LOADING: taskLangPaths.STOCK_LOADING + 'name',
    STOCK_TAKING: taskLangPaths.STOCK_TAKING + 'name',
    STOCK_PICKING: taskLangPaths.STOCK_PICKING + 'name',
    STOCK_PICKING_SET: taskLangPaths.STOCK_PICKING_SET + 'name',
    MOVE_PRODUCTS: taskLangPaths.MOVE_PRODUCTS + 'name',
    LOCATION_TRANSFER: taskLangPaths.LOCATION_TRANSFER + 'name',
    SUBSTOCK_TRANSFER: taskLangPaths.SUBSTOCK_TRANSFER + 'name',
    PREPARE_PACKAGE: taskLangPaths.PREPARE_PACKAGE + 'name',
    EXTERNAL_ORDER: taskLangPaths.EXTERNAL_ORDER + 'name'
};

const taskIcons = {
    DELIVERY_ACCEPT: '$taskDeliveryAccept',
    STOCK_LOADING: '$taskStockLoading',
    STOCK_TAKING: '$taskStockTaking',
    STOCK_PICKING: '$taskStockPicking',
    STOCK_PICKING_SET: '$taskStockPickingSet',
    MOVE_PRODUCTS: '$taskMoveProducts',
    LOCATION_TRANSFER: '$taskLocationTransfer',
    SUBSTOCK_TRANSFER: '$taskSubstockTransfer',
    PREPARE_PACKAGE: '$taskPreparePackage',
    EXTERNAL_ORDER: '$taskExternalOrder'
};

const taskLinks = {
    DELIVERY_ACCEPT: '/delivery-accept',
    STOCK_LOADING: '/stock-loading',
    STOCK_TAKING: '/stock-taking',
    STOCK_PICKING: '/stock-picking',
    STOCK_PICKING_SET: '/stock-picking-set',
    MOVE_PRODUCTS: '/move-products',
    LOCATION_TRANSFER: '/location-transfer',
    SUBSTOCK_TRANSFER: '/substock-transfer',
    PREPARE_PACKAGE: '/prepare-package',
    EXTERNAL_ORDER: '/external-order'
};

const taskListDetails = {
    DELIVERY_ACCEPT: details => details ? `${details.supplier ? details.supplier.name : ''}` : null,
    STOCK_LOADING: details => details ? `${details.supplier ? details.supplier.name : ''} →
        ${details.subordinate_stock.name}` : null,
    STOCK_PICKING: details => details ? `← ${details.subordinate_stock.name}` : null,
    STOCK_PICKING_SET: details => details ? `← ${details.subordinate_stock.name}` : null,
    MOVE_PRODUCTS: details => details ? `${details.subordinate_stock.name}: 
        ${details.source_location ? details.source_location.name : '*'} → 
        ${details.target_location ? details.target_location.name : '*'}` : null,
    LOCATION_TRANSFER: details => details ? details.stock.name : null,
    SUBSTOCK_TRANSFER: details => details ? `${details.source_subordinate_stock.name} →
        ${details.destination_subordinate_stock.name}` : null,
    PREPARE_PACKAGE: details => details ? locationLabel(details.stock_location) : null,
    EXTERNAL_ORDER: details => details ? (details.carrier_api_error_messages ?
        `❌ ${details.carrier_api_error_messages.join(', ')}` : details.external_note) : null
};

const taskApi = {
    DELIVERY_ACCEPT: TaskDeliveryAcceptAPI,
    STOCK_LOADING: TaskStockLoadingAPI,
    STOCK_TAKING: TaskStockTakingAPI,
    STOCK_PICKING: TaskStockPickingAPI,
    STOCK_PICKING_SET: TaskStockPickingSetAPI,
    MOVE_PRODUCTS: TaskMoveProductsAPI,
    LOCATION_TRANSFER: TaskLocationTransferAPI,
    SUBSTOCK_TRANSFER: TaskSubstockTransferAPI,
    PREPARE_PACKAGE: TaskPreparePackageAPI,
    EXTERNAL_ORDER: TaskExternalOrderAPI
};

const configurableTasks = [
    taskTypes.STOCK_LOADING,
    taskTypes.STOCK_PICKING,
    taskTypes.STOCK_PICKING_SET,
    taskTypes.MOVE_PRODUCTS,
    taskTypes.SUBSTOCK_TRANSFER,
];

export {taskTypes, taskLangPaths, taskNames, taskIcons, taskLinks, taskListDetails, taskApi, configurableTasks};
