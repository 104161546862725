<template>
  <v-layout wrap>
    <v-flex
      v-for="(field, key) in render"
      v-show="field.show === undefined || (typeof field.show === 'function' ? field.show(form) : field.show)"
      :key="key"
      :xl2="!singlePerRow && xlSizing === 12 && lgSizing === 12 && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :xl3="!singlePerRow && xlSizing < 12 && xlSizing > 5 && lgSizing === 12 && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :xl6="!singlePerRow && xlSizing < 6 && xlSizing > 2 && lgSizing === 12 && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :lg3="!singlePerRow && lgSizing === 12 && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :lg6="!singlePerRow && lgSizing < 12 && lgSizing > 5 && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :md4="!singlePerRow && mdSizing === 12 && smSizing === 12 && xsSizing === 12"
      :sm6="!singlePerRow && smSizing === 12 && xsSizing === 12"
      xs12
      class="px-2"
    >
      <slot
        v-if="$scopedSlots[key] !== undefined"
        :fieldRender="field"
        :name="key"
      />
      <component
        :is="field.autocomplete !== undefined && field.autocomplete.allowCustom === true ? 'v-combobox' : 'v-autocomplete'"
        v-else-if="field.autocomplete !== undefined"
        v-show="field.show === undefined || (typeof field.show === 'function' ? field.show(form) : field.show)"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :search-input.sync="field.searchInput"
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :chips="!!field.autocomplete.chips"
        :clearable="!field.sticky"
        :small-chips="field.autocomplete.chips"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :items="field.autocomplete.items"
        :item-text="field.autocomplete.render || 'text'"
        :label="$t(langPath + key)"
        :loading="field.loading"
        :multiple="!!field.autocomplete.multiple"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="(field.rules === undefined ? [] : field.rules)
          .concat(field.max === undefined ? [] : [rules.maxLen(field.max)])
          .concat(field.required !== true ? [] : [rules.required])"
        :suffix="(field.required === true) ? '*' : ''"
        autocomplete="off"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="isChief && field.createNew !== undefined"
          #no-data
        >
          <v-list>
            <v-list-item
              @click="field.createNew.action"
            >
              <v-list-item-avatar>
                <v-icon>{{ field.createNew.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t(field.createNew.label) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template
          v-if="$scopedSlots[key + '-item'] !== undefined"
          #item="{ item }"
        >
          <slot
            :name="key + '-item'"
            :item="item"
          />
        </template>
      </component>
      <v-select
        v-else-if="field.select !== undefined"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :items="field.select"
        :label="$t(langPath + key)"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="(field.rules === undefined ? [] : field.rules)
          .concat(field.required !== true ? [] : [rules.required])"
        :suffix="field.required === true ? '*' : ''"
        autocomplete="off"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="$scopedSlots[key + '-item'] !== undefined"
          #item="{ item }"
        >
          <slot
            :name="key + '-item'"
            :item="item"
          />
        </template>
      </v-select>
      <v-textarea
        v-else-if="field.textarea === true"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        auto-grow
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="$t(langPath + key)"
        :name="key"
        outlined
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="(field.icon)"
        :readonly="field.readonly"
        :rows="1"
        :rules="(field.rules === undefined ? [] : field.rules)
          .concat(field.max === undefined ? [] : [rules.maxLen(field.max)])
          .concat(field.required !== true ? [] : [rules.required])"
        :suffix="field.required === true ? '*' : ''"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      />
      <v-checkbox
        v-else-if="field.checkbox === true"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        color="secondary"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="$t(langPath + key)"
        :name="key"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="(field.icon)"
        :readonly="field.readonly"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      />
      <FormDateTimePicker
        v-else-if="field.datePicker || field.dateTimePicker"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        v-bind="field"
        :label="$t(langPath + key)"
        :name="key"
        :time-picker="!!field.dateTimePicker"
        @input="clearUserErrors"
        @clickHelp="helpClick(field.help)"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </FormDateTimePicker>
      <v-text-field
        v-else
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-icon="field.type === 'password' ? (pwVisible[key] ? '$showItem' : '$hideItem') : undefined"
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :counter="field.max"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="$t(langPath + key)"
        :name="key"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="(field.rules === undefined ? [] : field.rules)
          .concat(field.max === undefined ? [] : [rules.maxLen(field.max)])
          .concat(field.required !== true ? [] : [rules.required])"
        :suffix="field.required === true ? '*' : ''"
        :type="(field.type === undefined ? 'text'
          : (field.type === 'password' ? (pwVisible[key] ? 'text' : 'password')
            : field.type))"
        @change="clearUserErrors"
        @input="clearUserErrors"
        @click:append="pwVisibleClick(key)"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </v-text-field>
    </v-flex>
    <v-dialog
      v-model="helpDialog"
      max-width="590"
    >
      <v-card>
        <v-card-title>
          {{ helpTitle }}
        </v-card-title>
        <v-card-text>
          {{ helpContent }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
    import {VAutocomplete, VCombobox} from "vuetify/lib";
    import rules from "@/utils/formRules";
    import {has} from "@/utils/object";
    import {HelpProvider} from "@/service/HelpProvider";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";

    export default {
        name: "FormFields",
        components: {FormDateTimePicker, VAutocomplete, VCombobox}, // dynamic components have to be manually imported
        mixins: [ACLMixin],
        props: {
            form: {
                type: Object,
                default: () => ({})
            },
            render: {
                type: Object, // TODO document this somewhere
                default: () => ({})
            },
            langPath: {
                type: String,
                default: 'base.'
            },
            singlePerRow: {
                type: Boolean,
                default: false
            },
            xsSizing: {
                type: Number,
                default: 12
            },
            smSizing: {
                type: Number,
                default: 12
            },
            mdSizing: {
                type: Number,
                default: 12
            },
            lgSizing: {
                type: Number,
                default: 12
            },
            xlSizing: {
                type: Number,
                default: 12
            }
        },
        data: () => ({
            rules: rules,
            pwVisible: {},
            helpDialog: false,
            helpKey: null
        }),
        computed: {
            helpTitle: function () {
                const help = HelpProvider.get();
                return has(help, this.helpKey) ? help[this.helpKey].title : '';
            },
            helpContent: function () {
                const help = HelpProvider.get();
                return has(help, this.helpKey) ? help[this.helpKey].content : '';
            },
        },
        createdOrActivated: function () {
            this.fetchAutocompletes();
        },
        methods: {
            fetchAutocompletes: function () { // fetch autocompletes
                for (const input in this.render) {
                    if (has(this.render, input)) {
                        const field = this.render[input];
                        if (has(field, 'autocomplete')) {
                            if (has(field.autocomplete, 'items')) {
                                if (has(field.autocomplete, 'autoFetched') && field.autocomplete.autoFetched) {
                                    // Items has been fetched previously, but we should refresh them in case something has changed
                                    this.actuallyFetchAutocompletes(field);
                                } else {
                                // Nothing to do here, items are handled from the component
                                }
                            } else {
                                if (field.readonly === true && !field.forceFetch) {
                                // Nothing to do here, item is disabled so we will not load possible items
                                } else {
                                    this.actuallyFetchAutocompletes(field);
                                }
                            }
                            // Regardless of how items are loaded, watch them and when there is exactly one item, set it as selected
                            this.$watch(() => field.autocomplete.items
                                            && field.autocomplete.items.length === 1
                                            && field.autocomplete.items[0].value,
                                        setFirst => {
                                            if (setFirst) {
                                                /* eslint-disable-next-line vue/no-mutating-props */
                                                this.form[input] = field.autocomplete.multiple ? [setFirst] : setFirst;
                                            }
                                        }
                            );
                        }
                    }
                }
            },
            actuallyFetchAutocompletes: function (field) {
                this.$set(field, 'loading', true);
                field.autocomplete.callFn()
                    .then(response => {
                        const items = field.autocomplete.thenFn(response);
                        if (field.prepend) {
                            items.unshift(field.prepend);
                        }
                        this.$set(field.autocomplete, 'items', items);
                        this.$set(field.autocomplete, 'autoFetched', true);
                    }).catch(err => {
                        if (typeof field.autocomplete.catchFn === 'function') {
                            field.autocomplete.catchFn(err);
                        } else {
                            this.snack(err);
                        }
                    }).finally(() => {
                        this.$set(field, 'loading', false);
                    });
            },
            clearUserErrors: function () {
                for (const item in this.render) {
                    if (has(this.render, item)) {
                        delete this.render[item].errors;
                    }
                }
            },
            pwVisibleClick: function (key) {
                if (this.pwVisible[key] === undefined) {
                    this.$set(this.pwVisible, key, true);
                } else {
                    this.$set(this.pwVisible, key, !this.pwVisible[key]);
                }
            },
            helpClick: function (helpKey) {
                this.helpKey = helpKey;
                this.helpDialog = true;
            }
        }
    };

// TODO multiple select all https://vuetifyjs.com/en/components/selects#prepend-append-item-slots
</script>

<style scoped>

</style>
