<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-layout wrap>
        <v-flex
          :lg8="detailsMode"
          xs12
        >
          <ShipmentsList
            :shipment-id="shipmentId"
          />
        </v-flex>
        <v-flex
          v-if="detailsMode && !isSmall"
          lg4
        >
          <ShipmentDetail
            :shipment="shipments[shipmentId]"
            closeable
            @close="detailsMode = false"
          />
          <OrderDetail
            :order="shipments[shipmentId] ? orders[shipments[shipmentId].external_order_id] : null"
            :shipment="shipments[shipmentId]"
            class="mt-2"
          />
        </v-flex>
        <v-dialog
          v-if="isSmall"
          v-model="detailsMode"
          width="500"
        >
          <v-card>
            <ShipmentDetail
              :shipment="shipments[shipmentId]"
            />
            <OrderDetail
              :order="shipments[shipmentId] ? orders[shipments[shipmentId].external_order_id] : null"
              :shipment="shipments[shipmentId]"
              class="mt-2"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import ShipmentsList from "@/app/overview/shipping/components/ShipmentsList.component";
    import ShipmentDetail from "@/app/components/details/ShipmentDetail.component";
    import OrderDetail from "@/app/components/details/OrderDetail.component";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {has} from "@/utils/object";

    export default {
        name: "Shipping",
        components: {ShipmentDetail, ShipmentsList, OrderDetail},
        data: () => ({
            shipments: {},
            orders: {}
        }),
        computed: {
            isSmall: function () {
                return this.$vuetify.breakpoint.mdAndDown;
            },
            shipmentId: function () {
                return +this.$route.query.shipmentId;
            },
            detailsMode: {
                get: function () {
                    return !isNaN(this.shipmentId);
                },
                set: function (newVal) {
                    if (!newVal) {
                        this.$router.push('/shipping');
                    }
                }
            }
        },
        watch: {
            shipmentId: {
                handler: function () {
                    if (this.shipmentId) {
                        let promise = Promise.resolve();
                        if (!has(this.shipments, this.shipmentId)) {
                            promise = ShipmentAPI.get(this.shipmentId)
                                .then(response => {
                                    this.$set(this.shipments, this.shipmentId, response.data);
                                });
                        }
                        promise.then(() => {
                            const externalOrderId = this.shipments[this.shipmentId].external_order_id;
                            if (!has(this.orders, externalOrderId)) {
                                TaskExternalOrderAPI.get(externalOrderId).then(response => {
                                    this.$set(this.orders, externalOrderId, response.data);
                                }).catch(this.snack);
                            }
                        }).catch(err => {
                            this.snack(err);
                            this.detailsMode = false;
                        });
                    }
                },
                immediate: true
            }
        },
        createdOrActivated: function () {
            this.shipments = {};
            this.orders = {};
        }
    };
</script>

<style scoped>

</style>
