import { render, staticRenderFns } from "./TaskAttachmentUpload.component.vue?vue&type=template&id=49c52c38&scoped=true&"
import script from "./TaskAttachmentUpload.component.vue?vue&type=script&lang=js&"
export * from "./TaskAttachmentUpload.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c52c38",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VChip,VChipGroup,VFileInput,VProgressCircular})
