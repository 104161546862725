import {APIFilterDataType, APIFilterOP} from "@/service/APIFilters";
import {APIFilters} from "@/service/APIFilters";

const APIFilterMixin = {
    props: {
        filter: {
            type: [Object],
            default: null
        },
        possibleValues: {
            type: Object,
            default: () => ({})
        },
        langPath: {
            type: String,
            default: ''
        },
        hardcodedAttribute: {
            type: String,
            default: null
        },
        allowedFilterTypes: {
            type: Array,
            default: null
        }
    },
    computed: {
        filterType: function () {
            if (Array.isArray(this.filter)) {
                return APIFilterOP.AND;
            }
            return Object.keys(this.filter)[0];
        },
        operator: function () {
            return Object.keys(this.filter)[0];
        },
        items: function () {
            return Object.keys(this.possibleValues).filter(key => {
                return APIFilters.isFilterOPAllowedForType(this.filterType, this.possibleValues[key].type);
            }).map(key => ({
                text: this.hardcodedAttribute ? '' : this.$t(this.langPath + key + '.name'),
                value: key
            }));
        },
        newType: function () {
            if (this.allowedFilterTypes === null) {
                return APIFilterOP.EQUALS;
            } else {
                return this.allowedFilterTypes.find(filter => [APIFilterOP.AND, APIFilterOP.OR].indexOf(filter) === -1);
            }
        }
    },
    methods: {
        getDefaultTypeValue: function (value) {
            value = this.possibleValues[value];
            if (Array.isArray(value.values)) {
                return value.values[0].value;
            } else {
                switch (value.type) {
                    case APIFilterDataType.NUMBER:
                        return 0;
                    case APIFilterDataType.DATE:
                        return this.$moment().startOf('day').format();
                    default:
                        return '';
                }
            }
        },
    }
};

export {APIFilterMixin};
