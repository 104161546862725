import {TableFilter} from "@/enum/table_filter";
import i18n from "@/service/lang/i18n";
import {has, unfold} from "@/utils/object";

/**
 * @param keys {{
 *     key: { // optional fields:
 *         label: {string} (table header translation path)
 *         filterType: TableFilter (one of enum value)
 *         sortable: {Boolean}
 *         itemLabel: {Function} (function which displays item in table)
 *         autocomplete: {
 *             callFn: {Function}
 *             thenFn: {Function}
 *             catchFn: {Function}
 *         }
 *     }
 * }}
 * @param langPath {string}
 * @param includeActions {boolean}
 * @param showExpand {boolean}
 * @param otherHeaders {Object}
 * When autocomplete filters are used, this function has to be called once - in createdOrActivated hook.
 * Otherwise, it is recommended to use as computed
 * @returns {Object[]}
 */
function createHeaders(keys, langPath = '', includeActions = true, showExpand = false, ...otherHeaders) {
    return [
        ...Object.keys(keys).map(key => {
            const header = {
                text: i18n.t(langPath + (has(keys[key], 'label') ? keys[key].label : key)),
                value: key,
                align: keys[key].align,
                filterType: keys[key].filterType,
                filterAction: keys[key].filterAction,
                filterAllowCustom: keys[key].filterAllowCustom,
                sortable: keys[key].sortable || keys[key].sortable === undefined,
                sortBy: keys[key].sortBy,
                itemLabel: keys[key].itemLabel !== undefined ? keys[key].itemLabel : (item => unfold(item, key)),
                noDataText: keys[key].noDataText
            };
            if ([TableFilter.SELECT, TableFilter.SELECT_MULTIPLE, TableFilter.ARRAY_CONTAINS, TableFilter.ARRAY_CONTAINS_MULTIPLE].includes(header.filterType)) {
                if (keys[key].filterItems !== undefined) {
                    header.filterItems = keys[key].filterItems;
                } else {
                    header.filterItems = [];
                    header.loading = true;
                    const catchFn = keys[key].autocomplete.catchFn;
                    keys[key].autocomplete.callFn()
                        .then(response => {
                            header.filterItems = keys[key].autocomplete
                                .thenFn(response)
                                .sort((a, b) => 0.5 - (a.text < b.text)); // Sort everything by its label
                        }).catch(err => {
                        if (typeof catchFn === 'function') {
                            catchFn(err);
                        } // TODO snack
                    }).finally(() => {
                        header.loading = false;
                    });
                }
            }
            return header;
        }),
        ...otherHeaders,
        ...includeActions ? [actionsHeader()] : [],
        ...showExpand ? [expandHeader()] : []
    ];
}

function actionsHeader () {
    return {
        text: i18n.t('base.actions'),
        value: 'x_actions',
        align: 'center',
        sortable: false
    };
}

function expandHeader () {
    return {
        value: 'data-table-expand'
    };
}

export {createHeaders, actionsHeader};
