import {BaseAPI} from "@/api/BaseAPI";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const TaskExternalOrderAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/external-order',
    LANG: 'tasks.externalOrder',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     */
    getAll(...params) {
        return this.API.get(
            this.DOMAIN,
            this.LANG_TASKS,
            merge(...params)
        );
    },

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    updateProcessingMode(taskId, processingMode) {
        return this.API.patch(
            [this.DOMAIN, taskId],
            { processing_mode: processingMode },
            [this.LANG, 'update']
        );
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    getByBuyer(buyerId) {
        return this.API.get(
            [this.DOMAIN, 'by-buyer', buyerId],
            this.LANG_TASKS
        );
    },

    updateSellPrice(taskId, itemId, sell_price_per_unit, price_vat) {
        return this.API.patch(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            {sell_price_per_unit, price_vat},
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    deleteItem(taskId, itemId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'assign'],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'unassign'],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    approve(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, 'approve'],
            data,
            [this.LANG, 'approve']
        );
    },

    close(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, 'close'],
            data,
            [this.LANG_TASKS, 'finish']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'cancel'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },
};

export {TaskExternalOrderAPI};
