<template>
  <v-card>
    <v-list>
      <template
        v-for="(task, index) of tasks"
      >
        <TaskListTask
          :key="index"
          :icon="taskIcons[task.type]"
          :header="'#' + task.id + ' ' + $t(taskNames[task.type])"
          :content="task.description"
          :sub-content="taskListDetails[task.type] ? taskListDetails[task.type](task.details) : null"
          :action-text="(task.updated_at || task.created_at) | momentAgo"
          :action-text-tooltip="tooltip(task)"
          :priority="task.priority"
          :to="taskLinks[task.type] + '/' + task.id"
        />
        <v-divider
          v-if="index !== tasks.length - 1"
          :key="'d' + index"
        />
      </template>
    </v-list>
  </v-card>
</template>

<script>
    import {taskIcons, taskNames, taskLinks, taskListDetails} from "@/enum/task_type";
    import TaskListTask from "@/app/tasks/components/taskList/TaskListTask.component";

    export default {
        name: "TaskListList",
        components: {TaskListTask},
        props: {
            tasks: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            taskIcons: taskIcons,
            taskNames: taskNames,
            taskLinks: taskLinks,
            taskListDetails: taskListDetails
        }),
        methods: {
            tooltip: function (task) {
                return this.$t('tasks.created_at') + ': ' + this.$options.filters.dateTime(task.created_at) + '<br>'
                    + (task.updated_at ? (this.$t('tasks.updated_at') + ': ' + this.$options.filters.dateTime(task.updated_at)) : '');
            }
        }
    };
</script>

<style scoped lang="sass">
.v-list
    padding: 0
</style>
