<template>
  <v-card
    class="ma-1"
  >
    <v-container>
      <v-icon class="mb-1">
        {{ actionIcons[action] }}
      </v-icon>
      <span class="text-subtitle-1 ml-2">
        {{ actionLabel }}
      </span>
      <v-tooltip
        v-if="finishAndApprove"
        top
      >
        <template #activator="{ on }">
          <v-icon
            class="mb-1 ml-1"
            v-on="on"
          >
            $hint
          </v-icon>
        </template>
        <span>
          {{ $t('tasks.finishAndApproveHint') }}
        </span>
      </v-tooltip>
      <v-divider
        class="my-2"
      />
      <FormFields
        v-if="action !== 'unassign'"
        :form="form"
        :render="formRender"
        lang-path="tasks."
        single-per-row
      />
      <div>
        <v-checkbox
          v-if="storekeeperOptions"
          v-model="keep_storekeeper"
          :label="$t('tasks.keep_storekeeper')"
        />
        <v-tooltip
          :disabled="!actionDisabled"
          top
        >
          <template #activator="{ on }">
            <span v-on="on">
              <v-btn
                :loading="loading"
                :disabled="actionDisabled"
                type="submit"
                color="accent"
                @click="submit()"
              >
                <v-icon
                  class="mr-2"
                >
                  {{ actionIcons[action] }}
                </v-icon>
                {{ actionLabel }}
              </v-btn>
            </span>
          </template>
          {{ $te('tasks.' + action + '.disabled') ? $t('tasks.' + action + '.disabled') : '' }}
        </v-tooltip>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {configurableTasks, taskNames, taskTypes} from "@/enum/task_type";
    import {Note} from "@/app/tasks/definitions/taskCommon.render.form";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {actionIcons} from "@/enum/icons";
    import {TaskState} from "@/enum/task_state";
    import * as Export from "@/service/Export";
    import {EventBus} from "@/service/EventBus";
    import {ReportType} from "@/enum/report_type";
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "TaskSimpleStateChangeCard",
        components: {FormFields},
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            taskType: {
                type: String,
                default: undefined
            },
            api: {
                type: Object,
                default: undefined
            },
            // one of values: assign, approve, finish, cancel or unassign
            action: {
                type: String,
                default: 'finish'
            },
            finishAndApprove: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            form: {
                note: null
            },
            formRender: {
                note: new Note,
            },
            actionIcons: actionIcons,
            keep_storekeeper: true,
            actionDisabled: false
        }),
        computed: {
            taskTypeLocal: function () {
                return this.taskType || this.taskInfo.details.type;
            },
            taskId: function () {
                return this.taskInfo.details.id;
            },
            storekeeperOptions: function () {
                return this.action === 'reject' && this.taskTypeLocal !== taskTypes.DELIVERY_ACCEPT;
            },
            actionLabel: function () {
                if (this.finishAndApprove === true) {
                    return this.$t('tasks.finishAndApprove');
                }
                return this.$t('tasks.' + this.action + '.name');
            }
        },
        watch: {
            'taskInfo.tab': {
                immediate: true,
                handler: function () {
                    if (this.action === 'finish' || this.action === 'approve') {
                        this.actionDisabled = false;
                        EventBus.$emit('check-items-on-locations', () => this.actionDisabled = true);
                    } else if (this.action === 'unassign') {
                        this.actionDisabled = false;
                        EventBus.$emit('check-items-in-inventory', () => this.actionDisabled = true);
                    }
                }
            }
        },
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            submit: function () {
                this.loading = true;
                const timeSpent = this.$store.getters['time/currentHours'](Date.now());
                const taskId = this.taskId;
                if (this.finishAndApprove === true) {
                    this.sendToApi('finish', timeSpent, this.form.note)
                        .then(() => {
                            this.api.get(taskId, true)
                                .then(response => {
                                    if (response.data.state === TaskState.WAITING_FOR_APPROVAL) {
                                        this.sendToApi('approve')
                                            .then(() => {
                                                this.closeProcedure('approve');
                                            });
                                    } else {
                                        this.closeProcedure('finish');
                                    }
                                }).catch(this.snack);
                        });
                } else {
                    this.sendToApi(this.action, timeSpent, this.form.note)
                        .then(() => {
                            this.closeProcedure(this.action);
                        });
                }
            },
            sendToApi: function (action, time, note) {
                return this.api[action](this.taskId, time, note, !this.keep_storekeeper)
                    .catch(err => {
                        setFormErrors.call(this, err);
                        this.loading = false;
                        return Promise.reject(err);
                    });
            },
            closeProcedure: function (action) {
                if (this.api.report !== undefined) {
                    this.api.get(this.taskId, true)
                        .then(response => {
                            const {state, subordinate_stock} = response.data;
                            if (state === TaskState.CLOSED) {
                                StockAPI.getSubStockTaskSettingsConfig(subordinate_stock.stock_id, subordinate_stock.id)
                                    .then(tasksConfig => {
                                        const taskConfig = tasksConfig.data.find(setting => setting.task_type === this.taskTypeLocal);
                                        if (!configurableTasks.includes(this.taskTypeLocal) || (taskConfig && taskConfig.auto_print_report)) {
                                            Export.report(this.api, this.taskId, ReportType.PDF).catch(this.snack);
                                        }
                                    });
                            }
                        }).catch(this.snack);
                }
                if (this.taskTypeLocal !== undefined) {
                    this.$store.dispatch('time/stop');
                    this.advancedSnack({
                        text: 'tasks.' + action + '.done' + (this.finishAndApprove ? 'Total' : ''),
                        params: [
                            this.taskId,
                            this.$t(taskNames[this.taskTypeLocal])
                        ]
                    });
                }
                this.loading = false;
                this.$router.push('/');
            }
        }
    };
</script>

<style scoped>

</style>
