import {Roles} from "@/enum/roles";
import StockStatus from "@/app/overview/status/StockStatus.view";
import StockLocationsStatus from "@/app/overview/status/StockLocationsStatus.view";

const BASE_URL = '/status';

const routes = {
    base: {
        path: BASE_URL,
        component: StockStatus,
        meta: {
            title: 'stocks.stockStatus.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    report: {
        path: BASE_URL + '/locations',
        component: StockLocationsStatus,
        meta: {
            title: 'stocks.stockStatus.locations.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }

    }
};

export default Object.values(routes);
