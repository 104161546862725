import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const TaskStockTakingAPI =  {

    API: BaseAPI,
    DOMAIN: 'tasks/stock-taking',
    LANG: 'tasks.stockTaking',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG_TASKS
        );
    },

    report(taskId, acceptType) {
        return this.API.get(
            [this.DOMAIN, taskId, 'print-detail'],
            this.LANG_TASKS,
            undefined,
            {
                headers: {
                    'Accept': acceptType
                }
            }
        );
    },

    approve(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'approve'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'approve']
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'assign'],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'cancel'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'finish'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },

    reject(taskId, hours, note, withdraw_storekeeper) {
        return this.API.post(
            [this.DOMAIN, taskId, 'reject'],
            {
                hours: hours,
                note: note,
                withdraw_storekeeper: withdraw_storekeeper
            },
            [this.LANG_TASKS, 'reject']
        );
    },



    // Items

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            this.LANG_TASKS
        );
    },

    /**
     * @throws 'PUT' Which means you should use 'updateItem' instead, because given product is already stocked
     */
    createItem(taskId, stock_location_id, product_instance_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            {
                stock_location_id: stock_location_id,
                product_instance_id: product_instance_id,
                quantity: quantity
            },
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    updateItem(taskId, itemId, quantity) {
        return this.API.put(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            {quantity: quantity},
            [this.LANG, this.DOMAIN_ITEMS, 'update']
        );
    },

    deleteItem(taskId, itemId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS, 'update']
        );
    }
};

export {TaskStockTakingAPI};
