import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

const ShipmentAPI = {

    API: BaseAPI,
    DOMAIN: 'shipments',
    DOMAIN_PACKAGE: 'packages',
    DOMAIN_REPORT: 'reports',
    DOMAIN_CARRIER: 'carriers',
    DOMAIN_CONTRACTED: 'contracted-for',
    DOMAIN_PROTOCOL: 'protocols',
    LANG: 'base.api.shipments',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(
            this.DOMAIN,
            this.LANG,
            merge(...params)
        );
    },

    getAllPages(...params) {
        return APIHelper.getAllPages(
            this.DOMAIN,
            this.LANG,
            merge(...params)
        );
    },

    get(shipmentId) {
        return this.API.get(
            [this.DOMAIN, shipmentId],
            this.LANG
        );
    },

    getReport(subStockId, ...params) {
        return this.API.get(
            [this.DOMAIN_REPORT, this.DOMAIN, subStockId],
            this.LANG,
            merge(...params)
        );
    },

    getReportAllPages(subStockId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN_REPORT, this.DOMAIN, subStockId],
            this.LANG,
            merge(...params)
        );
    },

    /**
     * @param shipmentId
     * @param data {{total_value: undefined, total_weight: undefined}}
     * @return {Promise<AxiosResponse<*>|never>}
     */
    updateShipment(shipmentId, data) {
        return this.API.patch(
            [this.DOMAIN, shipmentId],
            data,
            this.LANG
        );
    },

    /**
     * @polling
     * @param shipmentId {number}
     * @return {Promise<any>}
     */
    printLabel(shipmentId) {
        return APIHelper.polling(
            this.API.post.bind(
                this.API,
                [this.DOMAIN, shipmentId, 'print-label'],
                undefined,
                [this.LANG]
            ), 7);
    },

    printDetail(shipmentIds, carrierId, subStockId) {
        return this.API.post(
            [this.DOMAIN, 'print-detail'],
            {
                shipments: shipmentIds,
                carrier_id: carrierId,
                subordinate_stock_id: subStockId
            },
            [this.LANG]
        );
    },

    // protocols

    getAllProtocols(stockId, ...params) {
        return this.API.get(
            [this.DOMAIN, 'stocks', stockId, this.DOMAIN_PROTOCOL],
            [this.LANG, this.DOMAIN_PROTOCOL, 'getAll'],
            merge(...params)
        );
    },

    getAllProtocolsAllPages(stockId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, 'stocks', stockId, this.DOMAIN_PROTOCOL],
            [this.LANG, this.DOMAIN_PROTOCOL, 'getAll'],
            merge(...params)
        );
    },

    /**
     * @polling
     * @param carrierId {number}
     * @param ownerId {number}
     * @param protocolId {number}
     * @returns {Promise<asy>}
     */
    getProtocol(carrierId, ownerId, protocolId) {
        return APIHelper.polling(
            this.API.get.bind(
                this.API,
                [this.DOMAIN, this.DOMAIN_CARRIER, carrierId, this.DOMAIN_CONTRACTED, ownerId, this.DOMAIN_PROTOCOL, protocolId],
                [this.LANG, this.DOMAIN_PROTOCOL]
            ),
            10
        );
    },

    createProtocol(carrierId, ownerId, subStockId, shipmentIds, checkWaitingState = true) {
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_CARRIER, carrierId, this.DOMAIN_CONTRACTED, ownerId, this.DOMAIN_PROTOCOL],
            {
                subordinate_stock_id: subStockId,
                shipment_ids: shipmentIds,
                check_waiting_state: checkWaitingState
            },
            [this.LANG, this.DOMAIN_PROTOCOL, 'create']
        );
    },


    sendShippingInformation(shipmentId) {
        return this.API.post(
            [this.DOMAIN, shipmentId, 'send-shipping-information'],
            null,
            this.LANG
        );
    },

    markAsSend(shipmentId) {
        return this.API.post(
            [this.DOMAIN, shipmentId, 'mark-as-sent'],
            null,
            this.LANG
        );
    },

    addPackage(shipmentId, quantity = 1) {
        return this.API.post(
            [this.DOMAIN, shipmentId, this.DOMAIN_PACKAGE],
            {quantity: quantity},
            [this.LANG, this.DOMAIN_PACKAGE]
        );
    },

    getAllPackages(shipmentId) {
        return this.API.get(
            [this.DOMAIN, shipmentId, this.DOMAIN_PACKAGE],
            [this.LANG, this.DOMAIN_PACKAGE]
        );
    },

    getPackage(shipmentId, packageId) {
        return this.API.get(
            [this.DOMAIN, shipmentId, this.DOMAIN_PACKAGE, packageId],
            [this.LANG, this.DOMAIN_PACKAGE]
        );
    },

    removePackage(shipmentId, packageId) {
        return this.API.delete(
            [this.DOMAIN, shipmentId, this.DOMAIN_PACKAGE, packageId],
            [this.LANG, this.DOMAIN_PACKAGE]
        );
    },
};

export {ShipmentAPI};
