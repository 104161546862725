<template>
  <div>
    <div>
      <span>
        <span class="text-caption">{{ $t('base.changes.created_by') }}</span>
        <span v-if="changes.created_at !== null">
          {{ created_by_user | fullName }}
          <DateTimeWithTooltip :date-time="changes.created_at" />
        </span>
      </span>
    </div>
    <div v-if="changes.updated_at !== null && changes.updated_by !== null">
      <span>
        <span class="text-caption">{{ $t('base.changes.updated_by') }}</span>
        {{ updated_by_user | fullName }}
        <DateTimeWithTooltip :date-time="changes.updated_at" />
      </span>
    </div>
    <div v-else>
      {{ $t('base.changes.noUpdates') }}
    </div>
  </div>
</template>

<script>
    import {UserAPI} from "@/api/UserAPI";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";

    export default {
        name: "EntityHistory",
        components: {DateTimeWithTooltip},
        props: {
            changes: {
                type: Object,
                default: () => ({
                    created_at: null,
                    created_by: null,
                    updated_at: null,
                    updated_by: null
                })
            }
        },
        data: () => ({
            created_by_user: null,
            updated_by_user: null
        }),
        computed: {
            created_by: function () {
                if (this.changes) {
                    return this.changes.created_by;
                } else {
                    return null;
                }
            },
            updated_by: function () {
                if (this.changes) {
                    return this.changes.updated_by;
                } else {
                    return null;
                }
            }
        },
        watch: {
            created_by: function () {
                return this.fetchCreatedDetails();
            },
            updated_by: function () {
                return this.fetchUpdatedDetails();
            }
        },
        created: function () {
            if (this.changes.created_by !== null) {
                this.fetchCreatedDetails();
            }
            if (this.changes.updated_by !== null) {
                this.fetchUpdatedDetails();
            }
        },
        methods: {
            fetchCreatedDetails: function () {
                UserAPI.get(this.created_by)
                    .then(response => {
                        this.created_by_user = response.data;
                    }).catch(/* do not show error */ () => {
                    });
            },
            fetchUpdatedDetails: function () {
                UserAPI.get(this.updated_by)
                    .then(response => {
                        this.updated_by_user = response.data;
                    }).catch(/* do not show error */ () => {
                    });
            }
        }
    };
</script>

<style scoped>

</style>
