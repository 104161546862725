<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent
    >
      <FormFields
        ref="fields"
        :form="form"
        :render="formRender"
        :lang-path="langPath"
        :single-per-row="true"
      />
      <v-subheader>
        {{ $t(langPath + 'strategyPriority') }}:
      </v-subheader>
      <v-sheet
        v-for="(strategy, index) of listStrategies"
        :key="strategy.name"
        class="d-flex mb-2 overflow-y-auto"
      >
        <v-item-group
          class="d-flex flex-column"
        >
          <v-btn
            icon
            color="secondary"
            :disabled="index === 0 || !strategy.enabled"
            @click="moveUp(index)"
          >
            <v-icon>
              $moveItemUp
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="secondary"
            :disabled="index === formStrategies.length - 1 || !strategy.enabled"
            @click="moveDown(index)"
          >
            <v-icon>
              $moveItemDown
            </v-icon>
          </v-btn>
        </v-item-group>
        <div class="d-flex flex-row align-center">
          <v-checkbox
            :input-value="strategy.enabled"
            color="secondary"
            :label="strategyLabel(index, strategy)"
            @change="changeStrategyEnabled($event, strategy.name)"
          />
        </div>
      </v-sheet>
      <v-btn
        :text="!valid"
        :loading="loading"
        type="submit"
        color="accent"
        @click="submit()"
      >
        <v-icon
          class="mr-2"
        >
          $saveItem
        </v-icon>
        {{ $t('form.save') }}
      </v-btn>
    </v-form>
    <div
      v-if="false /* #16230 */"
    >
      <v-divider class="my-4" />
      <v-btn
        class="accent"
        :loading="loading"
        @click="runNow"
      >
        {{ $t(langPath + 'runNow') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {
        OrderProcessingForm,
        OrderProcessingFormRender
    } from "@/app/stocks/substocks/definitions/orderProcessing.form";
    import {setFormErrors} from "@/utils/form";

    export default {
        name: "StockSubstockOrderProcessing",
        components: {FormFields},
        mixins: [RouteParamsMapperMixin, FormFetchItemMixin],
        data: () => ({
            form: new OrderProcessingForm,
            formRender: new OrderProcessingFormRender,
            valid: true,
            loading: false,
            langPath: 'stocks.substocks.order_processing.',
            formStrategies: [],
            allStrategies: []
        }),
        computed: {
            listStrategies: function () {
                return [
                    ...this.formStrategies.map(name => ({
                        enabled: true,
                        name
                    })),
                    ...this.allStrategies.filter(name => !this.formStrategies.includes(name)).map(name => ({
                        enabled: false,
                        name
                    }))
                ];
            },
            isEdit: function () {
                return true;
            }
        },
        createdOrActivated: function () {
            StockAPI.getAllOrderProcessingStrategies()
                .then(response => {
                    this.allStrategies = response.data;
                });
        },
        methods: {
            formFetchItem: function () {
                return StockAPI.getSubStockOrderProcessingConfig(this.stockId, this.subStockId)
                    .then(response => {
                        this.formStrategies = response.data.strategies;
                        return response;
                    });
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.form.strategies = this.formStrategies;
                StockAPI.updateSubStockOrderProcessingConfig(this.stockId, this.subStockId, this.form)
                    .then(() => {
                        this.snack(this.langPath + 'updateDone');
                    }).catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });

            },
            runNow: function () {
                this.loading = true;
                StockAPI.runSubStockOrderProcessing(this.stockId, this.subStockId)
                    .then(() => this.snack(this.langPath + "run.status.204"))
                    .catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            strategyLabel: function (index, {name, enabled}) {
                return (enabled ? (index + 1 + '. ') : '')
                    + this.$t(`${this.langPath}strategy.${name}`);
            },
            moveUp: function (index) {
                const strategy = this.formStrategies.splice(index, 1);
                this.formStrategies.splice(index - 1, 0, strategy[0]);
            },
            moveDown: function (index) {
                const strategy = this.formStrategies.splice(index, 1);
                this.formStrategies.splice(index + 1, 0, strategy[0]);
            },
            changeStrategyEnabled: function (enabled, name) {
                if (enabled) {
                    this.formStrategies.push(name);
                } else {
                    this.formStrategies.splice(this.formStrategies.indexOf(name), 1);
                }
            }
        }
    };
</script>

<style scoped>

</style>
