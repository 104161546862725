import {PrintType} from "@/enum/print_type";

const ReportType = {
    PDF: {
        mime: 'application/pdf',
        suffix: 'pdf',
        print: PrintType.PDF
    },
    HTML: {
        mime: 'text/html',
        suffix: 'html',
        print: PrintType.HTML
    },
    CSV: {
        mime: 'text/csv',
        suffix: 'csv',
        print: null // not supported
    }
};

const ReportPrintTypes = [ReportType.HTML, ReportType.PDF]; // The order matters

export {ReportType, ReportPrintTypes};
