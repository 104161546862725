import {taskIcons, taskLinks, taskNames, taskTypes} from "@/enum/task_type";

const availableTasks = [
    taskTypes.STOCK_LOADING,
    taskTypes.MOVE_PRODUCTS,
    taskTypes.LOCATION_TRANSFER,
    taskTypes.SUBSTOCK_TRANSFER,
    taskTypes.STOCK_PICKING,
    taskTypes.STOCK_PICKING_SET,
    taskTypes.STOCK_TAKING
];

const actionsChief = availableTasks.map(taskType => ({
        icon: taskIcons[taskType],
        label: taskNames[taskType],
        to: taskLinks[taskType] + '/create'
    })
);

export {actionsChief};
