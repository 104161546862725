var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-toolbar'),_c('v-container',[_c('v-card',{staticClass:"mb-2"},[_c('v-container',[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"stocks.stockChoose.form."}})],1)],1),(_vm.form.stockId && _vm.form.subStockId)?_c('v-card',[_c('x-data-table',{attrs:{"headers":_vm.headers,"actions":_vm.actions,"items":_vm.items,"loading":_vm.loading,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload,"search-hint":_vm.$t('base.shipping.report.table.searchHint')},on:{"update:items":function($event){_vm.items=$event},"update:loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"item.external_order_task_id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(item.external_order_task_id)?_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_task_id}},[_vm._v(" #"+_vm._s(item.external_order_task_id)+" ")]):_vm._e()],1)]}},{key:"item.external_order_states",fn:function(ref){
var item = ref.item;
return _vm._l((item.external_order_states),function(state){return _c('v-chip',{key:state,staticClass:"ma-1",attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.state.' + state))+" ")])})}},{key:"item.buyer_name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":'/buyers/' + item.buyer_id}},[_vm._v(" "+_vm._s(item.buyer_name)+" ")])],1)]}},{key:"item.eshop_created_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.eshop_created_at,"abs-date-first":true}})]}},{key:"item.wms_created_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.wms_created_at,"abs-date-first":true}})]}},{key:"item.work_started_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.work_started_at,"abs-date-first":true}})]}},{key:"item.work_ended_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.work_ended_at,"abs-date-first":true}})]}},{key:"item.carrier_accepted_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.carrier_accepted_at,"abs-date-first":true}})]}},{key:"item.carrier_delivered_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.carrier_delivered_at,"abs-date-first":true}})]}},{key:"item.shipment_state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('base.shipping.shipment.state.' + item.shipment_state))+" ")]}}],null,false,3912724341)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }