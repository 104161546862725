<template>
  <div>
    <v-menu
      v-model="dialog"
      :close-on-content-click="false"
      :max-width="(timePicker ? 2 : 1) * (290 + 2*4)"
    >
      <template #activator="{ on }">
        <v-text-field
          :ref="name"
          :append-outer-icon="help !== undefined ? 'help_outline' : ''"
          :clearable="!sticky"
          :dense="single"
          :disabled="readonly"
          :error-messages="errors"
          :hide-details="single"
          :hint="$t(hint)"
          :label="label"
          :name="name"
          :outlined="single"
          :persistent-hint="hint !== undefined"
          :prepend-icon="single ? null : (icon || '$datePick')"
          readonly
          :rules="(rules === undefined ? [] : rules)
            .concat(required !== true ? [] : [rulesImpl.required])"
          :suffix="required === true ? '*' : ''"
          :value="value ? $moment(value).format(dateFormat) : ''"
          v-on="on"
          @click:clear="onClickClear"
          @click:append-outer.stop="$emit('clickHelp')"
        >
          <template #append-outer>
            <slot name="append-outer" />
          </template>
        </v-text-field>
      </template>
      <v-sheet class="d-flex flex-row flex-wrap">
        <v-date-picker
          v-model="localDateValue"
          show-current
          class="pa-1"
        />
        <v-time-picker
          v-if="timePicker"
          v-model="localTimeValue"
          format="24hr"
          use-seconds
          class="pa-1"
        />
        <v-btn
          text
          block
          @click="onConfirm"
        >
          <v-icon>
            check
          </v-icon>
        </v-btn>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
    import rulesImpl from "@/utils/formRules";
    import {DATE_DISPLAY_FORMAT, DATETIME_DISPLAY_FORMAT, isoFromDateTime} from "@/utils/datetime";

    export default {
        // TODO handle validation
        name: "FormDateTimePicker",
        props: {
            errors: {
                type: Array,
                default: () => []
            },
            help: {
                type: String,
                default: undefined
            },
            hint: {
                type: String,
                default: undefined
            },
            icon: {
                type: String,
                default: 'today'
            },
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: 'datetimepicker'
            },
            single: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            rules: {
                type: Array,
                default: () => []
            },
            sticky: {
                type: Boolean,
                default: false
            },
            timePicker: {
                type: Boolean,
                default: true
            },
            value: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            rulesImpl: rulesImpl,
            dialog: false,
            localDateValue: null,
            localTimeValue: null
        }),
        computed: {
            dateFormat: function () {
                if (this.timePicker) {
                    return DATETIME_DISPLAY_FORMAT;
                } else {
                    return DATE_DISPLAY_FORMAT;
                }
            }
        },
        watch: {
            value: function (newValue) {
                if (newValue) {
                    const newDate = this.$moment(newValue).format(this.$moment.HTML5_FMT.DATE);
                    if (this.localDateValue !== newDate) {
                        this.localDateValue = newDate;
                    }
                    const newTime = this.$moment(newValue).format(this.$moment.HTML5_FMT.TIME_SECONDS);
                    if (this.localTimeValue !== newTime) {
                        this.localTimeValue = newTime;
                    }
                }
            },
            localDateValue: function () {
                this.checkAndEmit();
            },
            localTimeValue: function () {
                this.checkAndEmit();
            }
        },
        methods: {
            onConfirm: function () {
                this.dialog = false;
            },
            onClickClear: function () {
                this.localDateValue = '';
                this.localTimeValue = '';
                this.$emit('input', null);
            },
            checkAndEmit: function () {
                if (!this.timePicker) {
                    this.localTimeValue = '00:00:00';
                }
                if (this.localDateValue && this.localTimeValue) {
                    this.$emit('input', isoFromDateTime(this.localDateValue, this.localTimeValue));
                }
            }
        }
    };
</script>

<style scoped>

</style>
