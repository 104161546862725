<template>
  <span>
    <template v-if="buyer">
      <span>
        {{ buyer.name }}
      </span>
      <div
        v-for="[prop, link, icon] of details"
        v-show="buyer[prop]"
        :key="prop"
      >
        <v-icon
          small
          dense
          class="mr-2"
        >{{ icon || prop }}</v-icon><a
          :href="link + buyer[prop]"
          :target="link !== 'tel:' ? '_blank' : false"
        >{{ buyer[prop] }}</a>
      </div>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </span>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";

    export default {
        name: "PreparePackageBuyerDetail",
        props: {
            buyerId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            buyer: null,
            details: [
                ['phone', 'tel:'],
                ['email', 'mailto:'],
                ['ico', 'https://wwwinfo.mfcr.cz/cgi-bin/ares/ares_es.cgi?xml=1&ico=', 'open_in_new']
            ]
        }),
        createdArActivated: function () {
            this.fetchBuyer();
        },
        watch: {
            buyerId: {
                handler: function () {
                    this.fetchBuyer();
                },
                immediate: true
            }
        },
        methods: {
            fetchBuyer: function () {
                if (this.buyerId) {
                    BuyerAPI.get(this.buyerId)
                        .then(response => {
                            this.buyer = response.data;
                        }).catch(this.snack);
                }
            }
        }
    };
</script>

<style scoped>

</style>
