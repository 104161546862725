<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-card
        class="mb-2"
      >
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <FormFields
              :form="form"
              :render="formRender"
              :lang-path="langPath + 'form.'"
            />
            <v-btn
              :text="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submitAfterEventCycle"
            >
              <v-icon
                class="mr-2"
              >
                update
              </v-icon>
              {{ $t('form.generate') }}
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
      <div
        v-if="items"
      >
        <v-divider
          class="my-3"
        />
        <x-data-table
          :headers="headers"
          :items="items || []"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        StockLocationsStatusForm,
        StockLocationsStatusRender
    } from "@/app/overview/status/definitions/stockLocationsStatus.form";
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import {TableFilter} from "@/enum/table_filter";
    import {createHeaders} from "@/utils/table";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";

    export default {
        name: "StockLocationsStatus",
        components: {XDataTable, FormFields},
        mixins: [FormAutocompleteMixin],
        data: () => ({
            langPath: 'stocks.stockStatus.locations.',
            valid: false,
            loading: false,
            form: new StockLocationsStatusForm,
            formRender: new StockLocationsStatusRender,
            items: null
        }),
        computed: {
            headers: function () {
                return createHeaders({
                    location_id: {
                        filterType: TableFilter.NUMBER
                    },
                    location_name: {
                        filterType: TableFilter.TEXT
                    },
                    days_used: {
                        filterType: TableFilter.NUMBER
                    }
                }, this.langPath + 'table.', false);
            }
        },
        watch: {
            'form.stockId': function () {
                if (this.form.stockId) {
                    this.fetchSubStocks();
                }
            },
            'form.dateFrom': function () {
                this.checkDates();
            },
            'form.dateTo': function () {
                this.checkDates();
            }
        },
        methods: {
            checkDates: function () {
                const difference = this.$moment(this.form.dateTo).diff(this.$moment(), 'seconds');
                if (difference > 0) {
                    this.formRender.dateTo.errors = [this.$t(this.langPath + 'form.mustBeUpToToday')];
                    return;
                } else {
                    this.formRender.dateTo.errors = [];
                }
                if (this.form.dateFrom && this.form.dateTo) {
                    const difference = this.$moment(this.form.dateTo).diff(this.$moment(this.form.dateFrom), 'days');
                    if (difference < 0) {
                        this.formRender.dateTo.errors = [this.$t(this.langPath + 'form.mustBeHigherThanFrom')];
                    } else if (difference > 30) {
                        this.formRender.dateTo.errors = [this.$t(this.langPath + 'form.max31Days')];
                    } else {
                        this.formRender.dateTo.errors = [];
                    }
                }
            },
            submitAfterEventCycle: function () {
                setTimeout(this.submit);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.items = null;
                this.loading = true;
                this.advancedSnack({
                    text: this.langPath + 'generating',
                    timeout: 0
                });
                StockStatusAPI.getLocationUsage(this.form.subStockId, this.form.dateFrom, this.form.dateTo)
                    .then(response => {
                        this.snack('');
                        const days = [this.$moment(this.form.dateFrom).format('YYYY-MM-DD')];
                        for (; ;) {
                            const lastDate = this.$moment(days[days.length - 1]);
                            if (lastDate.format('YYYY-MM-DD') >= this.$moment(this.form.dateTo).format('YYYY-MM-DD')) {
                                break;
                            }
                            days.push(lastDate.add(1, 'd').format('YYYY-MM-DD'));
                        }
                        this.items = Object.values(response.data)
                            .map(item => {
                                item.days.forEach(day => {
                                    item.days[day.date] = day.occupied;
                                });
                                return item;
                            })
                            .map(item => {
                                item.days_used = 0;
                                days.forEach(day => {
                                    const value = (item.days[day]) ? 1 : 0;
                                    item[day] = value;
                                    item.days_used += value;
                                });
                                delete item.days;
                                return item;
                            });
                    })
                    .catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
