<template>
  <div>
    <v-card
      class="ma-1"
    >
      <v-container fluid>
        <v-icon class="mb-1">
          {{ actionIcons.approve }}
        </v-icon>
        <span class="text-subtitle-1 ml-2">
          {{ $t('tasks.approve.name') }}
        </span>
        <v-divider
          class="my-2"
        />
        <v-layout wrap>
          <v-flex
            xs12
          >
            <v-subheader>
              {{ $t('tasks.deliveryAccept.updateDetails') }}
            </v-subheader>
            <v-layout wrap>
              <v-flex
                xs12
              >
                <FormFields
                  ref="fields"
                  :form="form"
                  :render="formRender"
                  :md-sizing="8"
                  lang-path="tasks."
                />
              </v-flex>
              <v-flex
                xs12
              >
                <TaskChooseItems
                  :chosen-items.sync="chosenItems"
                  :available-items="availableItems"
                  title-path="tasks.deliveryAccept.items.delivered"
                  :loading="loading"
                  :loading-items="loadingAvailableItems"
                  choose-instance
                  :choose-just-instance="false"
                  create-new-instances
                >
                  <template
                    #item="{ item }"
                  >
                    <v-layout
                      v-if="(form.delivery_type === deliveryTypes.RETURN && Object.values(InstanceType).includes(item.type))
                        || (form.delivery_type === deliveryTypes.DELIVERY && !Object.values(InstanceType).includes(item.type))"
                    >
                      <v-flex class="orange--text">
                        {{ item | instanceLabel }}
                      </v-flex>
                      <v-flex class="text-right">
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-icon color="orange">
                                $info
                              </v-icon>
                            </div>
                          </template>
                          <span v-if="form.delivery_type === deliveryTypes.RETURN">
                            {{ $t('tasks.deliveryAccept.items.isNotCustomInstance') }}
                          </span>
                          <span v-else>
                            {{ $t('tasks.deliveryAccept.items.isCustomInstance') }}
                          </span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                    <span v-else>
                      {{ item | instanceLabel }}
                    </span>
                  </template>
                  <template
                    #selection="{ item }"
                  >
                    <v-layout
                      v-if="(form.delivery_type === deliveryTypes.RETURN && Object.values(InstanceType).includes(item.type))
                        || (form.delivery_type === deliveryTypes.DELIVERY && !Object.values(InstanceType).includes(item.type))"
                    >
                      <v-flex class="orange--text">
                        {{ item | instanceLabel }}
                      </v-flex>
                      <v-flex class="text-right">
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-icon color="orange">
                                $info
                              </v-icon>
                            </div>
                          </template>
                          <span v-if="form.delivery_type === deliveryTypes.RETURN">
                            {{ $t('tasks.deliveryAccept.items.isNotCustomInstance') }}
                          </span>
                          <span v-else>
                            {{ $t('tasks.deliveryAccept.items.isCustomInstance') }}
                          </span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                    <span v-else>
                      {{ item | instanceLabel }}
                    </span>
                  </template>
                </TaskChooseItems>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider />
          </v-flex>
          <v-flex
            xs12
          >
            <v-subheader>
              {{ $t('tasks.deliveryAccept.newTask') }}
            </v-subheader>
            <FormFields
              :form="form"
              :render="formNewTaskRender"
              :md-sizing="8"
              lang-path="tasks."
            >
              <template #stock_loading_strict_mode-item="{ item }">
                <v-layout>
                  <v-flex>
                    {{ item.text }}
                  </v-flex>
                  <v-flex class="text-right">
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon>$info</v-icon>
                        </div>
                      </template>
                      <span>{{ $t('tasks.stockLoading.strictHint.' + item.value) }}</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </template>
            </FormFields>
          </v-flex>
        </v-layout>
        <div>
          <v-btn
            :loading="loading"
            type="submit"
            color="accent"
            @click="approve()"
          >
            <v-icon
              class="mr-2"
            >
              $approve
            </v-icon>
            {{ $t('tasks.approve.name') }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <div class="my-2" />
    <SupplierCreateUpdate
      v-if="showCreateSupplier"
      id="supplierForm"
      ref="supplierForm"
      class="ma-1"
      :is-edit="false"
      is-component
    />
    <ProductCustomInstanceCreateUpdate
      v-if="creatingInstanceForProductId !== null"
      id="instanceForm"
      ref="instanceForm"
      class="ma-1"
      :product-id-prop="creatingInstanceForProductId"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        DeliveryAcceptApproveForm, DeliveryAcceptApproveNewTaskRender,
        DeliveryAcceptApproveRender
    } from "@/app/tasks/deliveryAccept/definitions/deliveryAcceptApprove.form";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {TaskDeliveryAcceptAPI as API} from "@/api/TaskDeliveryAcceptAPI";
    import {populate} from "@/utils/object";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {actionIcons} from "@/enum/icons";
    import {StockAPI} from "@/api/StockAPI";
    import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {ProductAPI} from "@/api/ProductAPI";
    import ProductCustomInstanceCreateUpdate from "@/app/products/custom-instances/ProductCustomInstanceCreateUpdate.view";
    import {deliveryTypes} from "@/enum/delivery_type";
    import {InstanceType} from "@/enum/instance_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "DeliveryAcceptApproveCard",
        components: {FormFields, SupplierCreateUpdate, TaskChooseItems, ProductCustomInstanceCreateUpdate},
        mixins: [EventsListenerMixin],
        props: {
            taskId: {
                type: Number,
                default: undefined
            },
            defaultData: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            form: new DeliveryAcceptApproveForm,
            formNewTaskRender: new DeliveryAcceptApproveNewTaskRender,
            formRender: new DeliveryAcceptApproveRender,
            loading: false,
            actionIcons: actionIcons,
            showCreateSupplier: false,
            creatingInstanceForProductId: null,
            chosenItems: [{product_id: null, product_instance_id: null}],
            availableItems: [],
            loadingAvailableItems: false,
            deliveryTypes: deliveryTypes,
            InstanceType: InstanceType
        }),
        computed: {
            events: function () {
                return {
                    'create-supplier': this.onCreateSupplier,
                    'create-supplier-created': this.onCreateSupplierCreated,
                    'create-supplier-cancelled': () => this.showCreateSupplier = false,
                    'create-instance': this.onCreateInstance,
                    'create-instance-created': () => this.creatingInstanceForProductId = null,
                    'create-instance-cancelled': () => this.creatingInstanceForProductId = null
                };
            }
        },
        watch: {
            'form.stock_loading_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
        },
        createdOrActivated: function () {
            populate(this.form, this.defaultData);
            this.fetchDeliveredItems();
            this.fetchProducts();
        },
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            fetchDeliveredItems: function () {
                this.loading = true;
                API.getAllItems(this.taskId).then(response => {
                    this.chosenItems = response.data;
                    this.chosenItems.push({product_id: null, product_instance_id: null});
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            fetchProducts: function () {
                this.loadingAvailableItems = true;
                ProductAPI.getAllPages().then(response => {
                    this.availableItems = response.data.items.map(product => {
                        product.value = product.id;
                        return product;
                    });
                }).catch(this.snack)
                    .finally(() => this.loadingAvailableItems = false);
            },
            loadSubStocks: function () {
                this.$set(this.formNewTaskRender.stock_loading_subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_loading_stock_id)
                    .then(response => {
                        this.formNewTaskRender.stock_loading_subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formNewTaskRender.stock_loading_subordinate_stock_id, 'loading', false);
                    });
            },
            onCreateSupplier: function () {
                this.showCreateSupplier = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.supplier_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('supplierForm');
                });
            },
            onCreateSupplierCreated: function (supplierId) {
                this.showCreateSupplier = false;
                this.$set(this.formRender.supplier_id, 'loading', true);
                this.formRender.supplier_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.supplier_id.autocomplete.items = this.formRender.supplier_id.autocomplete.thenFn(response);
                        this.form.supplier_id = Number.parseInt(supplierId, 10);
                    }).finally(() => this.$set(this.formRender.supplier_id, 'loading', false));
            },
            onCreateInstance: function (productId) {
                this.creatingInstanceForProductId = productId;
                this.$nextTick(() => {
                    scrollTo('instanceForm');
                });
            },
            approve: function () {
                this.loading = true;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                const data = {...this.form};
                data.stock_loading_items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        delete item.product_id;
                        return item.product_instance_id !== null;
                    });
                API.approve(this.taskId, data)
                    .then(() => {
                        this.$store.dispatch('time/stop');
                        this.advancedSnack({
                            text: 'tasks.approve.done',
                            params: [
                                this.taskId,
                                this.$t(taskNames[taskTypes.DELIVERY_ACCEPT])
                            ]
                        });
                        this.$router.push('/');
                    }).catch(errors => {
                        setFormErrors.call(this, errors, ['formRender', 'formNewTaskRender']);
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
