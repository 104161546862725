var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.deletedCarrier !== null)?_c('ConfirmDeleteDialog',{attrs:{"show":_vm.confirmDialog,"text":_vm.deletedCarrier.name + ', ' + _vm.deletedCarrier.type},on:{"update:show":function($event){_vm.confirmDialog=$event},"confirm-delete":_vm.reallyDeleteCarrier}}):_vm._e(),_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.tableItems,"item-class":function (item) { return _vm.itemClass(item); },"actions":_vm.actions,"search-hint":_vm.$t('carriers.searchHint')},on:{"update:loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"item.tableImage",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.image !== null && item.image.url !== null)?_c('v-img',{staticStyle:{"float":"right"},attrs:{"src":item.image.url,"contain":"","max-height":"40px","max-width":"40px"}}):_vm._e()],1)]}},{key:"item.tableFoilImage",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.foil_image !== null && item.foil_image.url !== null)?_c('v-img',{staticStyle:{"float":"right"},attrs:{"src":item.foil_image.url,"contain":"","max-height":"40px","max-width":"40px"}}):_vm._e()],1)]}},{key:"item.services",fn:function(ref){
var item = ref.item;
return [_c('CarrierServicesList',{attrs:{"text-class":_vm.itemClass(item),"services":item.services,"inline":true}})]}},(_vm.ownerId)?{key:"item.contracted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesOrNo")(item.contracted))+" ")]}}:{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesOrNo")(item.active))+" ")]}},{key:"footer",fn:function(){return [_c('TableAddItemButton',{attrs:{"to":_vm.carrierCreateLink,"label":"carriers.create.title"}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }