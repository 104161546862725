export default {
    title: "Odberatelia",
    link: "Odberatelia",

    name: "Názov",
    ico: "IČO",
    dic: "DIČ",
    phone: "Telefón",
    email: "E-mail",
    website: "Web",
    billing_address: "Fakturačná adresa",
    delivery_address: "Doručovacia adresa",
    address: "Doručovacia adresa",
    delivery_addresses: "Doručovacie adresy",
    type_id: "Typ",
    type: {
        id: "Typ",
        label: "Typ"
    },

    addresses: {
        public: "<verejná>",
        privacy: "Súkromná užívateľa",
        default: "Predvolená",
        markDefault: "Označiť ako predvolené",
        searchHint: "Prehľadáva ulicu, číslo popisné, upresnenie adresy, mesto, PSČ a krajinu",

        create: {
            title: "Vytvoriť doručovaciu adresu pre odberateľa '{0}'",
            titleSimple: "Vytvoriť novú doručovaciu adresu",
            done: "Doručovacia adresa vytvorená."
        },
        update: {
            titleSimple: "Upraviť doručovaciu adresu odberateľa",
            title: "Upraviť doručovaciu adresu pre odberateľa '{0}'",
            done: "Doručovacia adresa aktualizovaná."
        },
        delete: {
            done: "Doručovacia adresa odberateľa zmazaná."
        },
        status: {
            404: "Odberateľ alebo jeho adresa neexistuje!",
            409: "Predvolená doručovacia adresa odberateľa nemôže byť súkromná!"
        }
    },

    types: {
        link: "Typy odberateľov",
        title: "Typy odberateľov",
        titleSingle: "Typ odberateľa",
        name: "Názov",

        create: {
            label: "Vytvoriť nový typ odberateľa",
            title: "Nový typ odberateľa",
            done: "Vytvorený typ '{0}'"
        },
        update: {
            titleSimple: "Zmeniť typ odberateľa",
            title: "Zmeniť '{0}'",
            done: "'{0}' zmenený"
        },
        delete: {
            done: "'{0}' zmazaný.",
            failed: "Nepodarilo sa vymazať '{0}'. Skúste to prosím neskôr.",
        },

        status: {
            404: "Typ odberateľa nebol nájdený!",
            409: "Nie je možné odstrániť typ odberateľa, pretože ho používajú niektorí odberatelia!"
        }
    },

    list: {
        unableToLoad: "Zoznam odberateľov nebolo možné načítať. Skúste to prosím opäť neskôr."
    },
    show: {
        title: "Odberateľ",
    },
    create: {
        title: "Vytvoriť odberateľa",
        done: "Odberateľ '{0}' vytvorený.",
        undo: {
            done: "Tvorba '{0}' úspešne zrušená!",
            failed: "Nepodarilo se zrušiť tvorbu odberateľa!"
        }
    },
    update: {
        titleSimple: "Upraviť odberateľa",
        title: "Upraviť {0}",
        done: "Odberateľ '{0}' upravený",
        undo: {
            done: "Úpravy '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť úpravu odberateľa!"
        }
    },
    delete: {
        done: "Odberateľ '{0}' zmazaný.",
        failed: "Odberateľa '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
        undo: {
            done: "Zmazanie '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť zmazanie odberateľa!"
        },
        status: {
            404: "Zadaný odberateľ neexistuje!",
            409: "Odberateľa nie je možné zmazať kvôli jeho vzťahom k ďalším objektom!"
        }
    },
    status: {
        404: "Zadaný odberateľ neexistuje!",
        409: "Vyplnené IČO už má iný odberateľ!"
    }
};
