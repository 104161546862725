export default {
    close: 'Zavřít',
    dataIterator: {
        pageText: '{0}-{1} z {2}',
        noResultsText: 'Nic nenalezeno',
        loadingText: 'Načítání...',
    },
    dataTable: {
        itemsPerPageText: 'Řádků na stránku:',
        ariaLabel: {
            sortDescending: ': Řazeno sestupně. Klikněte pro zrušení řazení.',
            sortAscending: ': Řazeno vzestupně. Klikněte pro sestupné řazení.',
            sortNone: ': Neseřazeno. Klikněte pro vzestupné řazení.',
            activateNone: 'Zrušit řazení',
            activateDescending: 'Řadit sestupně',
            activateAscending: 'Řadit vzestupně'
        },
        sortBy: 'Seřadit podle ...',
    },
    dataFooter: {
        pageText: '{0}-{1} z {2}',
        itemsPerPageText: 'Řádků na stránku:',
        itemsPerPageAll: 'Vše',
        nextPage: 'Další',
        prevPage: 'Předchozí',
        firstPage: 'První',
        lastPage: 'Poslední',
    },
    datePicker: {
        itemsSelected: '{0} vybráno',
        prevMonthAriaLabel: 'předchozí měsíc',
        nextMonthAriaLabel: 'následující měsíc',
    },
    noDataText: 'Nic nenalezeno',
    carousel: {
        prev: 'Předchozí',
        next: 'Další',
    },
    calendar: {
        moreEvents: '{0} více',
    },
    fileInput: {
        counterSize: '{0}x soubor ({1} celkem)'
    }
};
