import {CachePath} from "@/service/cache/CacheConfiguration";
import {IndexedDB} from "@/service/cache/IndexedDB";

const AllowedLocationIdsCacheMixin = {
    methods: {
        clearAllowedLocationIdsCacheByStock(stockId) {
            IndexedDB.getKeys(CachePath.allowedLocationIds).then(keys => {
                // intentionally compare string with number
                const keysWithStock = keys.find(key => key.split('-') == stockId);
                keysWithStock.forEach(key => {
                    IndexedDB.clearByKey(CachePath.allowedLocationIds, key);
                });
            });
        },
        clearAllowedLocationIdsCacheBySubStock(subStockId) {
            IndexedDB.getKeys(CachePath.allowedLocationIds).then(keys => {
                // intentionally compare string with number
                const keysWithStock = keys.filter(key => {
                    const keyParts = key.split('-');
                    if (keyParts.length === 2) {
                        // TODO old key format - drop later
                        return true;
                    }
                    return keyParts[1] == subStockId;
                });
                keysWithStock.forEach(key => {
                    IndexedDB.clearByKey(CachePath.allowedLocationIds, key);
                });
            });
        },
        clearAllowedLocationIdsCacheByInstance(instanceId) {
            IndexedDB.getKeys(CachePath.allowedLocationIds).then(keys => {
                // intentionally compare string with number
                const keysWithStock = keys.filter(key => {
                        const keyParts = key.split('-');
                        if (keyParts.length === 2) {
                            // TODO old key format - drop later
                            return true;
                        }
                        return keyParts[2] == instanceId;
                });
                keysWithStock.forEach(key => {
                    IndexedDB.clearByKey(CachePath.allowedLocationIds, key);
                });
            });
        }
    }
};

export {AllowedLocationIdsCacheMixin};
