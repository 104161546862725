var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('x-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":function (item) { return item.id === _vm.shipmentId && 'font-weight-bold'; },"loading":_vm.loading,"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"batch-actions":_vm.batchActions,"item-select-color":_vm.itemSelectColor,"search-hint":_vm.$t('base.shipping.table.searchHint')},on:{"update:items":function($event){_vm.items=$event},"update:loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"item.external_order_id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_id}},[_vm._v(" #"+_vm._s(item.external_order_id)+" ")])],1)]}},{key:"item.stock_picking_id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.STOCK_PICKING] + '/' + item.stock_picking_id}},[_vm._v(" #"+_vm._s(item.stock_picking_id)+" ")])],1)]}},{key:"item.buyer.name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":'/buyers/' + item.buyer.id}},[_vm._v(" "+_vm._s(item.buyer.name)+" ")])],1)]}},{key:"item.last_validated",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.last_validated}})]}},{key:"item.carrier_api_error_messages",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":item.carrier_api_error_messages === null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.last_validated !== null && item.carrier_api_error_messages === null)?_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" $success ")])],1):(item.carrier_api_error_messages === null)?_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" $waiting ")])],1):_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" $error ")])],1)]}}],null,true)},_vm._l((item.carrier_api_error_messages),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(decodeURIComponent(error))+" ")])}),0)]}},{key:"item.external_order_created_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.external_order_created_at,"abs-date-first":true}})]}},{key:"item.label_first_printed_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.label_first_printed_at,"abs-date-first":true}})]}},{key:"item.last_protocol_created_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.last_protocol_created_at,"abs-date-first":true}})]}},{key:"item.sent_date",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.sent_date,"abs-date-first":true}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }