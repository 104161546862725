import {TableFilter} from "@/enum/table_filter";
import {ShipmentState} from "@/enum/shipment_state";
import {EventBus} from "@/service/EventBus";
import i18n from "@/service/lang/i18n";

const shippingTable = (forHandover = false, substocks = [], substocksLoading = false, carriers = [], carriersLoading = false) => {
    let cols = {};
    if (forHandover) {
        cols = {
            ...cols,
            last_protocol_created_at: {

            },
            'external_order_external_order_id': {
                filterType: TableFilter.TEXT
            },
        };
    }
    cols = {
        ...cols,
        state: {
            filterType: forHandover ? undefined : TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(ShipmentState).map(state => ({
                text: i18n.t('base.shipping.shipment.state.' + state),
                value: state
            })),
            itemLabel: item => i18n.t('base.shipping.shipment.state.' + item.state)
        }
    };
    if (!forHandover) {
        cols = {
            ...cols,
            'subordinate_stock.id': {
                filterType: TableFilter.SELECT_MULTIPLE,
                filterItems: substocks,
                filterAction: currentFilter => {
                    EventBus.$emit('subStock-filtered', currentFilter);
                },
                loading: substocksLoading,
                itemLabel: item => item.subordinate_stock ? item.subordinate_stock.name : '-',
                sortBy: 'subordinate_stock.id'
            },
            'carrier.id': {
                filterType: TableFilter.SELECT_MULTIPLE,
                filterItems: carriers,
                filterAction: currentFilter => {
                    EventBus.$emit('carrier-filtered', currentFilter);
                },
                loading: carriersLoading,
                itemLabel: item => item.carrier ? item.carrier.name : "-",
                sortBy: 'carrier.name'
            },
            'external_order_external_order_id': {
                filterType: TableFilter.TEXT
            },
        };
    }
    cols = {
        ...cols,
        external_order_id: {
            sortable: false
        },
        stock_picking_id: {
            sortable: false
        },
        'buyer.name': {
            filterType: TableFilter.TEXT
        },
        'buyer.email': {
            filterType: TableFilter.TEXT
        },
        'buyer.phone': {
            filterType: TableFilter.TEXT
        }
    };
    if (!forHandover) {
        cols = {
            ...cols,
            last_validated: {
                filterType: TableFilter.DATETIME
            },
            carrier_api_error_messages: {
                sortable: false,
                filterType: TableFilter.IS_NOT_NULL
            },
            external_order_created_at: {
                filterType: TableFilter.DATETIME
            },
            label_first_printed_at: {
                filterType: TableFilter.DATETIME
            },
            last_protocol_created_at: {
                filterType: TableFilter.DATETIME
            },
            sent_date: {
                filterType: TableFilter.DATETIME
            }
        };
    }
    return cols;
};

export {shippingTable};