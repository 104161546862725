<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products."
          />
          <v-flex
            xs12
            class="pa-2"
          >
            <v-input
              prepend-icon="$description"
            >
              <tiptap-vuetify
                v-model="form.description"
                :extensions="htmlEditorExtensions"
                :toolbar-attributes="{ color: $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4' }"
                :placeholder="$t('products.description')"
              />
            </v-input>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-tooltip top>
              <template #activator="{ on }">
                <span
                  class="text-subtitle-1 ml-2"
                  v-on="on"
                >
                  {{ $t('products.attributes.name') }}:
                </span>
              </template>
              {{ $t('products.attributes.hint') }}
            </v-tooltip>
          </v-flex>
          <v-flex
            xs12
          >
            <v-layout
              v-for="(attribute, index) of attributes"
              :key="attribute.id"
              wrap
              align-center
            >
              <v-checkbox
                v-if="form.type_id && form.type_id !== defaultTypeId"
                v-model="attribute.suggested"
                :hint="$t('products.types.suggestedAttributes.hint')"
                :persistent-hint="true"
              />
              <v-flex
                xs12
                sm6
                md4
                lg3
                xl2
                class="px-1"
              >
                <v-combobox
                  v-model="attribute.key"
                  :items="allAttributes"
                  :error-messages="attribute.errors"
                  :label="$t('products.attributes.key')"
                  prepend-icon="$attributeKey"
                  :rules="[formRules.maxLen(30)]"
                  @input="attributeChanged(index)"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
                lg3
                xl2
                class="px-1"
              >
                <v-text-field
                  v-model="attribute.value"
                  :label="$t('products.attributes.value')"
                  prepend-icon="$attributeValue"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md8
                lg4
                xl3
                class="px-1"
              >
                <v-text-field
                  v-model="attribute.description"
                  :rules="[formRules.maxLen(255)]"
                  :label="$t('products.types.suggestedAttributes.description')"
                  prepend-icon="$description"
                />
              </v-flex>
              <v-flex
                xs2
                sm1
                md1
                lg1
                xl1
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      v-show="attribute.key !== ''"
                      :disabled="loading"
                      icon
                      v-on="on"
                      @click="removeAttribute(index)"
                    >
                      <v-icon>
                        clear
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('products.types.removeAttribute') }}
                  </span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submitAfterEventCycle()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <ManufacturerCreateUpdate
      v-if="showCreateManufacturer"
      id="manufacturerForm"
      ref="manufacturerForm"
      class="ma-1"
      is-component
    />
    <ProductTypeCreateUpdate
      v-if="showCreateType"
      id="typeForm"
      ref="typeForm"
      class="ma-1"
      is-component
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductForm, ProductRender} from "@/app/products/definitions/product.form";
    import {ProductAPI} from "@/api/ProductAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import formRules from "@/utils/formRules";
    import {ProductTypeAPI} from "@/api/ProductTypeAPI";
    import {
        TiptapVuetify,
        History,
        Heading,
        Underline,
        Italic,
        Bold,
        ListItem,
        BulletList,
        OrderedList
    } from 'tiptap-vuetify';
    import ManufacturerCreateUpdate from "@/app/manufacturers/ManufacturerCreateUpdate.view";
    import ProductTypeCreateUpdate from "@/app/products/types/ProductTypeCreateUpdate.view";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {scrollTo} from "@/service/Vuetify";
    import {getIdFromLocation} from "@/utils/url";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ProductCreateUpdate",
        components: {FormFields, TiptapVuetify, ManufacturerCreateUpdate, ProductTypeCreateUpdate},
        mixins: [FormFetchItemMixin, InstanceCacheMixin, RouteParamsMapperMixin, EventsListenerMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            form: new ProductForm,
            formRender: new ProductRender,
            valid: true,
            loading: false,
            htmlEditorExtensions: [
                History,
                Bold,
                Underline,
                Italic,
                [Heading, {
                    options: {
                        levels: [1, 2, 3, 4]
                    }
                }
                ],
                ListItem,
                BulletList,
                OrderedList,
            ],
            attributes: [{key: '', description: ''}],
            allAttributes: [],
            formRules: formRules,
            defaultTypeId: null,
            showCreateManufacturer: false,
            showCreateType: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('products.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            events: function () {
                return {
                    'create-manufacturer': this.onCreateManufacturer,
                    'create-manufacturer-created': this.onCreateManufacturerCreated,
                    'create-manufacturer-cancelled': () => this.showCreateManufacturer = false,
                    'manufacturers-list-reload': this.fetchManufacturers,
                    'create-type': this.onCreateType,
                    'create-type-created': this.onCreateTypeCreated,
                    'create-type-cancelled': () => this.showCreateType = false
                };
            }
        },
        watch: {
            'form.type_id': {
                handler: function (newValue) {
                    if (newValue !== undefined && newValue !== null && newValue !== this.defaultTypeId) {
                        this.loadSuggestedAttributes();
                    } else {
                        this.attributes = this.attributes.map(attribute => {
                            attribute.suggested = false;
                            return attribute;
                        });
                    }
                },
                immediate: true
            }
        },
        created: function () {
            this.loading = true;
            const promises = [];
            const filter = {
                [APIFilterOP.EQUALS]: {
                    default: true
                }
            };
            promises.push(
                ProductTypeAPI.getAll({filter: APIFilters.makeFilter(filter)}).then(response => {
                    this.defaultTypeId = response.data.items[0].id;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/products/');
                })
            );
            promises.push(
                ProductAPI.getAllPossibleAttributes()
                    .then(response => {
                        this.allAttributes = response.data;
                    })
            );
            if (this.isEdit) {
                promises.push(
                    ProductAPI.getAllAttributes(this.productId)
                        .then(response => {
                            this.attributes = response.data.map(attribute => {
                                attribute.errors = [];
                                return attribute;
                            });
                            this.attributes.push({key: '', description: ''});
                        }).catch(err => {
                            this.snack(err);
                            this.$router.push('/products/');
                        })
                );
            }
            Promise.all(promises).finally(() => this.loading = false);
        },
        methods: {
            formFetchItem: function () {
                return ProductAPI.get(this.productId);
            },
            fetchManufacturers: function (manufacturerId) {
                this.$set(this.formRender.manufacturer_id, 'loading', true);
                this.formRender.manufacturer_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.manufacturer_id.autocomplete.items = this.formRender.manufacturer_id.autocomplete.thenFn(response);
                        if (manufacturerId) {
                            this.form.manufacturer_id = Number.parseInt(manufacturerId, 10);
                        }
                    }).finally(() => this.$set(this.formRender.manufacturer_id, 'loading', false));
            },
            loadSuggestedAttributes: function () {
                this.loading = true;
                ProductTypeAPI.getSuggestedAttributes(this.form.type_id)
                    .then(response => {
                        let suggestedAttributes = response.data.map(el => ({
                            key: el.key,
                            description: el.description,
                            errors: []
                        }));
                        this.attributes.pop();
                        this.attributes.map(attribute => {
                            const suggestedAttribute = suggestedAttributes.find(atr => atr.key === attribute.key);
                            if (suggestedAttribute) {
                                attribute.description = suggestedAttribute.description;
                                attribute.suggested = true;
                                suggestedAttributes = suggestedAttributes.filter(atr => atr !== suggestedAttribute);
                            } else {
                                attribute.suggested = false;
                            }
                            return attribute;
                        });
                        this.attributes.push(...suggestedAttributes.map(atr => {
                            atr.suggested = true;
                            return atr;
                        }));
                        this.attributes.push({key: '', description: ''});
                    }).catch(err => {
                        this.snack(err);
                    }).finally(() => this.loading = false);
            },
            onCreateManufacturer: function () {
                this.showCreateManufacturer = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.manufacturer_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('manufacturerForm');
                });
            },
            onCreateManufacturerCreated: function (manufacturerId) {
                this.showCreateManufacturer = false;
                this.fetchManufacturers(manufacturerId);
            },
            onCreateType: function () {
                this.showCreateType = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.type_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('typeForm');
                });
            },
            onCreateTypeCreated: function (typeId) {
                this.showCreateType = false;
                this.$set(this.formRender.type_id, 'loading', true);
                this.formRender.type_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.type_id.autocomplete.items = this.formRender.type_id.autocomplete.thenFn(response);
                        this.form.type_id = Number.parseInt(typeId, 10);
                    }).finally(() => this.$set(this.formRender.type_id, 'loading', false));
            },
            attributeChanged: function (index) {
                const attributeKey = this.attributes[index].key;
                if (this.attributes.filter(item => item.key === attributeKey).length > 1) {
                    this.attributes[index].errors = [this.$t('products.attributeAlreadyEntered')];
                } else {
                    this.attributes[index].errors = [];
                }
                if (this.attributes.filter(item => item.key === '').length === 0) {
                    this.attributes.push({key: '', description: ''});
                }
            },
            removeAttribute: function (index) {
                if (this.attributes.length === 1) {
                    this.snack('products.unableToRemoveLastAttribute');
                    return;
                }
                this.attributes.splice(index, 1);
            },
            submitAfterEventCycle: function () {
                setTimeout(this.submit);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const attributes = this.attributes
                    .filter(attribute => {
                        delete attribute.errors;
                        return attribute.key !== '' && attribute.value !== undefined && attribute.value !== '';
                    });
                const promises = [];
                if (this.isEdit) {
                    promises.push(ProductAPI.update(this.productId, this.form)
                        .then(() => {
                            promises.push(ProductAPI.getVersion(this.productId)
                                .then(() => {
                                    promises.push(ProductAPI.updateAttributes(this.productId, attributes));
                                }));
                        }).catch(setFormErrors.bind(this)));
                } else {
                    promises.push(ProductAPI.create(this.form)
                        .then(response => {
                            if (attributes.length) {
                                const productId = getIdFromLocation(response);
                                //HEAD does not work and since I know there are no attributes yet this has the same outcome - I get current version
                                promises.push(ProductAPI.getAllAttributes(productId)
                                    .then(() => {
                                        promises.push(ProductAPI.updateAttributes(productId, attributes));
                                    }));
                            }
                        }).catch(setFormErrors.bind(this)));
                }
                Promise.all(promises)
                    .then(() => {
                        if (!this.form.type_id) {
                            this.form.type_id = this.defaultTypeId;
                            return Promise.resolve();
                        } else {
                            const suggestedAttributes = this.attributes
                                .filter(attribute => attribute.key !== '' && attribute.suggested);
                            return ProductTypeAPI.updateSuggestedAttributes(this.form.type_id, suggestedAttributes);
                        }
                    })
                    .then(this.finalizeSubmit)
                    .catch(err => {
                        this.clearInstanceCachesByProduct(this.productId);
                        this.snack(err);
                    })
                    .finally(() => this.loading = false);
            },
            finalizeSubmit: function (response) {
                this.advancedSnack({
                    text: this.isEdit ? 'products.update.done' : 'products.create.done',
                    params: [this.form.name]
                });
                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                if (this.isEdit) {
                    this.clearInstanceCachesByProduct(this.productId);
                    this.$router.push('/products/' + this.productId);
                } else {
                    this.$router.push(response.headers.location);
                }
            }
        }
    };
</script>

<style scoped>

</style>
