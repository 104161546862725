function resolve(type) {
    return this.details && this.details.type === type ||
        this.taskInfo && this.taskInfo.details && this.taskInfo.details.type === type;
}

function resolveIncludes(type) {
    return this.details && type.indexOf(this.details.type) !== -1 ||
        this.taskInfo && this.taskInfo.details && type.indexOf(this.taskInfo.details.type) !== -1;
}

/**
 * Requires:
 * - this[.taskInfo].details.state
 */
const TaskTypeMixin = {
    methods: {
        /**
         * @param taskType {string}
         * @return {Boolean}
         */
        isType: function (taskType) {
            return resolve.call(this, taskType);
        },
        /**
         * @param taskTypes {string[]}
         * @return {Boolean}
         */
        isAnyOfTypes: function (taskTypes) {
            return resolveIncludes.call(this, taskTypes);
        }
    }
};

export {TaskTypeMixin};
