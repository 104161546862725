import { render, staticRenderFns } from "./TaskItemsCardHeader.vue?vue&type=template&id=5902b59c&"
import script from "./TaskItemsCardHeader.vue?vue&type=script&lang=js&"
export * from "./TaskItemsCardHeader.vue?vue&type=script&lang=js&"
import style0 from "./TaskItemsCardHeader.vue?vue&type=style&index=0&id=5902b59c&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VChip,VDivider,VFlex,VIcon,VLayout,VSheet})
