var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-icon',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.actionIcons.approve)+" ")]),_c('span',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('tasks.approve.name'))+" ")]),_c('v-divider',{staticClass:"my-2"}),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.updateDetails'))+" ")]),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"md-sizing":8,"lang-path":"tasks."}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('TaskChooseItems',{attrs:{"chosen-items":_vm.chosenItems,"available-items":_vm.availableItems,"title-path":"tasks.deliveryAccept.items.delivered","loading":_vm.loading,"loading-items":_vm.loadingAvailableItems,"choose-instance":"","choose-just-instance":false,"create-new-instances":""},on:{"update:chosenItems":function($event){_vm.chosenItems=$event},"update:chosen-items":function($event){_vm.chosenItems=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [((_vm.form.delivery_type === _vm.deliveryTypes.RETURN && Object.values(_vm.InstanceType).includes(item.type))
                      || (_vm.form.delivery_type === _vm.deliveryTypes.DELIVERY && !Object.values(_vm.InstanceType).includes(item.type)))?_c('v-layout',[_c('v-flex',{staticClass:"orange--text"},[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(item))+" ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" $info ")])],1)]}}],null,true)},[(_vm.form.delivery_type === _vm.deliveryTypes.RETURN)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isNotCustomInstance'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isCustomInstance'))+" ")])])],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(item))+" ")])]}},{key:"selection",fn:function(ref){
                      var item = ref.item;
return [((_vm.form.delivery_type === _vm.deliveryTypes.RETURN && Object.values(_vm.InstanceType).includes(item.type))
                      || (_vm.form.delivery_type === _vm.deliveryTypes.DELIVERY && !Object.values(_vm.InstanceType).includes(item.type)))?_c('v-layout',[_c('v-flex',{staticClass:"orange--text"},[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(item))+" ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" $info ")])],1)]}}],null,true)},[(_vm.form.delivery_type === _vm.deliveryTypes.RETURN)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isNotCustomInstance'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isCustomInstance'))+" ")])])],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(item))+" ")])]}}])})],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.newTask'))+" ")]),_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formNewTaskRender,"md-sizing":8,"lang-path":"tasks."},scopedSlots:_vm._u([{key:"stock_loading_strict_mode-item",fn:function(ref){
                      var item = ref.item;
return [_c('v-layout',[_c('v-flex',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',[_vm._v("$info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks.stockLoading.strictHint.' + item.value)))])])],1)],1)]}}])})],1)],1),_c('div',[_c('v-btn',{attrs:{"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.approve()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" $approve ")]),_vm._v(" "+_vm._s(_vm.$t('tasks.approve.name'))+" ")],1)],1)],1)],1),_c('div',{staticClass:"my-2"}),(_vm.showCreateSupplier)?_c('SupplierCreateUpdate',{ref:"supplierForm",staticClass:"ma-1",attrs:{"id":"supplierForm","is-edit":false,"is-component":""}}):_vm._e(),(_vm.creatingInstanceForProductId !== null)?_c('ProductCustomInstanceCreateUpdate',{ref:"instanceForm",staticClass:"ma-1",attrs:{"id":"instanceForm","product-id-prop":_vm.creatingInstanceForProductId,"is-edit":false,"is-component":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }