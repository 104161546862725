import { render, staticRenderFns } from "./ToolbarReaderFeedback.component.vue?vue&type=template&id=b1bb0c28&scoped=true&"
import script from "./ToolbarReaderFeedback.component.vue?vue&type=script&lang=js&"
export * from "./ToolbarReaderFeedback.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1bb0c28",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VListItem})
