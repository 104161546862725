export default {
    locationVsSubstock: {
        title: 'Mi a különbség egy helyszín és egy alraktár között?',
        content: 'A hely egy készletben lévő valós pozíció, míg az álraktár a készlet virtuális része, amelyben a készletelemek egy másik jogalany tulajdonában lehetnek. A több alraktárból származó tételek egy helyen, együtt tárolhatók.'
    },
    smallWarehouse: {
        title: 'Mi az a kis raktár?',
        content: 'Egy kis raktárnak mindig legfeljebb egy helye van. Az alraktárak száma nem korlátozott.'
    },
    productLabels: {
        title: "Mit változtat a 'Tárolt készlet címkék' model/név váltása?",
        content: 'Megváltoztatja, hogy a név vagy a model megjelenik-e minden egyes raktárkészletben lévő tételhez, ez tükröződik például a raktáráthelyezések oldalon, a feladatoknál és azok létrehozásánál.'
    },
    setProductAttributes: {
        title: 'Hogyan állíthatom be egy készletelem attribútumait?',
        content: 'Készletelem létrehozásakor az aktuálisan kiválasztott készletelemtípus ajánlott attribútumai egymás után töltődnek be. ' +
            'Ezek közül az attribútumok közül csak azok lesznek elmentve, amelyek kitöltött értékkel rendelkeznek. ' +
            'A készletelem szerkesztésekor a készletelem attribútumai töltődnek be, a készletelem típusának megváltoztatásakor az adott típus ajánlott attribútumai töltődnek be újra. ' +
            'Ha a készletelem attribútum és az ajánlott attribútum neve megegyezik, akkor a készletelem attribútum az ajánlott attribútum leírását veszi át. ' + +
                'Mindkét esetben (létrehozás és szerkesztés során) egyszerre állíthatók be az aktuálisan kiválasztott típus ajánlott attribútumai. ' +
            'Ezeknek nem kell kitöltött értékkel rendelkezniük, és a leírás opcionális mind a készletelem attribútumok, mind a kiemelt attribútumok esetében.'
    }
};
