var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-1 pb-1"},[_c('BarcodePrintDialog',{attrs:{"show":_vm.print,"print-type":_vm.printType,"print-just-one-per-item":_vm.printJustOnePerItem,"title":_vm.$t('products.instances.barcodes.batchPrint.label'),"confirm-text":_vm.$t('stocks.locations.printBarcodeMany'),"batch-print":""},on:{"update:show":function($event){_vm.print=$event},"update:printType":function($event){_vm.printType=$event},"update:print-type":function($event){_vm.printType=$event},"update:printJustOnePerItem":function($event){_vm.printJustOnePerItem=$event},"update:print-just-one-per-item":function($event){_vm.printJustOnePerItem=$event},"print-barcode":_vm.batchPrint}}),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSingle),expression:"!isSingle"}],staticClass:"subtitle-1 pr-9 py-0 normalBreak"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.' + _vm.taskLangPath + _vm.cardType) + ' ' + _vm.headerDetailText)+" "),(_vm.isAllowedToChangeTarget)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.changeTargetDialog = true}}},on),[_c('v-icon',[_vm._v(" $itemChangeTargetLocation ")])],1)]}}],null,false,3550691076)},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks.itemsCard.changeTargetLocation')))])]):_vm._e()],1),(_vm.allowBatchPrint && _vm.items.length !== 0)?_c('v-btn',{staticClass:"mb-2",attrs:{"loading":_vm.printing,"color":"secondary"},on:{"click":function($event){_vm.print = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" $printItem ")]),_vm._v(" "+_vm._s(_vm.$t('products.instances.barcodes.printMany'))+" ")],1):_vm._e()],1),(_vm.allowLocationBottomSheet)?_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1"},on),[_vm._v(" $hint ")])]}}],null,false,2711618624),model:{value:(_vm.showSubstockLocationInfo),callback:function ($$v) {_vm.showSubstockLocationInfo=$$v},expression:"showSubstockLocationInfo"}},[_c('v-sheet',[_c('v-layout',{staticClass:"py-3",attrs:{"align-center":"","column":"","wrap":""}},[_c('v-flex',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.substockLocations') + ':')+" ")]),_c('v-flex',{staticClass:"mx-2"},_vm._l((_vm.locationBottomSheetLocations),function(location,index){return _c('v-chip',{key:index,attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("locationLabel")(location))+" ")])}),1)],1)],1)],1):_vm._e()],1),(_vm.isMultipleLocations)?[_c('transition-group',{attrs:{"name":"card-list","mode":"out-in"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDummyCard),expression:"showDummyCard"}],key:"dummy",staticClass:"card-list-item ma-1 mb-3 pa-2 elevation-8 overflow-hidden",attrs:{"color":_vm.cardType === _vm.TaskItemsCardType.MOVED ? _vm.activeCardColor : ''}},[_c('div',{staticClass:"overline pa-2"},[_c('TaskItemsCardHeader',{attrs:{"location-id":_vm.activeLocationId,"allowed-instances":_vm.allowedInstances[_vm.activeLocationId],"allowed-locations-loaded":_vm.allowedLocationsLoaded,"card-type":_vm.cardType,"num-of-instances":_vm.numOfItemsInTask}})],1)]),_vm._l((_vm.multipleLocations),function(locationId){return _c('v-lazy',{key:locationId,attrs:{"transition":"slide-y-reverse-transition","min-height":"72px"}},[_c('v-card',{key:locationId,staticClass:"card-list-item overflow-hidden",class:{
            'ma-3 pa-3 elevation-1 text--disabled': !_vm.highlightActiveCard(locationId),
            'ma-1 mb-3 pa-2 elevation-8': _vm.highlightActiveCard(locationId)
          },attrs:{"color":_vm.highlightActiveCard(locationId) ? _vm.activeCardColor : ''}},[_c('div',{staticClass:"overline pa-2"},[_c('TaskItemsCardHeader',{attrs:{"location-id":locationId,"allowed-instances":_vm.allowedInstances[locationId],"allowed-locations-loaded":_vm.allowedLocationsLoaded,"card-type":_vm.cardType,"num-of-instances":_vm.numOfItemsInTask}})],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.mdAndDown }},_vm._l((_vm.itemGroups),function(itemGroup,index){return _c('div',{key:'ig' + index,class:{ 'mb-2': _vm.$vuetify.breakpoint.mdAndDown },style:(_vm.$vuetify.breakpoint.mdAndUp ? 'width: ' + (100 / _vm.itemGroups.length) + '%' : '')},[_c('transition-group',{attrs:{"name":"chip-list","mode":"out-in"}},_vm._l((_vm.items.filter(function (itm) { return itemGroup.filter(itm) && _vm.itemQuantityOnLocation(itm, locationId); })),function(item,idx){return _c('v-lazy',{key:item.id,staticClass:"chip-list-item d-block",attrs:{"transition":"slide-y-reverse-transition","min-height":"32px"}},[_c('div',[(itemGroup.label && idx === 0)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(itemGroup.label)+": ")]):_vm._e(),_c('TaskItemsCardItem',{attrs:{"item":item,"active-location-id":_vm.activeLocationId,"active-location-side":_vm.activeLocationSide,"card-type":_vm.cardType,"quantity-current":_vm.itemQuantityOnLocation,"prices":_vm.prices,"location-id":locationId,"task-info":_vm.taskInfo,"inventory-empty":_vm.inventoryEmpty},on:{"back":_vm.onClickBack,"remove":_vm.onClickRemove}})],1)])}),1)],1)}),0)])],1)}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNothingCard),expression:"showNothingCard"}],key:"empty",staticClass:"card-list-item mx-1 pa-2 pt-3 text--disabled overflow-hidden",attrs:{"outlined":""}},[_c('v-icon',{staticClass:"mb-1 mx-2"},[_vm._v(" $nothing ")]),_vm._v(" "+_vm._s(_vm.$t('base.nothing'))+" ")],1)],2)]:[_c('v-card',{staticClass:"mx-1 pa-2 pt-3",class:{
        'ma-1 mb-3 pa-2 elevation-8': _vm.highlightSingleLocation
      },attrs:{"color":_vm.highlightSingleLocation ? _vm.activeCardColor : '',"outlined":_vm.items.length === 0}},[(_vm.items.length > 0)?_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.mdAndDown }},_vm._l((_vm.itemGroups),function(itemGroup,index){return _c('div',{key:'ig' + index,class:{ 'mb-2': _vm.$vuetify.breakpoint.mdAndDown },style:(_vm.$vuetify.breakpoint.mdAndUp ? 'width: ' + (100 / _vm.itemGroups.length) + '%' : '')},[_c('transition-group',{attrs:{"name":"chip-list","mode":"out-in"}},_vm._l((_vm.items.filter(function (itm) { return itemGroup.filter(itm); })),function(item,idx){return _c('v-lazy',{key:item.id,staticClass:"chip-list-item d-block",attrs:{"transition":"slide-y-reverse-transition","min-height":"32px"}},[_c('div',[(itemGroup.label && idx === 0)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(itemGroup.label)+": ")]):_vm._e(),_c('TaskItemsCardItem',{attrs:{"item":item,"active-location-id":_vm.activeLocationId,"active-location-side":_vm.activeLocationSide,"card-type":_vm.cardType,"quantity-current":_vm.quantityCurrent,"prices":_vm.prices,"location-id":_vm.cardType === _vm.TaskItemsCardType.MOVED
                    ? (_vm.destinationLocation ? _vm.destinationLocation.id : _vm.taskInfo.details.target_location_id)
                    : null,"task-info":_vm.taskInfo,"inventory-empty":_vm.inventoryEmpty},on:{"back":_vm.onClickBack,"remove":_vm.onClickRemove}})],1)])}),1)],1)}),0):_c('span',{staticClass:"text--disabled"},[_c('v-icon',{staticClass:"mb-1 mx-2"},[_vm._v(" $nothing ")]),_vm._v(" "+_vm._s(_vm.$t('base.nothing'))+" ")],1)])],(_vm.showMoveAllButton)?_c('v-btn',{staticClass:"ml-3 my-2 moveAllButton",attrs:{"color":"accent darken-2","outlined":""},on:{"click":function($event){return _vm.$emit('moveAll')}}},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.moveEverything'))+_vm._s(_vm._f("locationLabel")(_vm.LocationCache[_vm.activeLocationId]))+" ")]):_vm._e(),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.changeTargetDialog),callback:function ($$v) {_vm.changeTargetDialog=$$v},expression:"changeTargetDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.changeTargetLocation'))+" ")]),_c('v-card-text',[(_vm.canActuallyChangeTarget)?_c('StockPickingTargetPicker',{attrs:{"api":_vm.api,"task-info":_vm.taskInfo}}):_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.cannotChangeTargetLocation'))+" ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }