import { render, staticRenderFns } from "./StockMovements.view.vue?vue&type=template&id=62ca386a&scoped=true&"
import script from "./StockMovements.view.vue?vue&type=script&lang=js&"
export * from "./StockMovements.view.vue?vue&type=script&lang=js&"
import style0 from "./StockMovements.view.vue?vue&type=style&index=0&id=62ca386a&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ca386a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer,VFlex,VLayout})
