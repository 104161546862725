import {Store} from "@/service/store/Store";
import {config} from "@/config/config";
import i18n from "@/service/lang/i18n";

const navigationGuard = (to, from, next) => {

    let allow = false;
    const data = to.meta.requireRole;
    if (!data) {
        allow = true;
    } else {
        for (const item of data) {
            if (Store.getters['oauth/hasScope'](item)) {
                allow = true;
            }
        }
    }

    if (allow) {
        if (timeTrackGuard(to, from)) {
            const appName = config.DOMAIN_LABEL;
            document.title = to.meta.title === undefined ? appName : i18n.t(to.meta.title) + ' | ' + appName;
            storeLastPath(to, from);
            next();
        } else {
            // https://stackoverflow.com/a/65326844/6078703
            // next(false);
        }
    } else {
        if (Store.getters['oauth/isAuthenticated']) {
            next('/');
        } else {
            sessionStorage.setItem('remember-url', to.fullPath);
            Store.dispatch('oauth/redirectToAuthUrl');
        }
    }
};

const timeTrackGuard = (to, from) => {
    if (Store.getters['userConfig/trackTime']) {
        if (from.meta.trackTime) {
            if (to.meta.trackTime && from.params.taskId === to.params.taskId) {
                return true;
            } else {
                if (Store.getters['time/isRunning']) {
                    Store.dispatch('time/showNotice', to.path);
                    return false;
                }
            }
        }
        if (to.meta.trackTime) {
            Store.dispatch('time/start');
        }
    }
    return true;
};

const storeLastPath = (to, from) => {
    Store.commit('router/setLastPath', from.fullPath);
    Store.commit('router/setLastName', i18n.t(from.meta.title));
};

const checkTimeOnReload = (Vue) => {
    if (Store.getters['userConfig/trackTime']) {
        if (!Vue.$route.meta.trackTime) {
            if (Store.getters['time/isRunning'] && !Store.getters['time/isReloaded']) {
                Store.dispatch('time/stop');
            }
        }
    }
};

export {navigationGuard, checkTimeOnReload};
