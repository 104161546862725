export default {
    required: "Toto pole je vyžadováno.",
    maxLen: "Maximální povolená délka je {0} znaků",
    minLen: "Minimální požadovaná délka je {0} znaků",
    pattern: "Vstup nesplňuje požadovaný formát",
    positiveInteger: "Vyplňte celé kladné číslo",
    range: "Musí být mezi {0} a {1}",
    save: "Uložit",
    send: "Odeslat",
    generate: "Vygenerovat",
    download: "Stáhnout",
    notFilled: "Nevyplněno"
};
