import {TableFilter} from "@/enum/table_filter";

function ProductAttributeForm() {
    return {
        key: '',
        value: null,
        description: null
    };
}

function ProductAttributeRender(isReadOnly) {
    return {
        key: {
            icon: '$attributeKey',
            max: 30,
            required: true,
            readonly: isReadOnly,
            autocomplete: {
                items: [],
                allowCustom: true,
                // TODO allow manual reload
            }
        },
        value: {
            icon: '$attributeValue',
            required: true
        },
        description: {
            icon: '$description',
            max: 255
        }
    };
}

const productAttributeTable = {
    key: {
        filterType: TableFilter.TEXT
    },
    value: {
        filterType: TableFilter.TEXT
    },
    description: {
        filterType: TableFilter.TEXT
    }
};

export {ProductAttributeForm, ProductAttributeRender, productAttributeTable};
