<template>
  <div v-if="userId">
    <div class="headline ma-4">
      {{ $t('users.permissions.title') }}
    </div>
    <div class="ml-4">
      {{ $t('users.permissions.info') }}
    </div>
    <template v-if="stocks.length">
      <v-form
        ref="form"
        @submit.prevent
      >
        <v-container>
          <v-row no-gutters>
            <v-col
              v-for="stock of stocks"
              :key="'s' + stock.id"
              cols="12"
              md="6"
              lg="4"
              xl="3"
            >
              <v-card
                class="ma-2 pa-5"
              >
                <v-list
                  flat
                >
                  <v-subheader>
                    {{ stock.name }}
                  </v-subheader>
                  <template
                    v-if="!stock.loading"
                  >
                    <v-list-item-group
                      v-model="allowedSubstocks"
                      :class="{ 'scrollable-list': $vuetify.breakpoint.mdAndUp }"
                      multiple
                      active-class=""
                    >
                      <v-list-item
                        v-for="substock of stock.substocks"
                        :key="'ss' + substock.id"
                        :value="substock.id"
                      >
                        <template #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                            />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ substock.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <div
                        v-if="!stock.substocks || stock.substocks.length === 0"
                        class="text-caption ml-8 mt-2"
                      >
                        {{ $t('stocks.substocks.noSubstocks') }}
                      </div>
                    </v-list-item-group>
                  </template>
                  <template v-else>
                    <v-skeleton-loader
                      v-for="i of 2"
                      :key="'sk' + i"
                      type="list-item"
                      class="ma-1"
                    />
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          :loading="loading"
          type="submit"
          color="accent"
          class="ma-2 ml-4"
          @click="submit()"
        >
          <v-icon
            class="mr-2"
          >
            $saveItem
          </v-icon>
          {{ $t('base.save') }}
        </v-btn>
      </v-form>
    </template>
    <template v-else>
      <v-skeleton-loader
        type="card-heading, list-item"
        class="ma-1"
      />
    </template>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {UserAPI} from "@/api/UserAPI";

    export default {
        name: "UserPermissionsList",
        props: {
            userId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            loading: false,
            stocks: [],
            allowedSubstocks: []
        }),
        createdOrActivated: function () {
            this.loading = true;
            Promise.all([
                this.fetchStocks(),
                this.fetchPermissions()
            ])
                .catch(err => {
                    this.snack(err);
                    this.$router.push('/users');
                }).finally(() => {
                    this.loading = false;
                });
        },
        methods: {
            fetchStocks: function () {
                return new Promise((resolve, reject) => {
                    const substockPromises = [];
                    StockAPI.getAllPages().then(response => {
                        this.stocks = response.data.items;
                        this.stocks.forEach(stock => {
                            stock.loading = true;
                            substockPromises.push(
                                StockAPI.getAllSubstockPages(stock.id, {onlyAllowed: false}).then(response => {
                                    this.$set(stock, 'substocks', response.data.items);
                                    stock.loading = false;
                                }));
                        });
                    }).catch(reject);
                    Promise.all(substockPromises)
                        .then(resolve)
                        .catch(reject);
                });
            },
            fetchPermissions: function () {
                return UserAPI.getPermissions(this.userId)
                    .then(response => {
                        this.allowedSubstocks = response.data.permissions
                            .filter(perm => perm.allowed)
                            .map(perm => perm.substock_id);
                    });
            },
            submit: function () {
                this.loading = true;
                UserAPI.setPermissions(this.userId, this.allowedSubstocks)
                    .then(() => {
                        this.snack('base.saved');
                        this.fetchPermissions();
                    })
                    .catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="sass">

.scrollable-list
  height: 250px
  overflow-y: scroll

</style>
