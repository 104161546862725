import {Roles} from "@/enum/roles";

export default {
    title: "Felhasználók",
    link: "Felhasználók",

    detail: "Felhasználó részletek",

    username: "Felhasználónév",
    email: "Email",
    first_name: "Név",
    last_name: "Utónév",
    password: "Jelszó",
    phone: "Telefon",
    active: "Aktív",
    roles: "szerepek",
    is_storekeeper: "Raktárkezelő",
    is_chief: "Raktárvezető",
    is_packer: "Csomagoló",

    role: {
        [Roles.STOREKEEPER]: 'Raktáros',
        [Roles.CHIEF]: 'Menedzser',
        [Roles.PACKER]: 'Csomagoló'
    },

    list: {
        unableToLoad: "A felhasználói listát nem sikerült betölteni. Kérjük, próbálja meg később újra."
    },
    create: {
        title: "Felhasználó létrehozása",
        label: 'Új felhasználó létrehozása',
        done: "Felhasználó '{0}' létrehozva.",
        undo: {
            done: "A '{0}' létrehozása sikeres!",
            failed: "A felhasználó létrehozása nem sikerült!"
        }
    },
    update: {
        titleSimple: "Felhasználó szerkesztése",
        title: "Szerkesztés {0}",
        done: "Felhasználó '{0}' módosította",
        password: {
            password: "Új jelszó",
            done: "Felhasználó {0} jelszava megváltozott!"
        },
        undo: {
            done: "Szerkesztés '{0}' sikeres!",
            failed: "Nem sikerült visszaállítani a felhasználó szerkesztését!"
        }
    },

    chooseOneRole: "Kérjük, válasszon legalább egy szerepet!",
    isNotChief: "A létrehozott felhasználó nem rendelkezik felügyelői szerepkörrel.",
    changePassword: "Jelszó módosítása",
    cannotChangePasswordOfAnotherChief: "Egy másik vezető jelszavát nem változtathatja meg, csak a raktári dolgozókét és a csomagolókét.",

    permissions: {
        title: "Hozzáférés az almappákhoz",
        info: "Ez a beállítás korlátozza a felhasználó számára megjelenő feladatokat. Ha a felhasználó raktárkezelői szerepet tölt be, szabadon beállíthatja, hogy mely alraktárakat kívánja kezelni.",
        hasAccess: "Hozzáférése van",
        addAccess: "Hozzáférés engedélyezése ehhez az almappához",
        removeAccess: "Hozzáférés megszüntetése ehhez az almappához"
    },

    settings: {
        status: {
            403: "Nem férhetsz hozzá valaki más beállításaihoz!",
            404: "A felhasználói beállítások nem találhatók!",
            409: "Ez a beállítás már létezik!"
        }
    },

    status: {
        404: "A megadott felhasználó nem létezik!",
        409: "Egy másik felhasználó már megadta a felhasználónevet!"
    }
};
