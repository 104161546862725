import {iconAliases} from "@/enum/icons";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import {themes} from "@/styles/theme";
import en from "vuetify/es5/locale/en";
import cs from "@/lang/vuetify.cs";
import sk from "@/lang/vuetify.sk";
import hu from "@/lang/vuetify.hu";

Vue.use(Vuetify);


const VuetifyInstance = new Vuetify({
    icons: {
        iconfont: 'md',
        values: iconAliases
    },
    lang: {
        locales: {en, cs, sk, hu},
        current: 'cs'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes
    }
});

/**
 * Vuetify's goTo does not seem to work with Router...
 * @param targetId
 */
function scrollTo(targetId) {
    const el = document.getElementById(targetId);
    if (el) {
        window.scrollTo({top: el.offsetTop});
    }
}

export {VuetifyInstance as Vuetify, scrollTo};
