const ShipmentState = {
    CREATED: 'CREATED',
    WAITING_FOR_PICKUP: 'WAITING_FOR_PICKUP',
    SENT: 'SENT',
    BEING_DELIVERED: 'BEING_DELIVERED',
    DELIVERED_TO_PICKUP_LOCATION: 'DELIVERED_TO_PICKUP_LOCATION',
    DELIVERED: 'DELIVERED',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    LOST: 'LOST',
    TO_BE_CANCELLED: 'TO_BE_CANCELLED',
    CANCELLED: 'CANCELLED'
};

const TerminalShipmentStates = [
    ShipmentState.DELIVERED,
    ShipmentState.RETURNED,
    ShipmentState.LOST
];

export {ShipmentState, TerminalShipmentStates};
