/**
 * @see https://cli.vuejs.org/guide/mode-and-env.html
 * @type {{DOMAIN_LABEL, AUTH_URL, API_URL, OAUTH_CLIENT_ID, APP_URL}}
 */
const config = {
    DOMAIN_LABEL: process.env.VUE_APP_DOMAIN_LABEL,
    API_URL: process.env.VUE_APP_API_URL,
    AUTH_URL: process.env.VUE_APP_AUTH_BASE,
    APP_URL: window.location.origin,
    OAUTH_CLIENT_ID: process.env.VUE_APP_OAUTH_CLIENT_ID
};

export {config};
