import {ProductAPI} from "@/api/ProductAPI";
import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
import {has} from "@/utils/object";
import {mapReduce, update} from "@/utils/array";
import {EventBus} from "@/service/EventBus";
import {StockStatusAPI} from "@/api/StockStatusAPI";
import {taskTypes} from "@/enum/task_type";
import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
import {StockAPI} from "@/api/StockAPI";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {IndexedDB} from "@/service/cache/IndexedDB";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskAllowedLocationsMixin} from "@/app/mixins/TaskAllowedLocationsMixin";
import debounce from "lodash.debounce";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";

const MAX_INSTANCES_PER_REQUEST = 100;

/**
 * Requires:
 * - this.API.getAllItems()
 * - this.items
 * - this.taskInfo.taskId
 * - this.taskInfo.details
 * - this.taskInfo.movementType (if STOCK_LOADING or SUBSTOCK_TRANSFER)
 */
const TaskFetchItemsMixin = {
    mixins: [TaskTypeMixin, TaskStateMixin, ReactiveLocationCacheMixin, TaskAllowedLocationsMixin],
    created: function () {
        EventBus.$on('fetch-allowed-locations', (item, reallyFetch) => {
            if (this.loadAllowedLocations || reallyFetch) {
                this.fetchInstanceAllowedLocations(item, reallyFetch);
            }
        });
        EventBus.$on('fetch-already-placed-at', item => {
            this.fetchInstanceAlreadyPlacedAt(item);
        });
    },
    computed: {
        subStockId: function () {
            return (this.taskInfo.details
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.source_subordinate_stock)
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.source_subordinate_stock).id
            );
        },
        destinationSubStock: function () {
            return (this.taskInfo.details
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.destination_subordinate_stock)
            );
        },
        fetchQuantitiesDebounced: function () {
            return debounce(this.fetchQuantities, 5000);
        },
    },
    methods: {
        fetchItems: function (config = {}) {
            const {initial, debounceQuantities, onlyInstanceId} = {
                ...{
                    initial: false,
                    debounceQuantities: false,
                    onlyInstanceId: null
                }, ...config
            };
            return new Promise((resolve, reject) => {
                this.API.getAllItems(this.taskInfo.taskId)
                    .then(response => {
                        let items = this.items.sort((a, b) => b.id - a.id);
                        if (initial) {
                            items = response.data.sort((a, b) => b.id - a.id);
                        } else {
                            update(items, response.data.sort((a, b) => b.id - a.id));
                        }
                        items = items.sort((a, b) => 0.5 - ((a.updated_at || a.created_at) > (b.updated_at || b.created_at)));
                        this.items = items;
                        this.fetchUnknownInstances()
                            .then(() => {
                                const promises = [
                                    this.getInstancesAllowedLocationsFromCache(),
                                    this.fetchUnknownProducts()
                                ];
                                if (onlyInstanceId !== null) {
                                    promises.push(this.fetchQuantities(onlyInstanceId));
                                }
                                if (debounceQuantities) {
                                    this.fetchQuantitiesDebounced();
                                } else if (!onlyInstanceId) {
                                    promises.push(this.fetchQuantities());
                                }
                                Promise.all(promises).then(resolve).catch(reject);
                            });
                    });
            });
        },
        fetchUnknownInstances: function () {
            return new Promise((resolve, reject) => {
                const promises = [];
                this.items
                    .filter(item => item.instance === undefined)
                    .filter(item => item.product_instance_id)
                    .forEach(item => {
                        promises.push(ProductAPI.getInstanceWOProduct(item.product_instance_id)
                            .then(response => {
                                this.$set(item, 'instance', response.data);
                            })
                        );
                    });
                Promise.all(promises)
                    .then(resolve)
                    .catch(err => {
                        this.snack(err);
                        reject();
                    });
            });
        },
        fetchUnknownProducts: function () {
            return new Promise((resolve, reject) => {
                const promises = [];
                this.items
                    .filter(item => item.instance === undefined)
                    .filter(item => item.product === undefined)
                    .filter(item => item.product_id)
                    .forEach(item => {
                        promises.push(ProductAPI.get(item.product_id)
                            .then(response => {
                                this.$set(item, 'product', response.data);
                            })
                        );
                    });
                Promise.all(promises)
                    .then(resolve)
                    .catch(err => {
                        this.snack(err);
                        reject();
                    });
            });
        },
        fetchQuantities: function (onlyInstanceId = null) {
            return new Promise((resolve, reject) => {
                if (this.isAnyOfTypes([
                    taskTypes.DELIVERY_ACCEPT,
                    taskTypes.STOCK_LOADING,
                    taskTypes.STOCK_TAKING,
                    taskTypes.LOCATION_TRANSFER,
                    taskTypes.EXTERNAL_ORDER
                ])) {
                    resolve();
                } else {
                    const fetchingForItems = this.items.filter(item =>
                        onlyInstanceId === null
                            ? item.instance && item.instance.id
                            : item.instance && item.instance.id === onlyInstanceId
                    );
                    const filterObject = [{
                        [APIFilterOP.EQUALS]: {
                            'substock.id': this.subStockId
                        }
                    }, {
                        [APIFilterOP.GREATER_THAN]: {
                            quantity: 0
                        }
                    }];
                    const fetchInstanceIds = [...new Set(fetchingForItems.map(item => item.instance.id))];
                    const promises = [];
                    while (fetchInstanceIds.length) {
                        promises.push(StockStatusAPI.getShortAllPages({
                            filter: APIFilters.makeFilter([...filterObject, {
                                [APIFilterOP.IN]: {
                                    'product_instance.id': fetchInstanceIds.splice(0, MAX_INSTANCES_PER_REQUEST)
                                }
                            }])
                        }));
                    }
                    Promise.all(promises).then(responses => {
                        const stockLocations = responses.reduce(
                            (acc, curr) => acc.concat(curr.data.items.filter(
                                location => location.stock_location.user === undefined
                            )), []
                        );
                        fetchingForItems.forEach(item => {
                            this.$set(item, 'locations', stockLocations.filter(location => location.product_instance_id === item.instance.id));
                            // TODO CRITICAL filter by allowed in substock
                        });
                        onlyInstanceId === null && EventBus.$emit('taskItems-quantitiesLoaded');
                        resolve();
                    }).catch(reject);
                }
            });
        },
        fetchSubstockItems: function (ssId) {
            this.loadingSubstockItems = true;
            StockStatusAPI.getCurrentInStock(ssId)
                .then(response => {
                    const locations = {};
                    response.data.items.map(stockStatus => {
                        if (has(stockStatus.stock_location, 'user') || stockStatus.quantity === 0) {
                            return stockStatus;
                        }
                        if (locations[stockStatus.product_instance.id] === undefined) {
                            locations[stockStatus.product_instance.id] = [{...stockStatus}];
                        } else {
                            locations[stockStatus.product_instance.id].push({...stockStatus});
                        }
                        return stockStatus;
                    });
                    response.data.items = mapReduce(response.data.items, ['product_instance', 'id'], 'quantity');
                    response.data.items = response.data.items.map(item => {
                        item.locations = locations[item.product_instance.id];
                        return item;
                    });
                    StockStatusAPI.getCurrentReservations(ssId)
                        .then(secondResponse => {
                            response.data.items.map(stockStatus => {
                                stockStatus.blocked = secondResponse.data.find(stockBlockedStatus =>
                                    stockBlockedStatus.product_instance.id === stockStatus.product_instance.id).blocked;
                                return stockStatus;
                            });
                            this.$set(this.substockItems, ssId, response.data.items
                                .map(el => ({
                                    instance: el.product_instance,
                                    locations: el.locations,
                                    quantity: el.quantity,
                                    blocked: el.blocked,
                                    value: el.product_instance.id
                                }))
                                .filter(el => el.quantity - el.blocked > 0)
                                .sort((a, b) => 0.5 - (
                                    this.$options.filters.instanceLabel(a.instance) < this.$options.filters.instanceLabel(b.instance)
                                )));
                        }).catch(this.snack)
                        .finally(() => this.loadingSubstockItems = false);
                }).catch((err) => {
                this.snack(err);
                this.loadingSubstockItems = false;
            });
        },
        getInstancesAllowedLocationsFromCache: function () {
            // gets allowed locations if they are present in cache
            return new Promise((resolve) => {
                if (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.SUBSTOCK_TRANSFER])
                    || (this.isType(taskTypes.MOVE_PRODUCTS)
                        && [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)
                    )) {
                    const promises = [];
                    const locationIds = new Set();
                    this.items
                        .filter(item => item.instance && item.instance.id)
                        .forEach(item => {
                            promises.push(this.getInstanceAllowedLocationsFromCache(item.instance.id)
                                .then(data => {
                                    this.$set(item, 'allowedLocationIds', data);
                                    data.forEach(locationId => {
                                        locationIds.add(locationId);
                                    });
                                })
                            );
                        });
                    // We do not care if some promises failed
                    Promise.allSettled(promises)
                        .finally(() => {
                            [...locationIds].forEach(locationId => {
                                this.cacheLocationLazy(StockAPI.getLocationWOStock.bind(StockAPI, locationId), locationId);
                            });
                            this.computeAllowedInstances();
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        getInstanceAllowedLocationsFromCache: function (instanceId) {
            return IndexedDB.get(CachePath.allowedLocationIds, [this.destinationSubStock.stock_id, this.destinationSubStock.id, instanceId].join('-'));
        },
        fetchInstanceAllowedLocations: function (item, reallyFetch = false) {
            return new Promise((resolve, reject) => {
                if (item.instance && item.instance.id && (item.allowedLocationIds === undefined || reallyFetch)) {
                    StockAPI.getSubstockAllowedLocationIds(this.destinationSubStock.stock_id, this.destinationSubStock.id, item.instance.id, reallyFetch)
                        .then(response => {
                            this.$set(item, 'allowedLocationIds', response.data);
                            response.data.forEach(locationId => {
                                this.cacheLocationLazy(StockAPI.getLocationWOStock.bind(StockAPI, locationId), locationId);
                            });
                            this.computeAllowedInstances();
                            resolve();
                        }).catch(reject);
                } else {
                    resolve();
                }
            });
        },
        fetchInstanceAlreadyPlacedAt: function (item) {
            return new Promise((resolve, reject) => {
                if (item.instance && item.instance.id && item.alreadyPlacedAt === undefined) {
                    StockStatusAPI.getAllPages({
                        filter: APIFilters.makeFilter([
                            {[APIFilterOP.EQUALS]: {'product_instance.id': item.instance.id}},
                            {[APIFilterOP.GREATER_THAN]: {quantity: 0}}
                        ]),
                        sort: APIFilters.makeSort({quantity: 'DESC'})
                    }).then(response => {
                        const filtered = response.data.items
                            .filter(location => location.stock_location.user === undefined);
                        filtered.forEach(location => {
                            delete location.product_instance;
                        });
                        const value = {};
                        for (const location of filtered) {
                            value[location.stock_location.id] = location;
                        }
                        this.$set(item, 'alreadyPlacedAt', value);
                        if (!this.isClosed) { // When closed, data are already good from stock-status
                            this.enrichAlreadyPlacedAtByLocationsInThisTask(item);
                            if (!item.enrichAlreadyPlacedAtByLocationsInThisTaskWatcherEnabled) {
                                item.enrichAlreadyPlacedAtByLocationsInThisTaskWatcherEnabled = true;
                                this.$watch(() => item.destination_locations, () => {
                                    this.enrichAlreadyPlacedAtByLocationsInThisTask(item);
                                }, {deep: true});
                            }
                        }
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },
        enrichAlreadyPlacedAtByLocationsInThisTask: function (item) {
            for (const locationInThisTask of [...item.destination_locations]) {
                const locationInAlreadyPlaced = item.alreadyPlacedAt[locationInThisTask.location_id || locationInThisTask.stock_location_id];
                if (locationInAlreadyPlaced !== undefined) {
                    if (locationInAlreadyPlaced.original_quantity === undefined) {
                        // On the first walkthrough, save the original quantity from StockStatus
                        locationInAlreadyPlaced.original_quantity = locationInAlreadyPlaced.quantity;
                    }
                    let quantityInThisTask = 0;
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                        quantityInThisTask = locationInThisTask.store_quantity;
                    }
                    if (this.isType(taskTypes.STOCK_LOADING)) {
                        quantityInThisTask = locationInThisTask.quantity;
                    }
                    locationInAlreadyPlaced.quantity = locationInAlreadyPlaced.original_quantity + quantityInThisTask;
                } else {
                    const newLocationInAlreadyPlaced = {...locationInThisTask};
                    newLocationInAlreadyPlaced.stock_location = this.LocationCache[newLocationInAlreadyPlaced.location_id];
                    newLocationInAlreadyPlaced.original_quantity = 0;
                    this.$set(item.alreadyPlacedAt, newLocationInAlreadyPlaced.location_id, newLocationInAlreadyPlaced);
                }
            }
        },
        readingDoneNoVibrate: function () {
            EventBus.$emit('reading-done', false);
        },
        readingDone: function (doVibrate = true) {
            EventBus.$emit('reading-done', doVibrate);
        },
        readingFail: function (message) {
            EventBus.$emit('reading-fail', message);
        }
    }
};

export {TaskFetchItemsMixin};
