import {TaskAPI} from "@/api/TaskAPI";
import {Roles} from "@/enum/roles";
import {Store} from "@/service/store/Store";
import {taskLinks} from "@/enum/task_type";
import TasksOverview from "@/app/overview/tasks/TasksOverview.view";

const BASE_URL = '/task';

const routes = {
    show: {
        path: BASE_URL + '/:taskId(\\d+)',
        beforeEnter: (to, from, next) => {
            TaskAPI.get(to.params.taskId)
                .then(response => {
                    next(taskLinks[response.data.type] + '/' + to.params.taskId);
                }).catch(err => {
                    Store.commit('snackbar/set', {text: err});
                    next('/');
                });
        },
        meta: {
            requireRole: [Roles.STOREKEEPER, Roles.CHIEF, Roles.PACKER],
        }
    },
    overview: {
        name: 'tasksOverview',
        path: '/tasks',
        component: TasksOverview,
        meta: {
            title: 'homepage.tasks',
            requireRole: [Roles.CHIEF, Roles.STOREKEEPER, Roles.PACKER],
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
