<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="buyers.addresses."
          />
          <FormAddress
            :form="form.address"
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {BuyerAddressForm, BuyerAddressRender} from "@/app/buyers/definitions/buyerAddress.form";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import FormAddress from "@/app/components/form/FormAddress.component";

    export default {
        name: "BuyerAddressCreateUpdate",
        components: {FormAddress, FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new BuyerAddressForm,
            formRender: new BuyerAddressRender,
            valid: true,
            loading: false,
            buyerName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.buyerName !== '') {
                    if (this.isEdit) {
                        title = this.$t('buyers.addresses.update.title', [this.buyerName]);
                    } else {
                        title = this.$t('buyers.addresses.create.title', [this.buyerName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            BuyerAPI.get(this.buyerId)
                .then(response => {
                    this.buyerName = response.data.name;
                }).catch(this.snack);
        },
        methods: {
            formFetchItem: function () {
                return BuyerAPI.getAddress(this.buyerId, this.addressId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    BuyerAPI.updateAddress(this.buyerId, this.addressId, this.form)
                        .then(() => {
                            this.snack('buyers.addresses.update.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    BuyerAPI.createAddress(this.buyerId, this.form)
                        .then(() => {
                            this.snack('buyers.addresses.create.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
