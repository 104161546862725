<template>
  <div>
    <component
      :is="filterGroup.configComponent"
      :filter="filter"
      :possible-values="possibleValues"
      :lang-path="langPath"
      :hardcoded-attribute="hardcodedAttribute"
      :allowed-filter-types="allowedFilterTypes"
    >
      <template #operator>
        <v-autocomplete
          outlined
          dense
          hide-details
          :label="$t('base.filterConfig.condition')"
          :value="filterType"
          :items="filterTypes"
          @input="filterTypeChanged"
        />
      </template>
    </component>
  </div>
</template>

<script>
    import FilterConfigUnaryOp from "@/app/components/filterConfig/FilterConfigUnaryOp.component";
    import FilterConfigBinaryOp from "@/app/components/filterConfig/FilterConfigBinaryOp.component";
    import FilterConfigTernaryOp from "@/app/components/filterConfig/FilterConfigTernaryOp.component";
    import FilterConfigArrayOp from "@/app/components/filterConfig/FilterConfigArrayOp.component";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {APIFilterMixin} from "@/app/mixins/APIFilterMixin";
    import {APIFilterDataTypeAllowedOperations} from "@/service/APIFilters";

    export default {
        name: "GeneralConfigFilterPart",
        components: {
            // dynamic components have to be manually imported
            FilterConfigUnaryOp, FilterConfigBinaryOp, FilterConfigTernaryOp, FilterConfigArrayOp,
            // recursive component has to be imported this way
            FilterConfigGroupOp: () => import("@/app/components/filterConfig/FilterConfigGroupOp.component")
        },
        mixins: [APIFilterMixin],
        computed: {
            filterGroup: function () {
                return APIFilters.getType(this.filterType);
            },
            filterTypes: function () {
                const possibleDataTypes = [...new Set(Object.values(this.possibleValues).map(val => val.type))];
                const allowedForDataTypes = new Set(possibleDataTypes.map(dataType => APIFilterDataTypeAllowedOperations[dataType]).flat());
                const allowed = Object.values(APIFilterOP)
                    .filter(filter => {
                        if (filter === APIFilterOP.FULL_TEXT) {
                            return false;
                        }
                        if (this.allowedFilterTypes !== null) {
                            return this.allowedFilterTypes.indexOf(filter) !== -1;
                        }
                        return allowedForDataTypes.has(filter);
                    });
                return allowed
                    .filter(filter => {
                        if (this.filterGroup.type === 'group') {
                            return APIFilters.getType(filter).type === 'group';
                        } else {
                            return APIFilters.getType(filter).type !== 'group';
                        }
                    })
                    .map(filter => ({
                        text: this.$t('base.filter.' + filter, ['...', '...', '...']),
                        value: filter
                    }));
            }
        },
        methods: {
            filterGroupValue: function (attr) {
                const defaultValue = this.getDefaultTypeValue(attr);
                const defaultValue2 = this.getDefaultTypeValue(attr);
                return {
                    group: [{
                        [this.newType]: {[attr]: defaultValue}
                    }],
                    unary: attr,
                    binary: {[attr]: defaultValue},
                    ternary: {[attr]: [defaultValue, defaultValue2]},
                    array: {[attr]: []},
                };
            },
            filterTypeChanged: function (newValue) {
                const possibleKeys = Object.keys(this.possibleValues).filter(key => {
                    return APIFilters.isFilterOPAllowedForType(newValue, this.possibleValues[key].type);
                });
                const curFilter = this.filter[this.filterType];
                const curKey = typeof curFilter === 'object' ? Object.keys(this.filter[this.filterType])[0] : curFilter;
                const oldValue = this.filterType;
                const newType = APIFilters.getType(newValue).type;
                const newKey = possibleKeys.includes(curKey) ? curKey : possibleKeys[0];

                if (APIFilters.getType(oldValue) === APIFilters.getType(newValue)) {
                    this.$set(this.filter, newValue, this.filter[oldValue]);
                } else {
                    this.$set(this.filter, newValue, this.filterGroupValue(newKey)[newType]);
                }
                delete this.filter[oldValue];
            }
        }
    };
</script>

<style scoped>

</style>
