<template>
  <div>
    <v-app-bar
      class="elevation-0"
      style="cursor: pointer"
      @click="clickLogo"
    >
      <v-img
        :src="publicPath + 'favicon/swordfish_logo.svg'"
        contain
        max-height="40px"
        max-width="39px"
      />
      <span
        class="v-toolbar__title ml-4"
      >
        {{ title }}
      </span>
    </v-app-bar>
    <div>
      <Search class="ma-3" />
      <v-divider />
    </div>
    <div
      v-for="(section, index) of sections"
      :key="index"
      class="mb-2"
    >
      <v-divider
        v-if="index !== 0"
      />
      <v-subheader>
        {{ $t(section.header) }}
        <v-spacer />
      </v-subheader>
      <v-list>
        <v-list-item
          v-for="item of section.items"
          :key="item.to"
          :to="item.to"
          exact
          :class="item.submenu ? 'text-body-2 ml-4 mt-n2' : ''"
          :style="item.submenu ? '' : 'z-index: 5'"
        >
          <v-list-item-action>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t(item.label) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
    import Search from "@/app/components/search/Search.component";
    import {config} from "@/config/config";

    export default {
        name: "DrawerMenu",
        components: {Search},
        data: () => ({
            publicPath: process.env.BASE_URL,
            sections: [
                {
                    header: 'homepage.overview',
                    items: [
                        {
                            to: '/',
                            label: 'homepage.tasks',
                            icon: '$task'
                        }, {
                            to: '/status',
                            label: 'stocks.stockStatus.link',
                            icon: '$stockStatus'
                        }, {
                            to: '/status/locations',
                            label: 'stocks.stockStatus.locations.link',
                            icon: '$stockLocationsStatus',
                            submenu: true
                        }, {
                            to: '/movements',
                            label: 'stocks.stockMovements.link',
                            icon: '$stockMovements'
                        // }, { // TODO #15618
                        //     to: '/time',
                        //     label: 'tasks.timeEntries.link',
                        //     icon: 'timer'
                        }, {
                            to: '/shipping',
                            label: 'base.shipping.link',
                            icon: '$shipping'
                        }, {
                            to: '/shipping/report',
                            label: 'base.shipping.report.link',
                            icon: '$shippingReport',
                            submenu: true
                        }, {
                            to: '/shipping/protocols',
                            label: 'base.shipping.handover.link',
                            icon: '$shippingHandover',
                            submenu: true
                        }
                    ]
                }, {
                    header: 'homepage.manage',
                    items: [
                        {
                            to: '/products',
                            label: 'products.link',
                            icon: '$product'
                        }, {
                            to: '/products/types',
                            label: 'products.types.link',
                            icon: '$productType',
                            submenu: true
                        }, {
                            to: '/stocks',
                            label: 'stocks.link',
                            icon: '$stock'
                        }, {
                            to: '/stocks/owners',
                            label: 'stocks.owners.link',
                            icon: '$owners',
                            submenu: true
                        }, {
                            to: '/manufacturers',
                            label: 'manufacturers.link',
                            icon: '$manufacturer'
                        }, {
                            to: '/suppliers',
                            label: 'suppliers.link',
                            icon: '$supplier'
                        }, {
                            to: '/carriers',
                            label: 'carriers.link',
                            icon: '$carrier'
                        }, {
                            to: '/buyers',
                            label: 'buyers.link',
                            icon: '$buyers'
                        }, {
                            to: '/buyers/types',
                            label: 'buyers.types.link',
                            icon: '$buyerType',
                            submenu: true
                        }, {
                            to: '/users',
                            label: 'users.link',
                            icon: '$users'
                        }
                    ]
                }
            ]
        }),
        computed: {
            title: function () {
                return config.DOMAIN_LABEL;
            }
        },
        methods: {
            clickLogo: function () {
                if (this.$route.fullPath !== '/') {
                    this.$router.push('/');
                }
            }
        }
    };
</script>

<style scoped>

</style>
