<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('stocks.locations.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="stocks.locations."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {StockLocationForm, StockLocationRender} from "@/app/stocks/locations/definitions/stockLocation.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {tabTitle} from "@/utils/string";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {EventBus} from "@/service/EventBus";
    import {AllowedLocationIdsCacheMixin} from "@/app/mixins/AllowedLocationIdsCacheMixin";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "StockLocationCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, AllowedLocationIdsCacheMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            },
            parentStockId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            form: new StockLocationForm,
            formRender: new StockLocationRender,
            valid: true,
            loading: false,
            code: null,
            stockName: '',
            batchNames: []
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.stockName !== '') {
                    if (this.isEdit) {
                        title = this.$t('stocks.locations.update.title', [this.form.name, this.stockName]);
                    } else {
                        title = this.$t('stocks.locations.create.title', [this.stockName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            if (!this.isComponent) {
                StockAPI.get(this.stockId)
                    .then(response => {
                        this.stockName = response.data.name;
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/stocks');
                    });
            } else {
                this.stockId = this.parentStockId;
            }
        },
        methods: {
            formFetchItem: function () {
                return StockAPI.getLocation(this.stockId, this.locationId)
                    .then(response => {
                        this.code = response.data.code;
                        return response;
                    });
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    StockAPI.updateLocation(this.stockId, this.locationId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'stocks.locations.update.done',
                                params: [this.form.name]
                            });
                            IndexedDB.clearByKey(CachePath.barcodes, this.code);
                            IndexedDB.clearByKey(CachePath.locations, this.locationId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/stocks/' + this.stockId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    if (this.form.batch) {
                        // A little hack for batch create, only Jagu should use this, TODO improve later for user usage as well.
                        this.batchNames = this.form.batch.split(';');
                        this.form.batch = null;
                        new Promise((resolve) => {
                            this.batchCreateRecurse(resolve);
                        }).then(() => {
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/stocks/' + this.stockId);
                        });
                    } else {
                        StockAPI.createLocation(this.stockId, this.form)
                            .then(response => {
                                this.advancedSnack({
                                    text: 'stocks.locations.create.done',
                                    params: [this.form.name]
                                });
                                this.clearAllowedLocationIdsCacheByStock(this.stockId);
                                if (this.isComponent) {
                                    EventBus.$emit('create-location-created', getIdFromLocation(response));
                                } else {
                                    this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                    this.$router.push('/stocks/' + this.stockId);
                                }
                            }).catch(setFormErrors.bind(this))
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            },
            batchCreateRecurse: function (resolve) {
                if (this.batchNames.length > 0) {
                    const name = this.batchNames.shift();
                    this.form.name = name;
                    this.form.code = name;
                    StockAPI.createLocation(this.stockId, this.form)
                        .finally(() => {
                            this.batchCreateRecurse(resolve);
                        });
                } else {
                    this.batchNames = [];
                    resolve();
                }
            }
        },
        close: function () {
            EventBus.$emit('create-location-cancelled');
        }
    };
</script>

<style scoped>

</style>
