import { render, staticRenderFns } from "./DashboardConfig.view.vue?vue&type=template&id=15d184a7&scoped=true&"
import script from "./DashboardConfig.view.vue?vue&type=script&lang=js&"
export * from "./DashboardConfig.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d184a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VContainer,VIcon,VItemGroup,VSkeletonLoader,VTooltip})
