import {APIFilterDataType} from "@/service/APIFilters";

const taskFilterColumns = {
    id: {
        type: APIFilterDataType.NUMBER,
        values: null
    },
    parent_task_id: {
        type: APIFilterDataType.NUMBER,
        values: null
    },
    'substock.id': {
        type: APIFilterDataType.NUMBER,
        values: []
    },
    external_id: {
        type: APIFilterDataType.TEXT,
        values: null
    },
    state: {
        type: APIFilterDataType.NUMBER,
        values: []
    },
    priority: {
        type: APIFilterDataType.NUMBER,
        values: []
    },
    type: {
        type: APIFilterDataType.TEXT,
        values: []
    },
    'assigned_user.id': {
        type: APIFilterDataType.NUMBER,
        values: []
    },
    created_at: {
        type: APIFilterDataType.DATE,
        values: null
    },
    updated_at: {
        type: APIFilterDataType.DATE,
        values: null
    }
};

export {taskFilterColumns};
