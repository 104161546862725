import { render, staticRenderFns } from "./StocksList.view.vue?vue&type=template&id=2b196dd4&scoped=true&"
import script from "./StocksList.view.vue?vue&type=script&lang=js&"
export * from "./StocksList.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b196dd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VIcon})
