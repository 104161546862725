export default {
    link: "Carriers",
    title: "Carriers",
    name: "Name",
    type: "Type",
    active: "Active",
    notActive: "Inactive",
    services: {
        title: "Services",
        noServices: "No available services",
        showAll: "Show all",
        hideAll: "Hide all",

        name: "Service",
        carrier_service_parameters: "Shipment parameters",
        required_carrier_service_parameters: "Required shipment parameters",
        optional_carrier_service_parameters: "Optional shipment parameters",
        type: "Label print format",
        create: {
            title: "Create carrier service",
            done: "Carrier service '{0}' created."
        },
        update: {
            titleSimple: "Update carrier service",
            title: "Update {0}",
            done: "Carrier service '{0}' updated"
        },
        delete: {
            done: "Carrier service '{0}' deleted.",
            failed: "Unable to delete '{0}'. Please try again later.",
        },
    },
    tableImage: "",
    tableFoilImage: "Foil",
    doesNotSupportMultiPackageShipments: "This carrier does not support multi-package shipments",
    searchHint: "Searches through name and type",

    show: {
        titleSimple: "Carrier details",
        configInSubstock: "You can configure carrier connection in each sub-warehouse's details panel."
    },

    create: {
        title: "Create carrier",
        done: "Carrier '{0}' created."
    },
    update: {
        titleSimple: "Update carrier",
        title: "Update {0}",
        done: "Carrier '{0}' updated"
    },
    delete: {
        done: "Carrier '{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
    },
    status: {
        404: "Carrier does not exist!",
        409: "Carrier name is already in use!"
    },

    images: {
        nameFoil: "Foil image",
        alt: "Image attached to carrier",
        altCreate: "Picture to be attached to carrier",
        unableToLoad: "Unable to load carrier image. Please try again later.",
        create: {
            foil: {
                titleSimple: "Add foil picture",
                title: "Add foil picture to '{0}'",
                done: "Foil picture added to '{0}'",
            }
        },
        update: {
            foil: {
                titleSimple: "Update foil picture",
                title: "Update foil picture of '{0}'",
                done: "Foil picture of '{0}' updated",
            }
        },
        status: {
            404: "Carrier or image not found!"
        }
    },

    contracted: {
        label: "Contracted",
        parameters: {
            required: {
                name: "Required parameters"
            },
            optional: {
                name: "Optional parameters"
            },
            key: "Name",
            value: "Value",
            writeOnly: "Cannot be loaded due to security reasons"
        },

        create: {
            label: "Add to owner's contracted carrier list",
            titleSimple: "Set contracted carrier's parameters",
            title: "Set parameters of '{0}'",
            info: "You are setting parameters of '{0}' for '{1}'",
            done: "'{0}' added to owner's contracted carrier list"
        },
        update: {
            label: "Update parameters of owner's contracted carrier.",
            titleSimple: "Update parameters of contracted carrier",
            title: "Update parameters of '{0}'",
            info: "You are updating parameters of '{0}' for '{1}'",
            done: "Parameters of '{0}' updated",
            substock: {
                title: "Contract for this sub-warehouse",
                done: "'{0}' is now contracted for this sub-warehouse"
            }
        },
        delete: {
            titleSimple: "Remove from owner's contracted carrier list",
            done: "'{0}' removed from owner's contracted carrier list",
            substock: {
                title: "Remove contract for this sub-warehouse",
                done: "'{0}' has been removed from this sub-warehouse."
            }
        }
    },

    run: {
        job: "Run a background job",
        validatePackages: "Shipment validation",
        generateProtocols: "Handover protocols",
        trackPackages: "Shipment tracking"
    }
};
