<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/stocks/' + stockId + '/locations/' + locationId + '/update'"
              >
                <v-icon>
                  $updateItem
                </v-icon>
              </v-btn>
              {{ details.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div>
                      <span class="text-caption">{{ $t('stocks.locations.code') + ': ' }}</span>
                      {{ details.code }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('stocks.locations.is_expedition') + ': ' }}</span>
                      {{ details.is_expedition | yesOrNo }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('stocks.locations.is_mobile') + ': ' }}</span>
                      {{ details.is_mobile | yesOrNo }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <EntityHistory :changes="details" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout wrap>
        <v-flex
          xs12
          class="pa-1"
        >
          <StockLocationLockTypes
            @locksLoaded="onLocksLoaded"
          />
        </v-flex>
        <v-flex
          xs12
          class="pa-1"
        >
          <v-expansion-panels
            :value="0"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle-1">
                {{ $t('stocks.locations.show.label') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <x-data-table
                  :headers="headers"
                  :loading="loading"
                  :items="items"
                  :item-class="item => item.visible === false && 'text--disabled'"
                  :actions="actions"
                >
                  <template #item.tableImage="{ item }">
                    <td>
                      <v-img
                        v-if="item.default_image !== null"
                        :src="item.default_image"
                        contain
                        max-height="40px"
                        max-width="40px"
                        style="float: right"
                      />
                    </td>
                  </template>
                </x-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
    <x-btn-fab-expander
      v-if="!hasAllLockTypes"
      :actions="fabActions"
    />
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {createHeaders} from "@/utils/table";
    import {TableFilter} from "@/enum/table_filter";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import StockLocationLockTypes from "@/app/stocks/locations/locks/components/StockLocationLockTypes.component";
    import {stockLocationFabActions} from "@/app/stocks/locations/locks/definitions/stockLocationFabActions.definition";

    export default {
        name: "StockLocationShow",
        components: {EntityHistory, StockLocationLockTypes},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null,
            stockName: '',
            substockName: '',
            loading: true,
            headers: [],
            items: [],
            hasAllLockTypes: true
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    if (this.stockName !== '') {
                        title = this.$t('stocks.locations.show.title', [this.details.name, this.stockName]);
                    } else if (this.substockName !== '') {
                        title = this.$t('stocks.locations.show.title', [this.details.name, this.substockName]);
                    } else {
                        title = this.$t('stocks.locations.show.title', [this.details.name]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            actions: function () {
                return [
                    {
                        routerLink: item => '/products/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        action: this.showStockStatus,
                        icon: '$stockStatus',
                        label: 'products.pieces.status'
                    }, {
                        action: this.showStockMovements,
                        icon: '$stockMovements',
                        label: 'products.pieces.movements'
                    }
                ];
            },
            fabActions: function () {
                return stockLocationFabActions(this.stockId, this.locationId);
            },
        },
        createdOrActivated: function () {
            this.headers = createHeaders({
                tableImage: {
                    sortable: false
                },
                name: {
                    filterType: TableFilter.TEXT
                },
                model: {
                    filterType: TableFilter.TEXT
                },
                'manufacturer.name': {
                    filterType: TableFilter.SELECT_MULTIPLE,
                    autocomplete: {
                        callFn: () => ManufacturerAPI.getAllPages(),
                        thenFn: response => response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }))
                    },
                },
                instance: {
                    itemLabel: item => item.product_instance.type === 'SERIAL_NUMBER' ? this.$t('products.instances.serial', [item.product_instance.serial_number])
                        : item.product_instance.type === 'BATCH' ? this.$t('products.instances.batch', [item.product_instance.batch_code]) : ' - ',
                    sortable: false
                },
                quantity: {
                    filterType: TableFilter.NUMBER
                }
            }, 'products.', true);

            this.loading = true;
            StockAPI.getLocation(this.stockId, this.locationId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks/' + this.stockId);
                });

            if (this.subStockId) {
                StockAPI.getSubstock(this.stockId, this.subStockId).then(response => {
                    this.substockName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks/' + this.stockId + '/substocks/' + this.subStockId);
                });

                this.fetchItems();
            } else {
                StockAPI.get(this.stockId).then(response => {
                    this.stockName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks/' + this.stockId);
                });

                this.fetchItems();
            }
        },
        methods: {
            fetchItems: function () {
                const promise = this.subStockId ? StockStatusAPI.getCurrentOnLocation(this.subStockId, this.locationId)
                    : StockStatusAPI.getCurrentOnLocationInWholeStock(this.stockId, this.locationId);
                promise.then(response => {
                    const responseItems = this.subStockId ? response.data.items : response.data;
                    this.items = responseItems.filter(item => this.subStockId ? item.quantity : item.summary_quantity);
                    this.items = this.items.map(item => ({
                        product_instance: item.product_instance,
                        default_image: item.product_instance.product.default_image,
                        name: item.product_instance.product.name,
                        model: item.product_instance.product.model,
                        manufacturer: item.product_instance.product.manufacturer,
                        quantity: this.subStockId ? item.quantity : item.summary_quantity,
                        id: item.product_instance.product.id,
                        visible: item.product_instance.product.visible
                    }));
                }).catch(err => {
                    this.snack(err);
                    if (this.subStockId) {
                        this.$router.push('/stocks/' + this.stockId + '/substocks/' + this.subStockId);
                    } else {
                        this.$router.push('/stocks/' + this.stockId);
                    }
                }).finally(() => this.loading = false);
            },
            showStockStatus: function (item) {
                let link = '/status?productId=' + item.id + '&stockId=' + this.stockId;
                if (this.subStockId) {
                    link += '&subStockId=' + this.subStockId;
                }
                if (item.product_instance.type !== 'NORMAL') {
                    link += '&instanceId=' + item.product_instance.id;
                }
                this.$router.push(link);
            },
            showStockMovements: function (item) {
                let link = '/movements?productId=' + item.id + '&stockId=' + this.stockId;
                if (this.subStockId) {
                    link += '&subStockId=' + this.subStockId;
                }
                if (item.product_instance.type !== 'NORMAL') {
                    link += '&instanceId=' + item.product_instance.id;
                }
                this.$router.push(link);
            },
            onLocksLoaded: function (value) {
                this.hasAllLockTypes = value;
            }
        }
    };
</script>

<style scoped>

</style>
