<template>
  <span>
    <template v-if="value === 'task'">
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <span
            :class="moveTypeClass(item)"
            v-on="on"
          >
            #{{ item.task.id }}
          </span>
        </template>
        {{ $t(taskNames[item.task.type]) }}
      </v-tooltip>
    </template>
    <template v-if="value === 'created_by'">
      {{ UserCache[item.created_by] | fullName }}
    </template>
    <template v-if="value === 'created_at'">
      {{ item.created_at | dateTime }}
    </template>
    <template v-if="value === 'product'">
      {{ item.product_instance | productLabel }}
    </template>
    <template v-if="value === 'amount'">
      <v-row
        class="float-right"
        :class="moveTypeClass(item)"
      >
        {{ (item.type === 'PICK' ? '-' : '+') + item.amount }}&nbsp;
        <span class="caption">{{ item.product_instance.product | productMeasureLabel }}</span>
      </v-row>
      <v-row class="mt-1 float-right text--disabled text-caption">
        {{
          item.stock_amount_after_change + (item.amount * (item.type === 'PICK' ? 1 : -1))
        }}➜{{ item.stock_amount_after_change }}
      </v-row>
    </template>
    <template v-if="value === 'movement'">
      <div
        class="d-flex align-center"
        :class="{
          'mobileTableMovement': $vuetify.breakpoint.xs
        }"
      >
        <span
          v-if="item.location.name"
          class="d-inline-flex"
        > <!-- TODO #15558 else -->
          <v-icon
            small
            class="mr-1"
          >
            $location
          </v-icon>
          {{ item.location.name }}
        </span>
        <span
          class="d-inline-flex"
          :class="moveTypeClass(item)"
        >
          <v-icon class="mx-1">
            {{ item.type === 'PICK' ? 'east' : 'west' }}
          </v-icon>
        </span>
        <span class="d-inline-flex">
          <v-icon
            small
            class="mr-1"
          >
            $storeKeeper
          </v-icon>{{ UserCache[item.location.user_id || item.created_by] | fullName }}
        </span>
      </div>
    </template>
    <template v-if="value === 'taskButton'">
      <div class="text-right mr-2 mt-2">
        <v-icon
          @click="action(item)"
        >
          $task
        </v-icon>
      </div>
    </template>
    <template v-if="value === 'filterButton'">
      <div class="text-right mr-2 mt-2">
        <v-icon
          @click="action(item)"
        >
          $filter
        </v-icon>
      </div>
    </template>
  </span>
</template>

<script>
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import {taskNames, taskTypes} from "@/enum/task_type";

    export default {
        name: "StockMovementItem",
        mixins: [ReactiveUserCacheMixin],
        props: {
            value: {
                type: String,
                default: ''
            },
            item: {
                type: Object,
                default: () => ({})
            },
            action: {
                type: Function,
                default: () => {
                }
            }
        },
        data: () => ({
            taskNames: taskNames
        }),
        methods: {
            isOutsideMovement: function (item) {
                return [
                    taskTypes.STOCK_LOADING,
                    taskTypes.STOCK_PICKING,
                    taskTypes.STOCK_PICKING_SET
                ].includes(item.task.type);
            },
            moveTypeClass: function (item) {
                return ['no-background', this.isOutsideMovement(item) ? (
                    item.location.type === 'STOCK' ? (item.type === 'PICK' ? 'moveOut' : 'moveIn') : 'move'
                ) : 'move'];
            }
        }
    };
</script>

<style scoped lang="sass">
.mobileTableMovement
  overflow-x: scroll
</style>
