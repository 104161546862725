/**
 * @param configObject {Object}
 * @return {Promise}
 */
import {Store} from "@/service/store/Store";
import {UserAPI} from "@/api/UserAPI";

const Dashboard = {

    version: 2,
    splitBy: 250,
    versionKey: 'dashboard-config-version',
    lengthKey: 'dashboard-config-length',

    saveHomepageConfig: function (configObject) {

        const userId = Store.getters['oauth/getUserId'];

        return new Promise((resolve, reject) => {
            const promises = [];
            const configString = JSON.stringify(configObject);
            let settings = {};

            UserAPI.getAllSettings(userId)
                .then(response => {
                    for (const set of response.data) {
                        settings[set.key] = set.value;
                    }

                    const lengthParams = [userId, this.lengthKey, Math.ceil(configString.length / this.splitBy)];
                    if (settings[this.lengthKey] !== undefined) {
                        promises.push(UserAPI.updateSetting(...lengthParams));
                        // Clear old records if older than new
                        const oldLength = Number.parseInt(settings[this.lengthKey], 10);
                        if (oldLength > configString.length) {
                            for (let i = configString.length - 1; i < oldLength; ++i) {
                                promises.push(UserAPI.deleteSetting(userId, 'dashboard-config[' + i + ']'));
                            }
                        }
                    } else {
                        promises.push(UserAPI.createSetting(...lengthParams));
                    }

                    for (let i = 0; i < configString.length; i += this.splitBy) {
                        const key = 'dashboard-config[' + i / this.splitBy + ']';
                        const part = configString.slice(i, i + this.splitBy);
                        if (settings[key] !== undefined) {
                            promises.push(UserAPI.updateSetting(userId, key, part));
                        } else {
                            promises.push(UserAPI.createSetting(userId, key, part));
                        }
                    }

                    Promise.all(promises)
                        .then(resolve)
                        .catch(reject);
                });
        });
    },

    /**
     * @return {Promise}
     */
    loadHomepageConfig: function () {

        return new Promise((resolve, reject) => {
            UserAPI.getAllSettings(Store.getters['oauth/getUserId'])
                .then(response => {
                    const settings = {};
                    for (const set of response.data) {
                        settings[set.key] = set.value;
                    }
                    const length = settings[this.lengthKey];
                    if (length !== undefined) {
                        let configString = '';
                        for (let i = 0; i < length; ++i) {
                            configString += settings['dashboard-config[' + i + ']'];
                        }
                        try {
                            const configObject = JSON.parse(configString);
                            if (Object.keys(configObject).length === 0) {
                                reject('not-defined');
                            } else {
                                this.checkVersion(configObject, settings[this.versionKey]);
                                resolve(configObject);
                            }
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        reject('not-defined');
                    }
                    resolve();
                }).catch(reject);
        });
    },

    checkVersion: function (config, version) {

        const userId = Store.getters['oauth/getUserId'];

        if (version === undefined) {
            return UserAPI.createSetting(userId, this.versionKey, this.version);
        } else {
            if (version === this.version) {
                return Promise.resolve();
            } else {
                this.convertVersion(config, version);
                return Promise.all([
                    UserAPI.updateSetting(userId, this.versionKey, this.version),
                    this.saveHomepageConfig(config)
                ]);
            }
        }
    },

    /**
     * Convert configObject in-place to the latest version
     * @param config
     * @param from
     */
    convertVersion: function (config, from) {
        from = Number.parseInt(from, 10);
        if (from >= this.version) {
            return;
        }
        switch (from) {
            case 1: // 1 to 2
                window.console.info(`Converting dashboard config version ${from} -> ${from + 1}`);
                config.forEach(item => {
                    if (item.props.value === 'ordersStats') {
                        item.type = 'ordersStats';
                        item.props.label = 'stats.orders.label';
                    } else {
                        item.type = 'taskList';
                    }
                });
                break;
            // Add case for each version which needs to be upgraded
        }
        return this.convertVersion(config, from + 1);
    }
};

export {Dashboard};
