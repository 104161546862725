<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card
      v-if="details"
      class="ma-1"
    >
      <v-container>
        <v-layout wrap>
          <v-flex
            xs12
            class="text-h5 ma-3"
          >
            <span
              :class="{'text--disabled': !details.active}"
            >
              {{ details.name }}
            </span>
            {{ details.active ? '' : (' - ' + $t('carriers.notActive')) }}
          </v-flex>
          <v-flex xs12>
            <div class="ml-2 my-3 font-italic">
              {{ $t('carriers.show.configInSubstock') }}
            </div>
          </v-flex>
          <ImagesList
            :single-image="details.image"
            @imageDeleted="details.image = null"
          />
          <ImagesList
            :single-image="details.foil_image"
            :is-foil-image="true"
            @imageDeleted="details.foil_image = null"
          />
        </v-layout>
      </v-container>
    </v-card>
    <v-card class="ma-1">
      <CarrierServicesList
        :inline="false"
      />
    </v-card>
    <x-btn-fab-expander
      :actions="fabActions"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {carrierFabActions} from "@/app/carriers/definitions/carrierFabActions.definition";
    import ImagesList from "@/app/images/components/ImagesList.component";
    import CarrierServicesList from "@/app/carriers/components/CarrierServicesList.component";

    export default {
        name: "CarrierShow",
        components: {ImagesList, CarrierServicesList},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            fabActions: function () {
                return carrierFabActions(this.carrierId, this.details !== null ? this.details.image : null,
                                         this.details !== null ? this.details.foil_image : null);
            },
        },
        createdOrActivated: function () {
            ShipmentCarrierAPI.get(this.carrierId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/carriers');
                });
        }
    };
</script>

<style scoped lang="sass">
.buttonGroupSmall
  display: block
  border-left-width: thin
</style>
