<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedSupplier !== null"
      :show.sync="confirmDialog"
      :text="personInLine(deletedSupplier)"
      @confirm-delete="reallyDeleteSupplier"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :actions="actions"
      >
        <template #item.ico="{ item }">
          <TableItemAresValidated :item="item" />
        </template>
        <template #footer>
          <TableAddItemButton
            :to="supplierCreateLink"
            label="suppliers.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="supplierCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {SupplierAPI as API} from "@/api/SupplierAPI";
    import {createHeaders} from "@/utils/table";
    import {supplierTable} from "@/app/suppliers/definitions/supplier.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import TableItemAresValidated from "@/app/components/table/TableItemAresValidated.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {personInLine} from "@/utils/string";

    export default {
        name: "SuppliersList",
        components: {TableItemAresValidated, TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            items: [],
            supplierCreateLink: '/suppliers/create',
            deletedSupplier: null,
            confirmDialog: true,
            personInLine: personInLine
        }),
        computed: {
            headers: () => createHeaders(supplierTable, 'suppliers.', true),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/suppliers/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                API.getAll()
                    .then(response => {
                        this.items = response.data;
                    }).catch(() => {
                        this.snack('suppliers.list.unableToLoad');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            deleteItem: function (item) {
                this.deletedSupplier = item;
                this.confirmDialog = true;
            },
            reallyDeleteSupplier: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.delete(this.deletedSupplier.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'suppliers.delete.done',
                            params: [this.deletedSupplier.name]
                        });
                    }).catch(this.snack)
                    .finally(this.getData);
            }
        }
    };
</script>

<style scoped>

</style>
