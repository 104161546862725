import {InstanceIdRender, ProductIdRender} from "@/app/overview/definitions/overview.render.form";
import {TableFilter} from "@/enum/table_filter";
import {locationLabel} from "@/utils/string";
import {StockAPI} from "@/api/StockAPI";
import {instanceLabel} from "@/utils/filters";
import {ProductAPI} from "@/api/ProductAPI";
import {productLabel} from "@/utils/filters";
import {productMeasureLabel} from "@/utils/filters";

function StockStatusForm() {
    return {
        stockId: null,
        subStockId: null,
        locationId: null,
        productId: null,
        instanceId: null
    };
}

function StockStatusSpecRender() {
    return {
        locationId: {
            sticky: true,
            readonly: true,
            autocomplete: {items: []}
        },
        productId: {
            ...(new ProductIdRender),
            readonly: true,
            forceFetch: true
        },
        instanceId: new InstanceIdRender
    };
}

const stockStatusTable = {
    'stock_location.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => StockAPI.getAllPages(),
            thenFn: response => response.data.items.map(stock => ({
                text: stock.name,
                value: stock.id
            }))
        },
        itemLabel: item => locationLabel(item.stock_location),
    },
    'product_instance.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => ProductAPI.getAllPages(),
            thenFn: response => response.data.items.map(product => ({
                text: productLabel(product),
                value: product.id,
                product: product
            }))
        },
        itemLabel: item => instanceLabel(item.product_instance)
    },
    quantity: {
        filterType: TableFilter.NUMBER,
        itemLabel: item =>  item.quantity + ' ' + productMeasureLabel(item.product_instance.product)
    },
    last_change: {
        filterType: TableFilter.DATETIME,
    }
};

export {StockStatusForm, StockStatusSpecRender, stockStatusTable};
