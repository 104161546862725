import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
import {ExternalOrderState, TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";
import {deliveryTypes} from "@/enum/delivery_type";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

const notFoundOrACL = "Zadané číslo úlohy nenájdené, alebo nemáte k úlohe prístup!";
const notFoundOrACLOrItem = "Zadané číslo úlohy alebo ID položky nenájdené, alebo nemáte k úlohe prístup!";
const approve409 = "Úloha nie je v stave, z ktorého by sa dala prijať!";
const reject409 = "Úloha nie je v stave, z ktorého sa dala odmietnuť!";
const notActive409 = "Úloha nie je v aktívnom stave, alebo neboli spracované všetky položky!";
const memberOfSet = "Táto akcia je zakázaná, pretože úloha je současťou skupiny!";

const state = {
    name: "Stav",
    [TaskState.CREATED]: "Nová",
    [TaskState.IN_PROGRESS]: "Prebiehajúca",
    [TaskState.WAITING_FOR_APPROVAL]: "Čáká na schválenie",
    [TaskState.CLOSED]: "Dokončená",
    [TaskState.CANCELED]: "Zrušená",
    [TaskState.UNASSIGNED]: "Nepriradený"
};

export default {
    name: "Úloha",
    external_id: "Externé číslo",
    parent_task_id: "Nadúkol",
    state: state,
    priority: {
        name: "Priorita",
        [TaskPriority.LOW]: "Nízká",
        [TaskPriority.NORMAL]: "Normálna",
        [TaskPriority.HIGH]: "Vysoká",
        unknown: "Neznámá priorita"
    },
    location: {
        name: "Umiestnenie",
        [TaskPreferredLocation.EXISTING]: "Akékoľvek existujúce",
        [TaskPreferredLocation.LAST]: "Posledné použité"
    },
    description: "Popis",
    assigned_user: "Priradené",
    nobody: "<nikto>",
    delivery_type: "Typ príjmu",
    delivery_number: "Číslo dodacieho listu",
    invoice_number: "Číslo faktúry",
    supplier_id: "Dodávateľ",
    external_order_task_id: "Číslo objednávky",
    supplier: "Dodávateľ",
    note: "Poznámka",
    time_spent: "Strávený čas",
    ean: "EAN",
    lastScanned: "Posledné skenovanie",
    queue: "Poradie",
    created_at: "Vytvorené",
    updated_at: "Aktualizované",
    viewTask: "Zobraziť úlohu",
    search: "Pokročilé hľadanie v úlohách",
    searchHint: "Prehľadáva číslo úlohy, číslo nadúlohy a externé číslo.",

    noItems: "Žiadne položky!",
    useScanner: "Použite čítačku alebo políčko vyššie pre vloženie položiek.",
    syncRunning: "Prebieha synchronizácia",
    generateReport: {
        pdf: "Vygenerovať report v PDF",
        csv: "Vygenerovať report v CSV",
        html: "Vygenerovať report v HTML"
    },

    keep_storekeeper: "Ponechať úlohu priradenú rovnakému skladníkovi",

    card: {
        actions: {
            label: "Štítok",
            labelDone: "Štítok '{0}' odoslaný na tlačiareň.",
            split: "Rozdeliť",
            splitDone: "'{0}' rozdelené!",
            splitFail: "Nie je možné rozdeliť tovar, ktorý má len 1 kus.",
            delete: "Zmazať",
            deleteDone: "'{0}' odstránené!"
        },
        create: {
            product: "Vytvoriť skladovú položku",
            location: "Vytvoriť umiestnenie"
        }
    },

    attachments: {
        name: "Prílohy",
        filename: "Názov súboru",
        download_link: "Stiahnuť",
        status: {
            404: "Zadané číslo úlohy alebo nepriradená príloha nenájdená!",
            498: "Nepodarilo sa nahrať vložený súbor!"
        }
    },

    notes: {
        name: "Poznámky",
        text: "Text",
        status: {
            404: "Zadané číslo úlohy alebo poznámky nenájdené!"
        }
    },

    filter: {
        chooseAttr: "Vyberte atribút úlohy",
        id: {
            name: "Číslo"
        },
        parent_task_id: {
            name: "Nadúkol"
        },
        substock: {
            id: {
                name: "Podsklad"
            }
        },
        external_id: {
            name: "Externé číslo"
        },
        state: state,
        priority: {
            name: "Priorita"
        },
        type: {
            name: 'Typ'
        },
        assigned_user: {
            id: {
                name: "Priradený užívateľ"
            }
        },
        created_at: {
            name: "Vytvorené"
        },
        updated_at: {
            name: "Aktualizované"
        }
    },

    timeEntries: {
        name: "Strávený čas",
        link: "Strávený čas v úlohách",
        title: "Strávený čas v úlohách",
        user: "Užívateľ",
        hours: "Hodín",
        created_at: "Vytvorené",
        track: "Zaznamenávať čas",
        saveTime: "Uložiť strávený čas?",
        saveTimeDetails: "Pri úlohe je spustený záznam stráveného času. Prajete si ho uložiť?",
        saved: "Strávený čas zaznamenaný.",
        saveFailed: "Nepodarilo sa uložiť strávený čas!",
        selectTask: "Zvoľte úlohu",
        edit: "Zmeniť čas",
        editDone: "Čas zmenený.",
        deleteDone: "Čas zmazaný!",
        table: {
            user: 'Užívateľ',
            hours: 'Hodín',
            created_at: 'Vytvorené'
        },
        status: {
            404: "Zadané číslo úlohy alebo záznamu času nenájdené!"
        }
    },

    chooseItems: {
        product: "Skladová položka",
        instance: "Konkrétna položka",
        quantity: "Množstvo",
        itemAlreadySelected: "Táto položka je už zvolená!",
        unableToRemoveLastItem: "Nie je možné odobrať poslednú položku!",
        removeItem: "Odobrať položku",

        scanOrChoose: "Načítajte ľubovoľnú položku alebo vyberte zo zoznamu nižšie",
        locationScanned: "Načítali ste umiestnenie namiesto položky!",
        itemNotAvailable: "Túto položku nemožno vybrať!",
        itemQuantityExceeded: "Tejto položky je možné vybrať najviac {0}!",
        itemCannotBePutOnDestination: "Túto položku nie je možné položiť na zvolené cieľové umiestnenie!",

        items: "položiek",
        pieces: "kusov"
    },

    strictMode: {
        label: "Je striktné",
        [TaskItemsStrictMode.FREE]: "Voľné",
        [TaskItemsStrictMode.NO_EXTRA]: "Nič naviac",
        [TaskItemsStrictMode.EXACT]: "Striktné",
    },

    deliveryAccept: {
        name: "Prijatie dodávky",
        create: "Vytvoriť príjem dodávky",
        done: "Dodávka úspešne prijatá",

        deliveryType: {
            [deliveryTypes.DELIVERY]: "Nový tovar",
            [deliveryTypes.RETURN]: "Vratka"
        },
        items: {
            delivered: "Dodané položky",
            isCustomInstance: "Ide o špeciálnu inštanciu",
            isNotCustomInstance: "Nejedná sa o špeciálnu inštanciu"
        },

        [TaskItemsCardType.ASSIGNMENT]: "Prijaté",

        acceptor: "Prijal",
        updateDetails: "Upraviť úlohu prijatia dodávky",
        newTask: "Parametre novej úlohy naskladnenia",
        status: {
            404: notFoundOrACL
        }
    },

    stock_loading_priority: "Priorita",
    stock_loading_preferred_location: "Preferované umiestnenie",
    stock_loading_description: "Popis",
    stock_loading_stock_id: "Sklad",
    stock_loading_subordinate_stock_id: "Podsklad",
    stock_loading_strict_mode: "Je striktné",
    stockLoading: {
        name: "Naskladnenie",
        create: "Vytvoriť úlohu naskladnenia",
        createDone: "Úloha naskladnenia vytvorená.",

        priority: "Priorita",
        preferred_location: "Preferované umiestnenie",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        supplier_id: "Dodávateľ",
        delivery_number: "Číslo dodacieho listu",
        invoice_number: "Číslo faktúry",
        strict_mode: "Je striktné",
        itemsToLoad: "Položky na naskladnenie",

        scanLocation: "Nejprv načítajte umiestnenie, kam chcete položku umiestniť.",
        scanSomeProducts: "Nie je možné dokončiť prázdné naskladnenie.",
        assignAllLocations: "Nastavte cieľové umiestnenie pre všetky položky.",
        chooseProductForLocation: "Nejprv zvoľte položku, ktorej chcete toto umiestnennie nastaviť.",
        locationNotAllowed: "Zvolené umiestnenie nie je v cieľovom podsklade povolené!",
        notToBeLoaded: "Načítaná položka nie je určená k naskladneniu!",
        loadedLimit: "Prekročený limit k naskladneniu: {0}/{1}.",
        onlyItemsFromInventory: "Pokiaľ sú v presune nejakej položky, možno na umiestnenie presúvať iba položky z presunu!",
        noItemsCanBePutOnLocation: "Na zvolené umiestnenie nemožno položiť žiadne položky z úlohy! Skúste načítať nejaké položky do presunu.",
        itemCanNotBePutOnLocation: "Načítanú položku nemožno položiť na zvolené umiestnenie!",

        loadTo: "Naskladniť do",
        chooseInstanceAndHowMuch: "Ak chcete vytlačiť štítky, vyberte konkrétnu položku a počet kusov.",
        chooseHowMuch: "Ak chcete vytlačiť štítky '{0}', vyberte počet kusov.",
        rightProductWrongInstanceScanned: "Nenačítali ste správnu konkrétnu položku.",
        lookForAnotherCode: "Nenačítali ste správnu konkrétnu položku, skúste na položke nájsť iný čiarový kód.",

        [TaskItemsCardType.TO_MOVE]: "K naskladneniu",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v presune",
        [TaskItemsCardType.MOVED]: "Naskladnené",
        [TaskItemsCardType.ASSIGNMENT]: "K naskladneniu",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Avizované položky sú len doporučenie, je možné naskladniť čokoľvek.",
            [TaskItemsStrictMode.NO_EXTRA]: "Je umožnené naskladniť len avizované položky, niektoré môžu chýbať, alebo ich byť menej.",
            [TaskItemsStrictMode.EXACT]: "Je umožnené naskladniť len avizované položky, žiadne nesmú chýbať a musí ich byť uvedené množstvo.",
        },

        confirmDelete: {
            fromInventory: "z presunu",
            fromDestination: "z umiestnenia"
        },

        navigation: {
            assignment: "Zadanie",
            stockLoading: "Naskladňovanie",
            finish: "Dokončenie",
            buyPrices: "Nastavenie nákupných cien"
        },
        buyPrices: {
            title: "Nákupné ceny",
            instance: "Skladová položka",
            manufacturer: {
                id: "Výrobca"
            },
            measure_unit: "Měrná jednotka",
            price: "Nákupná cena za jednotku",
            vat: "Sadzba DPH",
            batchMultiply: {
                by: "Koeficient",
                action: "Vynásobiť vyfiltrované ceny",
                done: "Ceny boli aktualizované",
                failed: "Aktualizácia zlyhala!"
            },
            status: {
                404: notFoundOrACLOrItem,
                409: approve409
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Sériové číslo položky je už použité inde, alebo úloha nie je v aktívnom stave!"
            },
            update: {
                status: {
                    404: notFoundOrACLOrItem,
                    409: notActive409
                }
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobok s daným sériovým číslom už existuje, alebo umiestnenie nie je pre zvolený podsklad povolené."
            }
        },
        status: {
            404: notFoundOrACL,
        }
    },

    stockTaking: {
        name: "Inventúra",
        create: "Vytvoriť úlohu inventúry",
        createDone: "Úloha inventúry vytvorená.",

        priority: "Priorita",
        description: "Popis",
        restrict_type: "Druh obmedzenia",
        restrict_reference_id: "Obmedzené na",
        stock_locations: "Umiestnenie",
        stock_id: "Sklad",
        attachments: "Prílohy",

        scanLocation: "Najprv načítajte umiestnenie, kde se položka nachádza.",
        noItems: "Žiadne položky k inventarizácii!",

        locationHelper: {
            label: "Použite vyhľadávač pre hromadné zvolenie viacerých umiestnení. Zadajte hľadaný výraz, skontrolujte vyfiltrované umiestnenia, a kliknite 'Nastaviť tieto umiestnenia'.",
            hint: "Pole prijíma ľubovoľný regulárny výraz",
            set: "Nastaviť tieto umiestnenia"
        },

        navigation: {
            assignment: "Zadanie",
            stockTaking: "Inventúra",
            finish: "Dokončenie"
        },

        restrictions: {
            [TaskStockTakingRestriction.NONE]: 'Žiadne',
            [TaskStockTakingRestriction.STOCK_OWNER]: 'Vlastník skladu',
            [TaskStockTakingRestriction.MANUFACTURER]: 'Výrobca',
            [TaskStockTakingRestriction.PRODUCT]: 'Skladová položka',
            [TaskStockTakingRestriction.STOCK_LOCATION]: 'Umiestenie',
            [TaskStockTakingRestriction.SUB_STOCK]: 'Podsklad',
            wrongLocation: 'Toto umiestnenie nie je predmetom inventúry!'
        },

        [TaskItemsCardType.PRESENT]: "Nájdené položky",
        [TaskItemsCardType.ASSIGNMENT]: "Predpokladané položky",

        items: {
            partOfInventory: {
                yes: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Položky výrobcu \'{0}\'',
                    [TaskStockTakingRestriction.PRODUCT]: 'Skladová položka \'{0}\'',
                },
                no: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Položky iných výrobcov',
                    [TaskStockTakingRestriction.PRODUCT]: 'Iné skladové položky',
                }
            },
            status: {
                404: notFoundOrACLOrItem,
                409: notActive409
            },
        },
        status: {
            404: notFoundOrACL
        }
    },

    stockPickingSet: {
        name: "Skupina vyskladnení",
        create: "Vytvoriť skupinu vyskladnení",
        createDone: "Úloha skupina vyskladnení vytvorená.",
        stockPickingTasks: "Úlohy vyskladnenia",
        usedStrategy: "Vytvorené pomocou stratégie",
        pickingAlreadySelected: "Táto úloha je už zvolená!",
        unableToRemoveLastItem: "Nie je možné odobrať poslednú položku!",
        stockPickingLocationFilled: "S cieľovým umiestnením",
        stockPickingLocationBlank: "Bez cieľového umiestnenia",
        itemAlreadySelected: "Duplicitná položka!",
        createDoneAdded: "Úloha pridaná k úlohe #{0}",
        strategies: {
            SAME_SINGLE_PRODUCT: "Rovnaké jednopoložkové",
            SINGLE_PRODUCT: "Jednopoložkové",
            IDENTICAL_ORDERS: "Rovnaké",
            FROM_OLDEST: "Od najstarších"
        },
        delete: {
            title: "Rozpustiť skupinu a zmazať nadúlohu",
            status: {
                404: notFoundOrACL,
                409: "Skupinu vyskladnenia nemožno zmazať kvôli jej vzťahom k ďalším objektom!"
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobok nie je na danom umiestnení, umiestnenie nie je pre zvolený podsklad povolené, alebo bol dosiahnutý limit pre presun."
            }
        },
        location: {
            status: {
                409: "Nie je možné zmeniť cieľové umiestnenie - na zvolenom umiestnení už prebieha balenie!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Vybrané úlohy vyskladnenia nemôžu byť zlúčené."
        }
    },

    stockPicking: {
        name: "Vyskladnenie",
        create: "Vytvoriť úlohu vyskladnenia",
        createDone: "Úloha vyskladnenia vytvorená",
        createDoneAdded: "Vyskladnenie pridané k úlohe #{0}",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        tasks: "Úlohy",
        shipping_type: "Spôsob dokončenia",
        destination_stock_location_id: "Umiestnenie na prípravu k expedícii",
        destination_stock_location_id_hint: "Umiestnenie, kam tovar presunúť a na ktorom bude prevedená expedícia.",
        buyer_id: "Odberateľ",
        buyer_delivery_address_id: "Doručovacia adresa odberateľa",
        destination_location_id: "Expedičné umiestnenie",
        partOfSet: "Súčasťou skupiny",
        external_order_id: "Objednávka",

        navigation: {
            assignment: "Zadanie",
            stockPicking: "Vyskladnenie",
            finish: "Dokončenie"
        },

        chooseTargetLocation: "Zvoľte cieľové umiestnenie!",
        scannedLocationNotEmpty: "Naskenované umiestnenie nie je prázdne, chcete ho napriek tomu zvoliť ako cieľové?",
        notToBeMoved: "Načítaná položka nie je určená k presunu, alebo už bola presunutá!",
        notInInventory: "Načítaná položka nie je v presune!",
        unableToFinishDueItems: "Úlohu nie je možné dokončiť, pretože nie sú presunuté všetky požadované položky!",
        lookForAnotherCode: "Nenačítali ste správnu konkrétnu položku, skúste na položke nájsť iný čiarový kód.",

        type: {
            label: "Spôsob prevzatia",
            [TaskShippingType.COURIER]: "Expedícia",
            [TaskShippingType.PERSONAL_COLLECTION]: "Priame predanie odberateľovi"
        },

        yetUnknown: "zatiaľ nezvolené umiestnenie",
        handOverTo: "odovzdanie odberateľovi",

        autoMoveInProgress: "Prebieha automatický presun položiek, ktoré už sú na svojom cieľovom umiestnení.",

        itemsToPick: "Položky k vyskladneniu",

        [TaskItemsCardType.TO_MOVE]: "Zostáva vyskladniť",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v presune",
        [TaskItemsCardType.MOVED]: "Vyskladnené",
        [TaskItemsCardType.ASSIGNMENT]: "K vyskladneniu",

        items: {
            product: "Skladová položka",
            quantity: "Množstvo",
            status: {
                404: notFoundOrACLOrItem,
                409: "Úloha nie je v stave, v ktorom by bolo možné upravovať predajnú cenu tovaru!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobok nie je na danom umiestnení, umiestnenie nie je pre zvolený podsklad povolené, alebo bol dosiahnutý limit pre presun."
            }
        },
        location: {
            status: {
                409: "Nie je možné zmeniť cieľové umiestnenie - na zvolenom umiestnení už prebieha balenie!"
            }
        },

        status: {
            404: notFoundOrACL,
            409: "V sklade nie je dostatok požadovaných položiek."
        }
    },

    preparePackage: {
        name: "Balenie",
        order: "Objednávka",
        subordinate_stock_id: "Podsklad",
        location: "Umiestnenie skladových položiek",
        disengagePackingMode: "Späť na prehľad objednávok",

        navigation: {
            assignment: "Zadanie",
            preparePackage: "Balenie",
            finish: "Dokončenie"
        },

        scanAnything: "Začnite naskenováním ľubovolnej nezabalenej položky.",
        scannedUnknown: "Naskenovaná položka nie je súčasťou žiadnej objednávky!",
        scannedUnknownOrTooMuch: "Naskenovaná položka nie je súčasťou žiadnej objednávky, alebo aspoň v tomto množstve!",
        scannedWrongItem: "Naskenovaná položka nie je súčásťou tejto objednávky!",
        scannedTooMuchItem: "Do tejto objednávky patrí menší počet kusov tejto položky!",

        ean_read_required: "V tomto podsklade je nutné napípnuť všetky položky na balenie čítačkou!",
        auto_packed_rest: "Objednávka bola automaticky zabalená!!",
        haveYouPackedAllPieces: "Sú zabalené všetky kusy?",
        packAllPieces: "Zabaliť {0}",

        orders: {
            label: "Objednávky",
            external_order_id: 'Číslo objednávky',
            external_order_author_name: 'Zákazník',
            external_order_note: 'Poznámka',
            printInvoice: 'Vytlačiť fakturu',
            printInvoiceAmount: {
                1: 'jednu',
                2: 'dve'
            },
            invoiceCopies: "kópie",
            unknown: "Neznámá objednávka!",
            cancelled: "Objednávka bola zrušená!"
        },

        shipments: {
            carrier: {
                label: "Dopravca"
            },
            lastValidated: "Posledná kontrola správnosti",
            never: "<nikdy>",
            valid: "Zásielku je možné odoslať",
            notValid: "Zásielku nemožno odoslať",
            notValidatedYet: "Ešte neprebehla kontrola odeslatelnosti zásielky.",
            errorMessages:  "Chybové hlásenia",
            package_count: "Počet balíkov",
            total_value: "Celková hodnota",
            total_weight: "Celková hmotnosť",
            change: {
                total_value: "Zmeniť celkovú hodnotu zásielky",
                total_weight: "Zmeniť celkovú hmotnosť zásielky"
            },
            print: 'Vytlačiť',
            label: "štítok",
            deliveryNote: "dodací list",
            printLabelPending: 'Získava sa štítok dopravcu ...'
        },

        items: {
            type: {
                [PreparePackageItemListTypes.TODO]: 'K zabaleniu',
                [PreparePackageItemListTypes.DONE]: 'Zabalené',
                [PreparePackageItemListTypes.ALL]: 'Objednaný tovar'
            }
        },

        packing: {
            status: {
                404: notFoundOrACL,
                409: "Táto položka nepatrí do zvolenej objednávky, alebo už je jej zabalené dostatočné množstvo!"
            }
        },

        status: {
            404: notFoundOrACL,
        }
    },

    moveProducts: {
        name: "Presun položiek",
        create: "Vytvoriť úlohu presunu položiek",
        createDone: "Úloha presunu položiek vytvorená.",
        type: {
            label: "Typ presunu",
            [TaskMoveProductsType.COLLAPSE]: "Zoskupiť položky odkiaľkoľvek na {1}",
            [TaskMoveProductsType.DISTRIBUTE]: "Rozmiestniť položky z {0} kamkoľvek",
            [TaskMoveProductsType.RELOCATE]: "Premiestniť položky z {0} na {1}",
            [TaskMoveProductsType.MANYTOMANY]: "Presunúť odkiaľkoľvek kamkoľvek"
        },

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        source_location_id: "Zdrojové umiestnenie",
        destination_location_id: "Cieľové umiestnenie",
        transfer_mode: "Je striktné",
        attachments: "Prílohy",

        anyLocation: "<ľubovoľné umiestnenie>",
        scanSourceOrDestLocation: "Načítajte prosím správne zdrojové alebo cieľové umiestnenie: {0} / {1}!",
        notToBeMoved: "Načítaná položka nie je určená k presunu, alebo už bola presunutá!",
        notInInventory: "Načítaná položka nie je v presune!",
        unableToFinishDueItems: "Úlohu nie je možné dokončiť, pretože nie sú presunuté všetky požadované položky!",

        unableToMoveAnyToAny: "Nie je možné presúvať z ľubovoľného na ľubovoľné umiestnenie. Vyberte aspoň jedno konkrétne umiestnenie.",
        unableToMoveToSame: "Nie je možné presúvať na rovnaké umiestnenie. Vyberte iné zdrojové alebo cieľové umiestnenie.",
        locationNotAllowed: "Zvolené umiestnenie nie je v cieľovom podsklade povolené!",
        notEnoughItems: "Na zvolenom umiestnení nie je dostatočné množstvo tejto položky!",
        noItemsCanBePutOnLocation: "Na zvolené umiestnenie nemožno položiť žiadne položky z úlohy!",
        itemCanNotBePutOnLocation: "Načítanú položku nemožno položiť na zvolené umiestnenie!",
        alreadyUsedAsDestination: "Na toto umiestnenie už bola položka položená! Vráťte ju z už presunutých.",
        alreadyUsedAsSource: "Z tohto miesta bola položka zhromaždená!",

        [TaskItemsCardType.TO_MOVE]: "Zostáva presunúť",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v presune",
        [TaskItemsCardType.MOVED]: "Presunuté",
        [TaskItemsCardType.ASSIGNMENT]: "K presunutiu",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Zadané položky sú iba odporúčania, je možné presunúť čokoľvek.",
            [TaskItemsStrictMode.NO_EXTRA]: "Je umožnené presunúť iba zadané položky, niektoré môžu chýbať alebo ich byť menej.",
            [TaskItemsStrictMode.EXACT]: "Je umožnené presunúť iba avizované položky, žiadne nesmú chýbať a musí ich byť uvedené množstvo.",
        },

        navigation: {
            assignment: "Zadanie",
            moveProducts: "Presun",
            finish: "Dokončenie"
        },

        items: {
            productsToMove: "Položky k presunu",
            status: {
                404: notFoundOrACLOrItem,
                409: "Nebolo možné pridať novú položku na presun!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobok nie je na danom umiestnení, umiestnenie nie je pre zvolený podsklad povolené, alebo bol dosiahnutý limit pre presun."
            }
        },
        finish: {
            status: {
                404: notFoundOrACL,
                409: "Úloha nie je v aktívnom stave, alebo máte u seba neumiestnené položky!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Na zdrojovom umiestnení alebo v podsklade nie je dostatok požadovaných položiek.",
        }
    },

    locationTransfer: {
        name: "Presun umiestnenia",
        create: "Vytvoriť úlohu presunu umiestnenia",
        createDone: "Úloha presunu umiestnenia vytvorená.",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        allow_filled_locations: "Povoliť presuny na už obsadené umiestnenia",
        attachments: "Prílohy",

        scanLocation: "Načítajte umiestnenie! V presune celého umiestnenia nie je možné načítať jednotlivé položky!",
        scanningTo: "Načítajte umiestnenie, na ktoré se všetky položky presunú.",

        canNotUseSame: "Už zvolené ako zdroj!",
        canNotUseNotEmpty: "Umiestnenie nie je prázdne!",

        confirmTitle: "Potvrďte presun {0} položiek ({1} ks) z '{2}' na '{3}'",
        confirmWithReader: "Pre potvrdenie môžete taktiež znova načítať kód '{0}'.",
        confirmButton: "Potvrdiť",

        moved: "Presunuté",

        navigation: {
            assignment: "Zadanie",
            locationTransfer: "Presun",
            finish: "Dokončenie"
        },

        status: {
            404: notFoundOrACL,
        }
    },

    substockTransfer: {
        name: "Presun medzi podskladmi",
        create: "Vytvoriť úlohu presunu medzi podskladmi",
        createDone: "Úloha presunu medzi podskladmi vytvorená.",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        source_subordinate_stock_id: "Zdrojový podsklad",
        destination_subordinate_stock_id: "Cieľový podsklad",
        attachments: "Prílohy",

        unableToMoveToSame: "Nie je možné presúvať na rovnaký podsklad. Vyberte iný zdrojový alebo cieľový podsklad.",
        notEnoughItems: "V zvolenom podsklade nie je dostatočné množstvo tejto položky!",
        noItemsCanBePutOnLocation: "Na zvolené umiestnenie nemožno položiť žiadne položky z úlohy!",
        itemCanNotBePutOnLocation: "Načítanú položku nemožno položiť na zvolené umiestnenie!",

        from: "Presunúť z",
        to: "Presun do",

        notToBeMoved: "Načítaná položka nie je určená k presunu, alebo už bola presunutá!",
        notInInventory: "Načítaná položka nie je v presune!",

        autoMoveInProgress: "Prebieha automatický presun položiek, ktoré už sú na svojom cieľovom umiestnení.",

        [TaskItemsCardType.TO_MOVE]: "Zostáva presunúť",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v presune",
        [TaskItemsCardType.MOVED]: "Presunuté",
        [TaskItemsCardType.ASSIGNMENT]: "K presunutiu",

        isAutocompleted: "Úloha bola automaticky dokončená, pretože cieľový podsklad povoľuje rovnaké umiestnenia ako zdrojový podsklad.",

        navigation: {
            assignment: "Zadanie",
            transferProducts: "Presun",
            finish: "Dokončenie"
        },

        items: {
            productsToMove: "Položky k presunu",
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobok nie je na danom umiestnení, umiestnenie nie je pre zvolený podsklad povolené, alebo bol dosiahnutý limit pre presun."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Máte nesprávne zvolené zdrojové a cieľové umiestnenie, alebo na zdrojovom umiestnení či v podsklade nie je dostatok požadovaných položiek."
        }
    },

    externalOrder: {
        name: "Externá objednávka",
        id: 'Číslo objednávky',
        subordinate_stock_id: "Podsklad",
        carrier: "Dopravca",
        buyer_id: "Objednávateľ",
        quantity: "Objednané množstvo",
        product: {
            name: "Položka"
        },
        stock_picking_task_ids: "Vyskladnenie",
        prepare_shipment_package_task_ids: "Balenie",
        shipment_ids: "Zásielky",
        selectedInstance: "Zvolený kus k vyskladneniu",
        default: "<predvolené>",
        externalFields: {
            label: "Parametre objednávky",
            author_id: "ID autora",
            author_name: "Meno autora",
            id: "Identifikátor",
            note: "Poznámka",
            order_created_at: "Vytvorená",
            order_id: "Číslo"
        },
        searchHint: "Prehľadáva číslo úlohy a číslo objednávky",

        state: {
            label: "Stav objednávky",
            [ExternalOrderState.EO_STATE_CREATED]: "Objednávka je nová alebo čaká na schválenie",
            [ExternalOrderState.EO_STATE_UNABLE_TO_PROCESS]: "Objednávka má chyby na API dopravcu",
            [ExternalOrderState.EO_STATE_UNABLE_TO_SATISFY]: "Časť objednávky nie je možné uspokojiť z dôvodu chýbajúcich položiek na sklade",
            [ExternalOrderState.EO_STATE_IN_PROGRESS]: "Na časti objednávky sa práve pracuje",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING]: "Objednávka čaká na vyskladnenie",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS]: "Objednávka je práve vyskladňovaná",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING]: "Objednávka čaká na balenie",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS]: "Objednávka je práve balená",
            [ExternalOrderState.EO_STATE_SHIPMENTS_WAITING]: "Niektoré zásielky čakajú na prevzatie dopravcom",
            [ExternalOrderState.EO_STATE_SHIPPED]: "Niektoré zásielky boli odoslané",
            [ExternalOrderState.EO_STATE_DELIVERED]: "Niektoré zásielky boli doručené",
            [ExternalOrderState.EO_STATE_RETURNING]: "Niektoré zásielky sa vracajú na sklad",
            [ExternalOrderState.EO_STATE_RETURNED]: "Niektoré zásielky sa vrátili na sklad",
            [ExternalOrderState.EO_STATE_CANCELLED]: "Objednávka bola stornovaná",
            [ExternalOrderState.EO_STATE_CLOSED]: "Objednávka je dokončená"
        },

        processingMode: {
            label: "Spôsob spracovania objednávky",
            [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Spracovať dohromady",
            [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Spracovať dostupné",
            [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Nespracovávať",

            hint: {
                [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Čaká sa na skladovú dostupnosť všetkých objednaných položiek.",
                [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Akonáhle sú niektoré položky na sklade, dôjde k vytvoreniu vyskladnenia.",
                [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Objednávka nebude spracovaná, nedôjde k vytvoreniu žiadneho vyskladnenia."
            }
        },

        createStockPicking: "Detaily novej úlohy vyskladnenia",

        navigation: {
            assignment: "Zadanie",
            order: "Objednávka",
            finish: "Dokončenie"
        },

        update: {
            status: {
                409: "Iba objednávka v stave 'Nová' môže byť zmenená!"
            }
        },

        approve: {
            status: {
                404: notFoundOrACL,
                409: "Úloha nie je v stave k schváleniu, alebo je požadováných viac položiek, než je na sklade!"
            }
        },

        close: {
            label: "Uzavrieť objednávku",
            note: "Poznámka",
            shipment_state: "Nový stav zásielok",
            done: "#{0} {1} uzavretá, zásielky nastavené na '{2}'"
        },

        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Úloha nie je v stave, kedy by bolo možné odstraniť položku!"
            }
        },

        show: {
            label: "Objednávky odberateľa"
        }
    },

    itemsCard: {
        canBeFoundAt: "Položku je možné nájsť na:",
        canBePutOn: "Položku je možné umiestniť na:",
        alreadyPlacedAt: "Položka už leží na:",
        pickedUpFrom: "Položka bola zobraná z:",

        updateQuantity: {
            notEnoughItemsInInventory: "Na umiestnenie nemožno presunúť viac kusov, než je aktuálne v presune!",
            notEnoughItemsOnSource: "Na zdrojovom umiestnení nie je dostatok položiek na presun!",
            itemNotPickedFromThisLocation: "Položka nebola zobraná z daného umiestnenia!",
            notSoManyItemsPickedFromSource: "Zo zdrojového umiestnenia nebolo zobráno toľko položiek!",
            destinationLimit: "Prekročený limit k presunu: {0}/{1}.",
        },

        returnAmount: "Vrátiť {0} {1}",
        returnWhere: "na {0}",
        returnToInventory: "medzi položkami v presune",
        returnTo: {
            location: "Vrátiť na pôvodné umiestnenie",
            inventory: "Vrátiť medzi položky v presune"
        },
        remove: "Zrušiť",

        from: "z",
        fromAnywhere: "odkiaľkoľvek",
        to: "na",
        toAnywhere: "kamkoľvek",
        substockLocations: "Umiestnenie povolené pre cieľový podsklad",

        scanLocation: "Začnite naskenovaním umiestnenia.",
        scanPackingLocation: "Začnite naskenováním baliaceho umiestnenia {0}",
        scanOrderItem: "Načítajte ľubovoľnú položku",
        scanLocationOrItem: "Začnite naskenováním umiestnenia alebo položky.",
        scanningFrom: "Načítajte položku k vyzdvihnutiu",
        scanningTo: "Načítajte položku k umiestneniu",
        scanningOrder: "Načítajte ďalšiu položku z objednávky #{0}",
        scannedExpeditionLocation: "Naozaj chcete načítať expedičné umiestnenie?",
        scannedMobileLocation: "Naozaj chcete načítať mobilné umiestnenie?",
        scanConfirm: "Načítať {0}",
        cancelByScanning: "Alebo načítajte iný kód",
        confirmByScanning: "Alebo načítajte kód {0} znovu",

        moreDetails: "Viac detailov",
        changeTargetLocation: "Zmeniť cieľové umiestnenie",
        cannotChangeTargetLocation: "Nie je možné zmeniť cieľové umiestnenie, najprv z aktuálneho umiestnenia zoberte položky späť!",

        moveEverything: "Presunúť možné na ",
        moveEverythingInProgress: "Prebieha presúvanie všetkých položiek, ktoré je možné položiť na cieľové umiestnenie.",
    },

    locationsCard: {
        canBePutOn: "Na umiestnenie možno z úlohy položiť:",
        cannotBePutOnThisLocation: "Na toto umiestnenie nemožno túto položku položiť!",
        cannotBePutOnAnyLocation: "Túto položku nemožno umiestniť na žiadne umiestnenie v úlohe!",
        reloadAllowedLocations: "Aktualizovať cache"
    },


    itemPick: {
        inStock: "na sklade",
        inProgress: "v zadaných úlohách",
        available: "k dispozícii"
    },

    unableToLoad: "Zoznam úloh nebolo možné načítať. Skúste to prosím opäť neskôr.",

    approve: {
        name: "Prijať",
        done: "#{0} {1} prijaté",
        doneTotal: "#{0} {1} dokončené",
        status: {
            404: notFoundOrACL,
            409: approve409,
            423: memberOfSet
        }
    },
    cancel: {
        name: "Stornovať",
        done: "#{0} {1} stornované",
        status: {
            404: notFoundOrACL,
            409: "Úloha nie je v stave, z ktorého by ju bolo možné zrušiť!"
        }
    },
    reject: {
        name: "Odmietnuť",
        done: "#{0} {1} odmietnuté",
        status: {
            404: notFoundOrACL,
            409: reject409,
            423: memberOfSet
        }
    },
    assign: {
        name: "Priradiť",
        self: "Začať pracovať na úlohe",
        notAssignable: "S vyskladnením nie je možné pracovať, pretože je súčasťou skupiny!",
        useReader: "Pre spustenie úlohy môžete taktiež načítať ľubovoľný kód čítačkou.",
        done: "#{0} {1} priradené",
        status: {
            404: notFoundOrACL,
            409: "Úloha je už priradená!",
            423: memberOfSet
        }
    },
    unassign: {
        name: "Odpriradiť",
        disabled: "Úlohu nemožno odpriradiť, kým sú v presune nejaké položky.",
        done: "#{0} {1} odpriradené",
        status: {
            404: "Zadané číslo úlohy nenájdené!",
            409: "Zadanú úlohu nemáte priradenú!",
            423: "Úlohu nemožno odpriradiť, pretože v presune sú nejaké položky!"
        }
    },
    finish: {
        name: "Odovzdať k schváleniu",
        disabled: "Úlohu nemožno dokončiť, pretože niektoré položky sú na nepovolených umiestneniach.",
        done: "#{0} {1} dokončené, odovzdané.",
        doneTotal: "#{0} {1} dokončené",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    handOver: {
        name: "Potvrdiť odovzdanie odberateľovi",
        done: "#{0} {1} dokončené, odovzdané.",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    finishAndApprove: "Dokončiť",
    finishAndApproveHint: "Táto akcia je dostupná, pretože máte súčasne rolu skladníka a aj vedúceho.",
    movementsOverview: {
        title: "Prehľad pohybov v úlohe",
        sourceSubstock: "Zdrojový podsklad",
        destinationSubstock: "Cieľový podsklad",
    },

    status: {
        404: "Zadané číslo úlohy nenájdené!",
        409: "Úloha ešte nebola dokončená!",
        423: memberOfSet
    }
};
