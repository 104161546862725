<template>
  <div
    class="d-flex flex-row"
  >
    <span
      v-for="(amount, index) of amounts"
      :key="amount.label"
      class="ml-2 my-2"
    >
      <span v-if="index > 0">, </span>
      {{ $t('tasks.chooseItems.' + amount.label) }}:
      <v-chip
        label
        outlined
      >
        {{ amount.value }}
      </v-chip>
    </span>
  </div>
</template>

<script>
    export default {
        name: "TaskItemAmounts",
        props: {
            items: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            amounts: function () {
                return [{
                    label: 'items',
                    value: this.items.length
                }, {
                    label: 'pieces',
                    value: this.items.reduce((acc, curr) => acc + (Number.parseInt(curr.quantity, 10) || 0), 0)
                }];
            }
        }
    };
</script>

<style scoped>

</style>
