var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-toolbar'),_c('v-container',[_c('v-card',{staticClass:"mb-2"},[_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.render,"lang-path":"base.shipping.handover.create.form."}}),(_vm.form.selectShipments && _vm.form.subStockId)?_c('x-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shipments,"loading":_vm.loading,"show-select":"","search-hint":_vm.$t('base.shipping.table.searchHint')},on:{"update:selection":function (selection) { return _vm.shipmentIds = selection; }},scopedSlots:_vm._u([{key:"item.last_protocol_created_at",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.last_protocol_created_at,"abs-date-first":true}})]}},{key:"item.external_order_id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_id}},[_vm._v(" #"+_vm._s(item.external_order_id)+" ")])],1)]}},{key:"item.stock_picking_id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.STOCK_PICKING] + '/' + item.stock_picking_id}},[_vm._v(" #"+_vm._s(item.stock_picking_id)+" ")])],1)]}},{key:"item.buyer.name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":'/buyers/' + item.buyer.id}},[_vm._v(" "+_vm._s(item.buyer.name)+" ")])],1)]}}],null,false,616348085)}):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"disabled":!_vm.form.carrierId || !_vm.form.stockId || _vm.stockShipmentsWaiting,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"text":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent","disabled":!_vm.loading && _vm.form.carrierId && _vm.form.stockId && !_vm.stockShipmentsWaiting},on:{"click":function($event){return _vm.printProtocols()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" $printItem ")]),_vm._v(" "+_vm._s(_vm.$t('base.shipping.handover.printProtocol'))+" ")],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('base.shipping.handover.noStockShipmentsWaiting'))+" ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }