import {BaseAPI} from "@/api/BaseAPI";
import {Sentry} from "@/service/Sentry";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";

const ManufacturerAPI =  {

    API: BaseAPI,
    DOMAIN: 'manufacturers',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },
    getAllPages(params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, params);
    },

    get(id) {
        return this.API.get([this.DOMAIN, id]);
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data
        );
    },

    undoCreateFn(id, resolve, reject) { // TODO test UNDO
        return () => {
            return this.API.delete([this.DOMAIN, id])
                .then(resolve)
                .catch(error => {
                    Sentry.captureException(error);
                    reject();
                });
        };
    },

    update(id, data) {
        return this.API.patch(
            [this.DOMAIN, id],
            data
        );
    },

    undoUpdateFn(id, resolve, reject) {
        return () => {
            return this.API.get([this.DOMAIN, id, 'history'])
                .then(response => {
                    const lastChange = response.data.pop();
                    if (lastChange.type !== 'UPDATED') {
                        reject();
                    }
                    let undoList = {};
                    lastChange.changes.map(obj => {
                        undoList[obj.property] = obj.old_value;
                    });
                    this.update(id, undoList).then(resolve).catch(reject);
                }).catch(error => {
                    Sentry.captureException(error);
                    reject();
                });
        };
    },

    delete(id) {
        return this.API.delete([this.DOMAIN, id], 'manufacturers.delete');
    },

    undoDeleteFn(id, resolve, reject) {
        return () => {
            return this.API.get([this.DOMAIN, id, 'history'])
                .then(response => {
                    const lastChange = response.data.pop();
                    if (lastChange.type !== 'REMOVED') {
                        reject();
                    }
                    let undoList = {};
                    lastChange.changes.map(obj => {
                        undoList[obj.property] = obj.old_value;
                    });
                    this.create(undoList).then(resolve).catch(reject);
                }).catch((error) => {
                    Sentry.captureException(error);
                    reject();
                });
        };
    }
};

export {ManufacturerAPI};
