import {Roles} from "@/enum/roles";
import {TaskState} from "@/enum/task_state";
import {taskTypes} from "@/enum/task_type";
import {APIFilterOP} from "@/service/APIFilters";
import {Store} from "@/service/store/Store";

function defaultDashboard() {
    const isStorekeeper = Store.getters['oauth/hasScope'](Roles.STOREKEEPER);
    const isChief = Store.getters['oauth/hasScope'](Roles.CHIEF);
    const isPacker = Store.getters['oauth/hasScope'](Roles.PACKER);
    const userId = Store.getters['oauth/getUserId'];

    const ret = [];

    if (isPacker) {
        ret.push({
            type: 'taskList',
            props: {
                label: 'toPack',
                filter: [
                    {[APIFilterOP.EQUALS]: {type: taskTypes.PREPARE_PACKAGE}},
                    {
                        [APIFilterOP.OR]: [
                            {[APIFilterOP.IN]: {state: [TaskState.CREATED, TaskState.UNASSIGNED]}},
                            {
                                [APIFilterOP.AND]: [
                                    {[APIFilterOP.EQUALS]: {['assigned_user.id']: userId,}},
                                    {[APIFilterOP.EQUALS]: {state: TaskState.CREATED}}
                                ]
                            }
                        ]
                    }
                ]
            }
        });
    }

    if (isStorekeeper || isChief) {
        ret.push({
            type: 'taskList',
            props: {
                label: 'running',
                filter: [
                    {[APIFilterOP.EQUALS]: {['assigned_user.id']: userId}},
                    {[APIFilterOP.EQUALS]: {state: TaskState.IN_PROGRESS}}
                ]
            }
        });
        ret.push({
            type: 'taskList',
            props: {
                label: 'assigned',
                filter: [
                    {[APIFilterOP.EQUALS]: {['assigned_user.id']: userId}},
                    {[APIFilterOP.EQUALS]: {state: TaskState.CREATED}}
                ]
            }
        });
    }

    if (isStorekeeper) {
        ret.push({
            type: 'taskList',
            props: {
                label: 'free',
                filter: [
                    {[APIFilterOP.IS_NULL]: 'assigned_user.id'},
                    {[APIFilterOP.IS_NULL]: 'parent_task_id'},
                    {[APIFilterOP.IN]: {state: [TaskState.CREATED, TaskState.IN_PROGRESS, TaskState.UNASSIGNED]}},
                    {[APIFilterOP.NOT_IN]: {type: [taskTypes.DELIVERY_ACCEPT, taskTypes.EXTERNAL_ORDER]}}
                ]
            }
        });
    }

    if (isChief && !isStorekeeper) {
        ret.push({
            type: 'taskList',
            props: {
                label: 'free',
                filter: [
                    {[APIFilterOP.IS_NULL]: 'assigned_user.id'},
                    {[APIFilterOP.IS_NULL]: 'parent_task_id'},
                    {[APIFilterOP.IN]: {state: [TaskState.CREATED, TaskState.UNASSIGNED]}}
                ]
            }
        });
    }

    if (isChief) {
        ret.push({
            type: 'taskList',
            props: {
                label: 'toApprove',
                filter: [
                    {[APIFilterOP.IS_NULL]: 'parent_task_id'},
                    {[APIFilterOP.EQUALS]: {state: TaskState.WAITING_FOR_APPROVAL}}
                ]
            }
        });

        ret.push({
            type: 'taskList',
            props: {
                label: 'storekeepersRunning',
                filter: [
                    {[APIFilterOP.IS_NULL]: 'parent_task_id'},
                    {[APIFilterOP.EQUALS]: {state: TaskState.IN_PROGRESS}}
                ]
            }
        });

        ret.push({
            type: 'taskList',
            props: {
                label: 'done',
                filter: [
                    {[APIFilterOP.EQUALS]: {state: TaskState.CLOSED}}
                ],
                sort: {
                    id: 'DESC'
                }
            }
        });

        ret.push({
            type: 'ordersStats',
            props: {
                label: 'stats.orders.label',
                value: 'ordersStats',
                filter: []
            }
        });
    }

    return ret;
}

export {defaultDashboard};
