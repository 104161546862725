<template>
  <v-card class="pb-3">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <GeneralConfigFilterPart
        :filter="filter"
        :possible-values="possibleValues"
        lang-path="base.table.filter."
        :hardcoded-attribute="attribute"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close');"
      >
        {{ $t('base.close') }}
      </v-btn>
      <v-spacer />
      <v-btn
        text
        color="accent"
        @click="reset"
      >
        {{ $t('base.reset') }}
      </v-btn>
      <v-btn
        text
        color="accent"
        @click="save"
      >
        {{ $t('base.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import GeneralConfigFilterPart from "@/app/components/filterConfig/GeneralConfigFilterPart.component";
    import {APIFilterDataType} from "@/service/APIFilters";

    export default {
        name: "TableDateFilter",
        components: {GeneralConfigFilterPart},
        props: {
            value: {
                type: Object,
                default: null
            },
            title: {
                type: String,
                default: ''
            },
            attribute: {
                type: String,
                default: null
            }
        },
        data: () => ({
            filter: {}
        }),
        computed: {
            possibleValues: function () {
                return {
                    [this.attribute]: {
                        type: APIFilterDataType.DATE,
                        values: null
                    }
                };
            },
            defaultFilter: function () {
                return {gt: {[this.attribute]: this.$moment().startOf('day').format()}};
            },
        },
        createdOrActivated: function () {
            this.filter = this.value || this.defaultFilter;
        },
        methods: {
            reset: function () {
                this.filter = this.defaultFilter;
                this.$emit('close');
                this.$emit('input', null);
            },
            save: function () {
                this.$emit('close');
                this.$emit('input', this.filter);
            }
        }
    };
</script>

<style scoped>

</style>
