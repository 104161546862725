import {ManufacturerAPI} from "@/api/ManufacturerAPI";
import {TableFilter} from "@/enum/table_filter";
import {ProductTypeAPI} from "@/api/ProductTypeAPI";
import i18n from "@/service/lang/i18n";
import {EventBus} from "@/service/EventBus";
import {measurementUnits} from "@/enum/measurement_unit";

function ProductForm() {
    return {
        name: '',
        model: '',
        manufacturer_id: null,
        type_id: null,
        description: '',
        measure_unit: '',
        visible: true,
        can_have_serial_number: false,
        can_have_batch: false,
        suggested_buy_price: 0,
        suggested_sell_price: 0,
        price_vat: 0.21,
        attributes: []
    };
}

const vatRateItems = [
    {
        text: '21%',
        value: 0.21
    }, {
        text: '15%',
        value: 0.15
    }, {
        text: '10%',
        value: 0.10
    }, {
        text: '0%',
        value: 0
    }
];

function ProductRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
        model: {
            icon: '$productModel',
            max: 30,
            required: true
        },
        manufacturer_id: {
            icon: '$manufacturer',
            required: true,
            autocomplete: {
                callFn: () => ManufacturerAPI.getAllPages(),
                thenFn: response => response.data.items.map(el => ({
                    text: (el.abbreviation + ' (' + el.name + ')'),
                    value: el.id
                }))
            },
            createNew: {
                icon: '$addManufacturer',
                label: 'manufacturers.create.label',
                action: () => {
                    EventBus.$emit('create-manufacturer');
                }
            }
        },
        type_id: {
            icon: '$productType',
            autocomplete: {
                callFn: () => ProductTypeAPI.getAllPages(),
                thenFn: response => response.data.items.filter(el => !el.default)
                    .map(el => ({
                        text: el.name,
                        value: el.id
                    }))
            },
            createNew: {
                icon: '$productType',
                label: 'products.types.create.label',
                action: () => {
                    EventBus.$emit('create-product-type');
                }
            }
        },
        measure_unit: {
            icon: '$productMeasureUnit',
            max: 25,
            autocomplete: {
                items: Object.values(measurementUnits).map(unit =>
                    i18n.t('products.measurementUnit.' + unit)
                ),
                allowCustom: true
            }
        },
        visible: {
            icon: '$productVisible',
            checkbox: true
        },
        can_have_serial_number: {
            icon: '$productSerialNumber',
            checkbox: true
        },
        can_have_batch: {
            icon: '$productBatch',
            checkbox: true
        },
        suggested_buy_price: {
            icon: '$productSuggestedBuyPrice',
            type: 'number'
        },
        suggested_sell_price: {
            icon: '$productSuggestedSellPrice',
            type: 'number'
        },
        price_vat: {
            icon: '$productPriceVat',
            type: 'number',
            autocomplete: {
                items: vatRateItems
            }
        }
    };
}

const productTable = {
    tableImage: {
        sortable: false
    },
    name: {
        filterType: TableFilter.TEXT
    },
    model: {
        filterType: TableFilter.TEXT
    },
    'manufacturer.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => ManufacturerAPI.getAllPages(),
            thenFn: response => response.data.items.map(el => ({
                text: el.name,
                value: el.id
            }))
        },
        itemLabel: item => item.manufacturer.name,
        sortable: true,
        sortBy: 'manufacturer.name'
    },
    'type.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => ProductTypeAPI.getAllPages(),
            thenFn: response => response.data.items.map(el => ({
                text: el.default ? i18n.t('products.types.default') : el.name,
                value: el.id
            }))
        },
        itemLabel: item => item.type.default ? i18n.t('products.types.default') : item.type.name,
        sortable: true,
        sortBy: 'type.id'
    },
    measure_unit: {
        label: 'measurementUnit.name',
        filterType: TableFilter.TEXT,
        itemLabel: item => item.measure_unit !== '' ? item.measure_unit : '-',
        sortable: true
    },
    can_have_batch: {
        filterType: TableFilter.BOOLEAN
    },
    can_have_serial_number: {
        filterType: TableFilter.BOOLEAN
    },
    created_at: {
        filterType: TableFilter.DATETIME
    }
};

export {ProductForm, ProductRender, productTable, vatRateItems};
