<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-layout row>
        <v-flex
          md4
          xs12
        >
          <v-card
            outlined
            elevation="4"
            class="ma-1"
          >
            <v-container>
              <FormFields
                single-per-row
                :form="form"
                :render="formRender"
                lang-path="stocks.stockChoose.form."
              />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <v-card
            outlined
            :elevation="dateTimeFrom && 4"
            class="ma-1"
          >
            <v-container>
              <div class="text-overline mb-3">
                {{ $t('stocks.stockMovements.dateFrom') }}
              </div>
              <FormDateTimePicker
                v-model="dateTimeFrom"
                single
              />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <v-card
            outlined
            :elevation="dateTimeTo && 4"
            class="ma-1"
          >
            <v-container>
              <div class="text-overline mb-3">
                {{ $t('stocks.stockMovements.dateTo') }}
              </div>
              <FormDateTimePicker
                v-model="dateTimeTo"
                single
              />
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
      <div
        v-if="form.subStockId !== null"
      >
        <StockMovementsTable
          :sub-stock-id="form.subStockId"
          :headers="headers"
          :parent-loading.sync="loading"
          :actions="actions"
          :api-filter="apiFilter"
          :reload="reload"
          :table-filter-active="tableFilterActive"
          @removeFilter="removeFilter"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {InstanceType} from "@/enum/instance_type";
    import {APIFilterOP} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {StockMovementsForm, StockMovementsRender} from "@/app/overview/movements/definitions/stockMovements.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import StockMovementsTable from "@/app/overview/movements/components/StockMovementsTable.component";
    import {taskLinks} from "@/enum/task_type";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";
    
    export default {
        name: "StockMovements",
        components: {FormDateTimePicker, StockMovementsTable, FormFields},
        mixins: [RouteParamsMapperMixin, FormAutocompleteMixin],
        data: () => ({
            form: new StockMovementsForm,
            formRender: new StockMovementsRender,
            dateTimeFrom: null,
            dateTimeTo: null,
            loading: false,
            apiFilter: [],
            reload: 0,
            filteredTask: null,
            filteredInstance: null
        }),
        computed: {
            formUpdated: function () {
                this.form.subStockId;
                this.form.instanceId;
                this.dateTimeFrom;
                this.dateTimeTo;
                this.filteredInstance;
                this.filteredTask;
                return Date.now();
            },
            headers: function () {
                const headers = {
                    task: {
                        sortable: false
                    },
                    created_by: {
                        sortable: false
                    },
                    created_at: {
                        sortable: false
                    }
                };
                if (this.form.instanceId === null || this.$vuetify.breakpoint.lgAndUp) {
                    headers.product = {
                        sortable: false
                    };
                }
                headers.amount = {
                    sortable: false,
                    align: 'right'
                };
                headers.movement = {
                    sortable: false
                };
                return createHeaders(headers, 'stocks.stockMovements.table.');
            },
            actions: function () {
                const actions = [
                    {
                        action: this.viewTask,
                        icon: '$task',
                        label: 'stocks.stockMovements.viewTask'
                    }
                ];
                if (this.filteredTask === null) {
                    actions.push({
                        action: this.filterTask,
                        icon: '$filter',
                        subscriptIcon: '$task',
                        label: 'stocks.stockMovements.filterTask',
                    });
                }
                if (this.form.productId === null) {
                    actions.push({
                        action: this.filterProduct,
                        icon: '$filter',
                        subscriptIcon: '$product',
                        label: 'stocks.stockMovements.filterProduct'
                    });
                }
                return actions;
            },
            showInstanceSelector: function () {
                return this.formRender.instanceId.autocomplete.items.length > 1;
            },
            tableFilterActive: function () {
                return !!this.filteredTask || !!this.filteredInstance;
            }
        },
        watch: {
            'form.stockId': function () {
                if (this.form.stockId) {
                    this.fetchSubStocks();
                }
            },
            'form.productId': function () {
                if (this.form.productId) {
                    this.fetchInstances();
                } else {
                    this.form.instanceId = null;
                    this.formRender.instanceId.autocomplete.items = [];
                }
            },
            formUpdated: function () {
                if (this.form.subStockId !== null) {
                    this.apiFilter = [];
                    if (this.filteredTask !== null) {
                        this.addTaskFilter(this.filteredTask);
                    }
                    if (this.form.instanceId !== null) {
                        this.apiFilter.push({
                            [APIFilterOP.EQUALS]: {
                                'product_instance.id': this.form.instanceId
                            }
                        });
                    }
                    if (this.dateTimeFrom !== null && this.dateTimeTo !== null) {
                        this.apiFilter.push({
                            [APIFilterOP.BETWEEN]: {
                                created_at: [this.dateTimeFrom, this.dateTimeTo]
                            }
                        });
                    } else if (this.dateTimeFrom !== null) {
                        this.apiFilter.push({
                            [APIFilterOP.GREATER_THAN_OR_EQUAL]: {
                                created_at: this.dateTimeFrom
                            }
                        });
                    } else if (this.dateTimeTo !== null) {
                        this.apiFilter.push({
                            [APIFilterOP.LOWER_THAN_OR_EQUAL]: {
                                created_at: this.dateTimeTo
                            }
                        });
                    }
                    this.reload++;
                }
            },
            showInstanceSelector: function (show) {
                this.$set(this.formRender.instanceId, 'show', show);
            }
        },
        createdOrActivated: function () {
            if (this.stockId !== undefined) {
                this.form.stockId = this.stockId;
            }

            if (this.subStockId !== undefined) {
                this.form.subStockId = this.subStockId;
            }

            if (this.productId !== undefined) {
                this.form.productId = this.productId;
                this.form.instanceId = null;
                if (this.instanceId !== undefined) {
                    this.form.instanceId = this.instanceId;
                } else {
                    this.setDefaultInstance();
                }
            }
        },
        methods: {
            fetchInstances: function () {
                this.$set(this.formRender.instanceId, 'loading', true);
                ProductAPI.getAllInstancesAllPages(this.form.productId)
                    .then(response => {
                        this.formRender.instanceId.autocomplete.items = response.data.items.map(el => ({
                            text: this.$options.filters.instanceTypeLabel(el),
                            type: el.type,
                            value: el.id
                        }));
                        this.setDefaultInstance();
                    }).finally(() => {
                        this.$set(this.formRender.instanceId, 'loading', false);
                    });
            },
            setDefaultInstance: function () {
                const items = this.formRender.instanceId.autocomplete.items;
                if (items.length === 1) {
                    this.form.instanceId = items[0].value;
                } else {
                    const normalInstance = items.find(item => item.type === InstanceType.NORMAL);
                    if (normalInstance) {
                        this.form.instanceId = normalInstance.value;
                    }
                }
            },
            viewTask: function (item) {
                this.$router.push(taskLinks[item.task.type] + '/' + item.task.id);
            },
            filterProduct: function (item) {
                this.loading = true;
                ProductAPI.getInstanceWOProduct(item.product_instance.id)
                    .then(response => {
                        this.form.productId = response.data.product.id;
                        this.form.instanceId = response.data.id;
                        this.filteredInstance = response.data.id;
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            filterTask: function (item) {
                this.addTaskFilter(item.task.id);
                this.filteredTask = item.task.id;
                this.reload++;
            },
            removeFilter: function () {
                if (this.filteredTask) {
                    this.filteredTask = null;
                }
                if (this.filteredInstance) {
                    this.form.productId = null;
                    this.form.instanceId = null;
                    this.formRender.instanceId.autocomplete.items = [];
                    this.filteredInstance = null;
                }
            // will trigger formUpdated watcher which will reload table
            },
            addTaskFilter: function (taskId) {
                this.apiFilter.push({
                    [APIFilterOP.EQUALS]: {
                        'task.id': taskId
                    }
                });
            }
        }
    };
</script>

<style scoped lang="sass">
.movementsTable .col
    padding: 5px
</style>
