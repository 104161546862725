import {AddressForm} from "@/app/components/form/definitions/address.form";
import {Roles} from "@/enum/roles";
import i18n from "@/service/lang/i18n";
import {UserAPI} from "@/api/UserAPI";
import {TableFilter} from "@/enum/table_filter";
import {roleIcons} from "@/enum/icons";
import {EventBus} from "@/service/EventBus";

function StockForm() {
    return {
        name: '',
        description: '',
        is_small: null,
        chief_id: null,
        address: new AddressForm
    };
}

const isSmallItems = [
    {
        text: '',
        value: null
    }, {
        text: i18n.t('base.no'),
        value: false
    }, {
        text: i18n.t('base.yes'),
        value: true
    }
];

function StockRender() {
    return {
        name: {
            icon: '$itemName',
            max: 100,
            required: true
        },
        description: {
            icon: '$description',
            max: 200,
            textarea: true
        },
        is_small: {
            icon: '$stockSmall',
            select: isSmallItems,
            help: 'smallWarehouse'
        },
        chief_id: {
            icon: roleIcons[Roles.CHIEF],
            required: true,
            autocomplete: {
                callFn: () => UserAPI.getChiefs(),
                thenFn: response => response.data.map(el => ({
                    text: (el.first_name + ' ' + el.last_name + ' (' + el.username + ')'),
                    value: el.id
                }))
            },
            createNew: {
                icon: '$addUser',
                label: 'users.create.label',
                action: () => {
                    EventBus.$emit('create-user');
                }
            }
        }
    };
}

const stockTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    is_small: {
        filterType: TableFilter.BOOLEAN
    }
};

export {StockForm, StockRender, stockTable};
