import {measurementUnits} from "@/enum/measurement_unit";

const measurementUnit = {
    name: 'Měrná jednotka',
    [measurementUnits.PIECE]: "Kus",
    [measurementUnits.CARTON]: "Karton",
    [measurementUnits.HECTOLITER]: "Hektolitr",
    [measurementUnits.LITER]: "Litr",
    [measurementUnits.DECILITER]: "Decilitr",
    [measurementUnits.CENTILITER]: "Centilitr",
    [measurementUnits.MILLILITER]: "Mililitr",
    [measurementUnits.TONNE]: "Tuna",
    [measurementUnits.KILOGRAM]: "Kilogram",
    [measurementUnits.DECAGRAM]: "Dekagram",
    [measurementUnits.GRAM]: "Gram",
    [measurementUnits.MILLIGRAM]: "Miligram",
    [measurementUnits.KILOMETER]: "Kilometr",
    [measurementUnits.METER]: "Metr",
    [measurementUnits.CENTIMETER]: "Centimetr",
    [measurementUnits.MILLIMETER]: "Milimetr",
    [measurementUnits.SQUARE_METER]: "Čtvereční metr",
    [measurementUnits.CUBIC_METER]: "Krychlový metr",

    [measurementUnits.MILE]: "Míle",
    [measurementUnits.INCH]: "Palec",
    [measurementUnits.POUND]: "Libra",
    [measurementUnits.OUNCE]: "Unce",
    [measurementUnits.US_GALLON]: "Americký galon",
    [measurementUnits.UK_GALLON]: "Britský galon",
    [measurementUnits.SQUARE_FOOT]: "Čtvereční stopa",
    [measurementUnits.CUBIC_FOOT]: "Krychlová stopa"
};

export default {
    title: "Skladové položky",
    titleSingle: "Skladová položka",
    link: "Skladové položky",

    name: "Název",
    description: "Popis",
    model: "Model",
    manufacturer_id: "Výrobce",
    type_id: "Typ",
    manufacturer: {
        id: "Výrobce",
        label: "Výrobce",
        name: "Výrobce"
    },
    type: {
        id: "Typ",
        label: "Typ"
    },
    measure_unit: "Měrná jednotka",
    measurementUnit: measurementUnit,
    visible: "Viditelný",
    notVisible: "Skrytý",
    deleted: "Smazaný",
    can_have_batch: "Může mít šarži",
    can_have_serial_number: "Může mít sériové číslo",
    created_at: "Vytvořeno",
    suggested_buy_price: "Standardní nákupní cena (bez DPH)",
    suggested_sell_price: "Doporučená prodejní cena (bez DPH)",
    buy_price: "Nákupní cena za jednotku",
    sell_price: "Prodejní cena za jednotku",
    price_vat: "Sazba DPH",
    vat_included: "vč. DPH",
    unknown: "<Neznámý>",
    tableImage: "",
    instance: "Šarže / Série",
    quantity: "Množství",
    searchHint: "Prohledává název, model, výrobce a měrnou jednotku",

    attributeAlreadyEntered: "Doporučovaný atribut už existuje!",
    unableToRemoveLastAttribute: "Nelze odebrat poslední atribut!",

    attributes: {
        name: "Atributy",
        key: "Název",
        value: "Hodnota",
        description: "Popis",
        unableToLoad: "Seznam atributů skladové položky nebylo možné načíst. Zkuste to prosím znovu později.",
        hint: "Atributy s nevyplněnou hodnotou se neuloží",
        create: {
            titleSimple: "Přidat atribut",
            title: "Přidat atribut k '{0}'",
            done: "Přidáno '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upravit atribut",
            title: "Upravit '{0}' u '{1}'",
            done: "Upraveno '{0}' u '{1}'"
        },
        delete: {
            done: "Atribut '{0}' smazán.",
            failed: "Atribut '{0}' nelze smazat. Zkuste to prosím znovu později."
        },
        status: {
            404: "Skladová karta nebo název atributu nenalezeny!",
            409: "Zadaný název atributu již existuje!"
        }
    },

    overview: {
        name: "Přehled položky",
        inStock: "Na skladě",
        notInStock: "Tato položka se na skladě nenachází",
        InTasks: "V úkolech",
        notInTasks: "Tato položka není blokována v žádných úkolech",
        Ordered: "Objednáno",
        notOrdered: "Pro tuto položku neexistují žádné objednávky",
        altogetherPieces: "{0} ks",
    },

    types: {
        link: "Typy skladových položek",
        title: "Typy skladových položek",
        titleSingle: "Typ skladové položky",
        name: "Název",
        default: "<výchozí>",
        suggestedAttributes: {
            name: "Doporučované atributy",
            key: "Název",
            description: "Popis",
            hint: "Je typový"
        },

        removeAttribute: "Odebrat atribut",

        create: {
            label: "Vytvořit nový typ skladové položky",
            title: "Nový typ skladové položky",
            done: "Typ skladové položky '{0}' vytvořen"
        },
        update: {
            titleSimple: "Upravit typ skladové položky",
            title: "Upravit '{0}'",
            done: "Typ skladové položky '{0}' upraven"
        },
        delete: {
            done: "Typ skladové položky '{0}' smazán.",
            failed: "Typ skladové položky '{0}' nelze smazat. Zkuste to prosím znovu později."
        },

        status: {
            404: "Typ skladové položky nenalezen!",
            409: "Typ skladové položky nelze odstranit, protože na skladě je položka s tímto typem!"
        }
    },

    batches: {
        name: "Šarže",
        code: "Kód",
        manufactured_date: "Datum výroby",
        manufactured_time: "Čas výroby",
        expire_date: "Datum expirace",
        expire_time: "Čas expirace",
        create: {
            titleSimple: "Přidat šarži",
            title: "Přidat šarži k '{0}'",
            done: "Přidáno '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upravit šarži",
            title: "Upravit šarži '{0}' u '{1}'",
            done: "Upravena šarže '{0}' u '{1}'"
        },
        delete: {
            done: "Šarže '{0}' smazána.",
            failed: "Šarži '{0}' nelze smazat. Zkuste to prosím znovu později.",
            status: {
                404: "Skladová karta nebo kód šarže nenalazen!",
                409: "Kód šarže nemůže být smazán kvůli jeho vztahům k dalším objektům!"
            }
        },
        status: {
            404: "Skladová karta nebo kód šarže nenalazen!",
            409: "Zadaný kód šarže již existuje!"
        }
    },

    customInstances: {
        name: "Speciální instance",
        hint: "Pokud potřebujete rozlišit stav, opotřebení položky a spravovat její kódy.",
        product_instance_type_id: "Typ",
        create: {
            titleSimple: "Přidat speciální instanci",
            label: "Vytvořit novou konkrétní položku",
            title: "Přidat speciální instanci k '{0}'",
            done: "Přidána instance typu '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upravit speciální instanci",
            title: "Upravit instanci typu '{0}' u '{1}'",
            done: "Upravena instance typu '{0}' u '{1}'",
            status: {
                404: "Skladová karta nebo instance nenalezena!",
                409: "Typ instance nemůže být změněn kvůli vztahům instance k dalším objektům!"
            }
        },
        status: {
            404: "Skladová karta nenalezena!",
            409: "Neznámý nebo neplatný typ instance!"
        }
    },

    images: {
        alt: "Obrázek přiřazený k položce",
        altCreate: "Obrázek k přiřazení k položce",
        unableToLoad: "Obrázky skladové položky nebylo možné načíst. Zkuste to prosím znovu později.",
        status: {
            404: "Skladová karta nebo obrázek nenalezen!"
        }
    },

    instances: {
        name: "Čárové kódy základní položky",
        type: {
            name: "Typ",
            NORMAL: "Běžný",
            SERIAL_NUMBER: "Sériové číslo",
            BATCH: "Šarže"
        },
        manufactured_date: "Datum výroby",
        expire_date: "Datum expirace",
        serial_number: "Sériové číslo",
        batch_code: "Číslo šarže",
        serial: "Série: {0}",
        batch: "Šarže: {0}",

        types: {
            label: "Speciální typy instancí",
            name: "Název",

            create: {
                title: "Nový speciální typ instance",
                label: "Vytvořit nový speciální typ instance",
                done: "Speciální typ instance '{0}' přidán"
            },
            update: {
                title: "Upravit speciální typ instance '{0}'",
                titleSimple: "Upravit speciální typ instance",
                done: "Speciální typ instance '{0}' upraven"
            },
            delete: {
                done: "Speciální typ instance '{0}' smazán!",
                status: {
                    404: "Speciální typ instance nenalezen!",
                    409: "Na skladě existují instance s daným typem!"
                }
            },
            status: {
                404: "Speciální typ instance nenalezen!",
                409: "Speciální typ s daným jménem již existuje!"
            }
        },

        barcodes: {
            link: "Detail",
            title: "Čárové kódy položky '{0}'",
            titleSimple: "Čárové kódy",
            name: "Čárový kód",
            code: "Kód",
            image: "Náhled",
            quantity: "Množství",
            description: "Popis kódu",
            descriptionHint: 'Například typ balení: "karton", "paleta" apod.',
            conflict: "Tento kód již má položka {0}",

            print: "Vytisknout čárový kód",
            printMany: "Vytisknout čárové kódy",
            printHowMuch: "Kolik štítků '{0}' vytisknout?",
            printMultipleHowMuch: "Kolik štítků vytisknout?",
            printDone: "Tisknu štítek '{0}'...",

            batchPrint: {
                label: "Zvolte parametry tisku",
                printAll: "Vytisknout tolik štítků, kolik je položek",
                printJustOne: "Pro každou položku pouze jeden štítek"
            },

            create: {
                title: "Přidat čárový kód k '{0}'",
                titleSimple: "Přidat čárový kód",
                done: "Kód '{0}' přidán k '{1}'"
            },
            update: {
                title: "Upravit kód '{0}' u '{1}",
                titleSimple: "Upravit kód u položky",
                done: "Kód '{0}' u '{1}' upraven"
            },
            delete: {
                done: "Kód '{0}' u '{1}' smazán!"
            },
            status: {
                404: "Skladová karta, konkrétní skladová položka nebo čárový kód nenalezen!",
                409: "Zadaný čárový kód již existuje!"
            }
        },

        status: {
            404: "Skladová karta nebo konkrétní skladová položka nenalezena!"
        }
    },

    serial_numbers: {
        name: "Sériová čísla",
        number: "Sériové číslo",
        serial_number: "Sériové číslo",
        same_barcode: "Také vytvořit stejný čárový kód",
        create: {
            titleSimple: "Přidat sériové číslo",
            title: "Přidat sériové číslo k '{0}'",
            done: "'{0}' přidáno k '{1}'"
        },
        delete: {
            done: "Sériové číslo odebráno.",
            failed: "Sériové číslo se nepodařilo odebrat. Zkuste to prosím znovu později.",
            status: {
                404: "Skladová karta, konkrétní skladová položka nebo sériové číslo nenalezeno!",
                409: "Sériové číslo nelze smazat kvůli jeho vztahům k dalším objektům!"
            }
        },
        status: {
            404: "Skladová karta, konkrétní skladová položka nebo sériové číslo nenalezeno!",
            409: "Zadané sériové číslo již existuje!"
        }
    },

    pieces: {
        name: "Kusy na skladě",
        status: "Stav na skladě",
        movements: "Pohyby položky"
    },

    show: {
        titleSimple: "Detail skladové položky",
        title: "{0}"
    },
    create: {
        title: "Nová skladová položka",
        done: "Skladová položka '{0}' vytvořena."
    },
    update: {
        titleSimple: "Upravit skladovou položku",
        title: "Upravit {0}",
        done: "Skladová položka '{0}' upravena."
    },
    delete: {
        done: "Skladová položka '{0}' smazána.",
        failed: "Skladovou položku '{0}' nelze smazat. Zkuste to prosím znovu později."
    },

    status: {
        404: "Skladová karta nenalezena!",
        409: "Položku nelze odstranit, protože je ještě na skladě!",
        412: "Skladová položka byla od posledního načtení upravena jiným uživatelem. Zkuste prosím akci provést znovu později."
    }
};
