import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

const StockMovementsAPI =  {

    API: BaseAPI,
    DOMAIN: 'stock-movements',

    /**
     * @param subStockId
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     productInstanceId: int
     *     from: string
     *     to: string
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(subStockId, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId],
            this.DOMAIN,
            merge(...params)
        );
    },
    getAllPages(subStockId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, subStockId],
            this.DOMAIN,
            merge(...params)
        );
    }
};

export {StockMovementsAPI};
