export default {
    locationVsSubstock: {
        title: 'Jaký je rozdíl mezi umístěním a podskladem?',
        content: 'Umístění je reálná pozice ve skladu, zatímco podsklad je virtuální součást skladu, ve kterém může skladové položky vlastnit jiný subjekt. Na jednom umístění může být společně uloženo i zboží z více podskladů.'
    },
    smallWarehouse: {
        title: 'Co je to malý sklad?',
        content: "Malý sklad má vždy maximálně jedno umístění. Množství podskladů není omezeno."
    },
    productLabels: {
        title: 'Co mění přepínač "Zobrazení položek" model/název?',
        content: 'Mění, zda se u jednotlivých položek na skladě zobrazuje jejich název či model, toto se projeví například na stránce skladových pohybů, u úkolů a jejich tvorby.'
    },
    setProductAttributes: {
        title: 'Jak nastavit atributy skladové položky?',
        content: 'Při vytváření skladové položky se postupně načítají doporučované atributy aktuálně zvoleného typu skladové položky. ' +
            'Z těchto atributů se uloží pouze ty, které budou mít vyplněnou hodnotu. ' +
            'Při úpravě skladové položky jsou načteny atributy dané skladové položky, při změně typu skladové položky se opět načítají i doporučované atributy daného typu. ' +
            'Pokud je shoda v názvu mezi atributem skladové položky a doporučovaným atributem, pak atribut skladové položky přejímá popis doporučovaného. ' +
            'V obou případech (při vytváření i úpravě) lze zároveň nastavovat doporučované atributy aktuálně zvoleného typu. ' +
            'Ty nemusí mít vyplněnou hodnotu a popis je volitelný u atributů skladové položky i doporučovaných atributů.'
    }
};
