import {DeliveryType, DeliveryNumber, InvoiceNumber, Note, SupplierId, ExternalOrderTaskId} from "@/app/tasks/definitions/taskCommon.render.form";
import {deliveryTypes} from "@/enum/delivery_type";

function DeliveryAcceptForm() {
    return {
        delivery_type: deliveryTypes.DELIVERY,
        delivery_number: null,
        invoice_number: null,
        supplier_id: null,
        external_order_task_id: null,
        hours: null,
        note: null,
        attachments: []
    };
}

function DeliveryAcceptRender() {
    return {
        delivery_type: new DeliveryType,
        delivery_number: new DeliveryNumber,
        invoice_number: new InvoiceNumber,
        supplier_id: new SupplierId,
        external_order_task_id: new ExternalOrderTaskId,
        note: new Note
    };
}

export {DeliveryAcceptForm, DeliveryAcceptRender};
