import {PrintType} from "@/enum/print_type";
import {ShipmentState} from "@/enum/shipment_state";
import {APIFilterOP} from "@/service/APIFilters";

export default {
    and: "a",
    or: "alebo",
    close: "Zavrieť",
    undo: "Zrušiť",
    open: "Otvoriť",
    reset: "Reset",
    loading: "Načítava sa",
    cancelLoading: "Zrušiť a prejsť na prehľad",
    cancelLoadingHint: "Načítavanie sa na pozadí môže, ale nemusí dokončiť!",
    home: "Domov",
    notFound: "Nenájdené",
    pageNotFound: "Stránka nenájdená",
    darkMode: "Tmavý režim",
    productLabels: "Zobrazenie položiek",
    productModel: {
        0: "Názov",
        1: "Model"
    },
    showProductImagesInTasks: "Zobrazenie fotografií produktov v úlohách",
    pickFromMobileLocation: "Navrhovať zber položiek z mobilného umiestnenia",
    automagicallyChooseInstance: {
        label: "Automaticky vyberať konkrétne položky",
        hint: "Pre tlač štítku, ak dôjde k načítaniu nesprávnej konkrétnej položky."
    },
    onBeep: "Pri napípnutí",
    search: "Hľadať",
    searchAll: "Hľadať všade",
    searchResult: {
        searchesIn: "Prehľadáva úlohy (číslo, externé id, stav, priorita a typ), skladové položky (výrobca, názov a model), sklady (názov), osoby (ičo) a čiarové kódy.",
        tasksById: "Úlohy",
        tasksByExternalId: "Úlohy s externým id {0}",
        tasksByState: "Úlohy so stavom {0}",
        tasksByPriority: "Úlohy s prioritou {0}",
        tasksByType: "Úlohy typu {0}",
        productsByBarcode: "Čiarový kód skladovej položky",
        productsByManufacturer: "Skladové položky výrobcu {0}",
        productsByName: "Skladové položky s '{0}' v názve",
        productsByModel: "Skladové položky s modelom {0}",
        stocksByName: "Sklady s '{0}' v názve",
        locationsByBarcode: "Čiarový kód umiestnenia",
        buyersByIco: "Odberateľ",
        suppliersByIco: "Dodávateľ",
        stockOwnersByIco: "Vlastník podskladu"
    },
    advancedFilter: "Pokročilé filtre",
    filterBy: "Filtrovať",
    orWriteYourOwn: "alebo začnite písať vlastné",
    noIco: "IČO nevyplnené",
    actions: "Akcie",
    nothing: "Nič",
    no: "Nie",
    yes: "Áno",
    of: 'z',
    pcs: 'ks',
    view: "Zobraziť",
    hide: "Skryť",
    showAll: "Zobraziť všetky",
    hideAll: "Skryť všetky",
    edit: "Upraviť",
    delete: "Zmazať",
    confirmDelete: "Naozaj zmazať?",
    reload: "Obnoviť",
    save: "Uložiť",
    saved: "Uložené",
    notSave: "Neukladať",
    cancel: "Storno",
    clickToCopy: "Skopírovať kliknutím",
    copyToClipboard: "Skopírovať do schránky",
    copyToClipboardDone: "'{0}' skopírované!",
    copyToClipboardFail: "Kopírovanie sa nepodarilo!",
    goBack: "Späť na predošlú stránku",
    goUp: "Späť o úroveň vyššie",
    lang: "Jazyk",
    help: {
        title: "Nápoveda"
    },
    sort: {
        DESC: 'zostupne',
        ASC: 'vzostupne'
    },
    cache: {
        title: "Spravovanie cache",
        contents: "Obsah cache",
        length: "Počet",
        size: "Veľkosť",
        clear: "Premazať",
        clearAll: "Premazať všetko",
        taskDetails: "Detail úlohy",
        barcodes: "Čiarový kód",
        buyers: "Odberateľ",
        locations: "Umiestnenie",
        allowedLocationIds: "Povolená umiestnenie skladové položky",
        instances: "Detail skladovej položky",
        products: "Skladová položka",
        instanceBarcodes: "Čiarové kódy skladové položky",
        instanceTypes: "Špeciálne inštancie",
        users: "Užívateľ"
    },
    login: "Prihlásiť sa",
    continueAs: "Pokračovať ako",
    loginOther: "Prihlásiť niekoho iného",
    logout: "Odhlásiť sa",
    version: "Verzia",
    offline: "Bez pripojenia k serveru!",
    downloadCsv: "Stiahnuť CSV",
    uploadAttachment: "Nahrať prílohu",
    filter: {
        [APIFilterOP.AND]: 'a zároveň',
        [APIFilterOP.OR]: 'alebo',
        [APIFilterOP.IS_NULL]: '{0} je prázdne',
        [APIFilterOP.IS_NOT_NULL]: '{0} nie je prázdne',
        [APIFilterOP.ARRAY_CONTAINS]: 'zoznam {0} obsahuje {1}',
        [APIFilterOP.ARRAY_NOT_CONTAINS]: 'zoznam {0} neobsahuje {1}',
        [APIFilterOP.ARRAY_EMPTY]: 'zoznam {0} je prázdny',
        [APIFilterOP.ARRAY_NOT_EMPTY]: 'zoznam {0} nie je prázdny',
        [APIFilterOP.LIKE]: '{0} obsahuje {1}',
        [APIFilterOP.NOT_LIKE]: '{0} neobsahuje {1}',
        [APIFilterOP.EQUALS]: '{0} je {1}',
        [APIFilterOP.NOT_EQUALS]: '{0} nie je {1}',
        [APIFilterOP.GREATER_THAN]: '{0} je väčšie než {1}',
        [APIFilterOP.LOWER_THAN]: '{0} je menšie než {1}',
        [APIFilterOP.GREATER_THAN_OR_EQUAL]: '{0} je väčšie alebo rovné {1}',
        [APIFilterOP.LOWER_THAN_OR_EQUAL]: '{0} je menšie alebo rovné {1}',
        [APIFilterOP.BETWEEN]: '{0} je medzi {1} a {2}',
        [APIFilterOP.IN]: '{0} je jedno z: {1}',
        [APIFilterOP.NOT_IN]: '{0} nie je žiadne z: {1}',
    },
    filterConfig: {
        filter: "Filter",
        invalid: "Filter nie je validný!",
        sort: "Zoradenie",
        condition: "Podmienka",
        addCondition: "Pridať podmienku",
        splitCondition: "Rozvetviť podmínku",
        removeCondition: "Zmazať podmienku",
        nestedGroup: "Vnorené podmienky",
        nonNested: "Priame podmienky",
        addSort: "Pridať zoradenie",
        removeSort: "Zmazať zoradenie",
        sortThen: "potom podľa",
        fill: "Vyplňte"
    },
    table: {
        batchActions: "Hromadné akcie",
        removeSelection: "Odstrániť výber",
        batchActionDisabledByFulltext: "Nie je možné kombinovať s fulltext hľadaním. Použite pokročilé filtre.",
        batchAllFiltered: "Pre všetky filtrované",
        batchAllSelected: "Pre všetky vybrané",
        filter: {
            chooseAttr: '' // intentionally blank
        }
    },
    shipping: {
        link: 'Zásielky',
        title: 'Zásielky',
        optional: "Voliteľné filtre",
        table: {
            state: "Stav",
            external_order_external_order_id: 'Ext. č. obj.',
            external_order_id: "Objednávka",
            stock_picking_id: "Vyskladnenie",
            buyer: {
                id: "Odberateľ",
                name: "Odberateľ",
                email: "E-mail",
                phone: "Telefón"
            },
            subordinate_stock: {
                id: "Podsklad",
                chooseStockFirst: "Nejprv vyberte sklad"
            },
            carrier: {
                id: "Dopravca"
            },
            last_validated: "Skontrolovaná",
            carrier_api_error_messages: "Chyby dopravcu",
            external_order_created_at: 'Objednávka vytvorená',
            created_at: 'Zásielka vytvorená',
            label_first_printed_at: "Štítok prvýkrát vytlačený",
            last_protocol_created_at: "Posledný protokol vytvorený",
            sent_date: "Odoslané",
            total_value: "Cena",
            total_weight: "Váha",
            package_count: "Počet balíkov",
            searchHint: "Prehľadáva dopravcu, odberateľe, e-mail, telefón a externé číslo objednávky."
        },
        shipment: {
            show: "Zobraziť zásielku",
            printLabel: "Vytlačiť štítky zásielky",
            printDetail: {
                label: "Vytlačiť prehľad zásielok",
                hint: "Najskôr vyberte práve jeden podsklad a práve jedného dopravcu."
            },
            track: "Sledovať zásielku",
            state: {
                [ShipmentState.CREATED]: "Nová",
                [ShipmentState.WAITING_FOR_PICKUP]: "Čaká na odoslanie",
                [ShipmentState.SENT]: "Odoslána",
                [ShipmentState.BEING_DELIVERED]: "Doručována",
                [ShipmentState.DELIVERED_TO_PICKUP_LOCATION]: "Doručená na výdajné miesto",
                [ShipmentState.DELIVERED]: "Doručená",
                [ShipmentState.RETURNING]: "Vracia sa",
                [ShipmentState.RETURNED]: "Vrátená",
                [ShipmentState.LOST]: "Stratená",
                [ShipmentState.TO_BE_CANCELLED]: "Ruší sa",
                [ShipmentState.CANCELLED]: "Zrušená"
            }
        },
        report: {
            link: 'Report zásielok',
            title: 'Report zásielok',
            table: {
                external_order_task_id: 'Objednávka',
                external_order_states: 'Stav objednávky',
                order_id: 'Externé číslo',
                buyer_name: 'Odberateľ',
                shipment_state: 'Stav zásielky',
                eshop_created_at: 'Prijatá eshopom',
                wms_created_at: 'Prijatá skladom',
                work_started_at: 'Práca začala',
                work_ended_at: 'Práca skončila',
                carrier_accepted_at: 'Prijatá dopravcom',
                carrier_delivered_at: 'Doručená',
                searchHint: "Prehľadáva číslo objednávky, externé číslo a odberateľa"
            }
        },
        handover: {
            link: 'Odovzdávanie zásielok',
            title: 'Odovzdávanie zásielok',

            form: {
                carrierId: "Zvoľte dopravcu",
                stockId: "Zvoľte sklad",
                subStockId: "Len podsklad ...",
                selectShipments: "Vybrať zásielky",
            },

            table: {
                created_at: "Dátum vytvorenia",
                substock: {
                    id: "Podsklad"
                },
                shipment: {
                    ids: "Počet zásielok",
                    detail: "Detail"
                },
                issueProtocol: "Odovzdať zásielky",
                notYetIssued: "Ešte nevystavený"
            },

            handOverAll: "Odovzdať všetky zásielky ({0})",
            reload: "Aktualizovať čakajúce zásielky",

            create: {
                title: "Vystaviť individuálny protokol",

                form: {
                    carrierId: "Zvoľte dopravcu",
                    stockId: "Zvoľte sklad",
                    subStockId: "Zvoľte podsklad",
                    selectShipments: "Vybrať zásielky",
                    noShipmentSelect: "Inak vytlačí protokol pre všetky zásielky, ktoré čakajú na odovzdanie",
                },
            },

            printProtocol: "Vytlačiť protokol",
            noStockShipmentsWaiting: "Žiadne zásielky z vybraného skladu nečakajú na odovzdanie vybranému dopravcovi.",
            noShipmentsWithoutProtocol: "Neexistuje žiadna zásielka na odovzdanie, ktorá by už nebola obsiahnutá v protokole." +
                "Pre vytvorenie ďalšieho protokolu pre takú zásielku, je potrebné ju explicitine vybrať."
        }
    },
    images: {
        name: "Obrázok",
        plural: "Obrázky",
        url: "Adresa",
        main: "Hlavný obrázok",
        setMain: "Nastaviť ako hlavný",
        type: {
            name: "Typ",
            local: "miestny",
            localLabel: "Nahrať obrázok",
            external: "externý",
            externalLabel: "Vložiť obrázok z URL"
        },
        create: {
            titleSimple: "Pridať obrázok",
            title: "Pridať obrázok k '{0}'",
            done: "Obrázok pridaný k '{0}'",
            unableToLoad: "Obrázok se nepodarilo načítať"
        },
        update: {
            titleSimple: "Upraviť obrázok",
            title: "Upraviť obrázok '{0}'",
            done: "Obrázok '{0}' upravený"
        },
        delete: {
            done: "Obrázok odobraný",
            failed: "Obrázok se nepodarilo odobrať. Skúste to prosím opäť neskôr."
        },
        store: {
            title: "Uložiť lokálne",
            hint_keep_link: "Uloží sa iba odkaz na obrázok",
            hint_store_link: "Obrázok sa pri uložení stiahne a uloží lokálne",
            done: "Externý obrázok bol úspešne stiahnutý a uložený ako miestny"
        }
    },
    print: {
        sent: "Odoslané na tlačiareň!",
        noConfigFor: "Nie je nakonfigurovaná tlač pre {0}!"
    },
    list: {
        loadMore: "Načítať ďalšie",
        loadingMore: "Načítavam ďalšie",
        unableToLoad: "Nepodarilo se načítať zoznam. Skúste to prosím znovu."
    },
    api: {
        401: "Užívateľ nie je prihlásený!",
        403: "K tejto funkcii nemáte prístup!",
        405: "Táto akcia aktuálne nie je povolená!",
        412: "Položka bola v medzičase zmenená iným užívateľom, Vykonajte prosím akciu znova.",
        unexpectedError: "Nastala neočekávaná chyba. Skúste to prosím opäť neskôr.",
        ares: {
            status: {
                404: "Subjekt so zadaným IČO nebol nájdený!"
            }
        },
        barcodes: {
            unknown: "Neznámy kód",
            status: {
                404: "Zadaný kód nenájdený!"
            }
        },
        shipments: {
            packages: {
                status: {
                    404: "Zásielka alebo balíček nenájdený!",
                    409: "Balíček nie je možné odobrať - zásielka už bola odoslaná!"
                }
            },
            protocols: {
                create: {
                    status: {
                        404: "Dopravca, podsklad alebo jedna zo zásielok nenájdená!",
                        409: "Niektorá zo zásielok nie je v stave 'Čaká na odoslanie' alebo nemá priradené doručovacie číslo."
                    }
                },
                getAll: {
                    status: {
                        404: "Zazmluvnený dopravca alebo podsklad nenájdený!"
                    }
                },
                status: {
                    404: "Požadovaný protokol nenájdený!",
                    423: "Požadovaný protokol ešte nie je pripravený, skúste to prosím opäť neskôr.",
                }
            },
            status: {
                404: "Požadovaná zásielka nenájdená!",
                423: "Štítok dopravcu ešte nie je pripravený, skúste to prosím opäť neskôr.",
                409: "Zásielka nie je v stave, v ktorom by ju bolo možné odoslať!"
            }
        },
        shipmentCarrier: {
            contracted: {
                all: {
                    status: {
                        404: "Vlastník podskladu nenájdený!"
                    }
                },

                status: {
                    404: "Dopravca alebo vlastník podskladu nenájdený!",
                    409: "Dopravcu sa nepodarilo odstrániť zo zoznamu zazmluvnených dopravcov."
                }
            },

            status: {
                404: "Požadovaný dopravca nenájdený!"
            }
        }
    },
    address: {
        street: "Ulica",
        house_number: "Popisné číslo",
        suburb: "Upresnenie adresy",
        city: "Mesto",
        postal_code: "PSČ",
        phone: "Telefón",
        email: "E-mail",
        country: "Krajina (lokálne)",
        country_iso_code: "Krajina (anglicky)",
    },
    bank_account: {
        prefix_number: "Prefix",
        account_number: "Číslo účtu",
        bank_code: "Kód banky",
        full_number: "Číslo účtu vrátane kódu banky",
        iban: "IBAN",
        swift: "SWIFT"
    },
    changes: {
        created_at: "Vytvorené",
        created_by: "Vytvoril",
        updated_at: "Posledná úprava",
        updated_by: "Upravil",
        noUpdates: "Žiadne následné úpravy"
    },
    ares: {
        load: "Načítať dáta z ARESu",
        validated: "Overené z ARESu",
        not_validated: "Nie je overené z ARESu"
    },
    print_type: {
        [PrintType.PDF]: "PDF",
        [PrintType.ZPL]: "ZPL",
        [PrintType.ZPLX]: "ZPLX",
        [PrintType.ZPLX2]: "ZPLX2",
        [PrintType.ESCP]: "ESCP",
        [PrintType.HTML]: "HTML"
    }
};
