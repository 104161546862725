<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks."
          />
          <v-flex
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :available-items="availableItems"
              title-path="tasks.deliveryAccept.items.delivered"
              :loading="loading"
              :loading-items="loadingAvailableItems"
              :choose-just-instance="false"
            >
              <template
                #item="{ item }"
              >
                <v-layout
                  v-if="(form.delivery_type === deliveryTypes.RETURN && Object.values(InstanceType).includes(item.type))
                    || (form.delivery_type === deliveryTypes.DELIVERY && !Object.values(InstanceType).includes(item.type))"
                >
                  <v-flex class="orange--text">
                    {{ item | instanceLabel }}
                  </v-flex>
                  <v-flex class="text-right">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon color="orange">
                            $info
                          </v-icon>
                        </div>
                      </template>
                      <span v-if="form.delivery_type === deliveryTypes.RETURN">
                        {{ $t('tasks.deliveryAccept.items.isNotCustomInstance') }}
                      </span>
                      <span v-else>
                        {{ $t('tasks.deliveryAccept.items.isCustomInstance') }}
                      </span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <span v-else>
                  {{ item | instanceLabel }}
                </span>
              </template>
            </TaskChooseItems>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskDeliveryAcceptSubmit
              </v-icon>
              {{ $t('tasks.deliveryAccept.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <SupplierCreateUpdate
      v-if="showCreateSupplier"
      id="supplierForm"
      ref="supplierForm"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import {taskNames, taskLinks, taskTypes} from "@/enum/task_type";
    import FormFields from "@/app/components/form/FormFields.component";
    import {DeliveryAcceptForm, DeliveryAcceptRender} from "@/app/tasks/deliveryAccept/definitions/deliveryAccept.form";
    import {TaskDeliveryAcceptAPI as API} from "@/api/TaskDeliveryAcceptAPI";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {ProductAPI} from "@/api/ProductAPI";
    import {deliveryTypes} from "@/enum/delivery_type";
    import {InstanceType} from "@/enum/instance_type";
    import {getIdFromLocation} from "@/utils/url";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "DeliveryAcceptCreate",
        components: {SupplierCreateUpdate, TaskAttachmentUpload, FormFields, TaskChooseItems},
        mixins: [ACLMixin, EventsListenerMixin],
        data: () => ({
            form: new DeliveryAcceptForm,
            formRender: new DeliveryAcceptRender,
            valid: true,
            validAttachments: true,
            loading: false,
            type: taskTypes.DELIVERY_ACCEPT,
            taskNames: taskNames,
            taskLinks: taskLinks,
            showCreateSupplier: false,
            chosenItems: [{product_id: null, product_instance_id: null}],
            availableItems: [],
            loadingAvailableItems: false,
            deliveryTypes: deliveryTypes,
            InstanceType: InstanceType
        }),
        computed: {
            events: function () {
                return {
                    'create-supplier': this.onCreateSupplier,
                    'create-supplier-created': this.onCreateSupplierCreated,
                    'create-supplier-cancelled': () => this.showCreateSupplier = false
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments;
            }
        },
        createdOrActivated: function () {
            this.fetchProducts();
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            fetchProducts: function () {
                this.loadingAvailableItems = true;
                ProductAPI.getAllPages().then(response => {
                    this.availableItems = response.data.items.map(product => {
                        product.value = product.id;
                        return product;
                    });
                }).catch(this.snack)
                    .finally(() => this.loadingAvailableItems = false);
            },
            onCreateSupplier: function () {
                this.showCreateSupplier = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.supplier_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('supplierForm');
                });
            },
            onCreateSupplierCreated: function (supplierId) {
                this.showCreateSupplier = false;
                this.$set(this.formRender.supplier_id, 'loading', true);
                this.formRender.supplier_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.supplier_id.autocomplete.items = this.formRender.supplier_id.autocomplete.thenFn(response);
                        this.form.supplier_id = Number.parseInt(supplierId, 10);
                    }).finally(() => this.$set(this.formRender.supplier_id, 'loading', false));
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                const data = {...this.form};
                data.delivered_items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_id !== null;
                    });
                API.create(data)
                    .then(response => {
                        this.$store.dispatch('time/stop');
                        this.snack('tasks.deliveryAccept.done');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        if (this.isChief) {
                            this.$router.push(taskLinks[taskTypes.DELIVERY_ACCEPT] + '/' + getIdFromLocation(response));
                        } else {
                            this.$router.push('/');
                        }
                    }).catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
