<template>
  <v-container>
    <v-row>
      <v-divider
        class="my-3"
      />
    </v-row>
    <v-row>
      <div class="text-subtitle-1 ml-2">
        {{ $t(titlePath) }}:
      </div>
    </v-row>
    <v-row>
      <v-col>
        <!-- do not validate this as part of outer form -->
        <v-form>
          <v-textarea
            auto-grow
            class="mt-2 readerInput-items iconScan"
            :error="unknownCode"
            :error-messages="readerInputErrors"
            :hide-details="readerQueue.length !== 0"
            :label="$t('tasks.chooseItems.scanOrChoose')"
            :loading="barcodeLoading || (!itemsFromStockStatus && availableItems.length === 0)"
            persistent-hint
            prepend-icon="$scannerIdle"
            :rows="1"
            outlined
            :value="readerInput"
            @input="readerInputReset"
            @keyup.enter.native.prevent="evt => onBarcodeRead(evt.target.value)"
            @keydown.enter.prevent=""
            @keyup.up.native="readerInput = readerLastScanned"
          />
        </v-form>
        <div
          v-if="readerQueue.length"
          class="d-flex v-messages ml-11 my-2 text--secondary"
        >
          <div class="widthFitContent v-messages__message">
            {{ $t('tasks.queue') }}:
          </div>
          <div class="d-flex flex-column flex-wrap ml-1">
            <div
              v-for="(item, index) of readerQueue"
              :key="index"
              class="v-messages__message"
            >
              <strong>{{ item.quantity }}x</strong>
              <TaskCodeLabel
                :code="item.code"
                :show-x="false"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-for="(pickingItem, index) of chosenItemsLocal"
      :key="index"
    >
      <v-layout
        wrap
        align-center
      >
        <v-flex
          v-if="!chooseJustInstance"
          xs12
          sm8
          md7
          lg4
          xl4
          class="px-1"
        >
          <v-autocomplete
            v-model="pickingItem.product_id"
            :items="availableItems"
            :error-messages="chooseJustInstance ? pickingItem.errors : []"
            :filter="filterProducts"
            :label="$t(langPath + 'product')"
            :loading="loadingItems"
            prepend-icon="$itemsToMove"
            single-line
            @input="productChanged(index)"
          >
            <template #item="{ item }">
              {{ item | productLabel }}
            </template>
            <template #selection="{ item }">
              {{ item | productLabel }}
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs12
          sm8
          md8
          lg5
          xl4
          class="px-1"
        >
          <v-autocomplete
            :ref="'instance_' + index"
            v-model="pickingItem.product_instance_id"
            :items="chooseJustInstance ? availableItems : productInstances[pickingItem.product_id]"
            :error-messages="pickingItem.errors"
            :filter="filterInstances"
            :label="chooseJustInstance ? $t(langPath + 'product') : $t(langPath + 'instance')"
            :loading="chooseJustInstance ?
              loadingItems
              : (pickingItem.product_id ?
                (!productInstances[pickingItem.product_id] || productInstances[pickingItem.product_id].length === 0)
                : false)"
            :prepend-icon="chooseJustInstance ? '$itemsToMove' : '$productInstance'"
            :rules="instanceRules(index, pickingItem.product_id)"
            single-line
            :suffix="instanceSuffix(index, pickingItem.product_id)"
            @input="instanceChanged(index)"
          >
            <template
              v-if="createNewInstances && pickingItem.product_id !== null"
              #prepend-item
            >
              <v-list>
                <v-list-item
                  :disabled="creatingInstanceForItem !== null"
                  @click="createNewInstance(index, pickingItem)"
                >
                  <v-list-item-avatar>
                    <v-icon :disabled="creatingInstanceForItem !== null">
                      $addProductInstance
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        $t('products.customInstances.create.label')
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <template #item="{ item }">
              <v-layout
                v-if="destinationLocationAllowedInstancesInfo && !destinationLocationAllowedInstancesInfo.allowAll
                  && !destinationLocationAllowedInstancesInfo.instanceIds.includes((itemsFromStockStatus ? item.instance.id : item.id))"
              >
                <v-flex class="red--text">
                  {{ (itemsFromStockStatus ? item.instance : item) | instanceLabel }}
                </v-flex>
                <v-flex class="text-right">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-icon color="red">
                          $info
                        </v-icon>
                      </div>
                    </template>
                    {{ $t('tasks.chooseItems.itemCannotBePutOnDestination') }}
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <slot
                v-else
                name="item"
                :item="item"
              >
                <v-layout>
                  <v-flex>
                    {{ (itemsFromStockStatus ? item.instance : item) | instanceLabel }}
                  </v-flex>
                </v-layout>
              </slot>
            </template>
            <template #selection="{ item }">
              <slot
                name="selection"
                :item="item"
              >
                {{ (itemsFromStockStatus ? item.instance : item) | instanceLabel }}
              </slot>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs5
          sm2
          md2
          class="px-1"
        >
          <v-text-field
            v-model="pickingItem.quantity"
            :label="$t(langPath + 'quantity')"
            prepend-icon="$quantity"
            :rules="chooseJustInstance ?
              (pickingItem.product_instance_id !== null ? [formRules.required, formRules.positiveInteger] : [])
              : (pickingItem.product_id !== null ? [formRules.required, formRules.positiveInteger]: [])"
            :suffix="chooseJustInstance ?
              (pickingItem.product_instance_id !== null ? '*' : '')
              : (pickingItem.product_id !== null ? '*' : '')"
            min="1"
            type="number"
            @change="clearItemErrors(pickingItem)"
            @input="clearItemErrors(pickingItem)"
          />
        </v-flex>
        <v-flex
          xs1
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                v-show="chooseJustInstance ? pickingItem.product_instance_id !== null : pickingItem.product_id !== null"
                :disabled="loading"
                icon
                v-on="on"
                @click="removeItem(index)"
              >
                <v-icon>
                  $removeItem
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t(langPath + 'removeItem') }}
            </span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-row>
    <TaskItemAmounts
      :items="chosenItems.filter(item => chooseJustInstance ? item.product_instance_id !== null : item.product_id !== null)"
      class="mt-4"
    />
  </v-container>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {has} from "@/utils/object";
    import {ProductAPI} from "@/api/ProductAPI";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import {ReactiveBarcodeCacheMixin} from "@/app/mixins/ReactiveBarcodeCacheMixin";
    import {CodeType} from "@/enum/code_type";
    import {readerFeedback} from "@/utils/readerFeedback";
    import TaskCodeLabel from "@/app/tasks/components/TaskCodeLabel.component";
    import {EventBus} from "@/service/EventBus";
    import {productMeasureLabel} from "@/utils/filters";
    import TaskItemAmounts from "@/app/tasks/components/TaskItemAmounts.component";
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "TaskChooseItems",
        components: {TaskItemAmounts, TaskCodeLabel},
        mixins: [EventsListenerMixin, ReactiveBarcodeCacheMixin],
        props: {
            chosenItems: {
                type: Array,
                default: () => []
            },
            availableItems: {
                type: Array,
                default: () => []
            },
            itemsFromStockStatus: {
                type: Boolean,
                default: false
            },
            titlePath: {
                type: String,
                default: ''
            },
            // user must choose a product and then instance - data of all instances are not available
            chooseInstance: {
                type: Boolean,
                default: false
            },
            // user chooses just instance
            chooseJustInstance: {
                type: Boolean,
                default: true
            },
            // allow user to create new instances
            createNewInstances: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            loadingItems: {
                type: Boolean,
                default: false
            },
            stockId: {
                type: Number,
                default: null
            },
            subStockId: {
                type: Number,
                default: null
            },
            destinationLocationId: {
                type: Number,
                default: null
            },
            allowEmpty: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            formRules: formRules,
            productInstances: {},
            langPath: 'tasks.chooseItems.',
            readerInput: null,
            barcodeLoading: false,
            readerLastScanned: null,
            unknownCode: false,
            readerInputErrors: [],
            EventBus: EventBus,
            creatingInstanceForItem: null,
            allowedInstancesIds: {},
            has: has,
            loadingAllowedInstances: false
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.onBarcodeRead,
                    'create-instance-created': this.onCreateInstanceCreated,
                    'create-instance-cancelled': this.onCreateInstanceCancelled
                };
            },
            chosenItemsLocal: {
                get: function () {
                    return this.chosenItems;
                },
                set: function (newValue) {
                    this.$emit('update:chosen-items', newValue);
                }
            },
            readerQueue: function () {
                return this.$store.getters['barcodeReaderQueue/list'];
            },
            destinationLocationAllowedInstancesInfo: function () {
                if (!this.stockId || this.destinationLocationId === null || this.destinationLocationId === undefined) {
                    return null;
                }
                if (this.loadingAllowedInstances) {
                    return null;
                }
                if (has(this.allowedInstancesIds, this.stockId) && has(this.allowedInstancesIds[this.stockId], this.destinationLocationId)) {
                    return this.allowedInstancesIds[this.stockId][this.destinationLocationId];
                }
                return null;
            }
        },
        watch: {
            barcodeLoading: function (value) {
                if (!value && !this.$store.getters['barcodeReaderQueue/empty']) {
                    const front = this.$store.getters['barcodeReaderQueue/front'];
                    this.$store.dispatch('barcodeReaderQueue/pop');
                    this.acceptBarcode(front);
                }
            },
            chosenItems: {
                immediate: true,
                handler: function () {
                    const productIds = new Set(this.chosenItems.map(item => item.product_id));
                    productIds.forEach(id => this.fetchInstances(id));
                }
            },
            stockId: function () {
                this.updateAllowedInstances();
            },
            destinationLocationId: function () {
                this.updateAllowedInstances();
            }
        },
        createdOrActivated: function () {
            this.productInstances = {};
            this.allowedInstancesIds = {};
        },
        methods: {
            filterProducts: function (item, query) {
                return this.$options.filters.productLabel(item).toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
            filterInstances: function (item, query) {
                const instance = this.itemsFromStockStatus ? item.instance : item;
                return this.$options.filters.instanceLabel(instance).toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
            fetchInstances: function (productId, reload = false) {
                if (!productId) {
                    return;
                }
                if (!has(this.productInstances, productId)) {
                    this.$set(this.productInstances, productId, []);
                }
                if (reload) {
                    // trigger loading and reload
                    this.$set(this.productInstances, productId, []);
                }
                if (this.productInstances[productId].length === 0) {
                    return ProductAPI.getAllInstancesAllPages(productId).then(response => {
                        const product = this.availableItems.find(item => item.id === productId);
                        const instances = response.data.items.map(instance => {
                            instance.value = instance.id;
                            instance.product = product;
                            return instance;
                        });
                        this.$set(this.productInstances, productId, instances);
                    });
                } else {
                    return Promise.resolve();
                }
            },
            productChanged: function (index) {
                const productId = this.chosenItemsLocal[index].product_id;
                this.$set(this.chosenItemsLocal[index], 'product_instance_id', null);
                this.fetchInstances(productId).then(() => {
                    if (this.productInstances[productId].length === 1) {
                        this.$set(this.chosenItemsLocal[index], 'product_instance_id', this.productInstances[productId][0].id);
                        this.instanceChanged(index);
                    }
                });
                if (this.chosenItemsLocal.filter(item => item.product_id === null).length === 0) {
                    this.chosenItemsLocal.push({product_id: null, product_instance_id: null});
                }
            },
            instanceChanged: function (index) {
                const instanceId = this.chosenItemsLocal[index].product_instance_id;
                if (this.chosenItemsLocal.filter(item => item.product_instance_id === instanceId).length > 1) {
                    this.chosenItemsLocal[index].errors = [this.$t(this.langPath + 'itemAlreadySelected')];
                } else if (this.destinationLocationAllowedInstancesInfo && !this.destinationLocationAllowedInstancesInfo.allowAll
                    && !this.destinationLocationAllowedInstancesInfo.instanceIds.includes(instanceId)) {
                    this.chosenItemsLocal[index].errors = [this.$t(this.langPath + 'itemCannotBePutOnDestination')];
                } else {
                    this.chosenItemsLocal[index].errors = [];
                }
                if (this.chosenItemsLocal.filter(item => item.product_instance_id === null).length === 0) {
                    this.chosenItemsLocal.push({product_id: null, product_instance_id: null});
                }
            },
            removeItem: function (index) {
                if (this.chosenItemsLocal.length === 1) {
                    this.snack(this.langPath + 'unableToRemoveLastItem');
                    return;
                }
                this.chosenItemsLocal.splice(index, 1);
            },
            instanceRules: function (index, productId) {
                if (this.chooseJustInstance) {
                    return (index === 0 && !this.allowEmpty) ? [formRules.required] : [];
                }
                if (this.chooseInstance) {
                    return (productId !== null && !this.allowEmpty) ? [formRules.required] : [];
                }
                return [];
            },
            instanceSuffix: function (index, productId) {
                if (this.chooseJustInstance) {
                    return index === 0 ? '*' : '';
                }
                if (this.chooseInstance) {
                    return productId !== null ? '*' : '';
                }
                return '';
            },
            onBarcodeRead: function (code, quantity = 1) {
                this.readerInputReset();
                code.split(/[\s,;]/)
                    .map(code => code.trim())
                    .filter(code => !!code)
                    .forEach(code => {
                        this.fetchBarcodeToCache(code);
                        if (this.barcodeLoading) {
                            this.$store.dispatch('barcodeReaderQueue/push', code);
                        } else {
                            this.acceptBarcode({code, quantity});
                        }
                    });
            },
            onCreateInstanceCreated: function (instanceId) {
                this.fetchInstances(this.creatingInstanceForItem.product_id, true).then(() => {
                    this.$set(this.creatingInstanceForItem, 'product_instance_id', Number.parseInt(instanceId, 10));
                    this.creatingInstanceForItem = null;
                });
            },
            onCreateInstanceCancelled: function () {
                this.creatingInstanceForItem = null;
            },
            readerInputReset: function (codeToSet = null) {
                this.readerInput = codeToSet;
                this.unknownCode = false;
                this.readerInputErrors = [];
            },
            handleError: function (untranslatedMessage = null, messageParam = []) {
                this.unknownCode = true;
                this.readerLastScanned = null;
                readerFeedback.error();
                untranslatedMessage && (this.readerInputErrors = [this.$t(untranslatedMessage, messageParam)]);
            },
            acceptBarcode: function ({code, quantity}) {
                this.barcodeLoading = true;
                BarcodeAPI.decode(code).then(response => {
                    const barcodeInfo = response.data;
                    if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                        this.readerLastScanned = code;
                        const instanceId = barcodeInfo.object_info.id;
                        let availableItem = null;
                        if (this.itemsFromStockStatus) {
                            availableItem = this.availableItems.find(item => item.value === instanceId);
                            if (!availableItem) {
                                this.handleError('tasks.chooseItems.itemNotAvailable');
                                return;
                            }
                        }
                        const item = this.chosenItemsLocal.find(item => item.product_instance_id === instanceId);
                        if (item) {
                            const desiredQuantity = +item.quantity + (quantity * barcodeInfo.quantity);

                            if (availableItem && desiredQuantity > availableItem.quantity) {
                                this.handleError('tasks.chooseItems.itemQuantityExceeded',
                                                 [availableItem.quantity + ' ' + productMeasureLabel(availableItem.instance.product)]);
                                return;
                            }
                            item.quantity = desiredQuantity;
                        } else {
                            const desiredQuantity = quantity * barcodeInfo.quantity;
                            if (availableItem && desiredQuantity > availableItem.quantity) {
                                this.handleError('tasks.chooseItems.itemQuantityExceeded',
                                                 [availableItem.quantity + ' ' + productMeasureLabel(availableItem.instance.product)]);
                                return;
                            }
                            const productId = barcodeInfo.object_info.product.id;
                            this.chosenItemsLocal.splice(this.chosenItemsLocal.length - 1, 0, {
                                product_id: productId,
                                product_instance_id: instanceId,
                                quantity: desiredQuantity
                            });
                            this.fetchInstances(productId);
                        }
                        readerFeedback.success();
                    } else {
                        this.handleError('tasks.chooseItems.locationScanned');
                    }
                }).catch(err => this.handleError(err))
                    .finally(() => this.barcodeLoading = false);
            },
            fetchBarcodeToCache: function (code) {
                this.cacheBarcode(BarcodeAPI.decode(code), code).catch(this.snack);
            },
            createNewInstance: function (index, forItem) {
                this.$refs['instance_' + index][0].blur();
                this.creatingInstanceForItem = forItem;
                EventBus.$emit('create-instance', forItem.product_id);
            },
            fetchAllowedInstances: function () {
                if (!has(this.allowedInstancesIds, this.stockId)) {
                    this.allowedInstancesIds[this.stockId] = {};
                }
                if (!has(this.allowedInstancesIds[this.stockId], this.destinationLocationId)) {
                    this.allowedInstancesIds[this.stockId][this.destinationLocationId] = {};
                    if (this.destinationLocationId === -1) {
                        this.allowedInstancesIds[this.stockId][this.destinationLocationId] = {
                            allowAll: true,
                            instanceIds: []
                        };
                        return Promise.resolve();
                    }
                    this.loadingAllowedInstances = true;
                    return StockAPI.getLocationAllowedInstancesIds(this.stockId, this.subStockId, this.destinationLocationId).then(response => {
                        const locationAllowedInstancesIds = this.allowedInstancesIds[this.stockId][this.destinationLocationId];
                        locationAllowedInstancesIds.allowAll = response.data.allow_all;
                        locationAllowedInstancesIds.instanceIds = response.data.product_instance_ids;
                        this.loadingAllowedInstances = false;
                    }).catch(() => this.loadingAllowedInstances = false);
                }
                return Promise.resolve();
            },
            updateAllowedInstances: function () {
                if (this.stockId && this.subStockId && this.destinationLocationId) {
                    this.fetchAllowedInstances().then(() => {
                        const info = this.allowedInstancesIds[this.stockId][this.destinationLocationId];
                        if (!info.allowAll) {
                            for (const item of this.chosenItemsLocal) {
                                if (item.product_instance_id && !info.instanceIds.includes(item.product_instance_id)) {
                                    item.errors = [this.$t(this.langPath + 'itemCannotBePutOnDestination')];
                                } else {
                                    item.errors = [];
                                }
                            }
                        } else {
                            for (const item of this.chosenItemsLocal) {
                                item.errors = [];
                            }
                        }
                        // for proper reactivity
                        this.chosenItemsLocal.pop();
                        this.chosenItemsLocal.push({product_id: null, product_instance_id: null});
                    });
                }
            },
            clearItemErrors: function (item) {
                delete item.errors;
            }
        }
    };
</script>

<style scoped>

</style>
