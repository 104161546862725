<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-card
        class="mb-2"
      >
        <v-container>
          <FormFields
            :form="form"
            :render="render"
            lang-path="base.shipping.handover.form."
          />
        </v-container>
      </v-card>
      <v-card v-if="form.carrierId && form.stockId">
        <x-data-table
          :headers="tableHeaders"
          :actions="actions"
          :items="[...possibleProtocols, ...fetchedItems]"
          :item-class="item => item.offerCreate === true && 'possible-protocol'"
          :additional-items-count="possibleProtocols.length"
          :loading="loading"
          item-key="protocol_id"
          :show-expand="!!$vuetify.breakpoint.smAndUp"
          :expanded.sync="expanded"
          :api-data-source="apiDataSource"
          :api-data-source-all-pages="apiDataSourceAllPages"
          :api-filter="apiFilter"
          :show-search-bar="false"
          :reload="reload"
          @update:loading="updateLoading"
          @update:items="updateItems"
        >
          <template #header>
            <div
              v-show="$vuetify.breakpoint.smAndUp"
            >
              <v-btn
                outlined
                color="accent darken-2"
                class="ml-3 my-2"
                :loading="loading"
                :disabled="!possibleProtocols || possibleProtocols.length === 0"
                @click="issueAllProtocols"
              >
                <v-icon class="mr-2">
                  $issueProtocol
                </v-icon>
                {{ $t('base.shipping.handover.handOverAll', [possibleProtocols.map(protocol => protocol.shipment_ids.length).reduce((acc, cur) => acc + cur, 0)]) }}
              </v-btn>
              <v-btn
                outlined
                color="accent darken-2"
                class="ml-3 my-2"
                :loading="loading"
                @click="clearStockShipments"
              >
                <v-icon class="mr-2">
                  $reloadData
                </v-icon>
                {{ $t('base.shipping.handover.reload') }}
              </v-btn>
            </div>
            <v-spacer />
          </template>
          <template #item.created_at="{ item }">
            <div v-if="item.offerCreate">
              <v-icon>
                $waiting
              </v-icon>
              {{ $t('base.shipping.handover.table.notYetIssued') }}
            </div>
            <DateTimeWithTooltip
              v-else
              :date-time="item.created_at"
              abs-date-first
            />
          </template>
          <template #item.substock.id="{ item }">
            {{ SubStockCache[item.substock_id] | subStockLabel }}
          </template>
          <template #item.shipment.ids="{ item }">
            {{ item.shipment_ids.length }}
            <v-chip
              label
              outlined
              class="ma-1"
              @click.stop="expandItem(item)"
            >
              {{ $t('base.shipping.handover.table.shipment.detail') }}
            </v-chip>
          </template>
          <template #expanded-item="{ item }">
            <BasicShipmentsList
              :shipment-ids="item.shipment_ids"
              :shipments="item.shipments"
            />
          </template>
          <template #footer>
            <TableAddItemButton
              :to="protocolCreateLink"
              label="base.shipping.handover.create.title"
            />
          </template>
        </x-data-table>
      </v-card>
    </v-container>
    <x-btn-fab
      :to="protocolCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {
        ShippingHandoverForm,
        ShippingHandoverRender
    } from "@/app/overview/shipping/definitions/shippingHandover.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import * as Export from "@/service/Export";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {TableFilter} from "@/enum/table_filter";
    import {createHeaders} from "@/utils/table";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import BasicShipmentsList from "@/app/overview/shipping/components/BasicShipmentsList.component";
    import {ShipmentProtocolMixin} from "@/app/mixins/ShipmentProtocolMixin";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import {ReactiveSubStockCacheMixin} from "@/app/mixins/ReactiveSubStockCacheMixin";
    import {StockAPI} from "@/api/StockAPI";
    import {ShipmentState} from "@/enum/shipment_state";
    import {has} from "@/utils/object";
    import {EventBus} from "@/service/EventBus";
    import qs from "qs";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "ShipmentProtocolsList",
        components: {FormFields, TableAddItemButton, BasicShipmentsList, DateTimeWithTooltip},
        mixins: [ShipmentProtocolMixin, ReactiveSubStockCacheMixin, RouteParamsMapperMixin],
        data: () => ({
            form: new ShippingHandoverForm(),
            render: new ShippingHandoverRender(),
            fetchedItems: [],
            loading: false,
            expanded: [],
            reload: 0,
            stockShipments: {}
        }),
        computed: {
            formUpdated: function () {
                this.form.carrierId;
                this.form.stockId;
                this.form.subStockId;
                return Date.now();
            },
            apiDataSource: function () {
                return ShipmentAPI.getAllProtocols.bind(
                    ShipmentAPI,
                    this.form.stockId,
                    {...this.apiFilterParam, sort: APIFilters.makeSort({created_at: 'DESC'})}
                );
            },
            apiDataSourceAllPages: function () {
                return ShipmentAPI.getAllProtocolsAllPages.bind(ShipmentAPI, this.form.stockId, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            },
            apiFilter: function () {
                const filter = [];
                if (this.form.carrierId) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            'carrier.id': this.form.carrierId
                        }
                    });
                }
                if (this.form.subStockId && this.form.subStockId.length !== 0 && !this.form.subStockId.includes(null)) {
                    filter.push({
                        [APIFilterOP.IN]: {
                            'substock.id': this.form.subStockId
                        }
                    });
                }
                return filter;
            },
            actions: function () {
                return [
                    {
                        condition: item => !item.offerCreate,
                        action: this.printProtocol,
                        icon: '$printItem',
                        label: 'base.shipping.handover.printProtocol',
                        notDefaultAction: true
                    },
                    {
                        condition: item => item.offerCreate,
                        action: this.issueProtocol,
                        icon: '$issueProtocol',
                        label: 'base.shipping.handover.table.issueProtocol',
                        notDefaultAction: true
                    }
                ];
            },
            tableHeaders: function () {
                return createHeaders(
                    {
                        created_at: {
                            filterType: TableFilter.DATETIME
                        },
                        'substock.id': {
                            filterType: TableFilter.SELECT_MULTIPLE,
                            filterItems: this.subStocks[null]?.[this.form.stockId] || [],
                            filterAction: this.subStockFiltered
                        },
                        'shipment.ids': {
                            sortable: false
                        }
                    }
                    , 'base.shipping.handover.table.', true, true);
            },
            protocolCreateLink: function () {
                const queryParams = {
                    carrierId: this.form.carrierId,
                    stockId: this.form.stockId,
                };
                if (this.form.subStockId && this.form.subStockId.length === 1&& !this.form.subStockId.includes(null)) {
                    queryParams.subStockId = this.form.subStockId[0];
                }
                const stringified = qs.stringify(queryParams, {skipNulls: true});
                return '/shipping/protocols/create' + (stringified.length === 0 ? '' : '?' + stringified);
            },
            possibleProtocols: function () {
                const stockId = this.form.stockId;
                const carrierId = this.form.carrierId;
                if (!carrierId || !stockId || !has(this.stockShipments, carrierId) || !has(this.stockShipments[carrierId], stockId)) {
                    return [];
                }
                const chosenStockShipments = this.stockShipments[carrierId][stockId];
                const chosenSubStocksShipments = [];
                let lastIdx = 0;
                let subStockIds = this.form.subStockId;
                if (!subStockIds || subStockIds.length === 0 || subStockIds.includes(null)) {
                    subStockIds = Object.keys(chosenStockShipments);
                }
                subStockIds.forEach(subStockId => {
                    if (has(chosenStockShipments, subStockId)) {
                        if (!this.SubStockCache[subStockId]) {
                            this.cacheSubStock(StockAPI.getSubstock(this.form.stockId, subStockId), subStockId);
                        }
                      
                        chosenSubStocksShipments.push({
                            protocol_id: --lastIdx,
                            carrier_id: carrierId,
                            substock_id: subStockId,
                            offerCreate: true,
                            shipment_ids: chosenStockShipments[subStockId].map(shipment => shipment.id),
                            shipments: chosenStockShipments[subStockId]
                        });
                    }
                });
                return chosenSubStocksShipments;
            }
        },
        watch: {
            formUpdated: function () {
                this.reload++;
            },
            'form.carrierId': function (newValue) {
                this.getSubStocksForCarrier(newValue, true);
            },
            'form.stockId': function (newValue) {
                if (newValue) {
                    this.fetchSubStocks(true);
                    this.getSubStocksForCarrier(this.form.carrierId, true);
                }
            },
            'form.subStockId': function (newValue) {
                EventBus.$emit('set-table-filter', 'substock.id', newValue);
            }
        },
        createdOrActivated: function () {
            this.clearStockShipments();

            if (this.carrierId !== undefined) {
                this.form.carrierId = this.carrierId;
            }

            if (this.stockId !== undefined) {
                if (this.stockId === this.form.stockId) {
                    this.fetchSubStocks(true);
                    this.getSubStocksForCarrier(this.form.carrierId, true);
                }
                this.form.stockId = this.stockId;
            } else if (this.form.stockId) {
                this.fetchSubStocks(true);
                this.getSubStocksForCarrier(this.form.carrierId, true);
            }

            if (this.subStockId !== undefined) {
                this.form.subStockId = [this.subStockId];
            }
        },
        methods: {
            updateLoading: function (newValue) {
                if (newValue) {
                    this.loading = true;
                }
                // else do nothing, we will set loading to false when shipments ready to be sent are loaded
            },
            updateItems: function (newItems) {
                const uniqueSubStockIds = new Set(newItems.map(item => item.substock_id));
                uniqueSubStockIds.forEach(subStockId => {
                    if (!this.SubStockCache[subStockId]) {
                        this.cacheSubStock(StockAPI.getSubstock(this.form.stockId, subStockId), subStockId);
                    }
                });

                this.fetchShipmentsWaitingForProtocol(newItems);
            },
            fetchShipmentsWaitingForProtocol: function (protocols) {
                const stockId = this.form.stockId;
                const carrierId = this.form.carrierId;
                if (!stockId || !carrierId) {
                    this.loading = false;
                    return;
                }
                let promise = Promise.resolve();
                if (!has(this.stockShipments, carrierId)) {
                    this.$set(this.stockShipments, carrierId, {});
                }
                if (!has(this.stockShipments[carrierId], stockId)) {
                    promise = ShipmentAPI.getAllPages({
                        filter: APIFilters.makeFilter([
                            {
                                [APIFilterOP.EQUALS]: {
                                    'carrier.id': carrierId
                                }
                            },
                            {
                                [APIFilterOP.EQUALS]: {
                                    'stock.id': stockId
                                }
                            },
                            {
                                [APIFilterOP.EQUALS]: {
                                    state: ShipmentState.WAITING_FOR_PICKUP
                                }
                            },
                            {
                                [APIFilterOP.IS_NULL]: 'last_protocol_created_at'
                            }
                        ])
                    }).then(response => {
                        const subStockShipments = {};
                        response.data.items.forEach(shipment => {
                            // group shipments by subStock
                            const subStockId = shipment.subordinate_stock.id;
                            if (!has(subStockShipments, subStockId)) {
                                subStockShipments[subStockId] = [];
                            }
                            subStockShipments[subStockId].push(shipment);
                        });
                        this.$set(this.stockShipments[carrierId], stockId, subStockShipments);
                    }).catch(this.snack);
                }
                promise.then(() => this.fetchedItems = protocols).finally(() => {
                    this.loading = false;
                });
            },
            clearStockShipments: function () {
                this.stockShipments = {};
                this.reload++;
            },
            subStockFiltered: function (filteredSubstocks) {
                this.form.subStockId = filteredSubstocks;
            },
            getShipmentsFilter: function (carrierId, subStockId = null) {
                const filter = [
                    {
                        [APIFilterOP.EQUALS]: {
                            'carrier.id': carrierId
                        }
                    }
                ];
                if (subStockId) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            'subordinate_stock.id': subStockId
                        }
                    });
                }
                return APIFilters.makeFilter(filter);
            },
            expandItem: function (item) {
                const expandedIdx = this.expanded.findIndex(itm => itm.protocol_id === item.protocol_id);
                if (expandedIdx > -1) {
                    this.expanded.splice(expandedIdx, 1);
                } else {
                    this.expanded.push(item);
                }
            },
            printProtocol: function (protocol) {
                this.loading = true;
                ShipmentAPI.getProtocol(protocol.carrier_id, protocol.stock_owner_id, protocol.protocol_id).then(response => {
                    Export.print(response.data.protocol_link, 'carrier_' + protocol.carrier_id + '_substock' + protocol.substock_id + '_shipment_protocol');
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            createAndPrintProtocol: function (protocol) {
                const stockId = this.form.stockId;
                const carrierId = this.form.carrierId;
              
                // intentional number and string conversion
                const subStock = this.subStocks[null][stockId].find(subStock => subStock.value == protocol.substock_id);
                return new Promise((resolve, reject) => {
                    ShipmentAPI.createProtocol(carrierId, subStock.ownerId, subStock.value, protocol.shipment_ids)
                        .then(response => {
                            const protocolId = response.headers.location.split('/').pop();
                            ShipmentAPI.getProtocol(carrierId, subStock.ownerId, protocolId).then(response => {
                                Export.print(response.data.protocol_link, 'carrier_' + carrierId + '_substock' + subStock.value + '_shipment_protocol');
                            }).catch(err => {
                                this.snack(err);
                                reject(err);
                            }).then(resolve);
                        }).catch(err => {
                            this.snack(err);
                            reject(err);
                        });  
                });
            },
            issueProtocol: function (protocol) {
                const stockId = this.form.stockId;
                const carrierId = this.form.carrierId;
                if (!stockId || !carrierId) {
                    return;
                }
                this.loading = true;
                this.createAndPrintProtocol(protocol)
                    .finally(() => {
                        this.loading = false;
                        this.clearStockShipments();
                    });
            },
            issueAllProtocols: function () {
                const stockId = this.form.stockId;
                const carrierId = this.form.carrierId;
                if (!stockId || !carrierId) {
                    return;
                }
                this.loading = true;
                const promises = [];
                this.possibleProtocols.forEach(protocol => promises.push(this.createAndPrintProtocol(protocol)));
                Promise.allSettled(promises).then(() => {
                    this.loading = false;
                    this.clearStockShipments();
                });
            }
        }
    };
</script>

<style lang="sass">
.possible-protocol
  background-color: var(--v-accent-base)
</style>