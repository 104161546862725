export default {
    close: 'Zavrieť',
    dataIterator: {
        pageText: '{0}-{1} z {2}',
        noResultsText: 'Nič nenájdené',
        loadingText: 'Načítavanie...',
    },
    dataTable: {
        itemsPerPageText: 'Riadkov na stránku:',
        ariaLabel: {
            sortDescending: ': Zoradené zostupne. Kliknite pre zrušenie zoradenia.',
            sortAscending: ': Zoradené vzostupne. Kliknite pre zostupné zoradenie.',
            sortNone: ': Nezoradené. Kliknite pre vzostupné zoradenie.',
            activateNone: 'Zrušiť zoradenie',
            activateDescending: 'Zoradiť zostupne',
            activateAscending: 'Zoradiť vzostupne'
        },
        sortBy: 'Zoradiť podľa ...',
    },
    dataFooter: {
        pageText: '{0}-{1} z {2}',
        itemsPerPageText: 'Riadkov na stránku:',
        itemsPerPageAll: 'Všetky',
        nextPage: 'Ďalšia',
        prevPage: 'Predošlá',
        firstPage: 'Prvá',
        lastPage: 'Posledná',
    },
    datePicker: {
        itemsSelected: '{0} vybraných',
        prevMonthAriaLabel: 'predchádzajúci mesiac',
        nextMonthAriaLabel: 'nasledujúci mesiac',
    },
    noDataText: 'Nič nenájdené',
    carousel: {
        prev: 'Predošlý',
        next: 'Ďalší',
    },
    calendar: {
        moreEvents: '{0} viac',
    },
    fileInput: {
        counterSize: '{0}x súbor ({1} celkom)'
    }
};
