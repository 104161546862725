import Vue from "vue";

// Share Cache values across all components which use this mixin
const BarcodeCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveBarcodeCacheMixin = {
    computed: {
        BarcodeCache: function () {
            // We need this to trigger an update in each components which might be using this Mixin simultaneously
            trigger.count;
            return BarcodeCache;
        }
    },
    methods: {
        cacheBarcode: function (runningRequest, key) {
            BarcodeCache[key] = null;
            return runningRequest.then(response => {
                BarcodeCache[key] = response.data;
                trigger.count++;
                return response;
            });
        }
    }
};

export {ReactiveBarcodeCacheMixin};
