<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-img
        :src="bigImageUrl"
      />
    </v-dialog>
    <v-img
      v-if="justOneImage && singleImage"
      :src="singleImage.url"
      :lazy-src="publicPath + 'assets/product_image_placeholder.jpg'"
      class="ma-1"
      contain
      @click="showImage(singleImage.url)"
    />
    <v-layout
      v-else-if="!justOneImage"
      class="justify-center mt-1"
      column
    >
      <v-flex>
        <v-layout
          class="justify-center mt-1"
          row
        >
          <v-flex
            v-for="(image, index) of images"
            :key="index"
            xs6
            md4
          >
            <v-img
              v-if="index < imageLimit"
              :src="image.url"
              :lazy-src="publicPath + 'assets/product_image_placeholder.jpg'"
              :aspect-ratio="1"
              class="grey lighten-2 ma-1"
              @click="showImage(image.url)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        v-model="addImageDialog"
        width="800"
      >
        <v-card>
          <v-card-title>
            {{ $t('base.images.create.title', [productName]) }}
          </v-card-title>
          <ImageCreateComponent
            :item-name="productName"
            :item-id="productId"
            @imagesUpdated="imagesUpdated"
          />
        </v-card>
      </v-dialog>
      <v-flex
        class="mt-2"
      >
        <v-btn
          outlined
          color="accent darken-2"
          class="ml-3 my-2"
          @click="addImageDialog = true"
        >
          <v-icon class="mr-2">
            $addItem
          </v-icon>
          {{ $t('base.images.create.titleSimple') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import ImageCreateComponent from "@/app/images/components/ImageCreate.component";
    import {Product} from "@/utils/product";

    export default {
        name: "TaskItemDetailImages",
        components: {ImageCreateComponent},
        props: {
            productId: {
                type: Number,
                default: null
            },
            lazyLoadTrigger: {
                type: Boolean,
                default: false
            },
            productName: {
                type: String,
                default: ''
            },
            justOneImage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            images: [],
            dialog: false,
            bigImageUrl: null,
            publicPath: process.env.BASE_URL,
            addImageDialog: false
        }),
        computed: {
            imageLimit: function () {
                return 12;
            },
            singleImage: function () {
                return Product.getSingleImage({images: this.images});
            }
        },
        watch: {
            lazyLoadTrigger: {
                handler: function (load) {
                    load && this.fetchImages();
                },
                immediate: true
            }
        },
        methods: {
            fetchImages: function () {
                if (this.productId === null) {
                    return;
                }
                ProductAPI.getAllImages(this.productId)
                    .then(response => {
                        if (response.data.length) {
                            this.images = response.data;
                            this.$emit('has-image', !!this.images.length);
                        }
                    })
                    .catch(() => {
                        this.snack('products.images.unableToLoad');
                    });
            },
            showImage: function (imageUrl) {
                this.dialog = true;
                this.bigImageUrl = imageUrl;
            },
            imagesUpdated() {
                this.addImageDialog = false;
                this.fetchImages();
            }
        }
    };
</script>

<style scoped>

</style>
