import {AddressForm} from "@/app/components/form/definitions/address.form";
import {TableFilter} from "@/enum/table_filter";
import {EventBus} from "@/service/EventBus";
import {BuyerTypeAPI} from "@/api/BuyerTypeAPI";

function BuyerForm() {
    return {
        name: '',
        type_id: 1,
        ico: null,
        dic: null,
        phone: null,
        email: null,
        website: null,
        billing_address: new AddressForm,
        delivery_address: new AddressForm
    };
}

function BuyerRender() {
    return {
        ico: {
            icon: '$ico',
            type: 'number',
            max: 30,
            appendOuter: true
        },
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
        type_id: {
            icon: '$buyerType',
            autocomplete: {
                callFn: () => BuyerTypeAPI.getAllPages(),
                thenFn: response => response.data.items
                    .map(el => ({
                        text: el.name,
                        value: el.id
                    }))
            },
            createNew: {
                icon: '$buyerType',
                label: 'buyers.types.create.label',
                action: () => {
                    EventBus.$emit('create-buyer-type');
                }
            }
        },
        dic: {
            icon: '$dic',
            max: 30
        },
        phone: {
            icon: '$phone',
            type: 'number',
            max: 20
        },
        email: {
            icon: '$email',
            type: 'email',
            max: 50
        },
        website: {
            icon: '$website'
        }
    };
}

const buyerTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    'type.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => BuyerTypeAPI.getAllPages(),
            thenFn: response => response.data.items.map(el => ({
                text: el.name,
                value: el.id
            }))
        },
        itemLabel: item => item.type.name,
        sortable: true,
        sortBy: 'type.id'
    },
    ico: {
        filterType: TableFilter.NUMBER
    },
    dic: {
        filterType: TableFilter.TEXT
    },
    phone: {
        filterType: TableFilter.NUMBER
    },
    email: {
        filterType: TableFilter.TEXT
    },
    website: {
        filterType: TableFilter.TEXT
    }
};

export {BuyerForm, BuyerRender, buyerTable};
