<template>
  <div>
    <v-layout wrap>
      <v-flex
        md6
        xs12
      >
        <v-card>
          <v-container>
            <v-form
              ref="formLocal"
              @submit.prevent
            >
              <v-text-field
                v-model="imageLocalName"
                :label="$t('base.images.type.localLabel')"
                prepend-icon="$attachment"
                clearable
                readonly
                @click="$refs.image.click()"
                @click:clear="onFileCleared()"
              />
              <input
                ref="image"
                type="file"
                style="display: none"
                accept="image/*"
                @change="onFilePicked"
              >
              <v-btn
                :text="!validLocal"
                :loading="loading"
                type="submit"
                color="accent"
                @click="submitLocal()"
              >
                <v-icon
                  class="mr-2"
                >
                  $saveItem
                </v-icon>
                {{ $t('form.save') }}
              </v-btn>
              <v-img
                v-show="validLocal"
                :src="imageLocalUrl"
                :alt="$t(langPath + 'images.altCreate')"
                height="200"
                contain
                @error="onFileError"
              />
            </v-form>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex
        md6
        xs12
      >
        <div
          v-show="$vuetify.breakpoint.smAndDown"
          class="my-2"
        />
        <v-card>
          <v-container>
            <v-form
              ref="formExternal"
              @submit.prevent
            >
              <v-text-field
                v-model="imageExternalUrl"
                :label="$t('base.images.type.externalLabel')"
                prepend-icon="$imageLink"
                clearable
                @change="onUrlChanged"
                @click:clear="onUrlCleared"
              />
              <v-switch
                v-model="store"
                :label="$t('base.images.store.title')"
                :hint="store ? $t('base.images.store.hint_store_link') : $t('base.images.store.hint_keep_link')"
                persistent-hint
                class="mt-0 mb-3"
              />
              <v-btn
                :text="!validExternal"
                :loading="loading"
                type="submit"
                color="accent"
                @click="submitExternal"
              >
                <v-icon
                  class="mr-2"
                >
                  $saveItem
                </v-icon>
                {{ $t('form.save') }}
              </v-btn>
              <v-img
                v-show="validExternal"
                :src="imageExternalUrl !== null ? imageExternalUrl : ''"
                :alt="$t(langPath + 'images.altCreate')"
                height="200"
                class="mt-3"
                contain
                @load="onUrlLoad"
                @error="onUrlError"
              />
            </v-form>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";

    export default {
        name: "ImageCreateComponent",
        mixins: [InstanceCacheMixin],
        props: {
            itemName: {
                type: String,
                default: ''
            },
            itemId: {
                type: Number,
                default: null
            },
            isProductImage: {
                type: Boolean,
                default: true
            },
            isFoilImage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            validLocal: false,
            imageLocalName: '',
            imageLocalUrl: '',
            imageLocalFile: '',
            validExternal: false,
            imageExternalUrl: '',
            store: false,
            api: ProductAPI,
            langPath: 'products.'
        }),
        created: function () {
            if (!this.isProductImage) {
                this.api = ShipmentCarrierAPI;
                this.langPath = 'carriers.';
            }
        },
        methods: {
            onFilePicked: function (e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.imageLocalName = files[0].name;
                    if (this.imageLocalName.lastIndexOf('.') <= 0) {
                        return;
                    }
                    const imageAsUrl = new FileReader();
                    imageAsUrl.readAsDataURL(files[0]);
                    imageAsUrl.addEventListener('load', () => {
                        this.imageLocalUrl = imageAsUrl.result;
                        this.imageLocalFile = files[0];
                        this.validLocal = true;
                    });
                } else {
                    this.onFileError();
                }
            },
            onFileCleared: function () {
                this.validLocal = false;
                this.imageLocalName = '';
                this.imageLocalFile = '';
                this.imageLocalUrl = '';
            },
            onFileError: function () {
                this.snack('base.images.create.unableToLoad');
                this.onFileCleared();
            },
            submitLocal: function () {
                if (!this.validLocal) {
                    this.$refs.formLocal.validate();
                    return;
                }
                this.loading = true;
                const promise = this.isFoilImage ? this.api.createLocalFoilImage(this.itemId, this.imageLocalFile) :
                    this.api.createLocalImage(this.itemId, this.imageLocalFile);
                promise
                    .then(() => {
                        this.advancedSnack({
                            text: 'base.images.create.done',
                            params: [this.itemName]
                        });
                        if (this.isProductImage) {
                            this.clearInstanceCachesByProduct(this.itemId);
                        }
                        this.validLocal = false;
                        this.imageLocalName = '';
                        this.imageLocalUrl = '';
                        this.imageLocalFile = '';
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$emit('imagesUpdated');
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onUrlChanged: function () {
            },
            onUrlCleared: function () {
                this.validExternal = false;
                this.imageExternalUrl = '';
            },
            onUrlLoad: function () {
                if (this.imageExternalUrl !== '') {
                    this.validExternal = true;
                }
            },
            onUrlError: function () {
                // TODO do not submit Sentry
                this.validExternal = false;
                this.snack('base.images.create.unableToLoad');
            },
            submitExternal: function () {
                if (!this.validExternal) {
                    this.$refs.formExternal.validate();
                    return;
                }
                this.loading = true;
                const promise = this.isFoilImage ? this.api.createExternalFoilImage(this.itemId, this.imageExternalUrl, this.store) :
                    this.api.createExternalImage(this.itemId, this.imageExternalUrl, this.store);
                promise
                    .then(() => {
                        this.advancedSnack({
                            text: 'base.images.create.done',
                            params: [this.itemName]
                        });
                        this.validExternal = false;
                        this.imageExternalUrl = '';
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$emit('imagesUpdated');
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
// TODO use https://vuetifyjs.com/en/components/file-inputs
</script>

<style scoped>

</style>
