import {measurementUnits} from "@/enum/measurement_unit";

const measurementUnit = {
    name: "Mérési egység",
    [measurementUnits.PIECE]: 'Darab',
    [measurementUnits.CARTON]: 'Karton',
    [measurementUnits.HECTOLITER]: 'Hektoliter',
    [measurementUnits.LITER]: "Liter",
    [measurementUnits.DECILITER]: "Deciliter",
    [measurementUnits.CENTILITER]: "Centiliter",
    [measurementUnits.MILLILITER]: "Milliliter",
    [measurementUnits.TONNE]: "Tonna",
    [measurementUnits.KILOGRAM]: "Kilogramm",
    [measurementUnits.DECAGRAM]: "Dekagramm",
    [measurementUnits.GRAM]: "Gramm",
    [measurementUnits.MILLIGRAM]: "Milligramm",
    [measurementUnits.KILOMETER]: "Kilométer",
    [measurementUnits.METER]: "Méter",
    [measurementUnits.CENTIMETER]: "Centiméter",
    [measurementUnits.MILLIMETER]: "Milliméter",
    [measurementUnits.SQUARE_METER]: "Négyzetméter",
    [measurementUnits.CUBIC_METER]: "Köbméter",

    [measurementUnits.MILE]: "Mérföld",
    [measurementUnits.INCH]: "Inch",
    [measurementUnits.POUND]: "Font",
    [measurementUnits.OUNCE]: "Uncia",
    [measurementUnits.US_GALLON]: "US gallon",
    [measurementUnits.UK_GALLON]: "Brit gallon",
    [measurementUnits.SQUARE_FOOT]: "Négyzetláb",
    [measurementUnits.CUBIC_FOOT]: "Köbláb"
};

export default {
    title: "Készletelemek",
    titleSingle: "Készletelem",
    link: "Készletelemek",

    name: "Név",
    description: "Leírás",
    model: "Modell",
    manufacturer_id: "Gyártó",
    type_id: "Típus",
    manufacturer: {
        id: "Gyártó",
        label: "Gyártó",
        name: "Gyártó"
    },
    type: {
        id: "Típus",
        label: "Típus"
    },

    measure_unit: "Mértékegység",
    measurementUnit: measurementUnit,
    visible: "Látható",
    notVisible: "Rejtett",
    deleted: "Törölve",
    can_have_batch: "Lehet tételekben számolni",
    can_have_serial_number: "Lehet sorozatszáma",
    created_at: "Elkészült",
    suggested_buy_price: "Általános vételár (áfa nélkül)",
    suggested_sell_price: "Javasolt eladási ár (áfa nélkül)",
    buy_price: "Vételár egységenként",
    sell_price: "Eladási ár egységenként",
    price_vat: "Áfa-kulcs",
    vat_included: "incl. ÁFÁT BELEÉRTVE",
    unknown: "<Ismeretlen>",
    tableImage: "",
    instance: "Tételes/Sorozat",
    quantity: "Mennyiség",
    searchHint: "Név, modell, gyártó és mértékegység keresése",

    attributeAlreadyEntered: "Az ajánlott attribútum már létezik!",
    unableToRemoveLastAttribute: "Az utolsó attribútum nem távolítható el!",

    attributes: {
        name: "Attribútumok",
        key: "Cím",
        value: "Érték",
        description: "Leírás",
        unableToLoad: "A készletelem attribútumainak listáját nem sikerült betölteni. Kérjük, próbálja meg később újra.",
        hint: "Az üres értékkel rendelkező attribútumok nem kerülnek mentésre",
        create: {
            titleSimple: "Attribútum hozzáadása",
            title: "Attribútum hozzáadása '{0}'",
            done: "Hozzáadva '{0}' az '{1}'-hez"
        },
        update: {
            titleSimple: "Attribútum szerkesztése",
            title: "Szerkesztés '{0}'/ '{1}'",
            done: "Módosította '{0}' / '{1}'"
        },
        delete: {
            done: "Attribútum '{0}' törölve.",
            failed: "A '{0}' attribútum nem törölhető. Kérjük, próbálja meg később újra."
        },
        status: {
            404: "A készletkártya vagy az attribútum neve nem található!",
            409: "A megadott attribútum neve már létezik!"
        }
    },

    overview: {
        name: "Tétel áttekintése",
        inStock: "Készleten",
        notInStock: "Ez a termék nincs raktáron",
        InTasks: "A feladatok között",
        notInTasks: "Ez az elem nincs blokkolva egyetlen feladatban sem",
        Ordered: "Megrendelve a",
        notOrdered: "Nincsenek megrendelések erre a tételre",
        altogetherPieces: "{0} db",
    },

    types: {
        link: "A készletelemek típusai",
        title: "A készletelemek típusai",
        titleSingle: "A készletelem típusa",
        name: "Név",
        default: "<alapértelmezett>",
        suggestedAttributes: {
            name: "Ajánlott jellemzők",
            key: "Név",
            description: "Leírás",
            hint: "Ez egyfajta"
        },

        removeAttribute: "Attribútum eltávolítása",

        create: {
            label: "Új készletelemtípus létrehozása",
            title: "Új készletelem típusa",
            done: "Létrehozott '{0}' típusú készletelem"
        },
        update: {
            titleSimple: "Készletelem típusának szerkesztése",
            title: "Szerkesztette '{0}'",
            done: "A '{0}' típusú raktárkészlet tételt módosította"
        },
        delete: {
            done: "A '{0}' típusú készletelem törlésre került.",
            failed: "A '{0}' típusú készletelemet nem sikerült törölni. Kérjük, próbálja meg később újra."
        },

        status: {
            404: "A készletelem típusa nem található!",
            409: "A raktári tételtípust nem lehetett törölni, mert van ilyen típusú termék a raktárban!"
        }
    },


    batches: {
        name: "Tételek",
        code: "Kód",
        manufactured_date: "A gyártás dátuma",
        manufactured_time: "A gyártás időpontja",
        expire_date: "Lejárati dátum",
        expire_time: "Lejárati idő",
        create: {
            titleSimple: "Tétel hozzáadása",
            title: "Tétel hozzáadása a következőhöz: '{0}'",
            done: "Hozzáadva '{0}' az '{1}'-hez'"
        },
        update: {
            titleSimple: "Tétel szerkesztése",
            title: "A '{0}' tétel szerkesztése a '{1}'-nél",
            done: "Módosított tétel '{0}' az '{1}'-nél"
        },
        delete: {
            done: "A '{0}' tétel törölve.",
            failed: "A '{0}' tétel nem törölhető. Kérjük, próbálja meg később újra.",
            status: {
                404: "Nem találtunk a készletkártyát vagy a tételkódot!",
                409: "A tételkód nem törölhető más objektumokkal való kapcsolatai miatt!"
            }
        },
        status: {
            404: "Nem találtunk készletkártyát vagy tételkódot!",
            409: "A megadott tételkód már létezik!"
        }
    },

    customInstances: {
        name: "Különleges esetek",
        hint: "Ha meg kell különböztetnie egy tárgy állapotát, kopását és elhasználódását, és kezelnie kell a kódjait.",
        product_instance_type_id: "Típus",
        create: {
            titleSimple: "Különleges példány hozzáadása",
            label: "Új egyedi elem létrehozása",
            title: "Különleges példány hozzáadása a következőhöz: '{0}'",
            done: "Hozzáadva egy példányt '{0}' az '{1}'-hez"
        },
        update: {
            titleSimple: "Különleges példány szerkesztése",
            title: "A '{0}' típuspéldányt szerkesztése a '{1}'-nél",
            done: "Módosított típuspéldányt '{0}' az '{1}'-nél",
            status: {
                404: "Nem talált készletkártya vagy példány!",
                409: "A példány típusa nem változtatható meg a példány más objektumokkal való kapcsolatai miatt!"
            }
        },
        status: {
            404: "A készletkártya nem található!",
            409: "Ismeretlen vagy érvénytelen példánytípus!!"
        }
    },

    images: {
        alt: "Az elemhez tartozó kép",
        altCreate: "Az elemhez rendelendő kép",
        unableToLoad: "A készletelemek képeit nem sikerült betölteni. Kérjük, próbálja meg később újra.",
        status: {
            404: "Nem találtuk meg a készletkártyát vagy a képet!"
        }
    },

    instances: {
        name: "Az alaptétel vonalkódjai",
        id: "Azonosító",
        type: {
            name: "Típus",
            NORMAL: "Közönséges",
            SERIAL_NUMBER: "Sorozatszám",
            BATCH: "Tétel"
        },
        manufactured_date: "A gyártás dátuma",
        expire_date: "Lejárati dátum",
        serial_number: "Sorozatszám",
        batch_code: "Tételszám",
        serial: "Sorozat: {0}",
        batch: "Tétel: {0}",

        types: {
            label: "Különleges példánytípusok",
            name: "Név",

            create: {
                titleSimple: "Speciális példánytípus hozzáadása",
                title: "Új speciális példánytípus",
                label: "Új speciális példánytípus létrehozása",
                done: "Külön példánytípus '{0}' hozzáadva"
            },
            update: {
                title: "Speciális példánytípus szerkesztése '{0}'",
                titleSimple: "Speciális példánytípus szerkesztése",
                done: "Speciális példánytípus '{0}' módosítva"
            },
            delete: {
                done: "Speciális példánytípus '{0}' törölve!",
                status: {
                    404: "Speciális példánytípus nem található!",
                    409: "Az adott típusú példányok vannak raktáron!"
                }
            },
            status: {
                404: "Speciális példánytípus nem található!",
                409: "A megadott névvel rendelkező speciális típus már létezik!"
            }
        },

        barcodes: {
            Link: "Részlet",
            title: "Vonalkódok a '{0}' tételhez",
            titleSimple: "Vonalkódok",
            name: "Vonalkód",
            code: "Kód",
            image: "Előnézet",
            quantity: "Mennyiség",
            description: "Kódleírás",
            descriptionHint: "csomagtípusok: 'karton', 'raklap' stb.",
            conflict: 'Ez a kód már tartalmaz egy elemet',

            print: 'Vonalkód nyomtatása',
            printMany: 'Vonalkódok nyomtatása',
            printHowMuch: "Hány '{0}' címkét kell nyomtatni?",
            printMultipleHowMuch: "Hány címkét kell nyomtatni?",
            printDone: "'{0}' címke nyomtatása...",

            batchPrint: {
                label: "Nyomtatási paraméterek kiválasztása",
                printAll: "Annyi címke nyomtatása, ahány elem van",
                printJustOne: "Minden tételhez csak egy címke"
            },

            create: {
                title: "Vonalkód hozzáadása: '{0}'",
                titleSimple: "Vonalkód hozzáadása",
                done: "'{0}' kód hozzáadva:'{1}'"
            },
            update: {
                title: "'{0}' kód szerkesztése a '{1}' helyen",
                titleSimple: "Kód szerkesztése az elemen",
                done: "'{0}' kód /'{1}' módosítva"
            },
            delete: {
                done: "Kód '{0}' / '{1}' törölve!"
            },
            status: {
                404: "A készletkártya, az adott készletelem vagy a vonalkód nem található!",
                409: "A megadott vonalkód már létezik!"
            }
        },

        status: {
            404: "A készletkártya vagy az adott készletelem nem található!"
        }
    },

    serial_numbers: {
        name: "Sorozatszámok",
        number: "Sorozatszám",
        serial_number: "Sorozatszám",
        same_barcode: "Készítse el ugyanazt a vonalkódot is",
        create: {
            titleSimple: "Sorszám hozzáadása",
            title: "Sorozatszám hozzáadása a '{0}'",
            done: "'{0}' hozzáadva '{1}'"
        },
        delete: {
            done: "Sorozatszám eltávolítva.",
            failed: "A sorozatszámot nem sikerült eltávolítani. Kérjük, próbálja meg később újra.",
            status: {
                404: "A készletkártya, az adott készletelem vagy a sorozatszám nem található!",
                409: "A sorozatszámot nem lehetett törölni más objektumokkal való kapcsolatai miatt!"
            }
        },
        status: {
            404: "A készletkártya, az adott készletelem vagy a sorozatszám nem található!",
            409: "A megadott sorozatszám már létezik!"
        }
    },

    pieces: {
        name: "Készleten lévő darabok",
        status: "Készleten",
        movements: "Tétel áthelyezések"
    },

    show: {
        titleSimple: "Készletelem részlet",
        title: "{0}"
    },
    create: {
        title: "Új készletelem",
        done: "Létrejött a '{0}' készletelem."
    },
    update: {
        titleSimple: "Készletelem szerkesztése",
        title: "Edit {0}",
        done: "A '{0}' készletelemet módosították."
    },
    delete: {
        done: "A '{0}' készletelemet törölték.",
        failed: "A '{0}' készletelem nem törölhető. Kérjük, próbálja meg később újra."
    },

    status: {
        404: "A készletkártya nem található!",
        409: "A tétel nem törölhető, mert még mindig van raktáron!",
        412: "A készletelemet az utolsó betöltés óta egy másik felhasználó módosította. Kérjük, próbálja meg később újra a műveletet."
    }
};
