import { render, staticRenderFns } from "./LoaderBottom.component.vue?vue&type=template&id=524c0001&scoped=true&"
import script from "./LoaderBottom.component.vue?vue&type=script&lang=js&"
export * from "./LoaderBottom.component.vue?vue&type=script&lang=js&"
import style0 from "./LoaderBottom.component.vue?vue&type=style&index=0&id=524c0001&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524c0001",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
