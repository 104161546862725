import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const TaskStockPickingSetAPI =  {
    API: BaseAPI,
    DOMAIN: 'tasks/stock-picking-set',
    LANG: 'tasks.stockPickingSet',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',
    LANG_INVENTORY: 'inventory',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG
        );
    },

    update(taskId, data) {
        return this.API.put(
            [this.DOMAIN, taskId],
            data
        );
    },

    delete(taskId) {
        return this.API.delete(
            [this.DOMAIN, taskId],
            [this.LANG, 'delete']
        );
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    setDestination(taskId, destination_stock_location_id) {
        return this.API.put(
            [this.DOMAIN, taskId, 'set-destination-location'],
            {destination_stock_location_id},
            [this.LANG, 'location']
        );
    },

    pickUpFromSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'move-from-source-to-inventory'],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'move-from-inventory-to-source'],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'move-from-inventory-to-destination'],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    pickUpFromDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'move-from-destination-to-inventory'],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    approve(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, 'approve'],
            data,
            [this.LANG_TASKS, 'approve']
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'assign'],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'unassign'],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'finish'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },

    handOver(taskId, hours, note) {
        return this.finish(taskId, hours, note);
    },

    reject(taskId, hours, note, withdraw_storekeeper) {
        return this.API.post(
            [this.DOMAIN, taskId, 'reject'],
            {
                hours: hours,
                note: note,
                withdraw_storekeeper: withdraw_storekeeper
            },
            [this.LANG_TASKS, 'reject']
        );
    }
};

export {TaskStockPickingSetAPI};
