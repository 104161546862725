<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <v-autocomplete
      v-show="hardcodedAttribute === null"
      outlined
      dense
      hide-details
      :label="$t(langPath + 'chooseAttr')"
      :value="filter[operator]"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
  </div>
</template>

<script>
    import {APIFilterMixin} from "@/app/mixins/APIFilterMixin";

    export default {
        name: "FilterConfigUnaryOp",
        mixins: [APIFilterMixin],
        methods: {
            propertyChanged: function (value) {
                this.$set(this.filter, this.operator, value);
            }
        }
    };
</script>

<style scoped>

</style>
