<template>
  <v-layout
    column
    align-center
  >
    <v-flex class="text-caption">
      <div class="d-flex align-center">
        <v-icon
          v-if="titleIcon"
          class="mr-2 mb-1"
        >
          {{ titleIcon }}
        </v-icon>
        {{ $t('tasks.itemsCard.' + titleLangPath) }}
      </div>
    </v-flex>
    <v-flex class="mx-2">
      <v-layout
        align-center
        wrap
        justify-center
      >
        <template v-if="itemOnWrongLocation">
          <Alert
            show-alert
            type="error"
            class="mb-n1"
          >
            <template #content>
              {{ $t(allowedLocationsWarningPath) }}
              <v-btn
                class="multiLineButton mt-2"
                color="white"
                light
                @click="() => EventBus.$emit('fetch-allowed-locations', item, true)"
              >
                <v-icon left>
                  $reloadData
                </v-icon>
                {{ $t('tasks.locationsCard.reloadAllowedLocations') }}
              </v-btn>
            </template>
          </Alert>
        </template>
        <template v-else-if="locations === undefined">
          <v-chip
            label
            outlined
          >
            <v-progress-circular
              indeterminate
              size="16"
              width="2"
            />
          </v-chip>
        </template>
        <template v-else>
          <WrappedChipList
            v-slot="{item: location}"
            :items="locations"
          >
            <template v-if="type === 'put'">
              {{ LocationCache[location] | locationLabel }}
            </template>
            <template v-else-if="type === 'picked'">
              {{ location.pick_quantity }}&nbsp;
              <span class="caption">{{ (item.product || item.instance.product) | productMeasureLabel }}</span>&nbsp;
              {{ LocationCache[location.stock_location_id] | locationLabel }}
            </template>
            <template v-else>
              {{ location.quantity }}&nbsp;
              <span class="caption">{{ (item.product || item.instance.product) | productMeasureLabel }}</span>&nbsp;
              {{ location.stock_location | locationLabel }}
            </template>
          </WrappedChipList>
        </template>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {EventBus} from "@/service/EventBus";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import Alert from "@/app/components/Alert.component";
    import WrappedChipList from "@/app/components/WrappedChipList.component";

    export default {
        name: "TaskItemDetailLocations",
        components: {WrappedChipList, Alert},
        mixins: [ReactiveLocationCacheMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            type: { // 'pick' or 'put', 'placed'
                type: String,
                default: 'pick'
            },
            cardType: {
                type: Number,
                default: 0
            },
            itemOnWrongLocation: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            EventBus: EventBus
        }),
        createdOrActivated: function () {
            if (this.type === 'pick' && !this.item.locations) {
                EventBus.$emit('fetch-locations', this.item.instance.id);
            }
        },
        computed: {
            titleIcon: function () {
                return {
                    put: '$canBePutOn',
                    pick: '$canBeFoundAt',
                    picked: '$pickedUpFrom',
                    placed: '$alreadyPlaced',
                }[this.type];
            },
            titleLangPath: function () {
                return {
                    put: 'canBePutOn',
                    pick: 'canBeFoundAt',
                    picked: 'pickedUpFrom',
                    placed: 'alreadyPlacedAt',
                }[this.type];
            },
            locations: function () {
                if (this.type === 'put') {
                    return this.item.allowedLocationIds;
                } else if (this.type === 'pick') {
                    if (!this.item.locations) {
                        return undefined;
                    }
                    const pickFromMobileLocation = this.$store.getters['userConfig/pickFromMobileLocation'];
                    return this.item.locations.filter(loc => !loc.stock_location.is_expedition
                        && (pickFromMobileLocation ? true : !loc.stock_location.is_mobile));
                } else if (this.type === 'picked') {
                    if (!this.item.source_locations) {
                        return undefined;
                    }
                    return this.item.source_locations;
                } else if (this.type === 'placed') {
                    if (!this.item.alreadyPlacedAt) {
                        return undefined;
                    }
                    return Object.values(this.item.alreadyPlacedAt).sort((a, b) => b.quantity - a.quantity);
                } else {
                    console.warn('Should not happen: unknown TaskItemDetailLocations.type');
                    return [];
                }
            },
            allowedLocationsWarningPath: function () {
                return this.cardType === TaskItemsCardType.IN_INVENTORY ? 'tasks.locationsCard.cannotBePutOnAnyLocation'
                    : 'tasks.locationsCard.cannotBePutOnThisLocation';
            }
        }
    };
</script>

<style scoped>

</style>
