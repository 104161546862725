<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="carriers.services."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submitAfterEventCycle"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {CarrierServiceForm, CarrierServiceFormRender} from "@/app/carriers/definitions/carrierService.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {setFormErrors} from "@/utils/form";
    import {tabTitle} from "@/utils/string";

    export default {
        name: "CarrierServiceCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            form: new CarrierServiceForm,
            formRender: new CarrierServiceFormRender,
            valid: true,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('carriers.services.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return ShipmentCarrierAPI.getService(this.carrierId, this.serviceId)
                    .then(response => {
                        response.data.required_carrier_service_parameters = response.data.carrier_service_parameters.filter(par => par.required).map(par => par.name);
                        response.data.optional_carrier_service_parameters = response.data.carrier_service_parameters.filter(par => !par.required).map(par => par.name);
                        return response;
                    });
            },
            submitAfterEventCycle: function () {
                setTimeout(this.submit);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.form.carrier_service_parameters = [
                    ...this.form.required_carrier_service_parameters.map(name => ({name, required: true})),
                    ...this.form.optional_carrier_service_parameters.map(name => ({name, required: false}))
                ];
                if (this.isEdit) {
                    ShipmentCarrierAPI.updateService(this.carrierId, this.serviceId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'carriers.services.update.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ShipmentCarrierAPI.createService(this.carrierId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'carriers.services.create.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
