import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const TaskDeliveryAcceptAPI =  {

    API: BaseAPI,
    DOMAIN: 'tasks/delivery-accept',
    LANG: 'tasks.deliveryAccept',
    LANG_TASKS: 'tasks',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG]
        }, forceFromAPI);
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, 'items'],
            this.LANG
        );
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data
        );
    },

    approve(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, 'approve'],
            data,
            [this.LANG_TASKS, 'approve']
        );
    },

    reject(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'reject'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'reject']
        );
    }

};

export {TaskDeliveryAcceptAPI};
