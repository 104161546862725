const themes = {
    light: {
        primary: '#009688',
        secondary: '#e91e63',
        accent: '#ffc107',
        muted: '#959595',

        error: '#d32f2f',
        success: '#4caf50',

        priorityLow: '#94ecb5',
        priorityNormal: '#ffd026',
        priorityHigh: '#ff7c94',

        priorityTextLow: '#00b114',
        priorityTextHigh: '#b10000',

        moveOut: '#b10000',
        moveIn: '#00b114',
        move: '#caaf00',

        background: '#f3f3f3',
    },
    dark: {
        primary: '#009688',
        secondary: '#c71e57',
        accent: '#c59400',
        muted: '#757575',

        error: '#ef9a9a',
        success: '#4caf50',

        priorityLow: '#94ecb5',
        priorityNormal: '#ffd026',
        priorityHigh: '#ff7c94',

        priorityTextLow: '#00b114',
        priorityTextHigh: '#ff4d4d',

        moveOut: '#ff4d4d',
        moveIn: '#00b114',
        move: '#fddb00',

        background: '#313131',

        activeCard: '#00968840'
    }
};

// Vuetify does not support alpha channel for theme colors, so we need this workaround
const activeCardColor = 'rgba(0, 150, 136, 0.1)';

export {themes, activeCardColor};
