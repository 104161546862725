<template>
  <v-expansion-panels class="my-2 px-1">
    <v-expansion-panel>
      <v-expansion-panel-header class="text-subtitle-1">
        {{ $t(langPath + 'label') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <span class="text-subtitle-2">
          {{ $t(langPath + 'autoApprove') }}
        </span>
        <div class="d-flex flex-wrap flex-row mb-3">
          <div
            v-for="taskType in configurableTasks"
            :key="taskType"
            class="px-2"
          >
            <v-checkbox
              v-model="autoApproveSettings[taskType]"
              :label="$t(taskNames[taskType])"
            />
          </div>
        </div>
        <span class="text-subtitle-2">
          {{ $t(langPath + 'autoPrintReport') }}
        </span>
        <div class="d-flex flex-wrap flex-row">
          <div
            v-for="taskType in configurableTasks"
            :key="taskType"
            class="px-2"
          >
            <v-checkbox
              v-model="autoPrintReportSettings[taskType]"
              :label="$t(taskNames[taskType])"
            />
          </div>
        </div>
        <v-btn
          :loading="loading"
          type="submit"
          color="accent"
          @click="submit()"
        >
          <v-icon
            class="mr-2"
          >
            $saveItem
          </v-icon>
          {{ $t('form.save') }}
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {configurableTasks, taskNames} from "@/enum/task_type";

    export default {
        name: "StockSubstockTaskSettings",
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            loading: false,
            configurableTasks: configurableTasks,
            autoApproveSettings: {},
            autoPrintReportSettings: {},
            taskNames: taskNames,
            langPath: 'stocks.substocks.task_settings.'
        }),
        createdOrActivated: function () {
            this.loading = true;
            StockAPI.getSubStockTaskSettingsConfig(this.stockId, this.subStockId)
                .then(response => {
                    for (const setting of response.data) {
                        this.autoApproveSettings[setting.task_type] = setting.auto_approve;
                        this.autoPrintReportSettings[setting.task_type] = setting.auto_print_report;
                    }
                }).catch(this.snack)
                .finally(() => {
                    this.loading = false;
                });
        },
        methods: {
            submit: function () {
                this.loading = true;
                const promises = [];
                for (const taskType of this.configurableTasks) {
                    promises.push(StockAPI.updateSubStockTaskSettingsConfig(this.stockId, this.subStockId, taskType, {
                        auto_approve: this.autoApproveSettings[taskType],
                        auto_print_report: this.autoPrintReportSettings[taskType]
                    }));
                }
                Promise.all(promises)
                    .then(() => {
                        this.snack(this.langPath + 'updateDone');
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
