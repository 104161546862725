<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/users/' + userId + '/update'"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
              {{ details.first_name }} {{ details.last_name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div>
                      <span class="text-caption">{{ $t('users.username') + ': ' }}</span>
                      {{ details.username }}
                    </div>
                    <div v-if="details.phone">
                      <span class="text-caption">{{ $t('users.phone') + ': ' }}</span>
                      {{ details.phone }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('users.email') + ': ' }}</span>
                      {{ details.email }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <span class="text-caption">{{ $t('users.roles') + ': ' }}</span>
                    <span
                      v-for="role of Roles"
                      :key="role"
                    >
                      <v-tooltip
                        v-if="details['is_' + role.split('_')[1].toLowerCase()]"
                        top
                      >
                        <template #activator="{ on }">
                          <v-chip
                            outlined
                            v-on="on"
                          >
                            <v-icon>
                              {{ icons[role] }}
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>
                          {{ $t('users.role.' + role) }}
                        </span>
                      </v-tooltip>
                    </span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout
        v-if="isChief"
        wrap
      >
        <UserPermissionsList
          :user-id="userId"
        />
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {UserAPI} from "@/api/UserAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {tabTitle} from "@/utils/string";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {Roles} from "@/enum/roles";
    import {roleIcons} from "@/enum/icons";
    import UserPermissionsList from "@/app/users/components/UserPermissionsList.component";

    export default {
        name: "UserShow",
        components: {UserPermissionsList},
        mixins: [RouteParamsMapperMixin, ACLMixin],
        data: () => ({
            details: null,
            Roles: Roles,
            icons: roleIcons,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.first_name + ' ' + this.details.last_name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            UserAPI.get(this.userId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/users/');
                });
        }
    };
</script>

<style scoped>

</style>
