import Axios from "axios";
import {Sentry} from "@/service/Sentry";
import {Store} from "@/service/store/Store";
import qs from "qs";
import {config} from "@/config/config";
import i18n from "@/service/lang/i18n";
import {assign} from "@/utils/object";

const BaseAPI = {

    // reference: https://https://api.atlantis.jagu.cz/docs

    URL: config.API_URL,

    // Limit max concurrent requests
    REQUESTS_MAX: 50,
    runningRequests: 0,
    requestQueue: [],

    /**
     * @param error {Object}
     * @param domain {string|Array} Path in translations. When passing an array, the content will be joined using '.'
     * @private
     */
    _resolveStatus(error, domain) {
        if (Array.isArray(domain)) {
            domain = domain.join('.');
        }

        if (error.response === undefined) {
            Store.dispatch('connection/change', false);
        } else {
            const status = error.response.status;
            if (status >= 401 && status <= 499) {
                if (i18n.te(domain + '.status.' + status)) {
                    throw (domain + '.status.' + status);
                } else if (i18n.te('base.api.' + status)) {
                    throw 'base.api.' + status;
                } else {
                    throw 'base.api.unexpectedError';
                }
            } else {
                let errorMessage = 'base.api.unexpectedError';
                if (status === 500) {
                    Sentry.captureException('API returned ' + status);
                } else {
                    errorMessage = error.response.data.errors;
                    // TODO search all lang strings to translate key
                }
                window.console.error(i18n.t(errorMessage));
                throw errorMessage;
            }
        }

    },

    /**
     * @param url {string|Array} Request URL. When passing an array, only the first element will be returned as lang path
     * @returns {string}
     * @private
     */
    _makeLangPath(url) {
        if (Array.isArray(url))
            return url[0];
        else {
            return url;
        }
    },

    /**
     * @param url {string|Array} Request URL. When passing an array, the content will be joined using '/'
     * @returns {string}
     * @private
     */
    _makeURL(url) {
        if (Array.isArray(url)) {
            url = url.join('/');
        } else if (url.startsWith('http')) {
            return url;
        }
        return this.URL + '/' + url;
    },

    /**
     * @param request {function (*=): (Promise)}
     * @param langPath {string}
     * @param handleError {boolean}
     * @return {Promise<unknown>}
     * @private
     */
    _makeRequest(request, langPath, handleError = true) {
        Store.commit('loader/bottom/show');
        if (this.runningRequests > this.REQUESTS_MAX) {
            return new Promise(resolve => {
                this.requestQueue.push({
                    request,
                    langPath,
                    resolve,
                    handleError
                });
            });
        } else {
            this.runningRequests++;
            return request()
                .catch(err => {
                    if (handleError) {
                        this._resolveStatus(err, langPath);
                    } else throw err;
                }).finally(this._requestFinally.bind(this));
        }
    },

    /**
     * @private
     */
    _requestFinally() {
        Store.commit('loader/bottom/hide');
        this.runningRequests--;
        if (this.requestQueue.length > 0) {
            const request = this.requestQueue.shift();
            this.runningRequests++;
            request.request()
                .then(request.resolve)
                .catch(err => {
                    if (request.handleError) {
                        this._resolveStatus(err, request.langPath);
                    } else throw err;
                }).finally(this._requestFinally.bind(this));
        }
    },

    /**
     * @param url {string|Array}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param params {Object} GET parameters
     * @param config {Object}
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    get(url, langPath = this._makeLangPath(url), params = undefined, config = {}) {
        const completeUrl = this._makeURL(url) + (params === undefined ? '' : ('?' + qs.stringify(params))); // @see https://github.com/ljharb/qs/issues/235
        const request = Axios.get.bind(this, completeUrl, config);
        return this._makeRequest(request, langPath);
    },

    /**
     * @param url {string|Array}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param params {Object} HEAD parameters
     * @param config {Object}
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    head(url, langPath = this._makeLangPath(url), params = undefined, config = {}) {
        const completeUrl = this._makeURL(url) + (params === undefined ? '' : ('?' + qs.stringify(params)));
        const request = Axios.head.bind(this, completeUrl, config);
        return this._makeRequest(request, langPath);
    },

    /**
     * @param url {string|Array}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param headers
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    delete(url, langPath = this._makeLangPath(url), headers = {}) {
        const request = Axios.delete.bind(this, this._makeURL(url), {headers: headers});
        return this._makeRequest(request, langPath);
    },

    /**
     * @param url {string|Array}
     * @param data {Object}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param headers
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    patch(url, data, langPath = this._makeLangPath(url), headers = {}) {
        const request = Axios.patch.bind(this, this._makeURL(url), {...data}, {headers: headers});
        return this._makeRequest(request, langPath);
    },

    /**
     * @param url {string|Array}
     * @param data {Object|FormData}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param contentType {string}
     * @param config
     * @param handleError
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    post(url, data = null, langPath = this._makeLangPath(url), contentType = 'application/json', config = {}, handleError = true) {
        const payload = contentType === 'application/json' ? {...data} : data;
        const request = Axios.post.bind(this, this._makeURL(url), payload, assign({
            headers: {
                'content-type': contentType
            }
        }, config, true));
        return this._makeRequest(request, langPath, handleError);
    },

    /**
     * @param url {string|Array}
     * @param data {Object}
     * @param langPath {string|Array} Path in translations. When passing an array, the content will be joined using '.' Can be omitted and the first part of URL will be used instead.
     * @param headers
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    put(url, data, langPath = this._makeLangPath(url), headers = {}) {
        const request = Axios.put.bind(this, this._makeURL(url), {...data}, {headers: headers});
        return this._makeRequest(request, langPath);
    }
};

export {BaseAPI};
