import {TaskState} from "@/enum/task_state";
import {taskTypes, taskNames} from "@/enum/task_type";
import {TaskPriority} from "@/enum/task_priority";
import {UserAPI} from "@/api/UserAPI";
import {ACLMixin} from "@/app/mixins/ACLMixin";
import {setDeep} from "@/utils/object";
import {StockAPI} from "@/api/StockAPI";
import {defaultDashboard} from "@/app/homepage/definitions/defaultDashboard.definition";
import {APIFilterOP} from "@/service/APIFilters";

const TaskFilterMixin = {
    mixins: [ACLMixin],
    computed: {
        renderValues: function () {
            const values = {};
            for (const property of Object.keys(this.possibleValues)) {
                if (Array.isArray(this.possibleValues[property].values)) {
                    for (const possibility of this.possibleValues[property].values) {
                        setDeep(values, property + '.' + possibility.value, possibility.text);
                    }
                }
            }
            return {
                tasks: {
                    filter: values
                }
            };
        }
    },
    methods: {
        getDefaultLists: function () {
            const defaultLists = defaultDashboard();
            for (const list of defaultLists) {
                list.showOnDesktop = true;
                list.showOnMobile = true;
                list.props.autoRefresh = false;
                list.props.autoRefreshIn = 60;
                list.props.label = this.$t('homepage.' + list.props.label);
                if (Array.isArray(list.props.filter)) {
                    list.props.filter = {[APIFilterOP.AND]: list.props.filter};
                }
            }
            return defaultLists;
        },
        fetchPossibleValues: async function () {
            const promises = [];
            const subStockPromises = [];
            promises.push(
                StockAPI.getAllPages().then(response => {
                    //let stocks = response.data.items;
                    const stocks = response.data.items.map(stock => {
                        subStockPromises.push(
                            StockAPI.getAllSubstockPages(stock.id).then(secondResponse => {
                                stock.subStocks = secondResponse.data.items;
                                return secondResponse;
                            })
                        );
                        return stock;
                    });
                    promises.push(...subStockPromises);
                    Promise.all(subStockPromises).then(() => {
                        const possibleValues = [];
                        stocks.forEach(stock => {
                            if (!stock.subStocks.length) {
                                return;
                            }
                            possibleValues.push({
                                header: stock.name
                            });
                            stock.subStocks.forEach(subStock => {
                                possibleValues.push({
                                    text: subStock.name,
                                    value: subStock.id
                                });
                            });
                        });
                        this.possibleValues['substock.id'].values = possibleValues;
                    });
                })
            );
            this.possibleValues.state.values = Object.values(TaskState).map(state => ({
                text: this.$t('tasks.state.' + state),
                value: state
            }));
            this.possibleValues.priority.values = Object.values(TaskPriority).map(priority => ({
                text: this.$t('tasks.priority.' + priority),
                value: priority
            }));
            this.possibleValues.type.values = Object.values(taskTypes).map(type => ({
                text: this.$t(taskNames[type]),
                value: type
            }));
            if (this.isChief) {
                promises.push(
                    UserAPI.getAll()
                        .then(response => {
                            this.possibleValues['assigned_user.id'].values = response.data.map(user => ({
                                text: this.$options.filters.fullName(user),
                                value: user.id
                            }));
                        }).catch(this.snack)
                );
            } else { // storekeeper can only filter himself
                this.possibleValues['assigned_user.id'].values = [{
                    text: this.$store.getters['oauth/getUserFullName'],
                    value: this.$store.getters['oauth/getUserId']
                }];
            }
            return Promise.all(promises);
        },
        validateFilter: function (filterObject) {
            const key = Object.keys(filterObject)[0];
            if (!Array.isArray(filterObject[key])) {
                return this.validateFilterComponent(filterObject);
            }
            if (filterObject[key].length === 0) {
                return false;
            }
            return this.validateFilterComponent(filterObject);
        },
        validateFilterComponent: function (filterObject) {
            if (filterObject === undefined || filterObject === null || filterObject === "") {
                return false;
            }
            if (typeof filterObject !== 'object' || Object.keys(filterObject).length === 0) {
                return true;
            }
            const key = Object.keys(filterObject)[0];
            const value = filterObject[key];
            if (Array.isArray(value)) {
                let isValid = true;
                for (const nested of value) {
                    if (!this.validateFilterComponent(nested)) {
                        isValid = false;
                        break;
                    }
                }
                return isValid;
            } else {
                return this.validateFilterComponent(value);
            }
        }
    }
};

export {TaskFilterMixin};
