<template>
  <v-card v-if="hasChangeQuantityOption">
    <v-flex>
      <span
        v-show="showInput"
      >
        <v-text-field
          ref="quantityInput"
          v-model="quantity"
          outlined
          hide-details
          type="number"
          min="1"
          step="1"
          class="mb-1 centered-input"
          @input="inputChanged()"
          @blur.once="toggleShowInput(item.id)"
          @keydown.stop="event => event.key === 'Enter' ? EventBus.$emit('close-bottom-sheet') : ''"
        />
      </span>
    </v-flex>
    <v-flex>
      <v-btn-toggle>
        <v-btn
          v-for="option of changeByOptions"
          :key="option"
          :disabled="disableOption(option)"
          outlined
          @click="changeQuantity(option)"
        >
          {{ (option > 0 ? '+' : '') + option }}
        </v-btn>
        <v-btn
          @click="toggleShowInput(item.id)"
        >
          <v-icon>
            $itemQuantityChange
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-flex>
  </v-card>
</template>

<script>
    import {EventBus} from "@/service/EventBus";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {changeByOptions} from "@/app/tasks/definitions/changeByOptions";
    import {taskTypes} from "@/enum/task_type";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";

    export default {
        name: "TaskItemDetailQuantity",
        mixins: [EventsListenerMixin, TaskTypeMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            locationId: {
                type: Number,
                default: null
            },
            taskType: {
                type: String,
                default: () => ''
            },
            taskStrictMode: {
                type: String,
                default: TaskItemsStrictMode.FREE
            },
            defaultQuantity: {
                type: Number,
                default: 0
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            sourceLocationId: {
                type: Number,
                default: null
            },
            taskShippingType: {
                type: String,
                default: TaskShippingType.COURIER
            }
        },
        data: () => ({
            quantity: 0,
            showInput: false,
            listenForChange: false,
            changeByOptions: changeByOptions,
            taskTypes: taskTypes,
            TaskItemsStrictMode: TaskItemsStrictMode,
            quantityChangedByEmit: false,
            EventBus: EventBus
        }),
        computed: {
            events: function () {
                return {
                    'taskItems-quantitiesLoaded': this.startListeningForChange,
                    'bad-quantity-entered': this.onBadQuantityEntered
                };
            },
            hasChangeQuantityOption: function () {
                if (this.isType(taskTypes.STOCK_TAKING)) {
                    return true;
                }
                for (const option of changeByOptions) {
                    if (!this.disableOption(option)) {
                        return true;
                    }
                }
                return false;
            },
            details: function () {
                return {
                    type: this.taskType
                };
            }
        },
        watch: {
            defaultQuantity: function (newValue) {
                this.listenForChange = false;
                this.quantity = newValue;
                this.$nextTick(() => this.listenForChange = true);
            },
            quantity: function (newValue, oldValue) {
                if (oldValue !== 0 && this.listenForChange && !this.quantityChangedByEmit) {
                    EventBus.$emit('update-quantity', {
                        itemId: this.item.id,
                        quantity: newValue,
                        locationId: this.locationId
                    });
                } else {
                    this.quantityChangedByEmit = false;
                }
            }
        },
        created: function () {
            this.quantity = this.defaultQuantity;
            if (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING, taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                this.listenForChange = true;
            }
        },
        activated: function () {
            this.quantity = this.defaultQuantity;
        },
        methods: {
            startListeningForChange: function () {
                this.listenForChange = true;
            },
            onBadQuantityEntered: function () {
                this.quantityChangedByEmit = true;
                this.$nextTick(() => {
                    this.quantity = this.defaultQuantity;
                });
            },
            changeQuantity: function (changeBy) {
                if (this.showInput) {
                    this.toggleShowInput();
                }
                this.quantity += changeBy;
            },
            toggleShowInput: function () {
                this.showInput = !this.showInput;
                if (this.showInput) {
                    this.$nextTick(() => {
                        const input = this.$refs['quantityInput'];
                        input.focus();
                        input.$refs.input.select();
                    });
                }
            },
            inputChanged: function () {
                this.quantity = Number.parseInt(this.quantity, 10);
                if (this.quantity === 0 || isNaN(this.quantity)) {
                    this.quantity = 1;
                }
                if (this.quantity < 1) {
                    this.quantity = Math.abs(this.quantity);
                }
            },
            disableOption: function (option) {
                if (this.quantity + option < 1) {
                    return true;
                }
                // stock taking or free modes
                if (this.isType(taskTypes.STOCK_TAKING)
                    || (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS])
                        && this.taskStrictMode === TaskItemsStrictMode.FREE)) {
                    return false;
                }
                // in inventory or moving directly from source to destination (in stock loading)
                // when task is stock loading, user can move right to destination when inventory is empty (therefore check for empty inventory)
                if (this.locationId === null || (this.isType(taskTypes.STOCK_LOADING) && this.inventoryEmpty)) {
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                        if (option < 1) {
                            // trying to return to source location
                            const sourceLocation = this.item.source_locations.find(srcLoc => srcLoc.stock_location_id === this.sourceLocationId);
                            if (!sourceLocation) {
                                return true;
                            }
                            return sourceLocation.pick_quantity < option
                                || (this.isType(taskTypes.SUBSTOCK_TRANSFER)
                                    ? this.item.quantity_in_source_user_inventory < -option : false);
                        } else {
                            // trying to pick more from location
                            const itemLocation = this.item.locations.find(loc => loc.stock_location.id === this.sourceLocationId);
                            if (!itemLocation) {
                                return true;
                            }
                            return itemLocation.quantity < option
                                || (this.taskShippingType === TaskShippingType.COURIER ? this.item.quantity_in_user_inventory : 0)
                                    + option + this.item.processed_quantity > this.item.quantity_to_move;
                        }
                    }
                    return this.item.quantity_in_user_inventory + option + this.item.processed_quantity > this.item.quantity_to_move;
                }
                // on destination
                return this.item.quantity_in_user_inventory - option < 0;
            }
        }
    };
</script>

<style scoped>

</style>
