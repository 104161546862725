import {Roles} from "@/enum/roles";
import {TableFilter} from "@/enum/table_filter";
import {roleIcons} from "@/enum/icons";
import formRules from "@/utils/formRules";
import Vue from "vue";

function UserForm() {
    return {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        active: true,
        is_storekeeper: false,
        is_packer: false,
        is_chief: false
    };
}

function UserRender() {
    return {
        active: {
            icon: '$userActive',
            checkbox: true
        },
        first_name: {
            icon: '$userFirstName',
            max: '255',
            required: true
        },
        last_name: {
            icon: '$userLastName',
            max: '255',
            required: true
        },
        username: {
            icon: '$username',
            max: '255',
            required: true
        },
        email: {
            icon: '$userEmail',
            max: '255',
            required: true
        },
        phone: {
            icon: '$userPhone',
            max: '255'
        },
        password: (new PasswordRender).password,
        is_storekeeper: {
            icon: roleIcons[Roles.STOREKEEPER],
            checkbox: true
        },
        is_packer: {
            icon: roleIcons[Roles.PACKER],
            checkbox: true
        },
        is_chief: {
            icon: roleIcons[Roles.CHIEF],
            checkbox: true
        }
    };
}

function PasswordForm() {
    return {
        password: ''
    };
}

function PasswordRender() {
    return {
        password: {
            icon: '$password',
            max: '255',
            required: true,
            type: 'password',
            rules: [
                formRules.minLen(5)
            ]
        }
    };
}

const usersTable = {
    first_name: {
        filterType: TableFilter.TEXT
    },
    last_name: {
        filterType: TableFilter.TEXT
    },
    username: {
        filterType: TableFilter.TEXT
    },
    email: {
        filterType: TableFilter.TEXT
    },
    phone: {
        filterType: TableFilter.TEXT
    },
    roles: {
        filterType: TableFilter.MULTI_BOOLEAN
    },
    active: {
        filterType: TableFilter.BOOLEAN,
        itemLabel: ({active}) => Vue.options.filters.yesOrNo(active)
    },
};

export {UserForm, UserRender, PasswordForm, PasswordRender, usersTable};
