import i18n from "@/service/lang/i18n";

const PrintType = {
    PDF: 'pdf',
    ZPL: 'zpl',
    ZPLX: 'zplx',
    ZPLX2: 'zplx2',
    ESCP: 'escp',
    HTML: 'html'
};

function getPrintTypeItems () {
    return Object.values(PrintType).map(printType => ({
        value: printType,
        text: i18n.t('base.print_type.' + printType)
    }));
}

export {PrintType, getPrintTypeItems};
