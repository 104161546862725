<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('products.customInstances.create.label') + ` '${productName}'` }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.customInstances."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <InstanceTypeCreateUpdate
      v-if="showCreateInstanceType"
      id="instanceTypeForm"
      ref="instanceTypeForm"
      class="ma-1"
      is-component
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI} from "@/api/ProductAPI";
    import {setFormErrors} from "@/utils/form";
    import {
        ProductCustomInstanceForm,
        ProductCustomInstanceRender
    } from "@/app/products/custom-instances/definitions/productCustomInstance.form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {EventBus} from "@/service/EventBus";
    import InstanceTypeCreateUpdate from "@/app/products/instances/types/InstanceTypeCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import {AllowedLocationIdsCacheMixin} from "@/app/mixins/AllowedLocationIdsCacheMixin";
    import {getIdFromLocation} from "@/utils/url";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ProductCustomInstanceCreateUpdate",
        components: {InstanceTypeCreateUpdate, FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, InstanceCacheMixin, AllowedLocationIdsCacheMixin, EventsListenerMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            },
            productIdProp: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            form: new ProductCustomInstanceForm,
            formRender: new ProductCustomInstanceRender,
            valid: true,
            loading: false,
            productName: '',
            showCreateInstanceType: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.productName !== '' && this.form.type) {
                    if (this.isEdit) {
                        title = this.$t('products.customInstances.update.title', [this.form.type.name, this.productName]);
                    } else {
                        title = this.$t('products.customInstances.create.title', [this.productName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            events: function () {
                return {
                    'create-instance-type': this.onCreateInstanceType,
                    'create-instance-type-created': this.onCreateInstanceTypeCreated,
                    'create-instance-type-cancelled': () => this.showCreateInstanceType = false
                };
            },
            productIdLocal: function () {
                if (this.productId !== undefined) {
                    return this.productId;
                }
                return this.productIdProp;
            }
        },
        createdOrActivated: function () {
            ProductAPI.get(this.productIdLocal)
                .then(response => {
                    this.productName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/products');
                });
        },
        methods: {
            formFetchItem: function () {
                return ProductAPI.getInstance(this.productIdLocal, this.instanceId);
            },
            onCreateInstanceType: function () {
                this.showCreateInstanceType = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.product_instance_type_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('instanceTypeForm');
                });
            },
            onCreateInstanceTypeCreated: function (instanceTypeId) {
                this.showCreateInstanceType = false;
                this.$set(this.formRender.product_instance_type_id, 'loading', true);
                this.formRender.product_instance_type_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.product_instance_type_id.autocomplete.items = this.formRender.product_instance_type_id.autocomplete.thenFn(response);
                        this.form.product_instance_type_id = Number.parseInt(instanceTypeId, 10);
                    }).finally(() => this.$set(this.formRender.product_instance_type_id, 'loading', false));
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const instanceTypeName = this.formRender.product_instance_type_id.autocomplete.items.find(el => el.value === this.form.product_instance_type_id).text;
                if (this.isEdit) {
                    ProductAPI.updateCustomInstance(this.productIdLocal, this.instanceId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.customInstances.update.done',
                                params: [instanceTypeName, this.productName]
                            });
                            this.clearInstanceCachesById(this.instanceId);
                            this.clearAllowedLocationIdsCacheByInstance(this.instanceId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/' + this.productIdLocal);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ProductAPI.createCustomInstance(this.productIdLocal, this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'products.customInstances.create.done',
                                params: [instanceTypeName, this.productName]
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-instance-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/products/' + this.productIdLocal);
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-instance-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
