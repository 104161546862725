import rules from "@/utils/formRules";

function BankAccountForm() {
    return {
        full_number: '',
        iban: null,
        swift: null
    };
}

function BankAccountRender() {
    return {
        full_number: {
            icon: '$fullNumber',
            required: true,
            rules: [
                rules.pattern(/^(([0-9]{0,6})-)?([0-9]{1,10})\/([0-9]{4})$/)
                // TODO use mask? https://vuetifyjs.com/en/components/text-fields#masks
            ]
        },
        iban: {
            icon: '$iban',
            rules: [
                rules.pattern(/^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/)
                // source: https://stackoverflow.com/a/44657292/6078703
            ]
        },
        swift: {
            icon: '$swift',
            rules: [
                rules.pattern(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/)
            ]
        }
    };
}

/**
 * Requires valid Bank account number w/ bank code
 * @param fullNumber
 * @param targetObject
 */
function distributeBAN(fullNumber, targetObject) {
    if (fullNumber.indexOf('-') === -1) {
        targetObject.prefix_number = '';
        targetObject.account_number = fullNumber.split('/')[0];
    } else {
        targetObject.prefix_number = fullNumber.split('-')[0];
        targetObject.account_number = fullNumber.split('-')[1].split('/')[0];
    }
    targetObject.bank_code = fullNumber.split('/')[1];
}

function mergeBAN(from) {
    return from.prefix_number + '-' + from.account_number + '/' + from.bank_code;
}

export {BankAccountForm, BankAccountRender, distributeBAN, mergeBAN};
