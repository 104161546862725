export default {
    required: "Ez a mező kötelező.",
    maxLen: "A megengedett maximális hossz {0} karakter",
    minLen: "A minimálisan szükséges hossz {0} karakter",
    pattern: "A bemenet nem felel meg az előírt formátumnak",
    positiveInteger: "Írjon be egy pozitív egész számot",
    range: "{0} és {1} között kell lennie",
    save: "Mentés",
    send: "Küldés",
    generate: "Generál",
    download: "Letöltés",
    notFilled: "Nincs kitöltve"
};
