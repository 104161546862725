import Vue from "vue";

// Share Cache values across all components which use this mixin
const LocationCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveLocationCacheMixin = {
    computed: {
        loadingLabel: function () {
            return `<${this.$t('base.loading')}...`;
        },
        LocationCache: function () {
            // We need this to trigger an update in each components which might be using this Mixin simultaneously
            trigger.count;
            return LocationCache;
        }
    },
    methods: {
        /**
         * @param runningRequest {Promise<{data}>}
         * @param key {number|string}
         * @return {Promise<{data}>}
         */
        cacheLocation: function (runningRequest, key) {
            LocationCache[key] = this.loadingLabel;
            return runningRequest.then(response => {
                LocationCache[key] = response.data;
                trigger.count++;
                return response;
            });
        },
        /**
         * Prepare cache entry, but only fetch it once it is read for the first time.
         * @param request {function (*=): (Promise<{data}>)}
         * @param key {number|string}
         */
        cacheLocationLazy: function (request, key) {
            Object.defineProperty(LocationCache, key, {
                configurable: true,
                get: () => {
                    Object.defineProperty(LocationCache, key, {
                        configurable: true,
                        writable: true,
                        value: this.loadingLabel,
                    });
                    request().then(response => {
                        LocationCache[key] = response.data;
                        trigger.count++;
                    }).catch(this.snack);
                    return this.loadingLabel;
                },
                set: value => {
                    Object.defineProperty(LocationCache, key, {
                        configurable: true,
                        writable: true,
                        value: value,
                    });
                }
            });
        }
    }
};

export {ReactiveLocationCacheMixin};
