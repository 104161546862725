<template>
  <div>
    <v-container class="pa-0">
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        class="movementsTable"
        :show-search-bar="false"
      >
        <!-- desktop view -->
        <template
          v-for="column of headers.filter(header => header.value)"
          #[slotName(column)]="{ item }"
        >
          <StockMovementItem
            :key="column.value"
            :value="column.value"
            :item="item"
          />
        </template>
        <!-- mobile view -->
        <template #item="{ item }">
          <v-container>
            <v-row
              justify="space-between"
              class="mr-1"
            >
              <StockMovementItem
                value="task"
                :item="item"
                class="ml-2"
              />
              <span class="text-right">
                <StockMovementItem
                  value="created_at"
                  :item="item"
                />
              </span>
            </v-row>
            <v-row>
              <StockMovementItem
                value="created_by"
                :item="item"
                class="text-caption ml-2"
              />
            </v-row>
            <v-row class="mr-1">
              <v-col cols="9">
                <StockMovementItem
                  value="product"
                  :item="item"
                  class="ml-2"
                />
              </v-col>
              <v-col cols="3">
                <StockMovementItem
                  value="amount"
                  :item="item"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <StockMovementItem
                  value="movement"
                  :item="item"
                />
              </v-col>
              <v-col
                v-if="!isInTask"
                cols="2"
              >
                <StockMovementItem
                  value="taskButton"
                  :item="item"
                  :action="viewTask"
                />
              </v-col>
              <v-col
                v-if="isInTask && apiFilter.length === 1"
                cols="2"
              >
                <StockMovementItem
                  value="filterButton"
                  :item="item"
                  :action="filterProduct"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="my-2" />
        </template>
        <template
          v-if="tableFilterActive"
          #header
        >
          <v-spacer />
          <v-btn
            class="text-body-2"
            text
            @click="$emit('removeFilter')"
          >
            {{ $t('stocks.stockMovements.removeFilter') }}
          </v-btn>
        </template>
      </x-data-table>
    </v-container>
  </div>
</template>
<script>
    import {StockMovementsAPI} from "@/api/StockMovementsAPI";
    import {UserAPI} from "@/api/UserAPI";
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import StockMovementItem from "@/app/overview/movements/components/StockMovementItem.component";
    import {taskLinks} from "@/enum/task_type";
    import {APIFilters} from "@/service/APIFilters";

    export default {
        name: "StockMovementsTable",
        components: {StockMovementItem},
        mixins: [ReactiveUserCacheMixin],
        props: {
            subStockId: {
                type: Number,
                default: null
            },
            headers: {
                type: Array,
                default: () => []
            },
            parentLoading: {
                type: Boolean,
                default: false
            },
            actions: {
                type: Array,
                default: () => []
            },
            apiFilter: {
                type: Array,
                default: () => []
            },
            isInTask: {
                type: Boolean,
                default: false
            },
            reload: {
                type: Number,
                default: null
            },
            tableFilterActive: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            items: [],
            loading: false
        }),
        computed: {
            apiDataSource: function () {
                return StockMovementsAPI.getAll.bind(StockMovementsAPI, this.subStockId, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return StockMovementsAPI.getAllPages.bind(StockMovementsAPI, this.subStockId, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            }
        },
        watch: {
            loading: function () {
                this.$emit('update:loading', this.loading);
            },
            parentLoading: function () {
                this.loading = this.parentLoading;
            },
            items: function () {
                [...new Set([
                    ...this.items.map(item => item.created_by),
                    ...this.items.map(item => item.location.user_id)
                ])]
                    .filter(userId => !!userId)
                    .forEach(userId => {
                        this.cacheUser(UserAPI.get(userId), userId).catch(this.snack);
                    });
            },
        },
        methods: {
            viewTask: function (item) {
                this.$router.push(taskLinks[item.task.type] + '/' + item.task.id);
            },
            filterProduct: function (item) {
                this.$emit('filterProduct', item);
            },
            slotName: function (column) {
                return 'item.' + column.value;
            }
        }
    };
</script>

<style scoped lang="sass">
.movementsTable .col
  padding: 5px
</style>
