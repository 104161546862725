var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap"},[(_vm.selection && _vm.selection.length)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"ml-4 mr-1"},[_vm._v(" $itemsSelected ")]),_vm._v(" "+_vm._s(_vm.selection.length)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('clear-selection')}}},on),[_c('v-icon',[_vm._v(" $removeSelection ")])],1)]}}],null,false,4126919507)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('base.table.removeSelection'))+" ")])])],1):_vm._e(),(_vm.batchActions && _vm.batchActions.length && ((_vm.selection && _vm.selection.length) || !_vm.fulltextActive))?_c('div',{staticClass:"float-left"},[_c('v-menu',{staticClass:"float-left",attrs:{"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"float-left"},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 my-2",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" expand_less ")]),_vm._v(" "+_vm._s(_vm.$t('base.table.batchActions'))+" ")],1)],1)]}}],null,false,3647802261)},[_c('v-list',[_c('v-subheader',[(_vm.selection && _vm.selection.length)?[_vm._v(" "+_vm._s(_vm.$t('base.table.batchAllSelected'))+" ("+_vm._s(_vm.selection.length)+") ")]:(_vm.getAllPagesRequest === null)?[_vm._v(" "+_vm._s(_vm.$t('base.table.batchAllFiltered'))+" ("+_vm._s(_vm.filteredItems.length)+") ")]:[_vm._v(" "+_vm._s(_vm.$t('base.table.batchAllFiltered'))+" ("+_vm._s(_vm.apiItemsLength)+") ")]],2),_vm._l((_vm.batchActions),function(action){return _c('v-tooltip',{key:action.label,attrs:{"disabled":!action.hint || (action.condition !== undefined && action.condition()),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-list-item',{attrs:{"disabled":action.condition !== undefined && !action.condition(),"link":""},on:{"click":function($event){action.action ? _vm.runBatchAction(action.action) : function () {}}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""}},[_vm._v(" "+_vm._s(action.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(action.label))+" ")],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(action.hint))+" ")])])})],2)],1)],1):_vm._e(),(_vm.batchActionProgress !== null)?[_c('v-btn',{staticClass:"ml-3 my-2",attrs:{"disabled":""}},[_c('v-progress-circular',{staticClass:"mx-3",attrs:{"indeterminate":"","size":16,"width":2}}),_vm._v(" "+_vm._s(_vm.batchActionProgress)+" / "+_vm._s(_vm.batchActionTotal)+" ")],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }