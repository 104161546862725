<template>
  <v-form class="d-flex flex-row align-center">
    <v-autocomplete
      v-model="selectedTargetLocation"
      clearable
      dense
      :loading="loading"
      :readonly="loading"
      hide-details
      :items="possibleTargetLocations"
      :placeholder="$t('tasks.stockPicking.chooseTargetLocation')"
      :solo="!invertedColors"
      :solo-inverted="invertedColors"
      @keyup.enter.native="confirmTargetLocation"
    />
    <v-btn
      outlined
      :disabled="selectedTargetLocation === null"
      :loading="loading"
      class="ml-2"
      type="submit"
      :color="invertedColors ? '' : 'accent'"
      @click.prevent="confirmTargetLocation"
    >
      {{ $t('base.save') }}
    </v-btn>
  </v-form>
</template>

<script>
    import {locationLabel} from "@/utils/string";
    import {TaskSetTargetLocationMixin} from "@/app/mixins/TaskSetTargetLocationMixin";

    export default {
        name: "StockPickingTargetPicker",
        mixins: [TaskSetTargetLocationMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                default: () => ({})
            },
            invertedColors: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            selectedTargetLocation: null,
            possibleTargetLocations: []
        }),
        computed: {
            API: function () {
                return this.api;
            }
        },
        watch: {
            'taskInfo.details.target_location': {
                handler: function (newValue) {
                    this.selectedTargetLocation = newValue.id;
                },
                immediate: true
            }
        },
        createdOrActivated: function () {
            this.fetchPossibleTargetLocations();
        },
        methods: {
            fetchPossibleTargetLocations: function () {
                // TODO use #16267
                this.loading = true;
                this.getEmptyLocations()
                    .then(response => {
                        const mapper = location => ({
                            value: location.id,
                            text: locationLabel(location)
                        });
                        this.possibleTargetLocations = [
                            {header: this.$t('stocks.locations.mobile')},
                            ...response.data.items.filter(location => location.is_mobile).map(mapper),
                            {header: this.$t('stocks.locations.nonMobile')},
                            ...response.data.items.filter(location => !location.is_mobile).map(mapper),
                        ];
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            confirmTargetLocation: function () {
                this.reallySetTargetLocation(this.selectedTargetLocation);
            }
        }
    };
</script>

<style scoped>

</style>
