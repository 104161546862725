<template>
  <v-list-item>
    <div class="d-flex">
      <v-switch
        v-model="value"
        :label="label"
        class="mb-3"
        hide-details
      />
      <v-tooltip
        v-if="hint"
        top
      >
        <template #activator="{ on }">
          <v-icon
            class="ml-1 mt-1"
            v-on="on"
          >
            $hint
          </v-icon>
        </template>
        {{ hint }}
      </v-tooltip>
    </div>
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarSwitcher",
        props: {
            label: {
                type: String,
                default: null
            },
            valueKey: {
                type: String,
                default: ''
            },
            hint: {
                type: String,
                default: null
            }
        },
        data: () => ({
            value: false
        }),
        watch: {
            value: function () {
                this.$store.dispatch('userConfig/set', {
                    key: this.valueKey,
                    value: this.value
                });
            }
        },
        created: function () {
            this.value = this.$store.getters['userConfig/' + this.valueKey];
        }
    };
</script>

<style scoped>

</style>
