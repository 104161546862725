<template>
  <x-data-table
    :headers="headers"
    :loading.sync="loading"
    :items.sync="items"
    :actions="actions"
    :api-data-source="apiDataSource"
    :api-data-source-all-pages="apiDataSourceAllPages"
    :api-filter="apiFilter"
    :show-search-bar="false"
  >
    <template #item.last_change="{ item }">
      <DateTimeWithTooltip
        :date-time="item.last_change"
        :abs-date-first="true"
      />
    </template>
  </x-data-table>
</template>

<script>
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import {has} from "@/utils/object";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {TableFilter} from "@/enum/table_filter";
    import {locationLabel} from "@/utils/string";
    import {instanceLabel} from "@/utils/filters";
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "ProductStockStatus",
        components: {DateTimeWithTooltip},
        props: {
            product: {
                type: Object,
                default: () => {}
            },
            stockId: {
                type: Number,
                default: null,
            },
            subStockId: {
                type: Number,
                default: null
            },
            instances: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            headers: [],
            items: [],
            loading: false
        }),
        computed: {
            actions: function () {
                return [
                    {
                        condition: item => !has(item.stock_location, 'user'),
                        loading: this.loading,
                        action: this.showLocation,
                        icon: '$location',
                        label: 'stocks.stockStatus.showLocation'
                    }
                ];
            },
            apiDataSource: function () {
                return StockStatusAPI.getAll.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return StockStatusAPI.getAllPages.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            },
            apiFilter: function () {
                return [
                    {
                        [APIFilterOP.GREATER_THAN]: {
                            quantity: 0
                        }
                    },
                    {
                        [APIFilterOP.EQUALS]: {
                            'substock.id': this.subStockId
                        }
                    }, {
                        [APIFilterOP.EQUALS]: {
                            'product.id': this.product.id
                        }
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders({ 
                'stock_location.id': {
                    filterType: TableFilter.SELECT_MULTIPLE,
                    autocomplete: {
                        callFn: () => StockAPI.getAllLocationsAllPages(this.stockId),
                        thenFn: response => response.data.items.map(location => ({
                            text: locationLabel(location),
                            value: location.id
                        }))
                    },
                    itemLabel: item => locationLabel(item.stock_location),
                },
                'product_instance.id': {
                    filterType: TableFilter.SELECT_MULTIPLE,
                    filterItems: this.instances.map(instance => ({
                        text: instanceLabel(instance, this.product),
                        value: instance.id
                    })),
                    itemLabel: item => instanceLabel(item.product_instance)
                },
                quantity: {
                    filterType: TableFilter.NUMBER,
                    itemLabel: item =>  item.quantity + ' ' + this.$options.filters.productMeasureLabel(item.product_instance.product)
                },
                last_change: {
                    filterType: TableFilter.DATETIME,
                }}, 'stocks.stockStatus.table.');
        },
        methods: {
            showLocation: function (item) {
                this.$router.push('/stocks/' + this.stockId + '/substocks/' + this.subStockId + '/locations/' + item.stock_location.id);
            }
        }
    };
</script>

<style scoped>

</style>
