<template>
  <div>
    <v-app-bar
      app
      dark
      :scroll-off-screen="hideOnScroll"
      :scroll-threshold="50"
      :dense="isSmall"
      color="primary"
      v-bind="$attrs"
      :class="{fullWidthExtensions: fullWidth}"
    >
      <v-app-bar-nav-icon
        v-if="hasDrawer"
        @click.stop="toggleDrawer"
      />
      <v-tooltip
        v-if="hasGoBack"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            icon
            :small="$vuetify.breakpoint.smAndDown"
            exact
            :class="{'mr-2': $vuetify.breakpoint.smAndDown}"
            v-on="on"
            @click="$router.push(goBackTo)"
          >
            <v-icon>$goBack</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('base.goBack') + ' (' + goBackName + ')' }}
        </span>
      </v-tooltip>
      <v-tooltip
        v-if="hasGoUp && !goBackSameAsUp"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            icon
            :small="$vuetify.breakpoint.smAndDown"
            :to="goUpTo"
            exact
            class="mr-2 iconUp"
            v-on="on"
          >
            <v-icon>$goUp</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('base.goUp') + ' (' + goUpName + ')' }}
        </span>
      </v-tooltip>
      <v-toolbar-title>
        {{ computedTitle }}
      </v-toolbar-title>
      <v-spacer />
      <ToolbarTrackTime />
      <v-menu
        v-model="isMenuVisible"
        offset-y
        :close-on-content-click="false"
        class="overflow-y-auto"
        max-height="90vh"
      >
        <template #activator="{ on }">
          <v-icon
            class="pa-2 pr-0"
            v-on="on"
          >
            $rightMenu
          </v-icon>
        </template>
        <v-list>
          <slot name="menu" />
          <div
            class="d-flex"
          >
            <ToolbarChangeLang />
            <v-btn
              icon
              class="mr-3 mt-1"
              @click="isMenuVisible = false"
            >
              <v-icon>
                $closeItem
              </v-icon>
            </v-btn>
          </div>
          <ToolbarSwitcher
            :label="$t('base.darkMode')"
            value-key="darkMode"
          />
          <div v-if="$store.getters['oauth/isAuthenticated']">
            <ToolbarProductModels />
            <ToolbarReaderFeedback />
            <ToolbarSwitcher
              v-for="switcher in authenticatedSwitches"
              :key="switcher.key"
              :label="switcher.label"
              :value-key="switcher.key"
              :hint="switcher.hint"
            />
            <v-divider class="my-1" />
            <ToolbarUser />
          </div>
          <v-divider class="my-1" />
          <ToolbarHelp />
          <ToolbarCacheManager />
          <ToolbarVersion />
        </v-list>
      </v-menu>
      <template
        v-if="extension"
        #extension
      >
        <slot name="extension" />
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="hasDrawer"
      v-model="drawer"
      app
      width="270"
    >
      <DrawerMenu />
    </v-navigation-drawer>
    <Breadcrumbs
      v-show="!isSmall"
    />
  </div>
</template>

<script>
    import ToolbarChangeLang from "@/app/components/toolbar/ToolbarChangeLang.component";
    import ToolbarUser from "@/app/components/toolbar/ToolbarUser.component";
    import {tabTitle} from "@/utils/string";
    import {routeBackTo} from "@/utils/url";
    import Breadcrumbs from "@/app/components/Breadcrumbs.component";
    import ToolbarVersion from "@/app/components/toolbar/ToolbarVersion.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import ToolbarTrackTime from "@/app/components/toolbar/ToolbarTrackTime.component";
    import ToolbarHelp from "@/app/components/toolbar/ToolbarHelp.component";
    import DrawerMenu from "@/app/components/DrawerMenu";
    import {config} from "@/config/config";
    import ToolbarProductModels from "@/app/components/toolbar/ToolbarProductModels.component";
    import ToolbarReaderFeedback from "@/app/components/toolbar/ToolbarReaderFeedback.component";
    import ToolbarCacheManager from "@/app/components/toolbar/ToolbarCacheManager.component";
    import ToolbarSwitcher from "@/app/components/toolbar/ToolbarSwitcher";

    export default {
        name: "Toolbar",
        components: {
            Breadcrumbs,
            DrawerMenu,
            ToolbarCacheManager,
            ToolbarProductModels,
            ToolbarReaderFeedback,
            ToolbarHelp,
            ToolbarTrackTime,
            ToolbarUser,
            ToolbarVersion,
            ToolbarChangeLang,
            ToolbarSwitcher
        },
        mixins: [ACLMixin],
        props: {
            title: {
                type: String,
                default: undefined
            },
            fullWidth: {
                type: Boolean,
                default: false
            },
            extension: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            drawer: null,
            isMenuVisible: false,
            hideOnScroll: true
        }),
        computed: {
            authenticatedSwitches: function () {
                return [{
                    label: this.$t('tasks.timeEntries.track'),
                    key: 'trackTime'
                }, {
                    label: this.$t('base.showProductImagesInTasks'),
                    key: 'showProductImagesInTasks'
                }, {
                    label: this.$t('base.pickFromMobileLocation'),
                    key: 'pickFromMobileLocation'
                }, {
                    label: this.$t('base.automagicallyChooseInstance.label'),
                    key: 'automagicallyChooseInstance',
                    hint: this.$t('base.automagicallyChooseInstance.hint')
                }];
            },
            hasGoBack: function () {
                return this.$store.getters['oauth/isAuthenticated'] && !this.goBackSameAsCurrent;
            },
            goBackTo: function () {
                return this.$store.getters['router/getLastPath'];
            },
            goBackName: function () {
                return this.$store.getters['router/getLastName'];
            },
            hasGoUp: function () {
                return this.$route.path !== '/'
                    && typeof this.$route.meta.getParent === 'function';
            },
            goUpTo: function () {
                return routeBackTo(this.$route);
            },
            goUpName: function () {
                try {
                    return this.$t(this.$route.meta.getParent().meta.title);
                } catch {
                    return config.DOMAIN_LABEL;
                }
            },
            goBackSameAsCurrent: function () {
                return this.stripSlash(this.goBackTo) === this.stripSlash(this.$route.fullPath);
            },
            goBackSameAsUp: function () {
                const backTo = this.stripSlash(this.goBackTo);
                const upTo = this.stripSlash(this.goUpTo);
                if (this.hasGoUp) {
                    return backTo === upTo;
                }
                return true;
            },
            hasDrawer: function () {
                return this.isChief;
            },
            isSmall: function () {
                return this.$vuetify.breakpoint.smAndDown;
            },
            computedTitle: function () {
                if (this.title === undefined) {
                    const title = this.$route.meta.title;
                    if (title !== undefined) {
                        const localisedTitle = this.$t(title);
                        document.title = tabTitle(localisedTitle);
                        return localisedTitle;
                    }
                    return this.hasDrawer ? null : config.DOMAIN_LABEL;
                } else {
                    return this.title;
                }
            }
        },
        watch: {
            drawer: function () {
                this.$store.dispatch('userConfig/set', {
                    key: 'drawer',
                    value: this.drawer
                });
            }
        },
        createdOrActivated: function () {
            if (this.isSmall) {
                this.drawer = false;
            } else {
                this.drawer = this.$store.getters['userConfig/drawer'];
            }
            this.toggleHiding();
            this.$router.afterEach(this.toggleHiding);
        },
        methods: {
            toggleDrawer: function () {
                this.drawer = !this.drawer;
            },
            stripSlash: function (str) {
                return str.endsWith('/') ? str.slice(0, -1) : str;
            },
            toggleHiding: function () {
                this.hideOnScroll = false;
                this.$nextTick(() => {
                    this.hideOnScroll = true;
                });
            }
        }
    };
</script>

<style scoped lang="sass">
.iconUp i.v-icon
    transform: rotate(90deg)
</style>
