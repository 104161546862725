export default {
    close: 'Bezárás',
    dataIterator: {
        pageText: '{0}-{1} innen: {2}',
        noResultsText: 'Semmit sem találtunk',
        loadingText: 'Betöltés...',
    },
    dataTable: {
        itemsPerPageText: 'Oldalankénti sorok:',
        ariaLabel: {
            sortDescending: ': Lefelé rendezve. Kattintson a rendezés megszüntetéséhez.',
            sortAscending: ': Felmenő sorrendben rendezve. Kattintson csökkenő rendezéshez.',
            sortNone: ': Válogatatlan. Kattintson a növekvő rendezéshez.',
            activateNone: 'Válogatás törlése',
            activateDescending: 'Lefelé rendezés',
            activateAscending: 'Felfelé rendezés'
        },
        sortBy: 'Rendezés ...',
    },
    dataFooter: {
        pageText: '{0}-{1} / {2}',
        itemsPerPageText: 'Oldalankénti sorok:',
        itemsPerPageAll: 'Minden',
        nextPage: 'Következő',
        prevPage: 'Előző',
        firstPage: 'Első',
        lastPage: 'Utolsó',
    },
    datePicker: {
        itemsSelected: '{0} kiválasztott',
        prevMonthAriaLabel: 'az előző hónapban',
        nextMonthAriaLabel: 'következő hónap'
    },
    noDataText: 'Semmit sem találtunk',
    carousel: {
        prev: 'előző',
        next: 'Következő',
    },
    calendar: {
        moreEvents: '{0} több',
    },
    fileInput: {
        counterSize: '{0}x fájl  összesen ({1})'
    }
};
