import {StockLocationLockType} from "@/enum/stock_location_lock_type";

const stockOrSubStockOrLocationNotFound = "Zadaný sklad, jeho podsklad alebo umiestnenie neexistujú!";

export default {
    title: "Sklady",
    link: "Sklady",

    name: "Názov skladu",
    description: "Popis",
    is_small: "Malý sklad",
    address: "Adresa",

    chief: "Vedúci",
    chief_id: "Vedúci",

    locations: {
        label: "Umiestnenie v sklade",
        name: "Názov umiestnenia",
        code: "Kód",
        availability: "Dostupnosť",
        availability_hint: "Vyššie číslo znamená lepšiu dostupnosť",
        setAvailability: "Nastaviť dostupnosť",
        availabilityBatch: {
            failed: "Nepodarilo sa nastaviť dostupnosť lokalít!",
            done: "Dostupnosť nastavená."
        },
        is_expedition: "Je expedičné",
        expedition: "Expedičné",
        is_mobile: "Je mobilné",
        mobile: "Mobilné",
        nonMobile: "Nemobilné",
        codehint: "Ponechajte prázdné pre automaticky generovaný.",
        none: "<neumiestnené>",
        at: "na umiestnení",
        is_allowed: "Povolené pre tento podsklad",
        stockStatus: "Položky na umiestnení",
        searchHint: "Prehľadáva názov umiestnenia a kód",

        printBarcode: "Vytlačiť štítok",
        printBarcodeMany: "Vytlačiť štítky",
        printBarcodeHowMuch: "Koľko štítkov '{0}' vytlačiť?",
        printBarcodesHowMuch: "Koľko štítkov vytlačiť?",
        printDone: "Tlačím štítok '{0}'...",

        items: {
            productsAtSubstock: "Položky na zvolenom umiestnení",
            noItems: "Na zvolenom umiestnení nič nie je!"
        },

        locks: {
            strict_mode: "Je striktné",
            strictHint: "Striktný zámok umožňuje položiť produkty iba na tie lokácie, na ktoré sú uzamknuté.",
            note: "Poznámka",
            lockTypes: {
                title: "Toto umiestnenie je omezeno na {0}",

                [StockLocationLockType.MANUFACTURER]: "Výrobcu",
                [StockLocationLockType.PRODUCT_TYPE]: "Typ skladovej položky",
                [StockLocationLockType.PRODUCT]: "Skladovú položku",
                [StockLocationLockType.INSTANCE]: "Konkrétnu skladovú položku",
            },
            lockItems: {
                title: "Obmedziť umiestnenie na",

                [StockLocationLockType.MANUFACTURER]: "Výrobce",
                [StockLocationLockType.PRODUCT_TYPE]: "Typ skladovej položky",
                [StockLocationLockType.PRODUCT]: "Skladová položka",
                [StockLocationLockType.INSTANCE]: "Konkrétna skladová položka",
            },

            newType: "Typ nového zámku",
            removeType: "Zámok na {0}",

            removeLock: "Odobrať zámok",
            unableToRemoveLastLock: "Nemožno odobrať posledný zámok!",
            lockAlreadySelected: "Tento zámok je už zvolený!",
            allTypesExist: "Toto umiestnenie je už obmedzeno na všetky možné typy!",

            create: {
                done: "Zámok na {0} vytvorený.",
                titleSimple: "Pridať zámok"
            },
            update: {
                done: "Zámok na {0} upravený."
            },
            delete: {
                done: "Zámok na {0} zmazaný."
            },

            status: {
                404: "Zadaný sklad, umiestnenie alebo typ zámku neexistuje!",
                409: "Nesprávny typ zámku!"
            }
        },

        show: {
            titleSimple: "Umiestnenie",
            title: "Umiestnenie '{0}' v '{1}'",
            label: "Položky na umiestnení"
        },
        create: {
            titleSimple: "Nové umiestnenie",
            title: "Nové umiestnenie v {0}",
            label: "Vytvorit nové umiestnenie",
            chooseStockAndSubstockFirst: "Najskôr vyberte sklad a podsklad!",
            done: "Umiestnenie '{0}' vytvorené."
        },
        update: {
            titleSimple: "Upraviť umiestnenie",
            title: "Upraviť umiestnenie '{0}' v {1}",
            done: "Umiestnenie '{0}' upravené."
        },
        delete: {
            done: "Umiestnenie '{0}' zmazané.",
            failed: "Umiestnenie'{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
            status: {
                404: "Zadaný sklad alebo umiestnenie neexistuje!",
                409: "Umiestnenie nemôže byť zmazané kvôli jeho vzťahom k ďalším objektom!"
            }
        },

        status: {
            400: "Nesprávny formát dátumu!",
            404: "Zadaný sklad alebo umiestnenie neexistuje!",
            409: "Zadaný kód už využíva iné umiestnenie!"
        }
    },

    substocks: {
        label: "Podsklady",
        name: "Názov podskladu",
        description: "Popis",
        noSubstocks: "Žiadne podsklady",
        owner: {
            name: "Vlastník"
        },
        owner_id: "Vlastník",
        prepare_package_mode: "Režim balenia zásielok",
        order_with_reservation: "Rezervovať objednané položky",
        auto_merge_stock_pickings: "Automaticky zlučovať podobné vyskladnenia",
        auto_merge_stock_pickings_hint: "Nemá vplyv na vyskladnenia vytvorené z objednávok.",

        items: {
            productsAtSubstock: "Položky v zvolenom podsklade",
            noItems: "V zvolenom podsklade nič nie je!"
        },

        show: {
            titleSimple: "Podsklad",
            title: "Podsklad '{0}' v '{1}'"
        },
        create: {
            titleSimple: "Nový podsklad",
            title: "Nový podsklad v {0}",
            label: "Vytvoriť nový podsklad",
            done: "Podsklad '{0}' vytvorený.",
            addedPermission: "'{0}' má teraz prístup k '{1}'. Doporučujeme nastaviť aj pre ostatných užívateľov."
        },
        update: {
            titleSimple: "Upraviť podsklad",
            title: "Upraviť podsklad '{0}' v '{1}'",
            done: "Podsklad '{0}' upravený."
        },
        delete: {
            done: "Podsklad '{0}' zmazaný.",
            failed: "Podsklad '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
            status: {
                404: "Zadaný sklad alebo jeho podsklad neexistuje!",
                409: "Podsklad nemôže byť zmazaný kvôli jeho vzťahom k ďalším objektom!"
            }
        },

        status: {
            404: "Zadaný sklad alebo jeho podsklad neexistuje!",
            409: "Podsklad nemôže byť zmazaný kvôli jeho vzťahom k ďalším objektom!"
        },

        allowedLocations: {
            status: {
                404: "Zadaný sklad, jeho podsklad alebo konkrétna skladová položka neexistuje!"
            }
        },

        allowedInstances: {
            status: {
                404: stockOrSubStockOrLocationNotFound
            }
        },

        whitelist: {
            list: "Zoznam povolených umiestnení v '{0}'",
            available: "Umiestnenia, ktorá je možné povoliť pre '{0}'",
            auto: "Automaticky povolené",

            create: {
                title: "Pridať do zoznamu povolených umiestnení",
                done: "Umiestnenie '{0}' povolené",
                failed: "Nepodarilo sa povoliť '{0}'"
            },
            delete: {
                title: "Odobrať zo zoznamu povolených umiestnení",
                done: "Umiestnenie '{0}' zakázané",
                failed: "Nepodarilo sa zakázať umiestnenie '{0}'",
                status: {
                    404: stockOrSubStockOrLocationNotFound,
                    409: "Umiestnenie nemôže byť odobrané z podskladu, pretože je na ňom umiestnený tovar!"
                }
            },
            batch: {
                true: "Povoliť vyfiltrované",
                false: "Odobrať povolenie vyfiltrovaných",
                checked: {
                    true: "Povoliť pre podsklad",
                    false: "Odobrať povolenie pre podsklad",
                },
                done: {
                    true: "Umiestnenie povolené",
                    false: "Zrušené povolenie",
                },
                failed: "Zmena povolenia zlyhala!"
            },
            status: {
                404: "Zadaný sklad, podsklad alebo umiestnenie neexistujú!",
                409: "Zvolené umiestnenie je už v tomto podsklade povolené!"
            }
        },

        prepare_package_mode_items: {
            EAN_READ_EACH: "Vyžadovať napípnutie každej položky",
            SELECT_EACH: "Umožniť voľbu každej položky ručne",
            SELECT_FIRST_AUTO_PACK_REST: "Umožniť voľbu prvej položky ručne, potom automaticky zabaliť všetko z danej objednávky"
        },

        task_settings: {
            label: "Konfigurácia úloh",
            autoApprove: "Automaticky schvaľovať dokončené úlohy",
            autoPrintReport: "Automaticky tlačiť reporty pri dokončení úlohy",
            updateDone: "Konfigurácia upravená",
            status: {
                404: "Zadaný podsklad neexistuje!"
            }
        },

        order_processing: {
            label: "Konfigurácia automatického zlučovania vyskladnenia objednávok",
            runNow: "Spustiť vytvorenie zlúčených vyskladnení z objednávok ihneď",
            enable_automatic_triggering: "Spúšťať automaticky",
            max_automatic_sets: "Maximálny počet zlúčených vyskladnení súčasne",
            min_orders_per_automatic_set: "Minimálny počet objednávok v zlúčenom vyskladnení",
            max_orders_per_automatic_set: "Maximálny počet objednávok v zlúčenom vyskladnení",
            strategies: "Stratégie tvorby zlúčeného vyskladnenia",
            strategyPriority: "Priorita stratégií",
            strategy: {
                SAME_SINGLE_PRODUCT: "Rovnaké jednopoložkové",
                SINGLE_PRODUCT: "Jednopoložkové",
                IDENTICAL_ORDERS: "Rovnaké",
                FROM_OLDEST: "Od najstarších"
            },
            updateDone: "Konfigurácia upravená",
            run: {
                status: {
                    204: "Úloha bola zaradená do poradia na spracovanie",
                    404: "Zadaný sklad alebo jeho podsklad neexistujú!",
                }
            },
            status: {
                404: "Zadaný sklad alebo jeho podsklad neexistujú!",
                409: "Neplatná konfigurácia spracovávania objednávok!"
            }
        },

        buyer_types: {
            label: "Priradené typy odberatelov"
        }
    },

    owners: {
        name: "Názov",
        ico: "IČO",
        dic: "DIČ",
        phone: "Telefón",
        email: "E-mail",
        website: "Web",
        billing_address: "Fakturačná adresa",
        bank_account: "Číslo účtu",

        unableToLoad: "Vlastníka nebolo možné načítať. Skúste to prosím znova.",

        link: "Vlastníci podskladov",

        list: {
            title: "Vlastníci podskladov"
        },
        show: {
            title: "Vlastník podskladu"
        },
        create: {
            label: "Vytvoriť nového vlastníka",
            titleSimple: "Nový vlastník podskladu",
            title: "Nový vlastník podskladu '{0}'",
            done: "Vlastník '{0}' vytvorený."
        },
        update: {
            titleSimple: "Upraviť vlastníka",
            title: "Upraviť vlastníka '{0}'",
            done: "Vlastník '{0}' upravený."
        },
        delete: {
            done: "Vlastník '{0}' zmazaný!",
            failed: "Vlastníka '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
            status: {
                404: "Zadaný vlastník podskladu neexistuje!",
                409: "Vlastník podskladu nemôže byť zmazaný kvôli jeho vzťahom k ďalším objektom!"
            }
        },

        status: {
            404: "Zadaný vlastník skladu neexistuje!",
            409: "Vyplnené IČO už má iný vlastník skladu!"
        }
    },

    stockChoose: {
        form: {
            stockId: "Zvoľte sklad",
            subStockId: "Zvoľte podsklad",
            locationId: "Len na umiestnení ...",
            productId: "Len skladová položka ...",
            instanceId: "Len konkrétna skladová položka ...",
        }
    },

    stockStatus: {
        link: "Stav skladu",
        title: "Prehľad stavu skladu",
        allSubstocks: "<všetky podsklady>",
        allLocations: "<všetky umiestnenia na sklade>",
        allLocationsIn: "<všetky umiestnenia v '{0}'>",
        allProducts: "<všetky položky '{0}'>",
        optional: "Voliteľné upresnenie",
        table: {
            stock: {
                id: "Sklad",
                choose: "Zvoľte sklad"
            },
            product_instance: {
                id: "Názov položky",
                choose: "Zvoľte produkt"
            },
            stock_location: {
                id: "Umiestnenie alebo skladník"
            },
            quantity: "Množstvo",
            last_change: "Dátum"
        },
        showItem: "Zobraziť položku",
        showLocation: "Zobraziť umiestnenie",
        useHeaderToSearch: "Na vyhľadávanie použite pole v hlavičke tabuľky",

        locations: {
            link: "Report miest",
            title: "Report denného využitia umiestnenia",
            generating: "Prebieha generovanie prehľadu...",
            form: {
                stockId: "Sklad",
                subStockId: "Podsklad",
                dateFrom: "od",
                dateTo: "do",
                mustBeHigherThanFrom: "Nemôže byť skôr ako 'od'!",
                mustBeUpToToday: "Nesmie byť neskôr ako dnes!",
                max31Days: "Max 31 dní!"
            },
            table: {
                location_id: "ID polohy",
                location_name: "Názov polohy",
                days_used: "Používané dni"
            }
        },

        buyPrices: {
            title: {
                IN: "Export nákupných cien - stav skladu",
                OUT: "Export nákupných cien - vyskladnenie"
            },

            stockId: "Sklad",
            subStockId: "Podsklad",
            from_date: "od",
            to_date: "do",

            status: {
                409: "Neplatný dátum"
            }
        }
    },

    stockMovements: {
        link: "Skladové pohyby",
        title: "Prehľad pohybov na sklade",
        allProducts: "<všetky skladové položky>",
        dateFrom: "Od ...",
        dateTo: "Do ...",
        selectDate: "Zvoľte dátum",
        selectTime: "Zvoľte čas",
        table: {
            amount: "Množstvo",
            created_at: "Dátum",
            created_by: "Užívateľ",
            product: "Skladová položka",
            movement: "Pohyb",
            task: "Úloha"
        },
        viewTask: "Zobraziť úlohu",
        filterProduct: "Filtrovať len túto položku",
        filterTask: "Filtrovať len túto úlohu",
        removeFilter: "Odstrániť filter"
    },

    list: {
        unableToLoad: "Zoznam skladov nebolo možné načítať. Skúste to prosím opäť neskôr.",
    },
    show: {
        titleSimple: "Detail skladu",
        title: "{0}"
    },
    create: {
        title: "Nový sklad",
        done: "Sklad '{0}' vytvorený."
    },
    update: {
        titleSimple: "Upraviť sklad",
        title: "Upraviť {0}",
        done: "Sklad '{0}' upravený."
    },
    delete: {
        done: "Sklad '{0}' zmazaný.",
        failed: "Sklad '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr."
    },

    status: {
        400: "Nesprávný formát dátumu!",
        404: "Zadaný sklad neexistuje!",
        409: "Sklad nemôže byť zmazaný kvôli jeho vzťahom k ďalším objektom!",
        423: "Prehľad ešte nie je pripravený. Skúste túto akciu neskôr."
    }
};
