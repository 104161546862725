<template>
  <div>
    <template v-if="order">
      <v-card class="mx-1 px-1">
        <v-container>
          <div>
            <span class="text-caption">{{
              $t('tasks.preparePackage.orders.external_order_id') + ': '
            }}</span>
            #{{ order.external_order_id || order.id }}
          </div>
          <div v-if="order.external_order_states && order.external_order_states.length > 0">
            <span class="text-caption">{{ $t('tasks.externalOrder.state.label') }}:</span>
            <ul>
              <li
                v-for="state of order.external_order_states"
                :key="state"
              >
                {{ $t('tasks.externalOrder.state.' + state) }}
              </li>
            </ul>
          </div>
          <div>
            <span class="text-caption">{{ $t('tasks.externalOrder.subordinate_stock_id') }}:</span>
            {{ order.subordinate_stock.name }}
          </div>
          <div>
            <span class="text-caption">{{
              $t('tasks.preparePackage.orders.external_order_author_name') + ': '
            }}</span>
            <PreparePackageBuyerDetail
              :buyer-id="order.buyer_id"
            />
          </div>
          <div v-if="order.external_note !== null">
            <span class="text-caption">{{
              $t('tasks.preparePackage.orders.external_order_note') + ': '
            }}</span>
            <strong>{{ order.external_note }}</strong>
          </div>
          <ExternalOrderParametersList :order="order" />
          <div>
            <span class="text-caption">{{ $t('tasks.externalOrder.externalFields.label') }}:</span>
            <ul>
              <div
                v-for="field of ['author_name', 'author_id', 'id']"
                :key="field"
              >
                <li v-if="order['external_' + field]">
                  <span class="text-caption">{{
                    $t('tasks.externalOrder.externalFields.' + field)
                  }}</span>:
                  {{ order['external_' + field] }}
                </li>
              </div>
              <li v-if="order.external_order_created_at">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.externalFields.order_created_at')
                }}</span>:
                <DateTimeWithTooltip :date-time="order.external_order_created_at" />
              </li>
            </ul>
          </div>
          <div
            v-if="invoiceLink"
            class="mt-3"
          >
            {{ $t('tasks.preparePackage.orders.printInvoice') }}:
            <v-btn
              v-for="copies of 2"
              :key="copies"
              color="secondary"
              outlined
              :loading="loadingPrint"
              @click="printInvoice(copies)"
            >
              <v-icon
                v-for="i of copies"
                :key="copies + '-' + i"
              >
                $invoice
              </v-icon>
              <span class="ml-2">
                {{ $t('tasks.preparePackage.orders.printInvoiceAmount.' + copies) }}
              </span>
            </v-btn>
            {{ $t('tasks.preparePackage.orders.invoiceCopies') }}
          </div>
          <v-divider
            class="my-2"
          />
          <Notes
            :notes="order.notes"
            inline
          />
          <v-divider
            v-if="order.attachments.length > 0"
            class="my-2"
          />
          <TaskAttachments
            :attachments="order.attachments"
            inline
          />
          <v-divider
            class="my-2"
          />
          <EntityHistory
            :changes="order"
          />
        </v-container>
      </v-card>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import PreparePackageBuyerDetail from "@/app/tasks/preparePackage/components/PreparePackageBuyerDetail.component";
    import * as Export from "@/service/Export";
    import {PrintType} from "@/enum/print_type";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import {Env} from "@/service/Environment";
    import ExternalOrderParametersList
        from "@/app/tasks/externalOrder/components/ExternalOrderParametersList.component";

    export default {
        name: "OrderDetail",
        components: {
            ExternalOrderParametersList,
            PreparePackageBuyerDetail, TaskAttachments, EntityHistory, Notes, DateTimeWithTooltip
        },
        mixins: [ACLMixin, RouteParamsMapperMixin],
        props: {
            order: {
                type: Object,
                default: () => null
            },
            shipment: {
                type: Object,
                default: () => null
            },
            firstItemPackedTrigger: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            loadingPrint: false
        }),
        computed: {
            invoiceLink: function () {
                const carrierServiceParams = this.order?.carrier_service_parameters;
                if (!carrierServiceParams) {
                    return null;
                }
                return carrierServiceParams.find(param => param.name === 'invoiceLink')?.value;
            }
        },
        watch: {
            firstItemPackedTrigger: function () {
                if (this.invoiceLink) {
                    this.printInvoice();
                }
            }
        },
        methods: {
            printInvoice: function (amount = 1) {
                if (!Env.isElectron()) {
                    // we want to download invoice just once
                    amount = 1;
                }
                for (let i = 0; i < amount; i++) {
                    Export.print(this.invoiceLink, 'invoice_' + this.order.id, PrintType.PDF);
                }
            }
        }
    };
</script>

<style scoped>

</style>
