<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-layout row>
        <v-flex
          md4
          xs12
        >
          <v-card
            outlined
            elevation="4"
            class="ma-1 ml-3"
            :class="{ 'mb-n10': $vuetify.breakpoint.mdAndUp }"
          >
            <v-container>
              <div class="text-overline mb-3">
                {{ $t('stocks.stockStatus.optional') }}
              </div>
              <FormFields
                single-per-row
                :form="form"
                :render="formRender"
                lang-path="stocks.stockChoose.form."
              />
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>

      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :api-filter="apiFilter"
        :show-search-bar="false"
      >
        <template #item.last_change="{ item }">
          <DateTimeWithTooltip
            :date-time="item.last_change"
            :abs-date-first="true"
          />
        </template>
        <template #filter-stock_location.id="{ filters, header }">
          <v-autocomplete
            v-model="filters[header.value]"
            :items="has(stockLocations, chosenStock) ? stockLocations[chosenStock] : []"
            :label="$t('base.filterBy') + ' ' + header.text"
            :loading="chosenStock !== null && !has(stockLocations, chosenStock)"
            multiple
            :no-data-text="header.noDataText"
            :clearable="filters[header.value] !== null"
            @input="onLocationChosen"
          >
            <template #prepend-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="chosenStock"
                      :items="header.filterItems"
                      :label="$t('stocks.stockStatus.table.stock.choose')"
                      :loading="header.loading"
                      :no-data-text="header.noDataText"
                      prepend-icon="$stock"
                      clearable
                      @input="onStockChosen($event, header.filterItems)"
                      @click:clear="filters[header.value] = null; form.stockId = null"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="chosenStock">
                  <div class="ml-4 mt-n4 mr-2">
                    <v-icon>
                      $info
                    </v-icon>
                    <span class="text-caption">
                      {{ $t('stocks.stockStatus.useHeaderToSearch') }}
                    </span>
                  </div>
                </v-row>
              </v-container>
            </template>
          </v-autocomplete>
        </template>
        <template #filter-product_instance.id="{ filters, header }">
          <v-autocomplete
            v-model="filters[header.value]"
            :items="has(productInstances, chosenProduct) ? productInstances[chosenProduct] : []"
            :label="$t('base.filterBy') + ' ' + header.text"
            :loading="chosenProduct !== null && !has(productInstances, chosenProduct)"
            multiple
            :no-data-text="header.noDataText"
            :clearable="filters[header.value] !== null"
            @input="onInstanceChosen"
          >
            <template #prepend-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="chosenProduct"
                      :items="header.filterItems"
                      :label="$t('stocks.stockStatus.table.product_instance.choose')"
                      :loading="header.loading"
                      :no-data-text="header.noDataText"
                      prepend-icon="$product"
                      clearable
                      @input="onProductChosen($event, header.filterItems)"
                      @click:clear="filters[header.value] = null; onProductChosen(null, header.filterItems)"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="chosenProduct">
                  <div class="ml-4 mt-n4 mr-2">
                    <v-icon>
                      $info
                    </v-icon>
                    <span class="text-caption">
                      {{ $t('stocks.stockStatus.useHeaderToSearch') }}
                    </span>
                  </div>
                </v-row>
              </v-container>
            </template>
          </v-autocomplete>
        </template>
      </x-data-table>
    </v-container>
    <v-divider
      class="my-3"
    />
    <v-container>
      <StockBuyPrices
        type="IN"
        class="mb-4"
      />
      <StockBuyPrices type="OUT" />
    </v-container>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {has} from "@/utils/object";
    import {ProductAPI} from "@/api/ProductAPI";
    import {createHeaders} from "@/utils/table";
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockStatusForm} from "@/app/overview/status/definitions/stockStatus.form";
    import {StocksRender} from "@/app/overview/definitions/overview.render.form";
    import {locationLabel} from "@/utils/string";
    import {stockStatusTable} from "@/app/overview/status/definitions/stockStatus.form";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import {APIFilters} from "@/service/APIFilters";
    import {APIFilterOP} from "@/service/APIFilters";
    import {instanceLabel} from "@/utils/filters";
    import {productLabel} from "@/utils/filters";
    import {EventBus} from "@/service/EventBus";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import StockBuyPrices from "@/app/overview/status/components/StockBuyPrices.component";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";

    export default {
        name: "StockStatus",
        components: {FormFields, DateTimeWithTooltip, StockBuyPrices},
        mixins: [RouteParamsMapperMixin, FormAutocompleteMixin],
        data: () => ({
            form: new StockStatusForm,
            formRender: new StocksRender(false, false, true),

            filter: {},
            apiFilter: [],
            items: [],
            headers: [],
            loading: false,
            reload: 0,

            has: has,
            chosenStock: null,
            stockLocations: {},
            chosenProduct: null,
            productInstances: {},
            initialFormSet: false,
            curRouteParams: [],
            lastRouteParams: []
        }),
        computed: {
            possibleFilters: function () {
                return {
                    STOCK: 'stock.id',
                    SUB_STOCK: 'substock.id',
                    STOCK_LOCATION: 'stock_location.id',
                    PRODUCT: 'product.id',
                    PRODUCT_INSTANCE: 'product_instance.id'
                };
            },
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        action: this.showItem,
                        icon: '$product',
                        label: 'stocks.stockStatus.showItem'
                    }, {
                        condition: item => !has(item.stock_location, 'user') && this.form.stockId !== null,
                        loading: this.loading,
                        action: this.showLocation,
                        icon: '$location',
                        label: 'stocks.stockStatus.showLocation'
                    }
                ];
            },
            apiDataSource: function () {
                return StockStatusAPI.getAll.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return StockStatusAPI.getAllPages.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            }
        },
        watch: {
            'form.stockId': function (newValue) {
                if (newValue) {
                    this.fetchSubStocks(['subStockId'], true);
                    this.chosenStock = newValue;
                    this.$set(this.filter, this.possibleFilters.STOCK, {
                        [APIFilterOP.EQUALS]: {
                            [this.possibleFilters.STOCK]: newValue
                        }
                    });
                    const stocks = this.formRender.stockId.autocomplete.items;
                    const stock = stocks ? stocks.find(item => item.value === newValue) : null;
                    if (!has(this.stockLocations, newValue)) {
                        this.loadStockLocations(newValue, stock);
                    }
                } else {
                    this.chosenStock = null;
                    this.clearFilterAttributes([this.possibleFilters.STOCK]);
                }
                if (!this.initialFormSet) {
                    this.form.subStockId = [];
                    this.clearFilterAttributes([this.possibleFilters.STOCK_LOCATION, this.possibleFilters.SUB_STOCK]);
                    this.formRender.subStockId.autocomplete.items = [];
                }
                this.initialFormSet = false;
                this.reload++;
            },
            'form.subStockId': function (newValue) {
                this.clearFilterAttributes([this.possibleFilters.SUB_STOCK]);
                if (newValue && Array.isArray(newValue)) {
                    if (newValue.length === 0 || newValue.includes(null)) {
                        this.form.subStockId = null;
                    } else {
                        this.$set(this.filter, this.possibleFilters.SUB_STOCK, {
                            [APIFilterOP.IN]: {
                                [this.possibleFilters.SUB_STOCK]: newValue
                            }
                        });
                    }
                }
                this.initialFormSet = false;
                this.reload++;
            },
            filter: {
                deep: true,
                handler: function () {
                    this.apiFilter = Object.values(this.filter).filter(filter => filter !== null);
                    this.reload++;
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.headers = createHeaders(stockStatusTable, 'stocks.stockStatus.table.');
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.filter = {
                    quantity: {
                        [APIFilterOP.GREATER_THAN]: {
                            quantity: 0
                        }
                    }
                };
            }
            this.stockLocations = {};
            this.productInstances = {};
            this.lastRouteParams = this.curRouteParams;
            this.curRouteParams = [];

            if (this.stockId !== undefined) {
                this.form.stockId = this.stockId;
                this.initialFormSet = true;
                this.curRouteParams.push(this.possibleFilters.STOCK);
            } else if (this.lastRouteParams.includes(this.possibleFilters.STOCK)) {
                this.form.stockId = null;
            }
            const chosenStockId = this.form.stockId;
            if (chosenStockId) {
                this.loadStockLocations(chosenStockId);
            }

            if (this.subStockId !== undefined) {
                this.form.subStockId = this.subStockId;
                this.initialFormSet = true;
                this.curRouteParams.push(this.possibleFilters.SUB_STOCK);
            } else if (this.lastRouteParams.includes(this.possibleFilters.SUB_STOCK)) {
                this.form.subStockId = [];
            }

            if (lifeCycleHook === this.LifeCycleHook.ACTIVATED) {
                this.mountedOrActivated();
            }

            this.reload++;
        },
        mounted: function () {
            this.mountedOrActivated();
        },
        methods: {
            mountedOrActivated: function () {
                if (this.locationId !== undefined) {
                    this.curRouteParams.push(this.possibleFilters.STOCK_LOCATION);
                    this.$set(this.filter, this.possibleFilters.STOCK_LOCATION, {
                        [APIFilterOP.EQUALS]: {
                            [this.possibleFilters.STOCK_LOCATION]: this.locationId
                        }
                    });
                    EventBus.$emit('set-table-filter', this.possibleFilters.STOCK_LOCATION, [this.locationId]);
                    EventBus.$emit('show-filters');
                } else if (this.lastRouteParams.includes(this.possibleFilters.STOCK_LOCATION)) {
                    this.clearFilterAttributes([this.possibleFilters.STOCK_LOCATION]);
                }

                if (this.productId !== undefined) {
                    this.chosenProduct = this.productId;
                    this.curRouteParams.push(this.possibleFilters.PRODUCT);
                    this.$set(this.filter, this.possibleFilters.PRODUCT, {
                        [APIFilterOP.EQUALS]: {
                            [this.possibleFilters.PRODUCT]: this.productId
                        }
                    });
                    EventBus.$emit('show-filters');
                    if (this.instanceId !== undefined) {
                        this.curRouteParams.push(this.possibleFilters.PRODUCT_INSTANCE);
                        this.$set(this.filter, this.possibleFilters.PRODUCT_INSTANCE, {
                            [APIFilterOP.EQUALS]: {
                                [this.possibleFilters.PRODUCT_INSTANCE]: this.instanceId
                            }
                        });
                        EventBus.$emit('set-table-filter', this.possibleFilters.PRODUCT_INSTANCE, [this.instanceId]);
                    } else if (this.lastRouteParams.includes(this.possibleFilters.PRODUCT_INSTANCE)) {
                        this.clearFilterAttributes([this.possibleFilters.PRODUCT_INSTANCE]);
                    }
                } else if (this.lastRouteParams.includes(this.possibleFilters.PRODUCT)) {
                    this.clearFilterAttributes([this.possibleFilters.PRODUCT]);
                    this.chosenProduct = null;
                    if (this.lastRouteParams.includes(this.possibleFilters.PRODUCT_INSTANCE)) {
                        this.clearFilterAttributes([this.possibleFilters.PRODUCT_INSTANCE]);
                    }
                }
                if (this.chosenProduct) {
                    ProductAPI.get(this.chosenProduct).then(response => {
                        this.loadProductInstances(this.chosenProduct, response.data);
                    });
                }
            },
            loadStockLocations: function (stockId, stock = null) {
                StockAPI.getAllLocationsAllPages(stockId).then(response => {
                    const autocompleteItems = response.data.items.map(location => ({
                        text: locationLabel(location),
                        value: location.id
                    }));
                    autocompleteItems.unshift({
                        text: stock ? this.$t('stocks.stockStatus.allLocationsIn', [stock.text]) : this.$t('stocks.stockStatus.allLocations'),
                        value: null
                    });
                    this.$set(this.stockLocations, stockId, autocompleteItems);
                }).catch(this.snack);
            },
            onStockChosen: function (input, items) {
                if (!input) {
                    this.clearFilterAttributes([this.possibleFilters.STOCK]);
                    return;
                }
                this.form.stockId = input;
                this.clearFilterAttributes([this.possibleFilters.STOCK_LOCATION]);
                this.$set(this.filter, this.possibleFilters.STOCK, {
                    [APIFilterOP.EQUALS]: {
                        [this.possibleFilters.STOCK]: input
                    }
                });
                this.reload++;
                const stock = items ? items.find(item => item.value === input) : null;
                if (!has(this.stockLocations, input)) {
                    this.loadStockLocations(input, stock);
                }
            },
            onLocationChosen: function (newValue) {
                this.$set(this.filter, this.possibleFilters.STOCK_LOCATION, null);
                if (!newValue || newValue.includes(null)) {
                    EventBus.$emit('set-table-filter', this.possibleFilters.STOCK_LOCATION, null);
                }
            },
            onInstanceChosen: function (newValue) {
                this.$set(this.filter, this.possibleFilters.PRODUCT_INSTANCE, null);
                if (!newValue || newValue.includes(null)) {
                    EventBus.$emit('set-table-filter', this.possibleFilters.PRODUCT_INSTANCE, null);
                }
            },
            loadProductInstances: function (productId, product) {
                ProductAPI.getAllInstancesAllPages(productId).then(response => {
                    const autocompleteItems = response.data.items.map(instance => ({
                        text: instanceLabel(instance, product),
                        value: instance.id
                    }));
                    autocompleteItems.unshift({
                        text: this.$t('stocks.stockStatus.allProducts', [productLabel(product)]),
                        value: null
                    });
                    this.$set(this.productInstances, productId, autocompleteItems);
                }).catch(this.snack);
            },
            onProductChosen: function (input, items) {
                if (!input) {
                    this.clearFilterAttributes([this.possibleFilters.PRODUCT, this.possibleFilters.PRODUCT_INSTANCE]);
                    return;
                }
                this.chosenProduct = input;
                this.clearFilterAttributes([this.possibleFilters.PRODUCT_INSTANCE]);
                this.$set(this.filter, this.possibleFilters.PRODUCT, {
                    [APIFilterOP.EQUALS]: {
                        [this.possibleFilters.PRODUCT]: input
                    }
                });
                this.reload++;
                const product = items.find(item => item.value === input);
                if (!has(this.productInstances, input)) {
                    this.loadProductInstances(input, product.product);
                }
            },
            showItem: function (item) {
                this.$router.push('/products/' + item.product_instance.product.id);
            },
            showLocation: function (item) {
                if (this.form.subStockId !== null) {
                    this.$router.push('/stocks/' + this.form.stockId + '/substocks/' + this.form.subStockId + '/locations/' + item.stock_location.id);
                } else {
                    this.$router.push('/stocks/' + this.form.stockId + '/locations/' + item.stock_location.id);
                }
            },
            clearFilterAttributes: function (attributes) {
                for (const attr of attributes) {
                    this.$set(this.filter, attr, null);
                    EventBus.$emit('set-table-filter', attr, null);
                }
            }
        }
    };
// TODO cache
// TODO more actions
</script>

<style scoped>

</style>
