import {BaseAPI} from "@/api/BaseAPI";
import {PrintType} from "@/enum/print_type";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {merge} from "@/utils/object";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";

const StockAPI = {

    API: BaseAPI,
    DOMAIN: 'stocks',
    DOMAIN_LOCATIONS: 'locations',
    DOMAIN_LOCATION_LOCKS: 'product-locks',
    DOMAIN_SUBSTOCKS: 'subordinates',
    LANG_LOCATION_LOCKS: 'locks',
    LANG_SUBSTOCKS: 'substocks',
    DOMAIN_WHITELIST: 'whitelist',
    DOMAIN_OWNERS: 'owners',
    DOMAIN_TASK_SETTINGS: 'task-settings',
    LANG_TASK_SETTINGS: 'task_settings',
    DOMAIN_BUYER_TYPES: 'buyer-types',
    LANG_BUYER_TYPES: 'buyer_types',
    DOMAIN_ORDER_PROCESSING: 'external-order-processing',
    LANG_ORDER_PROCESSING: 'order_processing',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    getAllPages(params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, params);
    },

    get(stockId) {
        return this.API.get([this.DOMAIN, stockId]);
    },

    create(data) {
        return this.API.post([this.DOMAIN], data);
    },

    update(stockId, data) {
        return this.API.patch(
            [this.DOMAIN, stockId],
            data
        );
    },

    delete(stockId) {
        return this.API.delete([this.DOMAIN, stockId]);
    },


    // Locations

    getAllLocations(stockId, ...params) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS],
            [this.DOMAIN, this.DOMAIN_LOCATIONS],
            merge(...params)
        );
    },

    getAllLocationsAllPages(stockId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS],
            [this.DOMAIN, this.DOMAIN_LOCATIONS],
            merge(...params)
        );
    },

    getLocation(stockId, locationId) {
        return APIHelper.getWithCache({
            collection: CachePath.locations,
            key: locationId,
            requestParams: [[this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId],
                            [this.DOMAIN, this.DOMAIN_LOCATIONS]]
        });
    },

    getLocationWOStock(locationId) {
        return APIHelper.getWithCache({
            collection: CachePath.locations,
            key: locationId,
            requestParams: [[this.DOMAIN, this.DOMAIN_LOCATIONS, locationId],
                            [this.DOMAIN, this.DOMAIN_LOCATIONS]]
        });
    },

    getLocationByUrl(url) {
        return this.API.get(
            url,
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    createLocation(stockId, data) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS],
            data,
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    updateLocation(stockId, locationId, data) {
        return this.API.patch(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId],
            data,
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    printLocationBarcode(stockId, locationId, type = PrintType.PDF, amount = 1) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, 'print-barcode'],
            {
                type: type,
                amount: amount
            },
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    printLocationBarcodeBatch(stockId, locationIDs, type = PrintType.PDF, amount = 1) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, 'batch', 'print-barcode'],
            {
                stock_location_ids: locationIDs,
                type: type,
                amount: amount
            },
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    deleteLocation(stockId, locationId) {
        return this.API.delete(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId],
            [this.DOMAIN, this.DOMAIN_LOCATIONS, 'delete']
        );
    },


    // Location locks

    getAllLocationLocks(stockId, locationId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_LOCATION_LOCKS],
            [this.DOMAIN, this.DOMAIN_LOCATIONS]
        );
    },

    getLocationLock(stockId, locationId, lockType) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_LOCATION_LOCKS, lockType],
            [this.DOMAIN, this.DOMAIN_LOCATIONS, this.LANG_LOCATION_LOCKS]
        );
    },

    createLocationLock(stockId, locationId, lockType, data) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_LOCATION_LOCKS, lockType],
            data,
            [this.DOMAIN, this.DOMAIN_LOCATIONS, this.LANG_LOCATION_LOCKS]
        );
    },

    updateLocationLock(stockId, locationId, lockType, data) {
        return this.API.put(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_LOCATION_LOCKS, lockType],
            data,
            [this.DOMAIN, this.DOMAIN_LOCATIONS, this.LANG_LOCATION_LOCKS]
        );
    },

    deleteLocationLock(stockId, locationId, lockType) {
        return this.API.delete(
            [this.DOMAIN, stockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_LOCATION_LOCKS, lockType],
            [this.DOMAIN, this.DOMAIN_LOCATIONS, this.LANG_LOCATION_LOCKS]
        );
    },


    // Substocks

    /**
     * @param stockId
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAllSubstocks(stockId, ...params) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS],
            undefined,
            merge(...params)
        );
    },

    getAllSubstockPages(stockId, params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS],
            undefined,
            params
        );
    },

    getSubstock(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    getSubstockWOStock(subStockId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_SUBSTOCKS, subStockId],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    createSubstock(stockId, data) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS],
            data,
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    updateSubstock(stockId, subStockId, data) {
        return this.API.patch(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId],
            data,
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    deleteSubstock(stockId, subStockId) {
        return this.API.delete(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    // Allowed locations

    getSubstockAllowedLocations(stockId, subStockId, instanceId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, 'instances', instanceId, 'allowed-locations'],
            [this.DOMAIN, this.LANG_SUBSTOCKS, 'allowedLocations']
        );
    },

    getSubstockAllowedLocationIds(stockId, subStockId, instanceId, reallyFetch = true) {
        return APIHelper.getWithCache({
            collection: CachePath.allowedLocationIds,
            key: [stockId, subStockId, instanceId].join('-'),
            maxAge: reallyFetch ? 0 : null,
            requestParams: [[this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, 'instances', instanceId, 'allowed-location-ids'],
                            [this.DOMAIN, this.LANG_SUBSTOCKS, 'allowedLocations']],
        });
    },

    // Allowed instances

    getLocationAllowedInstancesIds(stockId, subStockId, locationId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_LOCATIONS, locationId, 'allowed-instances-ids'],
            [this.DOMAIN, this.LANG_SUBSTOCKS, 'allowedInstances']
        );
    },

    // Whitelist

    getAllSubstockLocations(stockId, subStockId, params) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_LOCATIONS],
            [this.DOMAIN, this.LANG_SUBSTOCKS],
            params
        );
    },

    getAllSubstockLocationsAllPages(stockId, subStockId, params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_LOCATIONS],
            [this.DOMAIN, this.LANG_SUBSTOCKS],
            params
        );
    },

    getAllSubstockAvailableLocations(stockId, subStockId, ...params) {
        return this.getAllSubstockLocations(stockId, subStockId, merge(...params, {
            filter: APIFilters.makeFilter({
                [APIFilterOP.EQUALS]: {
                    is_allowed: true
                }
            })
        }));
    },

    getAllSubstockAvailableLocationsAllPages(stockId, subStockId) {
        return this.getAllSubstockLocationsAllPages(stockId, subStockId, {
            filter: APIFilters.makeFilter({
                [APIFilterOP.EQUALS]: {
                    is_allowed: true
                }
            })
        });
    },

    getAllSubstockWhitelistedLocations(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_WHITELIST],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    getSubstockWhitelistLocation(stockId, subStockId, locationId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_WHITELIST, locationId],
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.DOMAIN_WHITELIST]
        );
    },

    getAllSubstockAvailableEmptyLocations(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_LOCATIONS, 'empty'],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    getAllSubstockAvailableEmptyLocationsAllPages(stockId, subStockId) {
        return APIHelper.getAllPages(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_LOCATIONS, 'empty'],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    createSubstockWhitelistLocation(stockId, subStockId, locationId) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_WHITELIST],
            {location_id: locationId},
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.DOMAIN_WHITELIST]
        );
    },

    deleteSubstockWhitelistLocation(stockId, subStockId, locationId) {
        return this.API.delete(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_WHITELIST, locationId],
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.DOMAIN_WHITELIST, 'delete']
        );
    },

    // Substock task settings

    getSubStockTaskSettingsConfig(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_TASK_SETTINGS],
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.LANG_TASK_SETTINGS]
        );
    },

    updateSubStockTaskSettingsConfig(stockId, subStockId, taskType, data) {
        return this.API.patch(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_TASK_SETTINGS, taskType],
            data,
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.LANG_TASK_SETTINGS]
        );
    },

    // Substock buyer types

    getSubStockAssignedBuyerTypes(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_BUYER_TYPES],
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.LANG_BUYER_TYPES]
        );
    },

    addSubStockAssignedBuyerType(stockId, subStockId, buyerTypeId) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_BUYER_TYPES, buyerTypeId]
        );
    },

    deleteSubStockAssignedBuyerType(stockId, subStockId, buyerTypeId) {
        return this.API.delete(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_BUYER_TYPES, buyerTypeId]
        );
    },

    // Substock external order processing strategies

    getSubStockOrderProcessingConfig(stockId, subStockId) {
        return this.API.get(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_ORDER_PROCESSING],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },

    runSubStockOrderProcessing(stockId, subStockId) {
        return this.API.post(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_ORDER_PROCESSING],
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.LANG_ORDER_PROCESSING, 'run']
        );
    },

    updateSubStockOrderProcessingConfig(stockId, subStockId, data) {
        return this.API.put(
            [this.DOMAIN, stockId, this.DOMAIN_SUBSTOCKS, subStockId, this.DOMAIN_ORDER_PROCESSING],
            data,
            [this.DOMAIN, this.LANG_SUBSTOCKS, this.LANG_ORDER_PROCESSING]
        );
    },

    getAllOrderProcessingStrategies() {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_SUBSTOCKS, this.DOMAIN_ORDER_PROCESSING, 'strategies'],
            [this.DOMAIN, this.LANG_SUBSTOCKS]
        );
    },


    // Owners

    getAllOwners() {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_OWNERS],
            [this.DOMAIN, this.DOMAIN_OWNERS]
        );
    },

    getOwner(ownerId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_OWNERS, ownerId],
            [this.DOMAIN, this.DOMAIN_OWNERS]
        );
    },

    getOwnerByIco(ico) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_OWNERS, 'by-ico', ico],
            [this.DOMAIN, this.DOMAIN_OWNERS]
        );
    },

    createOwner(data) {
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_OWNERS],
            data,
            [this.DOMAIN, this.DOMAIN_OWNERS]
        );
    },

    updateOwner(ownerId, data) {
        return this.API.patch(
            [this.DOMAIN, this.DOMAIN_OWNERS, ownerId],
            data,
            [this.DOMAIN, this.DOMAIN_OWNERS]
        );
    },

    deleteOwner(ownerId) {
        return this.API.delete(
            [this.DOMAIN, this.DOMAIN_OWNERS, ownerId],
            [this.DOMAIN, this.DOMAIN_OWNERS, 'delete'],
        );
    }
};

export {StockAPI};
