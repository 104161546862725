import formRules from "@/utils/formRules";

function OrderProcessingForm() {
    return {
        enable_automatic_triggering: false,
        max_automatic_sets: 0,
        min_orders_per_automatic_set: 0,
        max_orders_per_automatic_set: 0,
        strategies: []
    };
}

function OrderProcessingFormRender() {
    return {
        max_automatic_sets: {
            icon: '$maxAutomaticSets',
            required: true,
            type: 'number',
            rules: [formRules.positiveInteger]
        },
        min_orders_per_automatic_set: {
            icon: '$minOrdersPerAutomaticSet',
            required: true,
            type: 'number',
            rules: [formRules.positiveInteger]
        },
        max_orders_per_automatic_set: {
            icon: '$maxOrdersPerAutomaticSet',
            required: true,
            type: 'number',
            rules: [formRules.positiveInteger]
        },
        enable_automatic_triggering: {
            icon: '$enableAutomaticTriggering',
            checkbox: true
        }
    };
}

export {OrderProcessingForm, OrderProcessingFormRender};
