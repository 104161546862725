export default {
    tasks: "Úkoly",
    noTasks: "Žádné úkoly!",
    running: "Moje rozpracované úkoly",
    storekeepersRunning: "Rozpracované úkoly skladníků",
    assigned: "Nové úkoly přiřazené mně",
    free: "Volné úkoly",
    allTasks: "Všechny volné úkoly",
    allDone: "Žádné úkoly k zobrazení!",
    overview: "Přehledy",
    toApprove: "Ke schválení",
    toPack: "K zabalení",
    status: "Stav",
    manage: "Správa",
    done: "Dokončené",
    noDone: "Žádné dokončené úkoly!",
    config: {
        title: "Konfigurace domovské obrazovky",
        config: "Nastavení seznamů",
        label: "Název",
        addTaskList: "Přidat seznam úkolů",
        addOrdersStats: "Přidat přehled objednávek",
        newList: "Nový seznam",
        all: "všechny",
        export: {
            title: "Exportovat",
            done: "Domovská obrazovka exportována."
        },
        import: {
            title: "Importovat",
            done: "Domovská obrazovka importována.",
            fail: "Import domovské obrazovky se nezdařil!"
        },
        reset: "Obnovit výchozí seznamy",
        resetDone: "Výchozí seznamy byly obnoveny",
        showOn: "Zobrazovat na",
        desktop: "počítač",
        mobile: "mobil",
        showOnDesktop: "Zobrazovat na počítači",
        showOnMobile: "Zobrazovat na mobilu",
        autoRefresh: "Automaticky obnovovat",
        autoRefreshValue: "Obnovovat po {0} sekundách",
        unableToLoad: "Nezdařilo se načíst konfiguraci domovské obrazovky. Zkuste prosím akci opakovat později.",
        unableToSave: "Nezdařilo se uložit nastavení!",
        arrayDateConflict: "Výběr více datumů současně není podporován. Použijte rozmezí od-do.",
        onlySubstocks: "Pouze podsklady"
    },
    stats: {
        orders: {
            label: 'Stav objednávek',
            unprocessed: 'nových',
            toBePicked: 'čeká na vyskladnění',
            beingPicked: 've vyskladnění',
            toBePacked: 'čeká na balení',
            beingPacked: 'v balení',
            packed: 'zabaleno',
            inShipping: 'v dopravě',
            returning: 'vrací se',
            delivered: 'doručených'
        }
    }
};
