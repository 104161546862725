<template>
  <div>
    <v-chip-group
      column
    >
      <v-chip
        v-for="(file, index) of files"
        :key="file.name + file.key"
        :color="file.uploaded === true ? 'primary' : 'warning'"
        close
        @click:close="removeFile(index)"
      >
        <v-progress-circular
          v-if="!file.uploaded === true"
          size="16"
          width="2"
          indeterminate
          class="mr-2"
        />
        {{ file.name }}
      </v-chip>
    </v-chip-group>
    <v-file-input
      chips
      multiple
      solo
      flat
      :label="$t('base.uploadAttachment')"
      :value="inputFiles"
      @change="fileChanged"
    />
  </div>
</template>

<script>
    import {TaskAPI} from "@/api/TaskAPI";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "TaskAttachmentUpload",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            filesToAttach: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            loading: false,
            inputFiles: [],
            files: []
        }),
        methods: {
            fileChanged: function (files) {
                this.inputFiles = [];
                this.$emit('input', false);
                const fileUploads = [];
                files.forEach((file) => {
                    this.files.push({name: file.name, uploaded: false, key: Math.random()});
                    const localIndex = this.files.length - 1;
                    const localFile = this.files[localIndex];
                    fileUploads.push(
                        this.uploadFile(file)
                            .then(() => {
                                localFile.uploaded = true;
                            })
                            .catch(() => {
                                this.files.splice(localIndex, 1);
                            })
                    );
                });
                Promise.all(fileUploads)
                    .finally(() => {
                        this.$emit('input', true);
                    });
            },
            uploadFile: function (file) {
                return new Promise((resolve, reject) => {
                    TaskAPI.createAttachment(file)
                        .then(response => {
                            resolve(getIdFromLocation(response));
                        }).catch(err => {
                            this.snack(err);
                            reject();
                        });
                }).then(id => {
                    /* eslint-disable-next-line vue/no-mutating-props */
                    this.filesToAttach.push(Number.parseInt(id, 10));
                });
            },
            removeFile: function (fileIndex) {
                this.files.splice(fileIndex, 1);
                /* eslint-disable-next-line vue/no-mutating-props */
                this.filesToAttach.splice(fileIndex, 1);
            }
        }
    };
</script>

<style scoped>

</style>
