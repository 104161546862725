<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels
        :value="0"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/stocks/owners/' + ownerId + '/update'"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
              {{ details.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div v-if="details.ico">
                      <span class="text-caption">{{ $t('stocks.owners.ico') + ': ' }}</span>
                      {{ details.ico }}
                    </div>
                    <div v-if="details.dic">
                      <span class="text-caption">{{ $t('stocks.owners.dic') + ': ' }}</span>
                      {{ details.dic }}
                    </div>
                    <div v-if="details.phone">
                      <span class="text-caption">{{ $t('stocks.owners.phone') + ': ' }}</span>
                      {{ details.phone }}
                    </div>
                    <div v-if="details.email">
                      <span class="text-caption">{{ $t('stocks.owners.email') + ': ' }}</span>
                      {{ details.email }}
                    </div>
                    <div v-if="details.website">
                      <span class="text-caption">{{ $t('stocks.owners.website') + ': ' }}</span>
                      {{ details.website }}
                    </div>
                    <div v-if="addressInline(details.billing_address) !== ''">
                      <span class="text-caption">{{ $t('stocks.owners.billing_address') + ': ' }}</span>
                      {{ details.billing_address | addressInline }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <EntityHistory :changes="details" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card class="mt-1">
              <v-card-title class="text-subtitle-1 mb-n5">
                {{ $t('stocks.owners.bank_account') }}
              </v-card-title>
              <v-container>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div v-if="details.bank_account.full_number">
                      <span class="text-caption">{{ $t('base.bank_account.full_number') + ': ' }}</span>
                      {{ details.bank_account.full_number }}
                    </div>
                    <div v-if="details.bank_account.iban">
                      <span class="text-caption">{{ $t('base.bank_account.iban') + ': ' }}</span>
                      {{ details.bank_account.iban }}
                    </div>
                    <div v-if="details.bank_account.swift">
                      <span class="text-caption">{{ $t('base.bank_account.swift') + ': ' }}</span>
                      {{ details.bank_account.swift }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout wrap />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {addressInline} from "@/utils/filters";

    export default {
        name: "StockOwnerShow",
        components: {EntityHistory},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null,
            addressInline: addressInline
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
        },
        createdOrActivated: function () {
            StockAPI.getOwner(this.ownerId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks/owners');
                });
        }
    };
</script>

<style scoped>

</style>
