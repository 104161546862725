import {StocksRender} from "@/app/overview/definitions/overview.render.form";

const dateFormRender = {
    dateTimePicker: true,
    required: true,
    sticky: true
};

function StockBuyPricesInForm() {
    return {
        stockId: null,
        subStockId: null,
        to_date: null
    };
}

function StockBuyPricesOutForm() {
    return {
        stockId: null,
        subStockId: null,
        to_date: null,
        from_date: null
    };
}

function StockBuyPricesInRender() {
    return {
        ...(new StocksRender(true, true)),
        to_date: dateFormRender
    };
}

function StockBuyPricesOutRender() {
    return {
        ...(new StocksRender(true, true)),
        from_date: dateFormRender,
        to_date: dateFormRender
    };
}

export {StockBuyPricesInForm, StockBuyPricesOutForm, StockBuyPricesInRender, StockBuyPricesOutRender};
