import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskMovementType} from "@/enum/task_movement_type";

const TaskStockLoadingAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/stock-loading',
    DOMAIN_ITEMS: 'items',
    DOMAIN_LOCATIONS: 'locations',
    LANG: 'tasks.stockLoading',
    LANG_TASKS: 'tasks',
    DOMAIN_BUYPRICES: 'buy-prices',
    LANG_BUYPRICES: 'buyPrices',
    LANG_INVENTORY: 'inventory',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG_TASKS
        );
    },

    report(taskId, acceptType) {
        return this.API.get(
            [this.DOMAIN, taskId, 'print-detail'],
            this.LANG_TASKS,
            undefined,
            {
                headers: {
                    'Accept': acceptType
                }
            }
        );
    },

    approve(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'approve'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'approve']
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'assign'],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'unassign'],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'cancel'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'finish'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },

    reject(taskId, hours, note, withdraw_storekeeper) {
        return this.API.post(
            [this.DOMAIN, taskId, 'reject'],
            {
                hours: hours,
                note: note,
                withdraw_storekeeper: withdraw_storekeeper
            },
            [this.LANG_TASKS, 'reject']
        );
    },


    // Buy prices

    getAllBuyPrices(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_BUYPRICES],
            [this.LANG_TASKS]
        );
    },

    getBuyPrice(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_BUYPRICES, itemId],
            [this.LANG, this.LANG_BUYPRICES]
        );
    },

    updateBuyPrice(taskId, priceId, price, vat) {
        return this.API.patch(
            [this.DOMAIN, taskId, this.DOMAIN_BUYPRICES, priceId],
            {
                price: price,
                vat: vat
            },
            [this.LANG, this.LANG_BUYPRICES]
        );
    },


    // Items

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            this.LANG_TASKS
        );
    },

    /**
     * @throws 'PUT' Which means you should use 'updateItem' instead, because given product is already stocked
     */
    createItem(taskId, stock_location_id, product_instance_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            {
                stock_location_id: stock_location_id,
                product_instance_id: product_instance_id,
                quantity: quantity
            },
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    updateItem(taskId, itemId, location_id, quantity_change) {
        if (location_id) {
            return this.API.post(
                [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, this.DOMAIN_LOCATIONS, location_id],
                {quantity_change: quantity_change},
                [this.LANG, this.DOMAIN_ITEMS, 'update']
            );
        } else {
            return this.API.post(
                [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
                {quantity_change: quantity_change},
                [this.LANG, this.DOMAIN_ITEMS, 'update']
            );
        }
    },

    pickUpFromSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_SOURCE_TO_INVENTORY],
            {
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_INVENTORY_TO_DESTINATION],
            {
                stock_location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    pickUpFromDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_DESTINATION_TO_INVENTORY],
            {
                stock_location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    deleteItem(taskId, itemId, location_id) {
        if (location_id) {
            return this.API.delete(
                [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, this.DOMAIN_LOCATIONS, location_id],
                [this.LANG, this.DOMAIN_ITEMS, 'update']
            );
        } else {
            return this.API.delete(
                [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
                [this.LANG, this.DOMAIN_ITEMS, 'update']
            );
        }
    }
};

export {TaskStockLoadingAPI};
