export default {
    link: "Dopravci",
    title: "Dopravci",
    name: "Název",
    type: "Typ",
    active: "Aktivní",
    notActive: "Neaktivní",
    services: {
        title: "Služby",
        noServices: "Žádné dostupné služby",
        showAll: "Zobrazit všechny",
        hideAll: "Skrýt všechny",

        name: "Služba",
        carrier_service_parameters: "Parametry zásilky",
        required_carrier_service_parameters: "Povinné parametry zásilek",
        optional_carrier_service_parameters: "Volitelné parametry zásilek",
        type: "Formát tisku štítku",
        create: {
            title: "Vytvořit službu dopravce",
            done: "Služba dopravce '{0}' vytvořena."
        },
        update: {
            titleSimple: "Upravit službu dopravce",
            title: "Upravit {0}",
            done: "Služba dopravce '{0}' upravena"
        },
        delete: {
            done: "Služba dopravce '{0}' smazána.",
            failed: "Službu dopravce '{0}' nelze smazat. Zkuste to prosím znovu později.",
        },
    },
    tableImage: "",
    tableFoilImage: "Fólie",
    doesNotSupportMultiPackageShipments: "Tento dopravce nepodporuje vícekusové zásilky",
    searchHint: "Prohledává název a typ",

    show: {
        titleSimple: "Detail dopravce",
        configInSubstock: "Konfiguraci napojení na dopravce naleznete v detailu každého podskladu.",
    },

    create: {
        title: "Vytvořit dopravce",
        done: "Dopravce '{0}' vytvořen."
    },
    update: {
        titleSimple: "Upravit dopravce",
        title: "Upravit {0}",
        done: "Dopravce '{0}' upraven"
    },
    delete: {
        done: "Dopravce '{0}' smazán.",
        failed: "Dopravce '{0}' nelze smazat. Zkuste to prosím znovu později.",
    },
    status: {
        404: "Zadaný dopravce neexistuje!",
        409: "Vyplněný název již existuje!"
    },

    images: {
        nameFoil: "Obrázek fólie",
        alt: "Obrázek přiřazený k dopravci",
        altCreate: "Obrázek k přiřazení k dopravci",
        unableToLoad: "Obrázek dopravce nebylo možné načíst. Zkuste to prosím znovu později.",
        create: {
            foil: {
                titleSimple: "Přidat obrázek fólie",
                title: "Přidat obrázek fólie k '{0}'",
                done: "Obrázek fólie přidán k '{0}'"
            }
        },
        update: {
            foil: {
                titleSimple: "Upravit obrázek fólie",
                title: "Upravit obrázek fólie '{0}'",
                done: "Obrázek fólie '{0}' upraven"
            }
        },
        status: {
            404: "Dopravce nebo obrázek nenalezen!"
        }
    },

    contracted: {
        label: "Je nasmlouvaný",
        parameters: {
            required: {
                name: "Potřebné parametry"
            },
            optional: {
                name: "Další volitelné parametry"
            },
            key: "Název",
            value: "Hodnota",
            writeOnly: "Není možné načíst z důvodu bezpečnosti"
        },

        create: {
            label: "Přidat do seznamu nasmlouvaných dopravců vlastníka",
            titleSimple: "Nastavit parametry nasmlouvaného dopravce",
            title: "Nastavit parametry '{0}'",
            info: "Nastavujete parametry '{0}' pro '{1}'",
            done: "'{0}' přidán do seznamu nasmlouvaných dopravců vlastníka"
        },
        update: {
            label: "Upravit parametry nasmlouvaného dopravce vlastníka",
            titleSimple: "Upravit parametry nasmlouvaného dopravce",
            title: "Upravit parametry '{0}'",
            info: "Upravujete parametry '{0}' pro '{1}'",
            done: "Parametry '{0}' upraveny",
            substock: {
                title: "Nasmlouvat pro tento podsklad",
                done: "'{0}' je nyní nasmlouván pro tento podsklad"
            }
        },
        delete: {
            titleSimple: "Odebrat ze seznamu nasmlouvaných dopravců vlastníka",
            done: "'{0}' odebrán ze seznamu nasmlouvaných dopravců vlastníka",
            substock: {
                title: "Zrušit nasmlouvání pro tento podsklad",
                done: "'{0}' již není nasmlouván pro tento podsklad"
            }
        }
    },

    run: {
        job: "Spustit práci na pozadí",
        validatePackages: "Validace zásilek",
        generateProtocols: "Předávací protokoly",
        trackPackages: "Sledování zásilek"
    }
};
