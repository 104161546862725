/**
 * This mixins walks through all PATH and QUERY parameters of current route and sets them as data properties of the component,
 * making them easy to access using `this.paramName`.
 * Component can re-declare this property with its own value which has precedence over value from the router path.
 * Numbers are converted from strings
 * QUERY parameters are reset upon deactivation.
 *
 * Requires:
 * - this.$route.params
 * - this.$route.query
 */
const RouteParamsMapperMixin = {
    data: () => ({
        setQueryParamsKeys: []
    }),
    beforeCreate: function () {
        for (const [key, value] of [...Object.entries(this.$route.params), ...Object.entries(this.$route.query)]) {
            this[key] = resolveValueFormat(value);
        }
    },
    createdOrActivated: function() {
        for (const [key, value] of Object.entries(this.$route.query)) {
            this[key] = resolveValueFormat(value);
        }
        this.setQueryParamsKeys = Object.keys(this.$route.query);
    },
    deactivated: function () {
        for (const key of this.setQueryParamsKeys) {
            this[key] = undefined;
        }
    },
};

function resolveValueFormat(value) {
    if (typeof value === "string") {
        return (value.startsWith('0') || isNaN(+value) || +value > Number.MAX_SAFE_INTEGER) ? value : +value;
    } else {
        return value;
    }
}

export {RouteParamsMapperMixin};
