<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="buyers."
          >
            <template #ico-append-outer>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!allowAres || loading"
                    :loading="loadingAres"
                    fab
                    small
                    bottom
                    color="secondary"
                    @click="loadAres()"
                    v-on="on"
                  >
                    <v-icon>$aresValidation</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('base.ares.load') }}</span>
              </v-tooltip>
            </template>
          </FormFields>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-subheader>{{ $t('buyers.billing_address') }}</v-subheader>
          </v-flex>
          <FormAddress
            :form="form.billing_address"
          />
          <template v-if="!isEdit">
            <v-flex
              xs12
            >
              <v-divider
                class="my-3"
              />
              <v-subheader>{{ $t('buyers.address') }}</v-subheader>
            </v-flex>
            <FormAddress
              :form="form.delivery_address"
            />
          </template>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :disabled="loadingAres"
              :text="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <v-card
      v-if="isEdit"
      class="mt-3"
    >
      <v-container>
        <v-subheader>{{ $t('buyers.delivery_addresses') }}</v-subheader>
        <BuyerDeliveryAddressList />
      </v-container>
    </v-card>
    <div class="my-2" />
    <BuyerTypeCreateUpdate
      v-if="showCreateType"
      id="typeForm"
      ref="typeForm"
      class="ma-1"
      is-component
    />
    <x-btn-fab
      v-if="isEdit"
      :to="'/buyers/' + buyerId + '/address/create'"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import FormAddress from "@/app/components/form/FormAddress.component";
    import {BuyerForm, BuyerRender} from "@/app/buyers/definitions/buyer.form";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {setFormErrors} from "@/utils/form";
    import {LoadAresMixin} from "@/app/mixins/LoadAresMixin";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import BuyerDeliveryAddressList from "@/app/buyers/components/BuyerDeliveryAddressList.component";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {scrollTo} from "@/service/Vuetify";
    import BuyerTypeCreateUpdate from "@/app/buyers/types/BuyerTypeCreateUpdate.view";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "BuyerCreateUpdate",
        components: {BuyerTypeCreateUpdate, BuyerDeliveryAddressList, FormAddress, FormFields},
        mixins: [FormFetchItemMixin, LoadAresMixin, RouteParamsMapperMixin, EventsListenerMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new BuyerForm,
            formRender: new BuyerRender,
            valid: true,
            loading: false,
            loadingAres: false,
            showCreateType: false
        }),
        computed: {
            events: function () {
                return {
                    'create-buyer-type': this.onCreateBuyerType,
                    'create-buyer-type-created': this.onCreateBuyerTypeCreated,
                    'create-buyer-type-cancelled': () => this.showCreateType = false
                };
            },
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('buyers.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return BuyerAPI.get(this.buyerId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    BuyerAPI.update(this.buyerId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'buyers.update.done',
                                params: [this.form.name]
                            });
                            IndexedDB.clearByKey(CachePath.buyers, this.buyerId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    BuyerAPI.create(this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'buyers.create.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            onCreateBuyerType: function () {
                this.showCreateType = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.type_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('typeForm');
                });
            },
            onCreateBuyerTypeCreated: function (typeId) {
                this.showCreateType = false;
                this.$set(this.formRender.type_id, 'loading', true);
                this.formRender.type_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.type_id.autocomplete.items = this.formRender.type_id.autocomplete.thenFn(response);
                        this.form.type_id = Number.parseInt(typeId, 10);
                    }).finally(() => this.$set(this.formRender.type_id, 'loading', false));
            }
        }
    };
</script>

<style scoped>

</style>
