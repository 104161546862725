import {StockAPI} from "@/api/StockAPI";
import {PreparePackageMode} from "@/enum/prepare_package_mode";
import i18n from "@/service/lang/i18n";
import {EventBus} from "@/service/EventBus";
import {TableFilter} from "@/enum/table_filter";

function SubstockForm() {
    return {
        name: '',
        description: '',
        owner_id: null,
        prepare_package_mode: PreparePackageMode.EAN_READ_EACH,
        order_with_reservation: false,
        auto_merge_stock_pickings: true
    };
}

function SubstockRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
        description: {
            icon: '$description',
            max: 200,
            textarea: true
        },
        owner_id: {
            icon: '$owners',
            required: true,
            autocomplete: {
                callFn: () => StockAPI.getAllOwners(),
                thenFn: response => response.data.map(el => ({
                    text: (el.name + ' (' + (el.ico || i18n.t('base.noIco')) + ')'),
                    value: el.id
                }))
            },
            createNew: {
                icon: 'person_add',
                label: 'stocks.owners.create.label',
                action: () => {
                    EventBus.$emit('create-owner');
                }
            }
        },
        prepare_package_mode: {
            icon: '$prepare_package_mode',
            autocomplete: {
                items: Object.values(PreparePackageMode).map(item => ({
                        text: i18n.t('stocks.substocks.prepare_package_mode_items.' + item),
                        value: item
                    })
                )
            }
        },
        order_with_reservation: {
            icon: '$order_with_reservation',
            checkbox: true
        },
        auto_merge_stock_pickings: {
            icon: '$auto_merge_stock_pickings',
            checkbox: true,
            hint: 'stocks.substocks.auto_merge_stock_pickings_hint'
        }
    };
}

const substockTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    'owner.name': {
        filterType: TableFilter.TEXT
    }
};

export {SubstockForm, SubstockRender, substockTable};
