const TaskStockTakingRestriction = {
    NONE: 'NONE',
    SUB_STOCK: 'SUB_STOCK',
    STOCK_LOCATION: 'STOCK_LOCATION',
    STOCK_OWNER: 'STOCK_OWNER',
    MANUFACTURER: 'MANUFACTURER',
    PRODUCT: 'PRODUCT'
};

export {TaskStockTakingRestriction};
