<template>
  <v-list-item class="d-flex justify-space-between">
    <span class="mt-5">
      {{ $t('base.onBeep') }}:
    </span>
    <v-checkbox
      v-model="vibrate"
      on-icon="vibration"
      off-icon="mobile_off"
      hide-details
      class="ml-8"
    />
    <v-checkbox
      v-model="beep"
      on-icon="volume_up"
      off-icon="volume_off"
      hide-details
      class="ml-8"
    />
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarProductModels",
        data: () => ({
            vibrate: true,
            beep: true
        }),
        watch: {
            vibrate: function () {
                this.$store.dispatch('userConfig/set', {
                    key: 'vibrate',
                    value: this.vibrate
                });
            },
            beep: function () {
                this.$store.dispatch('userConfig/set', {
                    key: 'beep',
                    value: this.beep
                });
            }
        },
        created: function () {
            this.vibrate = this.$store.getters['userConfig/vibrate'];
            this.beep = this.$store.getters['userConfig/beep'];
        }
    };
</script>

<style scoped>

</style>
