<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-layout
        wrap
        class="mx-3"
      >
        <v-flex
          xs12
          class="px-2"
        >
          <v-autocomplete
            v-model="lockType"
            :items="availableTypes"
            :label="$t('stocks.locations.locks.newType')"
            :loading="loading"
            :prepend-icon="'$locationLock'"
            :rules="[formRules.required]"
            single-line
            suffix="*"
          />
        </v-flex>
        <v-flex
          xs12
        >
          <v-divider
            class="my-3"
          />
          <span 
            v-if="lockType"
            class="text-subtitle-1 ml-2"
          >
            {{ $t('stocks.locations.locks.lockItems.title') }}:
          </span>
        </v-flex>
        <StockLocationLocksList
          v-if="lockType !== null"
          :lock-type="lockType"
          :is-edit="false"
        />
      </v-layout>
    </v-card>
  </div>
</template>

<script>
    import StockLocationLocksList from "@/app/stocks/locations/locks/components/StockLocationLocksList.component";
    import {tabTitle} from "@/utils/string";
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import formRules from "@/utils/formRules";
    import {StockLocationLockType} from "@/enum/stock_location_lock_type";

    export default {
        name: "StockLocationLockCreate",
        components: {StockLocationLocksList},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            lockType: null,
            availableTypes: [],
            loading: false,
            formRules: formRules
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            this.loading = true;
            StockAPI.getAllLocationLocks(this.stockId, this.locationId).then(response => {
                const existingTypes = response.data.map(lock => lock.lock_type);
                this.availableTypes = Object.values(StockLocationLockType).filter(type => !existingTypes.includes(type));
                if (!this.availableTypes.length) {
                    this.snack('stocks.locations.locks.allTypesExist');
                    this.$router.push('/stocks/' + this.stockId + '/locations/' + this.locationId);
                }
                this.availableTypes = this.availableTypes.map(type => ({
                    text: this.$t('stocks.locations.locks.lockItems.' + type),
                    value: type
                }));
                this.lockType = this.availableTypes[0].value;
            }).catch(err => {
                this.snack(err);
                this.$router.push('/stocks/' + this.stockId + '/locations/' + this.locationId);
            }).finally(() => this.loading = false);
        }
    };
</script>

<style scoped>

</style>