<template>
  <v-alert
    :value="showAlert"
    :type="type"
    class="mt-1"
  >
    <slot name="content">
      {{ displayText }}
    </slot>
  </v-alert>
</template>

<script>
    export default {
        name: "Alert",
        props: {
            showAlert: {
                type: Boolean,
                default: false,
            },
            displayText: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'info'
            }
        }
    };
</script>

<style scoped>

</style>
