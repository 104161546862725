import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
import {ExternalOrderState, TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";
import {deliveryTypes} from "@/enum/delivery_type";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

const notFoundOrACL = "Task not found or inaccessible!";
const notFoundOrACLOrItem = "Task or item not found or inaccesible!";
const approve409 = "Task is not in a state from which it can be approved!";
const reject409 = "Task is not in a state from which it can be rejected!";
const notActive409 = "Task is not in progress or some items have not been processed!";
const memberOfSet = "This action is forbidden, because this task belongs to a group!";

const state = {
    name: "State",
    [TaskState.CREATED]: "New",
    [TaskState.IN_PROGRESS]: "In progress",
    [TaskState.WAITING_FOR_APPROVAL]: "Waiting for approval",
    [TaskState.CLOSED]: "Finished",
    [TaskState.CANCELED]: "Canceled",
    [TaskState.UNASSIGNED]: "Unassigned"
};

export default {
    name: "Task",
    external_id: "External ID",
    parent_task_id: "Parent task",
    state: state,
    priority: {
        name: "Priority",
        [TaskPriority.LOW]: "Low",
        [TaskPriority.NORMAL]: "Normal",
        [TaskPriority.HIGH]: "High",
        unknown: "Unknown priority"
    },
    location: {
        name: "Location",
        [TaskPreferredLocation.EXISTING]: "Any existing",
        [TaskPreferredLocation.LAST]: "Last used"
    },
    description: "Description",
    assigned_user: "Assigned to",
    nobody: "<nobody>",
    delivery_type: "Delivery type",
    delivery_number: "Delivery number",
    invoice_number: "Invoice number",
    supplier_id: "Supplier",
    external_order_task_id: "External order number",
    supplier: "Supplier",
    note: "Note",
    time_spent: "Time spent",
    ean: "EAN",
    lastScanned: "Last scanned",
    queue: "Queue",
    created_at: "Created at",
    updated_at: "Last updated at",
    viewTask: "View task",
    search: "Advanced search",
    searchHint: "Searches through task ID, parent task ID and external ID.",

    noItems: "No items!",
    useScanner: "Use a scanner or the input field above to add items.",
    syncRunning: "Synchronizing",
    generateReport: {
        pdf: "Generate PDF report",
        csv: "Generate CSV report",
        html: "Generate HTML report",
    },

    keep_storekeeper: "Keep task assigned to the same worker",

    card: {
        actions: {
            label: "Label",
            labelDone: "Barcode '{0}' sent to the printer.",
            split: "Split",
            splitDone: "'{0}' split!",
            splitFail: "Unable to split a single item",
            delete: "Delete",
            deleteDone: "'{0}' removed!"
        },
        create: {
            product: "Create product",
            location: "Create location"
        }
    },

    attachments: {
        name: "Attachments",
        filename: "File name",
        download_link: "Download",
        status: {
            404: "Task or attachment not found!",
            498: "Failed to upload selected file!"
        }
    },

    notes: {
        name: "Notes",
        text: "Text",
        status: {
            404: "Task or note not found!"
        }
    },

    filter: {
        chooseAttr: "Select a task attribute",
        id: {
            name: "ID"
        },
        parent_task_id: {
            name: "Parent task"
        },
        substock: {
            id: {
                name: "Sub-warehouse"
            }
        },
        external_id: {
            name: "External ID"
        },
        state: state,
        priority: {
            name: "Priority"
        },
        type: {
            name: "Type"
        },
        assigned_user: {
            id: {
                name: 'Assigned user'
            }
        },
        created_at: {
            name: "Created at"
        },
        updated_at: {
            name: "Last updated at"
        }
    },

    timeEntries: {
        name: "Time spent",
        link: "Time spent in tasks",
        title: "Time spent in tasks",
        user: "User",
        hours: "Hours",
        created_at: "Created at",
        track: "Track time",
        saveTime: "Save tracked time?",
        saveTimeDetails: "There is a running time tracker. Do you wish to save it?",
        saved: "Tracked time saved.",
        saveFailed: "Failed to save time tracked!",
        selectTask: "Select task",
        edit: "Update time",
        editDone: "Time updated.",
        deleteDone: "Time deleted!",
        table: {
            user: 'User',
            hours: 'Hours',
            created_at: 'Created at'
        },
        status: {
            404: "Given task ID or time entry not found!"
        }
    },

    chooseItems: {
        product: "Product",
        instance: "Concrete product",
        quantity: "Quantity",
        itemAlreadySelected: "This item is already selected!",
        unableToRemoveLastItem: "Unable to remove last item!",
        removeItem: "Remove item",

        scanOrChoose: "Scan any item or choose from list beneath",
        locationScanned: "You scanned a location instead of an item!",
        itemNotAvailable: "This item cannot be chosen!",
        itemQuantityExceeded: "You cannot choose more than {0} of this item!",
        itemCannotBePutOnDestination: "This item cannot be put on chosen target location!",

        items: "items",
        pieces: "pieces"
    },

    strictMode: {
        label: "Is strict",
        [TaskItemsStrictMode.FREE]: "Free",
        [TaskItemsStrictMode.NO_EXTRA]: "No extra",
        [TaskItemsStrictMode.EXACT]: "Exact",
    },

    deliveryAccept: {
        name: "Delivery arrival",
        create: "Create delivery arrival",
        done: "Delivery successfully received.",

        deliveryType: {
            [deliveryTypes.DELIVERY]: "New goods",
            [deliveryTypes.RETURN]: "Return"
        },
        items: {
            delivered: "Delivered items",
            isCustomInstance: "Is custom instance",
            isNotCustomInstance: "Is not custom instance"
        },

        [TaskItemsCardType.ASSIGNMENT]: "Delivered",

        acceptor: "Received by",
        updateDetails: "Update delivery details",
        newTask: "Associated stocking task's parameters",
        status: {
            404: notFoundOrACL
        }
    },

    stock_loading_priority: "Priority",
    stock_loading_preferred_location: "Preferred location",
    stock_loading_description: "Description",
    stock_loading_stock_id: "Warehouse",
    stock_loading_subordinate_stock_id: "Sub-warehouse",
    stock_loading_strict_mode: "Is strict",
    stockLoading: {
        name: "Stocking",
        create: "Create stocking task",
        createDone: "Stocking task created.",

        priority: "Priority",
        preferred_location: "Preferred location",
        description: "Description",
        stock_id: "Warehouse",
        subordinate_stock_id: "Sub-warehouse",
        supplier_id: "Supplier",
        delivery_number: "Delivery number",
        invoice_number: "Invoice number",
        strict_mode: "Is strict",
        itemsToLoad: "Items to load",

        scanLocation: "First scan the location you want to put items on.",
        scanSomeProducts: "Cannot finish an empty stocking task",
        assignAllLocations: "Set a target location for all items",
        chooseProductForLocation: "First select a product to assign the location to.",
        locationNotAllowed: "This location is not allowed in the target sub-warehouse!",
        notToBeLoaded: "This item is not meant to be stocked!",
        loadedLimit: "Stocking limit exceeded: {0}/{1}.",
        onlyItemsFromInventory: "When items are being moved, only those can be put on a location!",
        noItemsCanBePutOnLocation: "You cannot put any items on the scanned location! Try to take some items first.",
        itemCanNotBePutOnLocation: "This item cannot be put on this location!",

        loadTo: "Stock to",
        chooseInstanceAndHowMuch: "If you want to print barcodes, select the item and the number of copies.",
        chooseHowMuch: "If you want to print barcodes of '{0}', select how many you want.",
        rightProductWrongInstanceScanned: "You did not scan the right instance of this product.",
        lookForAnotherCode: "You did not scan the right code, try to find another one on this item.",

        [TaskItemsCardType.TO_MOVE]: "To be stocked",
        [TaskItemsCardType.IN_INVENTORY]: "Being moved",
        [TaskItemsCardType.MOVED]: "Stocked",
        [TaskItemsCardType.ASSIGNMENT]: "To be stocked",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "The specified items are only a suggestion, it is possible to stock anything.",
            [TaskItemsStrictMode.NO_EXTRA]: "It is only permitted to stock the specified items, but some of them can be missing.",
            [TaskItemsStrictMode.EXACT]: "It is only permitted to stock the specified items and their quantities must match.",
        },

        confirmDelete: {
            fromInventory: "being moved",
            fromDestination: "from location"
        },

        navigation: {
            assignment: "Assignment",
            stockLoading: "Stocking",
            finish: "Finish",
            buyPrices: "Purchase prices update"
        },
        buyPrices: {
            title: "Purchase prices",
            instance: "Product",
            manufacturer: {
                id: "Manufacturer"
            },
            measure_unit: "Unit of measurement",
            price: "Purchase price per unit",
            vat: "VAT rate",
            batchMultiply: {
                by: "Coefficient",
                action: "Multiply filtered prices",
                done: "Prices have been updated",
                failed: "Change of prices has failed!"
            },
            status: {
                404: notFoundOrACLOrItem,
                409: approve409
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Product serial number is already used elsewhere, or task is not in progress!"
            },
            update: {
                status: {
                    404: notFoundOrACLOrItem,
                    409: notActive409
                }
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Item with this serial number already exists, or location is not allowed in current sub-warehouse"
            }
        },
        status: {
            404: notFoundOrACL,
        }
    },

    stockTaking: {
        name: "Inventorying",
        create: "Create inventorying task",
        createDone: "Inventorying task created.",

        priority: "Priority",
        description: "Description",
        restrict_type: "Restriction type",
        restrict_reference_id: "Restricted to",
        stock_locations: "Location",
        stock_id: "Warehouse",
        attachments: "Attachments",

        scanLocation: "First scan the location of the product.",
        noItems: "No items to be inventoried!",

        locationHelper: {
            label: "Use the search bar to select multiple locations. Enter a search term, check if the locations are correct, then press 'Set these locations'.",
            hint: "You can search using regular expressions",
            set: "Set these locations"
        },

        navigation: {
            assignment: "Assignment",
            stockTaking: "Inventory",
            finish: "Finish"
        },

        restrictions: {
            [TaskStockTakingRestriction.NONE]: 'None',
            [TaskStockTakingRestriction.STOCK_OWNER]: 'Warehouse owner',
            [TaskStockTakingRestriction.MANUFACTURER]: 'Manufacturer',
            [TaskStockTakingRestriction.PRODUCT]: 'Product',
            [TaskStockTakingRestriction.STOCK_LOCATION]: 'Location',
            [TaskStockTakingRestriction.SUB_STOCK]: 'Sub-warehouse',
            wrongLocation: 'This location is not subject to the inventory!'
        },

        [TaskItemsCardType.PRESENT]: "Items found",
        [TaskItemsCardType.ASSIGNMENT]: "Expected",

        items: {
            partOfInventory: {
                yes: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Products of manufacturer \'{0}\'',
                    [TaskStockTakingRestriction.PRODUCT]: 'Product \'{0}\'',
                },
                no: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Products of other manufacturers',
                    [TaskStockTakingRestriction.PRODUCT]: 'Other products',
                }
            },
            status: {
                404: notFoundOrACLOrItem,
                409: notActive409
            },
        },
        status: {
            404: notFoundOrACL
        }
    },

    stockPickingSet: {
        name: "Item picking group",
        create: "Create item picking group",
        createDone: "Item picking group created.",
        stockPickingTasks: "Item picking tasks",
        usedStrategy: "Strategy used for grouping: ",
        pickingAlreadySelected: "This task has been selected already!",
        unableToRemoveLastItem: "Cannot remove last item!",
        stockPickingLocationFilled: "Target location",
        stockPickingLocationBlank: "No target location",
        itemAlreadySelected: "Duplicate item!",
        createDoneAdded: "Task added to task #{0}",
        strategies: {
            SAME_SINGLE_PRODUCT: "Identical single-item tasks",
            SINGLE_PRODUCT: "Single-item tasks",
            IDENTICAL_ORDERS: "Identical tasks",
            FROM_OLDEST: "From oldest"
        },
        delete: {
            title: "Dissolve group and remove parent task",
            status: {
                404: notFoundOrACL,
                409: "Unable to delete stock picking set due to references to other objects!"
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Item is not on location, location is not allowed in the current sub-warehouse, or the movement limit has been reached!"
            }
        },
        location: {
            status: {
                409: "Cannot move target location here because of packing in progress!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Selected item picking tasks cannot be merged."
        }
    },

    stockPicking: {
        name: "Item picking",
        create: "Create item picking task",
        createDone: "Item picking task created.",
        createDoneAdded: "Item picking added to task #{0}",

        priority: "Priority",
        description: "Description",
        stock_id: "Warehouse",
        subordinate_stock_id: "Sub-warehouse",
        tasks: "Tasks",
        shipping_type: "Handover method",
        destination_stock_location_id: "Packing location",
        destination_stock_location_id_hint: "Location where the items will be packed",
        buyer_id: "Buyer",
        buyer_delivery_address_id: "Buyer's delivery address",
        destination_location_id: "Packing location",
        partOfSet: "Part of a group",
        external_order_id: "Order",

        navigation: {
            assignment: "Assignment",
            stockPicking: "Item picking",
            finish: "Finish"
        },

        chooseTargetLocation: "Select target location!",
        scannedLocationNotEmpty: "The scanned location is not empty; are you sure you want to set it as the target location?",
        notToBeMoved: "The scanned item is not meant to be moved, or has been moved already!",
        notInInventory: "The scanned item is not being moved!",
        unableToFinishDueItems: "Cannot finish the task because not all required items have been moved yet!",
        lookForAnotherCode: "You did not scan the right instance; try to find another code on the product.",

        type: {
            label: "Handover method",
            [TaskShippingType.COURIER]: "Dispatch",
            [TaskShippingType.PERSONAL_COLLECTION]: "Local collection"
        },

        yetUnknown: "location yet unselected",
        handOverTo: "the buyer",

        autoMoveInProgress: "Automatically transferring correctly placed items.",

        itemsToPick: "Products to pick",

        removeItem: "Remove item",

        [TaskItemsCardType.TO_MOVE]: "To be picked",
        [TaskItemsCardType.IN_INVENTORY]: "Being moved",
        [TaskItemsCardType.MOVED]: "Moved",
        [TaskItemsCardType.ASSIGNMENT]: "To be picked",

        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Task is not in a state which allows the sell price to be updated!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Item is not at this location, location is not allowed in the current sub-warehouse, or the movement limit has been reached!"
            }
        },
        location: {
            status: {
                409: "Cannot move target location here because of packing in progress!"
            }
        },

        status: {
            404: notFoundOrACL,
            409: "There are not enough required items in stock."
        }
    },

    preparePackage: {
        name: "Packing",
        order: "Order",
        subordinate_stock_id: "Sub-warehouse",
        location: "Location",
        disengagePackingMode: "Back to order overview",

        navigation: {
            assignment: "Assignment",
            preparePackage: "Packing",
            finish: "Finish"
        },

        scanAnything: "Start by scanning any unpacked item.",
        scannedUnknown: "The scanned item is not part of any order!",
        scannedUnknownOrTooMuch: "The canned item is not part of any order, or at least not in this amount!",
        scannedWrongItem: "The scanned item is not part of this order!",
        scannedTooMuchItem: "Too many items for this order!",

        ean_read_required: "This sub-warehouse requires to scan all items being packed!",
        auto_packed_rest: "The order has been packed automatically!",
        haveYouPackedAllPieces: "Have all items been packed?",
        packAllPieces: "Pack {0}",

        orders: {
            label: "Orders",
            external_order_id: 'Order ID',
            external_order_author_name: 'Customer',
            external_order_note: 'Note',
            printInvoice: "Print invoice",
            printInvoiceAmount: {
                1: 'one',
                2: 'two'
            },
            invoiceCopies: "copies",
            unknown: "Unknown order!",
            cancelled: "The order has been cancelled!"
        },

        shipments: {
            carrier: {
                label: "Carrier"
            },
            lastValidated: "Last validated",
            never: "<never>",
            valid: "The shipment can be sent",
            notValid: "The shipment cannot be sent",
            notValidatedYet: "Not yet checked whether the shipment can be sent",
            errorMessages:  "Error messages",
            package_count: "Package count",
            total_value: "Total value",
            total_weight: "Total weight",
            change: {
                total_value: "Change shipment value",
                total_weight: "Change shipment weight"
            },
            print: 'Print',
            label: "label",
            deliveryNote: "delivery note",
            printLabelPending: 'Requesting shipping label ...'
        },

        items: {
            type: {
                [PreparePackageItemListTypes.TODO]: 'To be packed',
                [PreparePackageItemListTypes.DONE]: 'Packed',
                [PreparePackageItemListTypes.ALL]: 'Ordered items'
            }
        },

        packing: {
            status: {
                404: notFoundOrACL,
                409: "This product does not belong to this order, or enough of it has been packed already"
            }
        },

        status: {
            404: notFoundOrACL,
        }
    },

    moveProducts: {
        name: "Stock transfer",
        create: "Create a stock transfer task",
        createDone: "Stock transfer task created.",
        type: {
            label: "Type of relocation",
            [TaskMoveProductsType.COLLAPSE]: "Collect items from anywhere to {1}",
            [TaskMoveProductsType.DISTRIBUTE]: "Distribute items from {0} to anywhere",
            [TaskMoveProductsType.RELOCATE]: "Relocate items from {0} to {1}",
            [TaskMoveProductsType.MANYTOMANY]: "Move from anywhere to anywhere"
        },

        priority: "Priority",
        description: "Description",
        stock_id: "Warehouse",
        subordinate_stock_id: "Sub-warehouse",
        source_location_id: "Source location",
        destination_location_id: "Target location",
        transfer_mode: "Is strict",
        attachments: "Attachments",

        anyLocation: "<any location>",
        scanSourceOrDestLocation: "Please scan the correct source or target location: {0} / {1}!",
        notToBeMoved: "The scanned item is not meant to be moved, or has been moved already!",
        notInInventory: "The scanned item is not being moved!",
        unableToFinishDueItems: "Cannot finish the task, because there are items yet to be moved!",

        unableToMoveAnyToAny: "Cannot move from any location to any location. Please select at least one location.",
        unableToMoveToSame: "Cannot move to the same location. Please select a different source or target.",
        locationNotAllowed: "This location is not allowed in the target sub-warehouse!",
        notEnoughItems: "Not enough items of this product at the location!",
        noItemsCanBePutOnLocation: "You cannot move any items in this task to this location!",
        itemCanNotBePutOnLocation: "This item cannot be put on this location!",
        alreadyUsedAsDestination: "An item has already been placed on this location! Return it from already moved.",
        alreadyUsedAsSource: "The item has been collected from this location!",

        [TaskItemsCardType.TO_MOVE]: "To be moved",
        [TaskItemsCardType.IN_INVENTORY]: "Being moved",
        [TaskItemsCardType.MOVED]: "Moved",
        [TaskItemsCardType.ASSIGNMENT]: "To be moved",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Items are only a suggestion, it is possible to move anything anywhere.",
            [TaskItemsStrictMode.NO_EXTRA]: "It is only possible to move entered items, some can be omitted or have lower quantity.",
            [TaskItemsStrictMode.EXACT]: "It is only possible to move entered items, everything must be moved.",
        },

        navigation: {
            assignment: "Assignment",
            moveProducts: "Stock transfer",
            finish: "Finish"
        },

        items: {
            productsToMove: "Products to move",
            status: {
                404: notFoundOrACLOrItem,
                409: "Unable to add new item to product movement!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Item is not at this location, this location is not allowed in the current sub-warehouse, or the movement limit has been reached!"
            }
        },
        finish: {
            status: {
                404: notFoundOrACL,
                409: "Task is not in progress or items are still being moved!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "There are not enough of the required items either at the source location or in the sub-warehouse.",
        }
    },

    locationTransfer: {
        name: "Location transfer",
        create: "Create location transfer task",
        createDone: "Location transfer task created.",

        priority: "Priority",
        description: "Description",
        stock_id: "Warehouse",
        allow_filled_locations: "Allow transfers to non-empty locations",
        attachments: "Attachments",

        scanLocation: "Scan location! In location transfer, separate items can not be scanned.",
        scanningTo: "Scan location to move the items to.",

        canNotUseSame: "Already chosen as a source!",
        canNotUseNotEmpty: "Locations is not empty!",

        confirmTitle: "Confirm movement of {0} items ({1} pcs) from '{2}' to '{3}'",
        confirmWithReader: "You can also scan '{0}' again to confirm.",
        confirmButton: "Confirm",

        moved: "Moved",

        navigation: {
            assignment: "Assignment",
            locationTransfer: "Locations transfer",
            finish: "Finish"
        },

        status: {
            404: notFoundOrACL,
        }
    },

    substockTransfer: {
        name: "Sub-warehouse transfer",
        create: "Create a stock transfer task",
        createDone: "Stock transfer task created.",

        priority: "Priority",
        description: "Description",
        stock_id: "Warehouse",
        source_subordinate_stock_id: "Source sub-warehouse",
        destination_subordinate_stock_id: "Target sub-warehouse",
        attachments: "Attachments",

        unableToMoveToSame: "Cannot move to the same sub-warehouse. Please select a different source or target.",
        notEnoughItems: "Not enough items of this product in this sub-warehouse!",
        noItemsCanBePutOnLocation: "You cannot move any items in this task to this location!",
        itemCanNotBePutOnLocation: "This item cannot be put on this location!",

        from: "Move from",
        to: "Move to",

        notToBeMoved: "The scanned item is not meant to be moved, or has been moved already!",
        notInInventory: "The scanned item is not being moved!",

        autoMoveInProgress: "Automatically transferring correctly placed items.",

        [TaskItemsCardType.TO_MOVE]: "To be moved",
        [TaskItemsCardType.IN_INVENTORY]: "Being moved",
        [TaskItemsCardType.MOVED]: "Moved",
        [TaskItemsCardType.ASSIGNMENT]: "To be moved",

        isAutocompleted: "This task has been completed automatically due to both sub-warehouses sharing locations.",

        navigation: {
            assignment: "Assignment",
            transferProducts: "Stock transfer",
            finish: "Finish"
        },

        items: {
            productsToMove: "Products to move",
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Item is not at this location, this location is not allowed in the current sub-warehouse, or the movement limit has been reached!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "The source and target locations are incorrect or there are not enough of the required items either at the source location or in the sub-warehouse."
        }
    },

    externalOrder: {
        name: "External order",
        id: 'Order ID',
        subordinate_stock_id: "Sub-warehouse",
        carrier: "Carrier",
        buyer_id: "Buyer",
        quantity: "Ordered quantity",
        product: {
            name: "Product"
        },
        stock_picking_task_ids: "Item picking tasks",
        prepare_shipment_package_task_ids: "Packing tasks",
        shipment_ids: "Shipments",
        selectedInstance: "Item to pick",
        default: "<default>",
        externalFields: {
            label: "Order parameters",
            author_id: "Author ID",
            author_name: "Author name",
            id: "ID",
            note: "Note",
            order_created_at: "Created",
            order_id: "Number"
        },
        searchHint: "Searches through task ID and external order ID",

        state: {
            label: "Order state",
            [ExternalOrderState.EO_STATE_CREATED]: "Order is new or waiting for approval",
            [ExternalOrderState.EO_STATE_UNABLE_TO_PROCESS]: "There are carrier API errors",
            [ExternalOrderState.EO_STATE_UNABLE_TO_SATISFY]: "Cannot be fulfilled due to out-of-stock items",
            [ExternalOrderState.EO_STATE_IN_PROGRESS]: "Being worked on",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING]: "Waiting to be picked",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS]: "Being picked",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING]: "Waiting to be packed",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS]: "Being packed",
            [ExternalOrderState.EO_STATE_SHIPMENTS_WAITING]: "Some shipments are waiting for handover",
            [ExternalOrderState.EO_STATE_SHIPPED]: "Some packages have been shipped",
            [ExternalOrderState.EO_STATE_DELIVERED]: "Some shipments have been delivered",
            [ExternalOrderState.EO_STATE_RETURNING]: "Some shipments are returning",
            [ExternalOrderState.EO_STATE_RETURNED]: "Some shipments have returned",
            [ExternalOrderState.EO_STATE_CANCELLED]: "Cancelled",
            [ExternalOrderState.EO_STATE_CLOSED]: "Closed"
        },

        processingMode: {
            label: "Order processing mode",
            [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Process together",
            [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Process available",
            [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Do not process",

            hint: {
                [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Waits for all ordered items to be in stock.",
                [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "As soon as some items are in stock new stock picking will be created.",
                [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Order will not be processed, no stock picking will be created."
            }
        },

        createStockPicking: "New stock picking task details",

        navigation: {
            assignment: "Assignment",
            order: "Order",
            finish: "Finish"
        },

        update: {
            status: {
                409: "Only external order that is in state 'New' can be changed!"
            }
        },

        approve: {
            status: {
                404: notFoundOrACL,
                409: "Task is not in a state to be approved, or the required quantity exceeds stocked quantity!"
            }
        },

        close: {
            label: "Close order",
            note: "Note",
            shipment_state: "New state of shipments",
            done: "#{0} {1} closed, shipments set to '{2}'"
        },

        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Task is not in a state which allows removing an item!"
            }
        },

        show: {
            label: "External orders of buyer"
        }
    },

    itemsCard: {
        canBeFoundAt: "Item can be found at:",
        canBePutOn: "Item can be put on:",
        alreadyPlacedAt: "Item already placed at:",
        pickedUpFrom: "Item has been taken from:",

        updateQuantity: {
            notEnoughItemsInInventory: "You cannot put more items on a location than are being moved!",
            notEnoughItemsOnSource: "There are not enough items to move on source location!",
            itemNotPickedFromThisLocation: "Item was not picked from this location!",
            notSoManyItemsPickedFromSource: "Not so many items were picked from source location!",
            destinationLimit: "Movement limit exceeded: {0}/{1}.",
        },

        returnAmount: "Return {0} {1}",
        returnWhere: "to {0}",
        returnToInventory: "to being moved",
        returnTo: {
            location: "Return to the original location",
            inventory: "Return to being moved"
        },
        remove: "Cancel",

        from: "from",
        fromAnywhere: "from anywhere",
        to: "to",
        toAnywhere: "anywhere",
        substockLocations: "Target locations",

        scanLocation: "First scan a location.",
        scanPackingLocation: "First scan the {0} packing location",
        scanOrderItem: "Scan any item",
        scanLocationOrItem: "First scan a location or an item.",
        scanningFrom: "Scan an item to pick it up",
        scanningTo: "Scan an item to place it",
        scanningOrder: "Scan the next item from order #{0}",
        scannedExpeditionLocation: "Do you really want to scan a packing location?",
        scannedMobileLocation: "Do you really want to scan a mobile location?",
        scanConfirm: "Scan {0}",
        cancelByScanning: "Scan another code",
        confirmByScanning: "Scan {0} one more time",

        moreDetails: "More details",
        changeTargetLocation: "Change target location",
        cannotChangeTargetLocation: "Unable to change target location; pick up items from the current location first!",

        moveEverything: "Move possible to ",
        moveEverythingInProgress: "Moving all allowed items to the target location.",
    },

    locationsCard: {
        canBePutOn: "Items that can be put on this location:",
        cannotBePutOnThisLocation: "This item cannot be put on this location!",
        cannotBePutOnAnyLocation: "This item cannot be put on any location in this task!",
        reloadAllowedLocations: "Reload cache"
    },

    itemPick: {
        inStock: "in stock",
        inProgress: "blocked by tasks",
        available: "available"
    },

    unableToLoad: "Unable to load task list. Please try again later.",

    approve: {
        name: "Approve",
        done: "#{0} {1} approved",
        doneTotal: "#{0} {1} finished",
        status: {
            404: notFoundOrACL,
            409: approve409,
            423: memberOfSet
        }
    },
    cancel: {
        name: "Cancel",
        done: "#{0} {1} canceled",
        status: {
            404: notFoundOrACL,
            409: "Task is not in a state from which it can be canceled!"
        }
    },
    reject: {
        name: "Reject",
        done: "#{0} {1} rejected",
        status: {
            404: notFoundOrACL,
            409: reject409,
            423: memberOfSet
        }
    },
    assign: {
        name: "Assign",
        self: "Start work on task",
        notAssignable: "This item picking task cannot be worked on by itself because it is part of a group!",
        useReader: "You can also start by scanning any code with the barcode reader",
        done: "#{0} {1} assigned",
        status: {
            404: notFoundOrACL,
            409: "Task has been assigned already!",
            423: memberOfSet
        }
    },
    unassign: {
        name: "Unassign",
        disabled: "Unable to unassign task while some items are being moved!",
        done: "#{0} {1} unassigned",
        status: {
            404: "Task not found!",
            409: "This task is not assigned to you!",
            423: "Task cannot be unassigned because some items are being moved!"
        }
    },
    finish: {
        name: "Submit for approval",
        disabled: "Unable to complete task because some items are in forbidden locations.",
        done: "#{0} {1} finished, submitted.",
        doneTotal: "#{0} {1} finished",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    handOver: {
        name: "Confirm handover to buyer",
        done: "#{0} {1} finished, handed over.",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    finishAndApprove: "Complete",
    finishAndApproveHint: "This action is available because you are both a worker and a manager.",
    movementsOverview: {
        title: "Task movement overview",
        sourceSubstock: "Source sub-warehouse",
        destinationSubstock: "Target sub-warehouse",
    },

    status: {
        404: "Task ID not found!",
        409: "Task has not been finished yet!",
        423: memberOfSet
    }
};
