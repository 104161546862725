<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('products.overview.name') }}
      </v-card-title>
    </v-card>
    <template v-if="!loading">
      <masonry
        :cols="$vuetify.breakpoint.lgAndUp ? 2 : 1"
        :gutter="0"
      >
        <div class="pa-1">
          <v-card outlined>
            <v-card-title
              v-if="stocksWithItem.length !== 0"
              class="body-1"
            >
              {{ $t('products.overview.inStock') }}
            </v-card-title>
            <v-card-title
              v-else
              class="body-1"
            >
              {{ $t('products.overview.notInStock') }}
            </v-card-title>
            <v-card
              v-for="stock in stocksWithItem"
              :key="stock.id"
              class="ma-2"
            >
              <v-card-title class="body-1">
                <span class="font-weight-bold mr-1">{{ stock.pieces }}</span>&nbsp;
                <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                &nbsp;{{ stock.name }}
              </v-card-title>
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="substock in stock.substocksWithItems"
                    :key="'s' + substock.id"
                  >
                    <v-expansion-panel-header>
                      <span>
                        <template v-if="stock.substocksWithItems.length !== 1">
                          <span>
                            <span class="font-weight-bold">{{ substock.quantity_in_stock }}</span>&nbsp;
                            <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                          </span>
                        </template>
                        {{ substock.name }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-progress-linear 
                        v-if="instancesLoading" 
                        indeterminate
                      />
                      <ProductStockStatus
                        v-else
                        :product="product"
                        :stock-id="stock.id"
                        :sub-stock-id="substock.id"
                        :instances="instances"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
        <div
          v-for="overview in basicOverviews"
          :key="overview.label"
          class="pa-1"
        >
          <v-card
            outlined
          >
            <v-card-title
              v-if="overview.stocks.length !== 0"
              class="body-1"
            >
              {{ $t('products.overview.' + overview.label) }}
            </v-card-title>
            <v-card-title
              v-else
              class="body-1"
            >
              {{ $t('products.overview.not' + overview.label) }}
            </v-card-title>
            <v-card
              v-for="stock in overview.stocks"
              :key="overview.label + stock.id"
              class="ma-2"
            >
              <v-card-title class="body-1">
                <span class="font-weight-bold mr-1">{{ overview.getQuantity(stock) }}</span>&nbsp;
                <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                &nbsp;{{ stock.name }}
              </v-card-title>
              <v-card-text>
                <v-list class="mt-n4">
                  <template
                    v-for="(substock, index) in overview.getSubstocks(stock)"
                  >
                    <v-list-item
                      :key="'s' + overview.label + substock.id"
                      class="row--dense"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="overview.getSubstocks(stock).length !== 1">
                            <span class="font-weight-bold">{{ overview.getQuantity(substock) }}</span>&nbsp;
                            <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                          </span>
                          <span>
                            {{ substock.name }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="overview.showMovements">
                        <v-btn
                          :to="movementsLink(stock.id, substock.id)"
                          outlined
                        >
                          <v-icon class="mr-2">
                            $stockMovements
                          </v-icon>
                          <span v-show="$vuetify.breakpoint.mdAndUp">
                            {{ $t('products.pieces.movements') }}
                          </span>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index !== overview.getSubstocks(stock).length - 1"
                      :key="'sD' + substock.id"
                    />
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
      </masonry>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import ProductStockStatus from "@/app/products/components/ProductStockStatus";
    import {ProductAPI} from "@/api/ProductAPI";

    export default {
        name: "ProductOverview",
        components: {ProductStockStatus},
        props: {
            product: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            stocks: [],
            loading: false,
            instances: [],
            instancesLoading: false
        }),
        computed: {
            basicOverviews: function () {
                return [
                    {
                        label: 'InTasks',
                        stocks: this.stocksWithReservedItem,
                        getSubstocks: (stock) => stock.substocksWithReservedItem,
                        getQuantity: (item) => item.quantity_reserved,
                        showMovements: true
                    },
                    {
                        label: 'Ordered',
                        stocks: this.stocksWithOrderedItem,
                        getSubstocks: (stock) => stock.substocksWithOrderedItem,
                        getQuantity: (item) => item.quantity_ordered,
                    }
                ];
            },
            stocksWithItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithItems = stock.substocks.filter(substock => substock.quantity_in_stock);
                        stock.pieces = stock.substocksWithItems.map(substock => substock.quantity_in_stock).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.pieces = 0;
                    }
                    return stock;
                }).filter(stock => stock.pieces);
            },
            stocksWithReservedItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithReservedItem = stock.substocks.filter(substock => substock.quantity_reserved);
                        stock.quantity_reserved = stock.substocksWithReservedItem.map(substock => substock.quantity_reserved).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.quantity_reserved = 0;
                    }
                    return stock;
                }).filter(stock => stock.quantity_reserved);
            },
            stocksWithOrderedItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithOrderedItem = stock.substocks.filter(substock => substock.quantity_ordered);
                        stock.quantity_ordered = stock.substocksWithOrderedItem.map(substock => substock.quantity_ordered).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.quantity_ordered = 0;
                    }
                    return stock;
                }).filter(stock => stock.quantity_ordered);
            }
        },
        createdOrActivated: function () {
            this.fetchStocks();
            this.instancesLoading = true;
            ProductAPI.getAllInstancesAllPages(this.product.id).then(response => {
                this.instances = response.data.items;
            }).catch(this.snack).finally(() => this.instancesLoading = false);
        },
        methods: {
            fetchStocks: function () {
                this.loading = true;
                const substockPromises = [];
                const substockStatusPromises = [];
                StockAPI.getAllPages().then(response => {
                    this.stocks = response.data.items;
                    const stockIds = response.data.items.map(stock => stock.id);
                    let stockStatusPromise = Promise.resolve();
                    if (stockIds.length !== 0) {
                        stockStatusPromise = StockStatusAPI.getShortAllPages({
                            filter: APIFilters.makeFilter([
                                {
                                    [APIFilterOP.EQUALS]: {
                                        'product.id': this.product.id
                                    }
                                },
                                {
                                    [APIFilterOP.GREATER_THAN]: {
                                        'quantity': 0
                                    }
                                },
                                {
                                    [APIFilterOP.OR]: [
                                        ...stockIds.map(id => ({[APIFilterOP.EQUALS]: {'stock.id': id}}))
                                    ]
                                }
                            ])
                        });   
                    }
                    this.stocks.map(stock => {
                        substockPromises.push(
                            StockAPI.getAllSubstockPages(stock.id).then(response => {
                                stock.substocks = response.data.items;
                                const subStockIds = response.data.items.map(substock => substock.id);
                                if (subStockIds.length !== 0) {
                                    const reservationPromise = StockStatusAPI.getCurrentReservationsWithOrders({
                                        filter: APIFilters.makeFilter([
                                            {
                                                [APIFilterOP.EQUALS]: {
                                                    'product_instance.product_id': this.product.id
                                                }
                                            }, {
                                                [APIFilterOP.OR]: [
                                                    ...subStockIds.map(id => ({[APIFilterOP.EQUALS]: {'substock_id': id}}))
                                                ]
                                            }
                                        ])
                                    });
                                    substockStatusPromises.push(
                                        Promise.all([reservationPromise, stockStatusPromise])
                                            .then(responses => {
                                                const reservationResponse = responses[0];
                                                const stockStatusResponse = responses[1];
                                                stock.substocks.map(substock => {
                                                    const substockId = substock.id;
                                                    const substockReservations = reservationResponse.data.items.filter(instanceInfo => instanceInfo.substock_id === substockId);
                                                    const substockStatuses = stockStatusResponse.data.items.filter(locationInfo => locationInfo.substock_id === substockId);
                                                    if (substockReservations.length) {
                                                        substock.quantity_ordered = substockReservations.reduce((acc, cur) => acc + cur.quantity_ordered, 0);
                                                        substock.quantity_reserved = substockReservations.reduce((acc, cur) => acc + cur.quantity_reserved, 0);
                                                    }
                                                    if (substockStatuses.length) {
                                                        substock.quantity_in_stock = substockStatuses.reduce((acc, cur) => acc + cur.quantity, 0);
                                                    }
                                                });
                                            })
                                    );
                                }
                                return stock;
                            }));
                    });
                    Promise.all(substockPromises).then(() =>
                        Promise.all(substockStatusPromises).catch(this.snack).finally(() => this.loading = false)
                    ).catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
                }).catch(err => {
                    this.snack(err);
                    this.loading = false;
                });
            },
            movementsLink: function (stockId, subStockId) {
                return '/movements?stockId=' + stockId + '&subStockId=' + subStockId + '&productId=' + this.product.id;
            }
        }
    };
</script>

<style scoped>

</style>
