import {TableFilter} from "@/enum/table_filter";

const productInstanceTable = {
    type: {
        label: 'type.name',
        filterType: TableFilter.TEXT,
        sortable: false
    }
};

const productSeriesTable = {
    serial_number: {
        filterType: TableFilter.TEXT
    }
};

const productBatchesTable = {
    code: {
        filterType: TableFilter.TEXT,
        label: 'batch_code'
    },
    manufactured_date: {
        filterType: TableFilter.DATETIME,
    },
    expire_date: {
        filterType: TableFilter.DATETIME
    }
};

export {productInstanceTable, productSeriesTable, productBatchesTable};
