<template>
  <v-card
    v-show="attachments.length > 0"
    :class="{'ma-1': !inline}"
    :flat="inline"
  >
    <v-container
      :class="{'ma-0 pa-0': inline}"
    >
      <div>
        <span class="text-caption">{{ $t('tasks.attachments.name') + ':' }}</span>
        <v-chip
          v-for="(attachment, index) of attachments"
          :key="attachment.id"
          class="ml-2"
          @click="download(index)"
        >
          <v-icon
            v-show="downloaded[index] === undefined"
            small
            class="mr-2"
          >
            $attachment
          </v-icon>
          <v-icon
            v-show="downloaded[index] === true"
            small
            class="mr-2"
          >
            $taskAttachmentDownloaded
          </v-icon>
          {{ attachment.file_name }}
        </v-chip>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import * as Export from "@/service/Export";

    export default {
        name: "TaskAttachments",
        props: {
            attachments: {
                type: Array,
                default: () => []
            },
            inline: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            downloaded: []
        }),
        methods: {
            download: function (index) {
                Export.url(this.attachments[index].download_link, this.attachments[index].file_name);
                this.$set(this.downloaded, index, true);
            }
        }
    };
    // TODO display supported attachments in-place
</script>

<style scoped>

</style>
