import i18n from "@/service/lang/i18n";
import {getPrintTypeItems, PrintType} from "@/enum/print_type";

function CarrierServiceForm() {
    return {
        name: '',
        required_carrier_service_parameters: [],
        optional_carrier_service_parameters: [],
        type: PrintType.ZPL
    };
}

function ParametersRender() {
    return {
        icon: '$carrierServiceParameters',
        autocomplete: {
            items: [
                'authentication',
                'authenticationNote',
                'branchId',
                'cod',
                'currency',
                'eshop',
                'invoiceLink',
                'swap',
                'swapNote',
                'swapCount',
                'value',
                'variableSymbol',
                'weight',
                {header: '... ' + i18n.t('base.orWriteYourOwn')}
            ],
            multiple: true,
            allowCustom: true,
            chips: true
        }
    };
}

function CarrierServiceFormRender() {
    return {
        name: {
            icon: '$carrierServiceName',
            max: 50,
            required: true
        },
        required_carrier_service_parameters: new ParametersRender,
        optional_carrier_service_parameters: new ParametersRender,
        type: {
            icon: '$barcodePrintFormat',
            autocomplete: {
                items: getPrintTypeItems()
            },
            required: true
        }
    };
}

const carrierServiceTable = {
    name: {},
    required_carrier_service_parameters: {},
    optional_carrier_service_parameters: {},
    type: {}
};

export {CarrierServiceForm, CarrierServiceFormRender, carrierServiceTable};
