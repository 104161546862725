import {PrintType} from "@/enum/print_type";
import {ShipmentState} from "@/enum/shipment_state";
import {APIFilterOP} from "@/service/APIFilters";

export default {
    and: "and",
    or: "or",
    close: "Close",
    undo: "Undo",
    open: "Open",
    reset: "Reset",
    loading: "Loading",
    cancelLoading: "Cancel and return to dashboard",
    cancelLoadingHint: "Loading may or may not complete in the background!",
    home: "Home",
    notFound: "Not found",
    pageNotFound: "Page not found",
    darkMode: "Dark mode",
    productLabels: "Item labels",
    productModel: {
        0: "Name",
        1: "Model"
    },
    showProductImagesInTasks: "Show product images in tasks",
    pickFromMobileLocation: "Suggest item collection from mobile locations",
    automagicallyChooseInstance: {
        label: "Automagically choose product instance",
        hint: "For which to print label, when user scans wrong product instance."
    },
    onBeep: "When beeped",
    search: "Search",
    searchAll: "Search all",
    searchResult: {
        searchesIn: "Searches through tasks (ID, external ID, state, priority and type), products (manufacturer, name and model), warehouses (name), persons (ID) and barcodes.",
        tasksById: "Tasks",
        tasksByExternalId: "Tasks where external ID is {0}",
        tasksByState: "Tasks where state is {0}",
        tasksByPriority: "Tasks with {0} priority",
        tasksByType: "{0} tasks",
        productsByBarcode: "Product barcode",
        productsByManufacturer: "Products from {0}",
        productsByName: "Products containing '{0}' in name",
        productsByModel: "Products where model is {0}",
        stocksByName: "Warehouses containing '{0}' in name",
        locationsByBarcode: "Location barcode",
        buyersByIco: "Buyer",
        suppliersByIco: "Supplier",
        stockOwnersByIco: "Sub-warehouse owner"
    },
    advancedFilter: "Advanced filters",
    filterBy: "Filter by",
    orWriteYourOwn: "or write your own",
    noIco: "No ID",
    actions: "Actions",
    nothing: "Nothing",
    no: "No",
    yes: "Yes",
    of: 'of',
    pcs: 'pcs',
    view: "View",
    hide: "Hide",
    showAll: "Show all",
    hideAll: "Hide all",
    edit: "Edit",
    delete: "Delete",
    confirmDelete: "Are you sure?",
    reload: "Reload",
    save: "Save",
    saved: "Saved",
    notSave: "Do not save",
    cancel: "Cancel",
    clickToCopy: "Click to copy",
    copyToClipboard: "Copy to clipboard",
    copyToClipboardDone: "'{0}' copied!",
    copyToClipboardFail: "Failed to copy!",
    goBack: "Back to the previous page",
    goUp: "Back up a level",
    lang: "Language",
    help: {
        title: "Help"
    },
    sort: {
        DESC: 'descending',
        ASC: 'ascending'
    },
    cache: {
        title: "Cache manager",
        contents: "Cache contents",
        length: "Amount",
        size: "Size",
        clear: "Clear",
        clearAll: "Clear all",
        taskDetails: "Task details",
        barcodes: "Barcodes",
        buyers: "Buyers",
        locations: "Locations",
        allowedLocationIds: "Permitted locations of items",
        instances: "Product details",
        products: "Products",
        instanceBarcodes: "Product barcodes",
        instanceTypes: "Custom instances",
        users: "Users"
    },
    login: "Log in",
    continueAs: "Continue as",
    loginOther: "Log someone else in",
    logout: "Log out",
    version: "Version",
    offline: "No connection to the server!",
    downloadCsv: "Download CSV",
    uploadAttachment: "Upload attachment",
    filter: {
        [APIFilterOP.AND]: 'and',
        [APIFilterOP.OR]: 'or',
        [APIFilterOP.IS_NULL]: '{0} is empty',
        [APIFilterOP.IS_NOT_NULL]: '{0} is not empty',
        [APIFilterOP.ARRAY_CONTAINS]: 'list {0} contains {1}',
        [APIFilterOP.ARRAY_NOT_CONTAINS]: 'list {0} does not contain {1}',
        [APIFilterOP.ARRAY_EMPTY]: 'list {0} is empty',
        [APIFilterOP.ARRAY_NOT_EMPTY]: 'list {0} is not empty',
        [APIFilterOP.LIKE]: '{0} includes {1}',
        [APIFilterOP.NOT_LIKE]: '{0} does not include {1}',
        [APIFilterOP.EQUALS]: '{0} is {1}',
        [APIFilterOP.NOT_EQUALS]: '{0} is not {1}',
        [APIFilterOP.GREATER_THAN]: '{0} is greater than {1}',
        [APIFilterOP.LOWER_THAN]: '{0} is lower than {1}',
        [APIFilterOP.GREATER_THAN_OR_EQUAL]: '{0} is greater than or equal to {1}',
        [APIFilterOP.LOWER_THAN_OR_EQUAL]: '{0} is lower than or equal to {1}',
        [APIFilterOP.BETWEEN]: '{0} is between {1} and {2}',
        [APIFilterOP.IN]: '{0} is one of: {1}',
        [APIFilterOP.NOT_IN]: '{0} is not one of: {1}',
    },
    filterConfig: {
        filter: "Filter",
        invalid: "Filter is not valid!",
        sort: "Sort by",
        condition: "Condition",
        addCondition: "Add condition",
        splitCondition: "Branch out condition",
        removeCondition: "Delete condition",
        nestedGroup: "Nested conditions",
        nonNested: "Direct conditions",
        addSort: "Add sort",
        removeSort: "Delete sort",
        sortThen: "then by",
        fill: "Fill"
    },
    table: {
        batchActions: "Batch actions",
        removeSelection: "Remove selection",
        batchActionDisabledByFulltext: "Disabled when fulltext search used. Use advanced filters.",
        batchAllFiltered: "All filtered",
        batchAllSelected: "All checked",
        filter: {
            chooseAttr: '' // intentionally blank
        }
    },
    shipping: {
        link: 'Shipments',
        title: 'Shipments',
        optional: "Optional filters",
        table: {
            state: "State",
            external_order_external_order_id: 'External ID of external order',
            external_order_id: "Order",
            stock_picking_id: "Item picking task",
            buyer: {
                id: "Buyer",
                name: "Buyer",
                email: "E-mail",
                phone: "Phone"
            },
            subordinate_stock: {
                id: "Sub-warehouse",
                chooseStockFirst: "Pick a warehouse first"
            },
            carrier: {
                id: "Carrier"
            },
            last_validated: "Checked",
            carrier_api_error_messages: "Carrier errors",
            external_order_created_at: 'Order created',
            created_at: 'Shipment created',
            label_first_printed_at: "First label printed",
            last_protocol_created_at: "Last protocol created at",
            sent_date: "Sent",
            total_value: "Price",
            total_weight: "Weight",
            package_count: "Packages",
            searchHint: "Searches through carrier, buyer, e-mail, phone and external ID of external order"
        },
        shipment: {
            show: "Show shipment",
            printLabel: "Print shipping label",
            printDetail: {
                label: "Print shipment report",
                hint: "Pick a sub-warehouse and a carrier first."
            },
            track: "Track shipment",
            state: {
                [ShipmentState.CREATED]: "New",
                [ShipmentState.WAITING_FOR_PICKUP]: "Waiting for dispatch",
                [ShipmentState.SENT]: "Sent",
                [ShipmentState.BEING_DELIVERED]: "Being delivered",
                [ShipmentState.DELIVERED_TO_PICKUP_LOCATION]: "Delivered to pick-up location",
                [ShipmentState.DELIVERED]: "Delivered",
                [ShipmentState.RETURNING]: "Returning",
                [ShipmentState.RETURNED]: "Returned",
                [ShipmentState.LOST]: "Lost",
                [ShipmentState.TO_BE_CANCELLED]: "Being cancelled",
                [ShipmentState.CANCELLED]: "Cancelled"
            }
        },
        report: {
            link: 'Shipment report',
            title: 'Shipment report',
            table: {
                external_order_task_id: 'Order',
                external_order_states: 'Order state',
                order_id: 'External ID',
                buyer_name: 'Buyer',
                shipment_state: 'Shipment state',
                eshop_created_at: 'Retailer created',
                wms_created_at: 'Warehouse accepted',
                work_started_at: 'Work started',
                work_ended_at: 'Work ended',
                carrier_accepted_at: 'Carrier received',
                carrier_delivered_at: 'Delivered',
                searchHint: "Searches through order ID, external ID and buyer"
            }
        },
        handover: {
            link: 'Handover of shipments',
            title: 'Handover of shipments',

            form: {
                carrierId: "Select carrier",
                stockId: "Select warehouse",
                subStockId: "Only sub-warehouse ...",
                selectShipments: "Select shipments",
            },

            table: {
                created_at: "Created at",
                substock: {
                    id: "Sub-warehouse",
                },
                shipment: {
                    ids: "Number of shipments",
                    detail: "Detail"
                },
                issueProtocol: "Hand over shipments",
                notYetIssued: "Not yet issued"
            },

            handOverAll: "Hand over all shipments ({0})",
            reload: "Reload waiting shipments",

            create: {
                title: "Issue individual protocol",

                form: {
                    carrierId: "Select carrier",
                    stockId: "Select warehouse",
                    subStockId: "Select sub-warehouse",
                    selectShipments: "Select shipments",
                    noShipmentSelect: "Otherwise will print protocol for all shipments that are waiting to be picked",
                },
            },

            printProtocol: "Print protocol",
            noStockShipmentsWaiting: "There are no shipments in selected warehouse waiting to be picked by selected carrier.",
            noShipmentsWithoutProtocol: "There is no waiting shipment which is not already included in a protocol." +
                "To create another protocol for such shipment you have to choose it explicitly."
        }
    },
    images: {
        name: "Image",
        plural: "Images",
        url: "URL",
        main: "Primary image",
        setMain: "Set as primary",
        type: {
            name: "Type",
            local: "local",
            localLabel: "Upload image",
            external: "external",
            externalLabel: "Attach image from URL"
        },
        create: {
            titleSimple: "Add image",
            title: "Add image to '{0}'",
            done: "Image added to '{0}'",
            unableToLoad: "Unable to load image"
        },
        update: {
            titleSimple: "Update image",
            title: "Update image of '{0}'",
            done: "Image of '{0}' updated"
        },
        delete: {
            done: "Image removed",
            failed: "Unable to remove image. Please try again later."
        },
        store: {
            title: "Store",
            hint_keep_link: "Only link to image will be stored",
            hint_store_link: "Image will be stored locally",
            done: "External image downloaded and stored locally"
        }
    },
    print: {
        sent: "Sent to the printer!",
        noConfigFor: "No print configuration for {0}!"
    },
    list: {
        loadMore: "Load more",
        loadingMore: "Loading more",
        unableToLoad: "Unable to load list. Please try again."
    },
    api: {
        401: "User is not logged in!",
        403: "You do not have access to this feature!",
        405: "This action is not allowed at the moment!",
        412: "This item was updated by another user in the meantime. Please try again.",
        unexpectedError: "An unexpected error occurred. Please try again later.",
        ares: {
            status: {
                404: "Subject with the given ID was not found!"
            }
        },
        barcodes: {
            unknown: "Unknown code!",
            status: {
                404: "Barcode not found!"
            }
        },
        shipments: {
            packages: {
                status: {
                    404: "Shipment or package not found!",
                    409: "Cannot remove package from an already sent shipment!"
                }
            },
            protocols: {
                create: {
                    status: {
                        404: "Carrier , sub-warehouse or one of the shipments does not exist!",
                        409: "One of the shipments is not in state 'Waiting for dispatch' or does not have delivery number assigned."
                    }
                },
                getAll: {
                    status: {
                        404: "Contracted carrier or sub-warehouse does not exist!"
                    }
                },
                status: {
                    404: "Shipment protocol not found!",
                    423: "Shipment protocol is not ready yet, please try again later.",
                }
            },
            status: {
                404: "Shipment not found!",
                423: "The shipping label is not ready yet, please try again later.",
                409: "The shipment is not sendable yet!"
            }
        },
        shipmentCarrier: {
            contracted: {
                all: {
                    status: {
                        404: "Sub-warehouse owner not found!"
                    }
                },

                status: {
                    404: "Carrier or sub-warehouse owner not found!",
                    409: "Unable to remove carrier from list!"
                }
            },

            status: {
                404: "Carrier not found!"
            }
        }
    },
    address: {
        street: "Street",
        house_number: "House number",
        suburb: "Address details",
        city: "City",
        postal_code: "Postal code",
        phone: "Phone",
        email: "E-mail",
        country: "Country (localized)",
        country_iso_code: "Country (English)"
    },
    bank_account: {
        prefix_number: "Prefix",
        account_number: "Account number",
        bank_code: "Bank code",
        full_number: "Account number w/ bank code",
        iban: "IBAN",
        swift: "SWIFT"
    },
    changes: {
        created_at: "Created at",
        created_by: "Created by",
        updated_at: "Last edited",
        updated_by: "Edited by",
        noUpdates: "No further edits"
    },
    ares: {
        load: "Load ARES data",
        validated: "Validated in ARES",
        not_validated: "Not validated in ARES"
    },
    print_type: {
        [PrintType.PDF]: "PDF",
        [PrintType.ZPL]: "ZPL",
        [PrintType.ZPLX]: "ZPLX",
        [PrintType.ZPLX2]: "ZPLX2",
        [PrintType.ESCP]: "ESCP",
        [PrintType.HTML]: "HTML"
    }
};
