<template>
  <div class="mt-2 d-flex">
    <v-text-field
      v-model="itemPrice"
      class="priceInput"
      dense
      hide-details
      :label="priceLabel"
      outlined
      type="number"
    />
    <v-select
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        prices[item.id].vat
      "
      class="vatSelect ml-1"
      dense
      hide-details
      :items="vatRateItems"
      :label="$t('products.price_vat')"
      outlined
      @change="priceChanged = true"
    />
    <v-btn
      :color="priceChanged ? 'accent' : 'default'"
      class="ml-2"
      @click="updatePrice"
    >
      <v-icon>
        $saveItem
      </v-icon>
      <span
        v-show="priceChanged && $vuetify.breakpoint.smAndUp"
        class="ml-1"
      >
        {{ $t('base.save') }}
      </span>
    </v-btn>
  </div>
</template>

<script>
    import {vatRateItems} from "@/app/products/definitions/product.form";
    import {taskTypes} from "@/enum/task_type";
    import {EventBus} from "@/service/EventBus";

    export default {
        name: "TaskItemDetailPrice",
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            taskType: {
                type: String,
                default: () => ''
            }
        },
        data: () => ({
            priceChanged: false,
            vatRateItems: vatRateItems,
            triggerPrice: 0
        }),
        computed: {
            itemPrice: {
                get: function () {
                    this.triggerPrice;
                    return this.prices[this.item.id].price;
                },
                set: function (newValue) {
                    if (newValue === null || newValue === "") {
                        const oldValue = this.prices[this.item.id].price;
                        // eslint-disable-next-line vue/no-mutating-props
                        this.prices[this.item.id].price = 0;
                        this.triggerPrice++;
                        this.$nextTick(() => {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.prices[this.item.id].price = oldValue;
                            this.triggerPrice++;
                        });
                        return;
                    }
                    // eslint-disable-next-line vue/no-mutating-props
                    this.prices[this.item.id].price = newValue;
                    this.priceChanged = true;
                }
            },
            priceLabel: function () {
                if (this.taskType === taskTypes.STOCK_LOADING) {
                    return this.$t('products.buy_price');
                } else if ([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET].includes(this.taskType)) {
                    return this.$t('products.sell_price');
                }
                return '';
            }
        },
        methods: {
            updatePrice: function () {
                EventBus.$emit('update-price', {
                    itemId: this.item.priceId || this.item.id,
                    price: Number.parseFloat(this.prices[this.item.id].price),
                    vat: this.prices[this.item.id].vat
                }, () => {
                    this.priceChanged = false;
                });
            }
        }
    };
</script>

<style scoped lang="sass">
.priceInput
  max-width: 9em

.vatSelect
  max-width: 6em
</style>
