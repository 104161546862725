<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedProduct !== null"
      :show.sync="confirmDialog"
      :text="deletedProduct.name + ', ' + deletedProduct.model + ', ' + deletedProduct.manufacturer.name"
      @confirm-delete="reallyDeleteProduct"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :item-class="item => item.visible === false && 'text--disabled'"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :search-hint="$t('products.searchHint')"
      >
        <template #item.tableImage="{ item }">
          <td>
            <v-img
              v-if="item.default_image !== null"
              :src="item.default_image"
              contain
              max-height="40px"
              max-width="40px"
              style="float: right"
            />
          </td>
        </template>
        <template #item.can_have_batch="{ item }">
          <span
            :class="{
              'text--disabled': !item.can_have_batch
            }"
          >
            {{ item.can_have_batch | yesOrNo }}
          </span>
        </template>
        <template #item.can_have_serial_number="{ item }">
          <span
            :class="{
              'text--disabled': !item.can_have_serial_number
            }"
          >
            {{ item.can_have_serial_number | yesOrNo }}
          </span>
        </template>
        <template #item.created_at="{ item }">
          <DateTimeWithTooltip
            :date-time="item.created_at"
          />
        </template>
        <template #footer>
          <TableAddItemButton
            :to="productCreateLink"
            label="products.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="productCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {APIFilters} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {productTable} from "@/app/products/definitions/product.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";

    export default {
        name: "ProductsList",
        components: {DateTimeWithTooltip, TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            headers: [],
            items: [],
            productCreateLink: '/products/create',
            confirmDialog: false,
            deletedProduct: null,
            reload: 0
        }),
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/products/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        routerLink: item => '/products/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        routerLink: item => '/status?productId=' + item.id,
                        icon: '$stockStatus',
                        label: 'products.pieces.status'
                    }, {
                        routerLink: item => '/movements?productId=' + item.id,
                        icon: '$stockMovements',
                        label: 'products.pieces.movements'
                    }, {
                        action: this.deleteItem,
                        icon: '$deleteItemForever',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return ProductAPI.getAll.bind(ProductAPI, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return ProductAPI.getAllPages.bind(ProductAPI);
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders(productTable, 'products.');
        },
        methods: {
            deleteItem: function (item) {
                this.deletedProduct = item;
                this.confirmDialog = true;
            },
            reallyDeleteProduct: function () {
                this.loading = true;
                this.confirmDialog = false;
                ProductAPI.delete(this.deletedProduct.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.delete.done',
                            params: [this.deletedProduct.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.reload++;
                        this.loading = false;
                    });
            },
        }
    // TODO display more details
    };
</script>

<style scoped>

</style>
