<template>
  <div>
    <x-toolbar />
    <v-container>
      <v-card
        class="mb-2"
      >
        <v-container>
          <FormFields
            :form="form"
            :render="formRender"
            lang-path="stocks.stockChoose.form."
          />
        </v-container>
      </v-card>
      <v-card v-if="form.stockId && form.subStockId">
        <x-data-table
          :headers="headers"
          :actions="actions"
          :items.sync="items"
          :loading.sync="loading"
          :api-data-source="apiDataSource"
          :api-data-source-all-pages="apiDataSourceAllPages"
          :reload="reload"
          :search-hint="$t('base.shipping.report.table.searchHint')"
        >
          <template #item.external_order_task_id="{ item }">
            <span @click.stop>
              <router-link
                v-if="item.external_order_task_id"
                :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_task_id"
              >
                #{{ item.external_order_task_id }}
              </router-link>
            </span>
          </template>
          <template #item.external_order_states="{ item }">
            <v-chip
              v-for="state of item.external_order_states"
              :key="state"
              label
              outlined
              class="ma-1"
            >
              {{ $t('tasks.externalOrder.state.' + state) }}
            </v-chip>
          </template>
          <template #item.buyer_name="{ item }">
            <span @click.stop>
              <router-link
                :to="'/buyers/' + item.buyer_id"
              >
                {{ item.buyer_name }}
              </router-link>
            </span>
          </template>
          <template #item.eshop_created_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.eshop_created_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.wms_created_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.wms_created_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.work_started_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.work_started_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.work_ended_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.work_ended_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.carrier_accepted_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.carrier_accepted_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.carrier_delivered_at="{ item }">
            <DateTimeWithTooltip
              :date-time="item.carrier_delivered_at"
              :abs-date-first="true"
            />
          </template>
          <template #item.shipment_state="{ item }">
            {{ $t('base.shipping.shipment.state.' + item.shipment_state) }}
          </template>
        </x-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import FormFields from "@/app/components/form/FormFields.component";
    import {StocksRender} from "@/app/overview/definitions/overview.render.form";
    import {ShippingReportForm} from "@/app/overview/shipping/definitions/shippingReport.form";
    import {ShipmentState} from "@/enum/shipment_state";
    import {TableFilter} from "@/enum/table_filter";
    import {createHeaders} from "@/utils/table";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";

    export default {
        name: "ShippingReport",
        components: {DateTimeWithTooltip, FormFields},
        mixins: [FormAutocompleteMixin],
        data: () => ({
            form: new ShippingReportForm,
            formRender: new StocksRender(true, true),
            loading: false,
            items: [],
            reload: 0,
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            apiDataSource: function () {
                return ShipmentAPI.getReport.bind(ShipmentAPI, this.form.subStockId);
            },
            apiDataSourceAllPages: function () {
                return ShipmentAPI.getReportAllPages.bind(ShipmentAPI, this.form.subStockId);
            },
            formUpdated: function () {
                this.form.stockId;
                this.form.subStockId;
                return Date.now();
            },
            actions: function () {
                return [{
                    condition: item => item.tracking_link !== null,
                    action: this.trackShipment,
                    icon: '$track',
                    label: 'base.shipping.shipment.track'
                }];
            },
            headers: function () {
                return createHeaders({
                    external_order_task_id: {
                        filterType: TableFilter.TEXT
                    },
                    external_order_states: {
                        sortable: false,
                    },
                    order_id: {
                        filterType: TableFilter.TEXT
                    },
                    buyer_name: {
                        filterType: TableFilter.TEXT
                    },
                    shipment_state: {
                        filterType: TableFilter.SELECT_MULTIPLE,
                        filterItems: Object.values(ShipmentState).map(state => ({
                            text: this.$t('base.shipping.shipment.state.' + state),
                            value: state
                        })),
                    },
                    eshop_created_at: {
                        filterType: TableFilter.DATETIME
                    },
                    wms_created_at: {
                        filterType: TableFilter.DATETIME
                    },
                    work_started_at: {
                        filterType: TableFilter.DATETIME
                    },
                    work_ended_at: {
                        filterType: TableFilter.DATETIME
                    },
                    carrier_accepted_at: {
                        filterType: TableFilter.DATETIME
                    },
                    carrier_delivered_at: {
                        filterType: TableFilter.DATETIME
                    }
                }, 'base.shipping.report.table.');
            }
        },
        watch: {
            'form.stockId': function () {
                if (this.form.stockId) {
                    this.fetchSubStocks();
                }
            },
            formUpdated: function () {
                this.reload++;
            },
        },
        methods: {
            trackShipment: function (item) {
                window.open(item.tracking_link, "_blank");
            }
        }
    };
</script>

<style scoped>

</style>
