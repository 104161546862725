import {Roles} from "@/enum/roles";

export default {
    title: "Uživatelé",
    link: "Uživatelé",

    detail: "Detail uživatele",

    username: "Uživatelské jméno",
    email: "E-mail",
    first_name: "Jméno",
    last_name: "Příjmení",
    password: "Heslo",
    phone: "Telefon",
    active: "Aktivní",
    roles: "Role",
    is_storekeeper: "Je skladník",
    is_chief: "Je správce skladu",
    is_packer: "Je balič",

    role: {
        [Roles.STOREKEEPER]: 'Skladník',
        [Roles.CHIEF]: 'Vedoucí',
        [Roles.PACKER]: 'Balič'
    },

    list: {
        unableToLoad: "Seznam uživatelů nebylo možné načíst. Zkuste to prosím znovu později."
    },
    create: {
        title: "Vytvořit uživatele",
        label: "Vytvořit nového uživatele",
        done: "Uživatel '{0}' vytvořen.",
        undo: {
            done: "Tvorba '{0}' úspěšně vrácena!",
            failed: "Nezdařilo se vrátit tvorbu uživatele!"
        }
    },
    update: {
        titleSimple: "Upravit uživatele",
        title: "Upravit {0}",
        done: "Uživatel '{0}' upraven",
        password: {
            password: "Nové heslo",
            done: "Heslo uživatele {0} změněno!"
        },
        undo: {
            done: "Úpravy '{0}' úspěšně vráceny!",
            failed: "Nezdařilo se vrátit úpravu uživatele!"
        }
    },

    chooseOneRole: "Zvolte prosím alespoň jednu roli!",
    isNotChief: "Vytvořený uživatel nemá roli vedoucího.",
    changePassword: "Změna hesla",
    cannotChangePasswordOfAnotherChief: "Nemůžete změnit heslo jinému vedoucímu, pouze skladníkům a baličům.",

    permissions: {
        title: "Přístup k podskladům",
        info: "Toto nastavení omezí úkoly, které se uživateli zobrazí. Má-li uživatel roli vedoucího skladu, může si sám sobě libovolně nastavit, které podsklady chce spravovat.",
        hasAccess: "Má přístup",
        addAccess: "Umožnit přístup k tomuto podskladu",
        removeAccess: "Odebrat přístup k tomuto podskladu"
    },

    settings: {
        status: {
            403: "K cizímu nastavení nemáte přístup!",
            404: "Nastavení uživatele nenalezeno!",
            409: "Toto nastavení již existuje!"
        }
    },

    status: {
        404: "Zadaný uživatel neexistuje!",
        409: "Vyplněné uživatelské jméno již má jiný uživatel!"
    }
};
