import {Roles} from "@/enum/roles";

export default {
    title: "Užívatelia",
    link: "Užívatelia",

    detail: "Detail užívateľa",

    username: "Užívateľské meno",
    email: "E-mail",
    first_name: "Meno",
    last_name: "Priezvisko",
    password: "Heslo",
    phone: "Telefón",
    active: "Aktívny",
    roles: "Rola",
    is_storekeeper: "Je skladník",
    is_chief: "Je správca skladu",
    is_packer: "Je balič",

    role: {
        [Roles.STOREKEEPER]: 'Skladník',
        [Roles.CHIEF]: 'Vedúci',
        [Roles.PACKER]: 'Balič'
    },

    list: {
        unableToLoad: "Zoznam užívateľov nebolo možné načítať. Skúste to prosím opäť neskôr."
    },
    create: {
        title: "Vytvoriť užívateľa",
        label: "Vytvoriť nového užívateľa",
        done: "Užívateľ '{0}' vytvorený.",
        undo: {
            done: "Tvorba '{0}' úspešne zrušená!",
            failed: "Nepodarilo sa zrušiť tvorbu užívateľa!"
        }
    },
    update: {
        titleSimple: "Upraviť užívateľa",
        title: "Upraviť {0}",
        done: "Užívateľ '{0}' upravený",
        password: {
            password: "Nové heslo",
            done: "Heslo užívateľa {0} zmenené!"
        },
        undo: {
            done: "Úpravy '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť úpravu užívateľa!"
        }
    },

    chooseOneRole: "Zvoľte prosím aspoň jednu rolu!",
    isNotChief: "Vytvorený užívateľ nemá rolu vedúceho.",
    changePassword: "Zmena hesla",
    cannotChangePasswordOfAnotherChief: "Nemôžete zmeniť heslo inému vedúcemu, iba skladníkom a baličom.",

    permissions: {
        title: "Prístup k podskladom",
        info: "Toto nastavenie obmedzí úlohy, ktoré sa užívateľovi zobrazia. Ak má užívateľ rolu vedúceho skladu, môže si sám sebe ľubovoľne nastaviť, ktoré podsklady chce spravovať.",
        hasAccess: "Má prístup",
        addAccess: "Umožniť prístup k tomuto podskladu",
        removeAccess: "Odobrať prístup k tomuto podskladu"
    },

    settings: {
        status: {
            403: "K nastaveniam tohto užívateľa nemáte prístup!",
            404: "Nastavenia užívateľa nenájdené!",
            409: "Tieto nastavenia už existujú!"
        }
    },

    status: {
        404: "Zadaný užívateľ neexistuje!",
        409: "Zvolené užívateľské meno už má iný užvateľ!"
    }
};
