import {assign, has} from "@/utils/object";

/**
 * Walks through **sorted (higher ID first!)** arrays and updates target with source values, leaving additional target properties untouched.
 * Source is always right:
 * - if item is missing in the source, remove it from target,
 * - if item from source in not in the target, add it.
 * @param target {Array}
 * @param source {Array}
 * @param id sorting and comparing parameter
 */
function update(target, source, id = 'id') {
    let targetIdx = 0;
    let sourceIdx = 0;
    while (sourceIdx < source.length || targetIdx < target.length) {
        if (sourceIdx >= source.length) {
            target.splice(targetIdx, 1);
        } else if (targetIdx >= target.length) {
            target.push(source[sourceIdx]);
            targetIdx++;
            sourceIdx++;
        } else if (target[targetIdx][id] > source[sourceIdx][id]) {
            target.splice(targetIdx, 1);
        } else if (target[targetIdx][id] < source[sourceIdx][id]) {
            target.splice(targetIdx, 0, source[sourceIdx]);
            targetIdx++;
            sourceIdx++;
        } else if (target[targetIdx][id] === source[sourceIdx][id]) {
            assign(target[targetIdx], source[sourceIdx]);
            targetIdx++;
            sourceIdx++;
        }
    }
}

/**
 * For each source entry, merges all records with the same values in `groupByPath`, accumulating property specified by `accumulate`.
 * Returns brand new array, shorter or same size of the source.
 * @param source {Array}
 * @param groupByPath {string[]}
 * @param accumulate {string}
 * @returns {Array}
 */
function mapReduce(source, groupByPath, accumulate) {
    return Object.values(source.reduce((acc, curr) => {
        let groupBy = curr;
        for (const property of groupByPath) {
            groupBy = groupBy[property];
        }
        if (has(acc, groupBy)) {
            acc[groupBy][accumulate] += curr[accumulate];
        } else {
            acc[groupBy] = curr;
        }
        return acc;
    }, {}));
}

export {update, mapReduce};
