export default {
    tasks: "Tasks",
    noTasks: "No tasks!",
    running: "Tasks in progress assigned to me",
    storekeepersRunning: "Workers' tasks in progress",
    assigned: "New tasks assigned to me",
    free: "Free tasks",
    allTasks: "All free tasks",
    allDone: "No tasks to show!",
    overview: "Overview",
    toApprove: "Waiting for approval",
    toPack: "To be packed",
    status: "Status",
    manage: "Manage",
    done: "Finished",
    noDone: "No finished tasks!",
    config: {
        title: "Homepage configuration",
        config: "List configuration",
        label: "Label",
        addTaskList: "Add task list",
        addOrdersStats: "Add orders overview",
        newList: "New list",
        all: "all",
        export: {
            title: "Export",
            done: "Homepage exported."
        },
        import: {
            title: "Import",
            done: "Homapage imported.",
            fail: "Homepage import failed!"
        },
        reset: "Restore default lists",
        resetDone: "Default list setting restored",
        showOn: "Show on",
        desktop: "computer",
        mobile: "mobile",
        showOnDesktop: "Show on computer",
        showOnMobile: "Show on mobile",
        autoRefresh: "Auto-refresh",
        autoRefreshValue: "Refresh every {0} seconds",
        unableToLoad: "Unable to load homepage configuration. Please try again later.",
        unableToSave: "Unable to save configuration!",
        arrayDateConflict: "Selection of multiple dates is not supported. Please use a range instead.",
        onlySubstocks: "Only sub-warehouses"
    },
    stats: {
        orders: {
            label: 'Order states',
            unprocessed: 'new',
            toBePicked: 'waiting to be picked',
            beingPicked: 'being picked',
            toBePacked: 'waiting to be packed',
            beingPacked: 'being packed',
            packed: 'packed',
            inShipping: 'shipping',
            returning: 'returning',
            delivered: 'delivered'
        }
    }
};
