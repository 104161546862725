<template>
  <v-list-item>
    <v-btn-toggle
      v-model="lang"
      mandatory
      class="mr-3"
    >
      <v-btn
        v-for="langName of langNames"
        :key="langName"
        text
      >
        {{ langName.toUpperCase() }}
      </v-btn>
    </v-btn-toggle>
    {{ $t('base.lang') }}
  </v-list-item>
</template>

<script>

    export default {
        name: "ToolbarChangeLang",
        data: () => ({
            lang: null,
            langNames: [
                'en',
                'cs',
                'sk',
                'hu'
            ]
        }),
        watch: {
            lang: function (newVal, oldVal) {
                if (oldVal === null) {
                    return;
                }
                const newLang = this.langNames[this.lang];
                this.$store.dispatch('userConfig/set', {
                    key: 'lang',
                    value: newLang
                });
            },
        },
        created: function () {
            const index = this.langNames.indexOf(this.$i18n.locale);
            this.lang = index !== -1 ? index : 0;
        }
    };
</script>

<style scoped>

</style>
