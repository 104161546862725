import {InstanceIdRender, ProductIdRender, StocksRender} from "@/app/overview/definitions/overview.render.form";
import i18n from "@/service/lang/i18n";

function StockMovementsForm() {
    return {
        stockId: null,
        subStockId: null,
        productId: null,
        instanceId: null
    };
}

function StockMovementsRender() {
    return {
        ...(new StocksRender(true, true, false)),
        productId: {
            ...(new ProductIdRender),
            icon: '$productInstance',
            prepend: {
                text: i18n.t('stocks.stockMovements.allProducts'),
                value: null
            }
        },
        instanceId: {
            ...(new InstanceIdRender),
            icon: 'closed_caption'
        }
    };
}

export {StockMovementsForm, StockMovementsRender};
