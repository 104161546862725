export default {
    required: "Toto pole je vyžadované.",
    maxLen: "Maximálna povolená dĺžka je {0} znakov",
    minLen: "Minimálna požadovaná dĺžka je {0} znakov",
    pattern: "Vstup nespĺňa požadovaný formát",
    positiveInteger: "Vyplňte celé kladné číslo",
    range: "Musí byť medzi {0} a {1}",
    save: "Uložiť",
    send: "Odoslať",
    generate: "Generovať",
    download: "Stiahnuť",
    notFilled: "Nevyplnené"
};
