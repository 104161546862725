var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-toolbar'),_c('v-card',{staticClass:"d-flex flex-column"},[(_vm.showFilters)?_c('div',{staticClass:"ml-6 mt-4"},[_c('Alert',{staticClass:"mr-4",attrs:{"show-alert":!_vm.filterValid,"display-text":_vm.$t('base.filterConfig.invalid'),"type":'warning'}}),_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('base.filterConfig.filter'))+": ")]),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.filterLabel(_vm.langPath, _vm.apiFilter, _vm.renderValues),staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.filterLabel(_vm.langPath, _vm.apiFilter, _vm.renderValues))+" ")])]),_c('div',{staticClass:"d-flex overflow-y-auto"},[_c('GeneralConfigFilterComponent',{staticClass:"mt-3",attrs:{"filter":_vm.apiFilter,"possible-values":_vm.possibleValues,"lang-path":"tasks.filter."}})],1)],1):_vm._e(),_c('v-container',[_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload,"custom-filters":true,"search-hint":_vm.$t('tasks.searchHint')},on:{"update:loading":function($event){_vm.loading=$event},"update:items":function($event){_vm.items=$event},"toggleFilters":_vm.toggleFilters},scopedSlots:_vm._u([{key:"item.task",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.taskIcons[item.type])+" ")]),_vm._v(" #"+_vm._s(item.id)+" "+_vm._s(' ' + _vm.$t(_vm.taskNames[item.type]))+" ")],1)]}},{key:"item.parent_task_id",fn:function(ref){
var item = ref.item;
return [(item.parent_task_id)?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":'/task/' + item.parent_task_id}},[_vm._v(" #"+_vm._s(item.parent_task_id)+" ")])],1):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("taskState")(item.state))+" ")]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('div',{class:'p' + item.priority},[_vm._v(" "+_vm._s(_vm._f("taskPriority")(item.priority))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.updated_at))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }