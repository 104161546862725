import {Roles} from "@/enum/roles";

export default {
    title: "Users",
    link: "Users",

    detail: "User details",

    username: "Username",
    email: "E-mail",
    first_name: "Name",
    last_name: "Surname",
    password: "Password",
    phone: "Phone",
    active: "Active",
    roles: "Roles",
    is_storekeeper: "Worker",
    is_chief: "Manager",
    is_packer: "Packer",

    role: {
        [Roles.STOREKEEPER]: 'Worker',
        [Roles.CHIEF]: 'Manager',
        [Roles.PACKER]: 'Packer'
    },

    list: {
        unableToLoad: "Unable to load user list. Please try again later."
    },
    create: {
        title: "Create user",
        label: "Create new user",
        done: "User '{0}' created.",
        undo: {
            done: "Creation of '{0}' successfully canceled!",
            failed: "Failed to undo user creation!"
        }
    },
    update: {
        titleSimple: "Update user",
        title: "Update {0}",
        done: "User '{0}' updated.",
        password: {
            password: "New password",
            done: "{0}'s password has been changed!"
        },
        undo: {
            done: "Update of '{0}' successfully canceled!",
            failed: "Failed to undo user update!"
        }
    },

    chooseOneRole: "Please select at least one role!",
    isNotChief: "The created user is not a manager.",
    changePassword: "Password change",
    cannotChangePasswordOfAnotherChief: "You cannot change password of another chief, only passwords of workers and packers",

    permissions: {
        title: "Sub-warehouse access",
        info: "This affects which tasks will be visible to user. User with Manager role can edit his access rights without limits according to which sub-warehouses he/she wants to manage.",
        hasAccess: "Has access",
        addAccess: "Allow access to this sub-warehouse",
        removeAccess: "Remove access to this sub-warehouse"
    },

    settings: {
        status: {
            403: "You do not have access to this user's settings!",
            404: "User settings not found!",
            409: "This setting already exists!"
        }
    },

    status: {
        404: "User does not exist!",
        409: "Username is already in use!"
    }
};
