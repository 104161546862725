<template>
  <div>
    <template v-if="loading">
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <v-container
        style="max-height: 40vh; overflow-y: scroll"
      >
        <v-row>
          <v-col
            v-for="header in headers"
            :key="header.value"
            class="text-caption"
          >
            {{ header.text }}
          </v-col>
        </v-row>
        
        <template
          v-for="shipment in shipmentsLocal"
        >
          <v-row :key="'d-' + shipment.id">
            <v-divider />
          </v-row>
          <v-row
            :key="shipment.id"
          >
            <v-col
              v-for="header in headers"
              :key="shipment.id + '_' + header.value"
            >
              <template v-if="header.value === 'last_protocol_created_at'">
                <DateTimeWithTooltip
                  :date-time="shipment.last_protocol_created_at"
                  :abs-date-first="true"
                />
              </template>
              <template v-else-if="header.value === 'external_order_external_order_id'">
                <span @click.stop>
                  <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + shipment.external_order_id">
                    {{ shipment.external_order_external_order_id }}
                  </router-link>
                </span>
              </template>
              <template v-else-if="header.value === 'external_order_id'">
                <span @click.stop>
                  <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + shipment.external_order_id">
                    #{{ shipment.external_order_id }}
                  </router-link>
                </span>
              </template>
              <template v-else-if="header.value === 'stock_picking_id'">
                <span @click.stop>
                  <router-link :to="taskLinks[taskTypes.STOCK_PICKING] + '/' + shipment.stock_picking_id">
                    #{{ shipment.stock_picking_id }}
                  </router-link>
                </span>
              </template>
              <template v-else-if="header.value === 'buyer.name'">
                <span @click.stop>
                  <router-link :to="'/buyers/' + shipment.buyer.id">
                    {{ shipment.buyer.name }}
                  </router-link>
                </span>
              </template>
              <template v-else>
                {{ header.itemLabel(shipment) }}
              </template>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </template>
  </div>
</template>

<script>
    import {APIFilters, APIFilterOP} from "@/service/APIFilters";
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import {createHeaders} from "@/utils/table";
    import {shippingTable} from "@/app/overview/shipping/definitions/shipping.form";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";

    export default {
        name: "BasicShipmentsList",
        components: {DateTimeWithTooltip},
        props: {
            shipmentIds: {
                type: Array,
                default: () => []
            },
            shipments: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            fetchedShipments: [],
            loading: false,
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            headers: function () {
                return createHeaders(shippingTable(true), 'base.shipping.table.', false);    
            },
            shipmentsLocal: function () {
                return this.shipments && this.shipments.length ? this.shipments : this.fetchedShipments;
            }
        },
        watch: {
            shipmentIds: {
                immediate: true,
                deep: true,
                handler: function () {
                    if (this.shipments && this.shipments.length) {
                        return;
                    }
                    this.loading = true;
                    ShipmentAPI.getAllPages({
                        filter: APIFilters.makeFilter({
                            [APIFilterOP.IN]: {
                                id: this.shipmentIds
                            }
                        })
                    }).then(response => this.fetchedShipments = response.data.items)
                        .catch(this.snack)
                        .finally(() => this.loading = false);
                }
            }
        }
    };
</script>

<style scoped>

</style>