const TaskState = {
    CREATED: 1,
    IN_PROGRESS: 2,
    WAITING_FOR_APPROVAL: 3,
    CLOSED: 4,
    CANCELED: 5,
    UNASSIGNED: 6
};

const ExternalOrderState = {
    EO_STATE_CREATED: "EO_STATE_CREATED",
    EO_STATE_UNABLE_TO_PROCESS: "EO_STATE_UNABLE_TO_PROCESS",
    EO_STATE_UNABLE_TO_SATISFY: "EO_STATE_UNABLE_TO_SATISFY",
    EO_STATE_IN_PROGRESS: "EO_STATE_IN_PROGRESS",
    EO_STATE_STOCK_PICKING_WAITING: "EO_STATE_STOCK_PICKING_WAITING",
    EO_STATE_STOCK_PICKING_IN_PROGRESS: "EO_STATE_STOCK_PICKING_IN_PROGRESS",
    EO_STATE_PREPARE_PACKAGE_WAITING: "EO_STATE_PREPARE_PACKAGE_WAITING",
    EO_STATE_PREPARE_PACKAGE_IN_PROGRESS: "EO_STATE_PREPARE_PACKAGE_IN_PROGRESS",
    EO_STATE_SHIPMENTS_WAITING: "EO_STATE_SHIPMENTS_WAITING",
    EO_STATE_SHIPPED: "EO_STATE_SHIPPED",
    EO_STATE_DELIVERED: "EO_STATE_DELIVERED",
    EO_STATE_RETURNING: "EO_STATE_RETURNING",
    EO_STATE_RETURNED: "EO_STATE_RETURNED",
    EO_STATE_CANCELLED: "EO_STATE_CANCELLED",
    EO_STATE_CLOSED: "EO_STATE_CLOSED"
};

export {TaskState, ExternalOrderState};
