<template>
  <span class="d-flex flex-wrap justify-center">
    <v-chip
      v-for="(item, index) of displayedItems"
      :key="item.id || index"
      label
      outlined
      class="ma-1"
      :class="chipClass"
      @click="onChipClick(item)"
    >
      <slot :item="item" />
    </v-chip>
    <v-chip
      v-if="hasHidden && !!hiddenCount"
      label
      outlined
      class="ma-1"
    >
      <span class="font-italic">
        ... + {{ hiddenCount }}
      </span>
    </v-chip>
    <v-chip
      v-if="hasHidden"
      label
      outlined
      class="ma-1"
      @click.stop="toggleShowAll"
    >
      <v-icon :left="!isSmall">
        {{ showAll ? '$showLess' : '$showMore' }}
      </v-icon>
      <strong v-if="!isSmall">
        {{ showAll ? $t('base.hideAll') : $t('base.showAll') }}
      </strong>
    </v-chip>
    <v-bottom-sheet
      v-if="$scopedSlots.bottomsheet"
      v-model="sheetModel"
      scrollable
    >
      <v-sheet v-if="selectedItem !== null">
        <slot
          name="bottomsheet"
          :item="selectedItem"
        />
      </v-sheet>
    </v-bottom-sheet>
  </span>
</template>

<script>
    const HARD_LIMIT = 100;

    export default {
        name: "WrappedChipList",
        props: {
            inline: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
            chipClass: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            showAll: false,
            sheetModel: false,
            selectedItem: null
        }),
        computed: {
            isSmall: function () {
                return this.$vuetify.breakpoint.xs;
            },
            displayCount: function () {
                const {xs, xl} = this.$vuetify.breakpoint;
                if (this.inline) {
                    return xs ? 1 : (xl ? 4 : 2);
                } else {
                    return xs ? 2 : (xl ? 20 : 5);
                }
            },
            displayedItems: function () {
                if (this.showAll) {
                    return this.items.slice(0, HARD_LIMIT);
                } else {
                    return this.items.slice(0, this.displayCount);
                }
            },
            hasHidden: function () {
                return this.items.length > this.displayCount;
            },
            hiddenCount: function () {
                return Math.max(this.items.length - (this.showAll ? HARD_LIMIT : this.displayCount), 0);
            }
        },
        watch: {
            sheetModel: function (newValue) {
                if (!newValue) {
                    this.selectedItem = null;
                }
            }
        },
        methods: {
            toggleShowAll: function () {
                this.showAll = !this.showAll;
            },
            onChipClick: function (item) {
                this.selectedItem = item;
                this.sheetModel = true;
            }
        }
    };
</script>

<style scoped>

</style>
