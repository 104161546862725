export default {
    required: "This field is required.",
    maxLen: "Maximum length is {0} characters",
    minLen: "Minimum length is {0} characters",
    pattern: "Input does not match required form",
    positiveInteger: "Value must be a positive integer",
    range: "Must be between {0} and {1}",
    save: "Save",
    send: "Send",
    generate: "Generate",
    download: "Download",
    notFilled: "Not filled"
};
