import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const TaskPreparePackageAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/prepare-package',
    LANG: 'tasks.preparePackage',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',
    DOMAIN_ORDERS: 'orders',
    LANG_PACKING: 'packing',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    getAllOrders(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ORDERS],
            this.LANG_TASKS
        );
    },

    getOrder(taskId, orderId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ORDERS, orderId],
            this.LANG_TASKS
        );
    },

    getOrderItems(taskId, orderId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ORDERS, orderId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    markItemAsPacked(taskId, itemId, order_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'mark-as-packed'],
            {
                order_id: order_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_PACKING]
        );
    },

    markItemAsUnpacked(taskId, itemId, order_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, 'mark-as-unpacked'],
            {
                order_id: order_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_PACKING]
        );
    },

    printInvoice(taskId, orderId, amount = 1) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ORDERS, orderId, 'print-invoice'],
            {amount: amount},
            this.LANG
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'assign'],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, 'unassign'],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, 'finish'],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },
};

export {TaskPreparePackageAPI};
