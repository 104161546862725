import {StockAPI} from "@/api/StockAPI";

/**
 * Requires:
 * - this.formRender[...targetProperties]
 * - this.form.stockId
 */
const FormAutocompleteMixin = {
    methods: {
        fetchSubStocks: function (targetProperties = ['subStockId'], includeAll = false) {
            targetProperties.forEach(property => this.setItemsLoading(property, true));
            StockAPI.getAllSubstockPages(this.form.stockId)
                .then(response => {
                    const items = response.data.items.map(substock => ({
                        text: substock.name,
                        value: substock.id
                    }));
                    if (includeAll && items.length > 1) {
                        items.unshift({
                            text: this.$t('stocks.stockStatus.allSubstocks'),
                            value: null
                        });
                    }
                    targetProperties.forEach(property => {
                        this.$set(this.formRender[property].autocomplete, 'items', items);
                    });
                }).catch(this.snack)
                .finally(() => {
                    targetProperties.forEach(property => this.setItemsLoading(property, false));
                });
        },
        setItemsLoading: function (property, isLoading) {
            this.$set(this.formRender[property], 'loading', isLoading);
        }
    }
};

export {FormAutocompleteMixin};
