import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const UserAPI =  {

    API: BaseAPI,
    DOMAIN: 'users',
    DOMAIN_SETTINGS: 'settings',

    getAll() {
        return this.API.get(this.DOMAIN);
    },

    get(userId) {
        return APIHelper.getWithCache({
            collection: CachePath.users,
            key: userId,
            requestParams: [[this.DOMAIN, userId]]
        });
    },

    getChiefs() {
        return this.API.get([this.DOMAIN, 'chiefs']);
    },

    getStorekeepers() {
        return this.API.get([this.DOMAIN, 'storekeepers']);
    },

    getPackers() {
        return this.API.get([this.DOMAIN, 'packers']);
    },

    create(data) {
        return this.API.post(this.DOMAIN, data);
    },

    update(userId, data) {
        return this.API.patch(
            [this.DOMAIN, userId],
            data
        );
    },

    updatePassword(userId, password) {
        return this.API.post([this.DOMAIN, userId, 'change-password'], {
            password: password
        });
    },

    // Settings

    getAllSettings(userId) {
        return this.API.get([this.DOMAIN, userId, this.DOMAIN_SETTINGS]);
    },

    getSetting(userId, key) {
        return this.API.get([this.DOMAIN, userId, this.DOMAIN_SETTINGS, key]);
    },

    createSetting(userId, key, value) {
        return this.API.post(
            [this.DOMAIN, userId, this.DOMAIN_SETTINGS],
            {key, value}
        );
    },

    updateSetting(userId, key, value) {
        return this.API.put(
            [this.DOMAIN, userId, this.DOMAIN_SETTINGS, key],
            {value}
        );
    },

    deleteSetting(userId, key) {
        return this.API.delete([this.DOMAIN, userId, this.DOMAIN_SETTINGS, key]);
    },

    // Permissions

    getPermissions(userId) {
        return this.API.get([this.DOMAIN, userId, 'permissions']);
    },

    setPermissions(userId, permissions) {
        return this.API.put(
            [this.DOMAIN, userId, 'permissions'],
            {permissions: permissions}
        );
    }
};

export {UserAPI};
