<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <v-autocomplete
      v-show="hardcodedAttribute === null"
      outlined
      dense
      hide-details
      :label="$t(langPath + 'chooseAttr')"
      :value="attr"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
    <v-autocomplete
      v-if="Array.isArray(possibleValues[attr].values)"
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      multiple
      chips
      small-chips
      hide-details
      :items="possibleValues[attr].values"
    />
    <v-alert
      v-else-if="possibleValues[attr].type === APIFilterDataType.DATE"
      type="error"
      text
      dense
      class="ml-1"
    >
      {{ $t('homepage.config.arrayDateConflict') }}
    </v-alert>
    <v-combobox
      v-else
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      multiple
      chips
      small-chips
      deletable-chips
      clearable
      hide-details
      append-icon=""
      :label="$t('base.filterConfig.fill')"
      :type="possibleValues[attr].type"
    />
  </div>
</template>

<script>
    import {APIFilterMixin} from "@/app/mixins/APIFilterMixin";
    import {APIFilterDataType} from "@/service/APIFilters";

    export default {
        name: "FilterConfigArrayOp",
        mixins: [APIFilterMixin],
        data: () => ({
            APIFilterDataType: APIFilterDataType
        }),
        computed: {
            attr: function () {
                return Object.keys(this.filter[this.operator])[0];
            }
        },
        methods: {
            propertyChanged: function (newValue) {
                const oldValue = this.attr;
                delete this.filter[this.operator][oldValue];
                this.$set(this.filter[this.operator], newValue, []);
            }
        }
    };
</script>

<style scoped>

</style>
